import {Box, Button, Typography} from '@mui/material';
import {useAuth} from '../../AuthContext/AuthContext';
import Lottie from '../Fragments/Lottie';

export default function StyledPage() {
	const {loggedInUser} = useAuth();

	return (
		<Box bgcolor='#E8EFFF' minHeight='100vh' width='100%' display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' overflow='hidden'>
			<Lottie url='https://assets6.lottiefiles.com/packages/lf20_i9al2qxo.json' width='500px' height='300px' />
			<Typography fontFamily='Poppins' fontWeight={600} variant='h3' gutterBottom>
				Something went wrong
			</Typography>
			<Box display='flex' gap='20%'>
				<Button size='small' variant='contained' sx={{textTransform: 'none', width: 150}} onClick={() => window.location.reload()}>
					Reload page
				</Button>
				<Button size='small' variant='outlined' LinkComponent='a' href={loggedInUser ? '/Dashboard' : '/'} sx={{textTransform: 'none', width: 200}}>
					{loggedInUser ? 'Back to Dashboard' : 'Back to Home'}
				</Button>
			</Box>
		</Box>
	);
}
