import {Button, Dialog, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useSnackbar} from 'notistack';
import {useCallback, useState} from 'react';
import {useAuth} from '../../../../AuthContext/AuthContext';
import {TextEditor} from '../../../Tiptap/Editor';
import firebase from 'firebase';
import axios from 'axios';
import Env from '../../../../util/Env';
import {Cancel, Edit} from '@mui/icons-material';
import {useRealtime} from '../../../../RealtimeContext/RealtimeContext';
import GenericCard from '../../../Fragments/GenericCard';
import StyledQuestionContent from '../../../Fragments/StyledQuestionContent';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../../ErrorBoundary/InternalErrorBoudaryPage';

const useStyles = makeStyles(theme => ({
	title: {
		'&::first-letter': {
			fontSize: '150%',
		},
	},
	content: {
		height: 'auto',
	},
	cardHeader: {
		paddingBottom: '0px !important',
	},
	cardContent: {
		paddingTop: '0px !important',
	},
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
	},
	cardActions: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function QuestionDialog({isUserQuestion, question, userId, currentQuiz, fetchUserQuestions, handleClose}) {
	const classes = useStyles();

	const {loggedInUser, token} = useAuth();

	const ref = firebase.app('linearPad').database().ref();
	const roomRef = ref.child(loggedInUser.uid);
	const currentQuizRef = roomRef.child('currentQuiz');
	const {realtime, setRealtime} = useRealtime();
	const {enqueueSnackbar} = useSnackbar();

	const [editMode, setEditMode] = useState(false);
	const [editedTitle, setEditedTitle] = useState('');
	const [editedProblem, setEditedProblem] = useState('');
	const [editedTestCases, setEditedTestCases] = useState('');
	const [titleError, setTitleError] = useState(false);
	const [chars, setChars] = useState(0);
	const [words, setWords] = useState(0);
	const [isContentBugged, setIsContentBugged] = useState(false);

	const handleStartQuiz = () => {
		currentQuizRef.set(question);
		setRealtime({...realtime, currentQuiz: question});
	};
	const handleStopQuiz = () => {
		currentQuizRef.set('');
		setRealtime({...realtime, currentQuiz: ''});
	};

	const handleEnableEditMode = () => {
		setEditedTitle(question.title);
		setEditedProblem(question.problem);
		setEditedTestCases(question.testCases);
		setEditMode(true);
	};

	const handleChangeTitle = useCallback(e => {
		if (!e.target.value) setTitleError(true);
		else setTitleError(false);
		setEditedTitle(e.target.value);
	}, []);

	const Actions = () =>
		isUserQuestion && editMode ? (
			<>
				<Button variant='contained' color='primary' size='small' className={classes.button} disabled={titleError} onClick={handleSaveEdits}>
					Save
				</Button>
				{isUserQuestion && editMode && <Box>{chars !== 0 && <Typography color='#AAA'>{`${chars} ${chars > 1 ? 'Characters' : 'Character'} / ${words} ${words > 1 ? 'Words' : 'Word'}`}</Typography>}</Box>}
				<Button variant='outlined' size='small' className={classes.button} onClick={() => setEditMode(false)} endIcon={<Cancel />}>
					Cancel
				</Button>
			</>
		) : (
			<>
				{currentQuiz && currentQuiz.id === question.id ? (
					<Button size='small' color='error' className={classes.button} variant='outlined' onClick={handleStopQuiz}>
						Stop this quiz
					</Button>
				) : (
					<Button size='small' color='success' className={classes.button} variant='outlined' onClick={handleStartQuiz}>
						Start this quiz
					</Button>
				)}
				{isUserQuestion && (
					<Button variant='outlined' size='small' className={classes.button} onClick={handleEnableEditMode} endIcon={<Edit />}>
						Edit
					</Button>
				)}
			</>
		);

	const handleSaveEdits = useCallback(() => {
		let tempQuestion;
		tempQuestion = question;
		delete tempQuestion.creationTime;
		delete tempQuestion.refreshTime;
		delete tempQuestion.updateTime;
		tempQuestion.title = editedTitle;
		tempQuestion.problem = editedProblem;
		tempQuestion.testCases = editedTestCases;

		axios
			.post(Env().url + '/questions', tempQuestion, {
				headers: {Authorization: 'Bearer ' + token},
			})
			.then(res => {
				enqueueSnackbar('question is updated!', {variant: 'success'});
				editMode && setEditMode(false);
				fetchUserQuestions();
			})
			.catch(err => {
				enqueueSnackbar("couldn't update question!", {variant: 'error'});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question, editedProblem, editedTitle, editedTestCases, token, userId]);

	return (
		<>
			<Dialog open={true} maxWidth='md' fullWidth scroll='paper' sx={{overflow: 'auto'}}>
				<GenericCard
					title={
						<Typography variant='h6' className={!editMode ? classes.title : ''} align='center' fontFamily='Fredoka' fontWeight={600}>
							{!editMode ? question.title : 'Edit Question'}
						</Typography>
					}
					handleClose={handleClose}
					actions={<Actions />}
				>
					<Box className={classes.content}>
						{isUserQuestion && editMode ? (
							<>
								<TextField size='small' fullWidth variant='outlined' label='Title' error={titleError} value={editedTitle} onChange={handleChangeTitle} sx={{mt: 1}} />
								<Typography fontSize={12} color='#aaa'>
									Problem
								</Typography>
								<Box border='1px solid #EEE' pl={1} pr={1} pb={1} mb={1} height={300} maxHeight={400} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
									<TextEditor notes={editedProblem} setNotes={setEditedProblem} setCharacters={setChars} setWords={setWords} editable={true} />
								</Box>
								<Typography fontSize={12} color='#aaa'>
									Test cases
								</Typography>
								<Box border='1px solid #EEE' pl={1} pr={1} pb={1} height={250} maxHeight={250} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
									<TextEditor notes={editedTestCases} setNotes={setEditedTestCases} setCharacters={setChars} setWords={setWords} editable={true} />
								</Box>
							</>
						) : (
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsContentBugged(false)} resetKeys={[isContentBugged]}>
								<StyledQuestionContent problem={question.problem} testCases={question.testCases} />
							</ErrorBoundary>
						)}
					</Box>
				</GenericCard>
			</Dialog>
		</>
	);
}
