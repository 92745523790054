import MuiPopper from '@mui/material/Popper';
import {styled} from '@mui/material/styles';

export const Popper = styled(MuiPopper, {
	shouldForwardProp: prop => prop !== 'arrow',
})(({theme, arrow}) => ({
	zIndex: 9999,
	'& > div': {
		position: 'relative',
	},
	'&[data-popper-placement*="bottom"]': {
		'& > div': {
			marginTop: arrow ? 2 : 0,
		},
		'& .MuiPopper-arrow': {
			top: 0,
			left: 0,
			marginTop: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent #fff transparent`,
			},
		},
	},
	'&[data-popper-placement*="top"]': {
		'& > div': {
			marginBottom: arrow ? 2 : 0,
		},
		'& .MuiPopper-arrow': {
			bottom: 0,
			left: 0,
			marginBottom: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '1em 1em 0 1em',
				borderColor: `#fff transparent transparent transparent`,
			},
		},
	},
	'&[data-popper-placement*="right"]': {
		'& > div': {
			marginLeft: arrow ? 2 : 0,
		},
		'& .MuiPopper-arrow': {
			left: 0,
			marginLeft: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 1em 1em 0',
				borderColor: `transparent #fff transparent transparent`,
			},
		},
	},
	'&[data-popper-placement*="left"]': {
		'& > div': {
			marginRight: arrow ? 2 : 0,
		},
		'& .MuiPopper-arrow': {
			right: 0,
			marginRight: '-0.9em',
			height: '3em',
			width: '1em',
			'&::before': {
				borderWidth: '1em 0 1em 1em',
				borderColor: `transparent transparent transparent #fff`,
			},
		},
	},
}));

export const Arrow = styled('div')({
	zIndex: 10,
	position: 'absolute',
	fontSize: 7,
	width: '3em',
	height: '3em',
	'&::before': {
		content: '""',
		margin: 'auto',
		display: 'block',
		width: 0,
		height: 0,
		borderStyle: 'solid',
	},
});
