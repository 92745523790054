import {FileDownload, Upload} from '@mui/icons-material';
import {CircularProgress, ClickAwayListener, List, ListItemButton, Typography} from '@mui/material';
import {useState} from 'react';
import {Arrow, Popper} from '../../../common/PopperFragments';
import DrawingsPopper from './DrawingsPopper';
import SavePopper from './SavePopper';

export default function OptionsButtonPopper({
	onClose,
	anchor,
	placement,
	arrow,
	handleSave,
	handleLoad,
	saveLoading,
	loadLoading,
	loadButton,
	openSavePopper,
	savePopperAnchor,
	handleCloseSavePopper,
	excaliRef,
	openDrawingsPopper,
	drawingsPopperAnchor,
	handleCloseDrawingsPopper,
}) {
	const [arrowRef, setArrowRef] = useState();
	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
				sx={{background: '#fff'}}
			>
				{openSavePopper && <SavePopper anchor={savePopperAnchor} arrow={true} onClose={handleCloseSavePopper} placement='left' excaliRef={excaliRef} />}
				{openDrawingsPopper && <DrawingsPopper anchor={drawingsPopperAnchor} arrow={true} onClose={handleCloseDrawingsPopper} placement='left' excaliRef={excaliRef} />}
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<List sx={{boxShadow: 1, borderRadius: 1, p: 1}}>
					<ListItemButton onClick={handleSave} dense disabled={saveLoading || loadLoading}>
						{saveLoading ? <CircularProgress size={20} /> : <Upload color='primary' />}
						<Typography ml={2}>Save</Typography>
					</ListItemButton>
					{loadButton && (
						<ListItemButton onClick={handleLoad} dense>
							{loadLoading ? <CircularProgress size={20} /> : <FileDownload color='primary' />}
							<Typography ml={2}>Load</Typography>
						</ListItemButton>
					)}
				</List>
			</Popper>
		</ClickAwayListener>
	);
}
