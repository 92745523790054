import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../../AuthContext/AuthContext';
import {useAccount} from '../../AccountContext/AccountContext';
import {Box} from '@mui/system';
import {Container, Skeleton} from '@mui/material';

export default function PrivateRoute({component: Component, ...rest}) {
	const {loggedInUser} = useAuth();
	const {planPermissions, planLoading} = useAccount();
	const route = rest.path.split('/').pop().toLowerCase();

	return !planLoading || planPermissions ? (
		planPermissions ? (
			<Route
				{...rest}
				render={props => {
					return loggedInUser ? (
						planPermissions.permissions && (planPermissions.permissions.includes('routes') || planPermissions.permissions.includes(`routes.${route}`)) ? (
							<Component {...props} />
						) : (
							<Redirect to={{pathname: '/404', state: {message: "you aren't authorized to access this page"}}} />
						)
					) : (
						<Redirect to={{pathname: '/', state: {login: true}}} />
					);
				}}
			></Route>
		) : null
	) : (
		<Container maxWidth='lg'>
			<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={1}>
				<Box width='30%' display='flex' flexDirection='column' gap={1} alignItems='center' borderRadius={2} overflow='hidden' height={350} boxShadow={2}>
					<Skeleton height={250} width='100%' variant='rectangular' />
					<Skeleton width='50%' variant='text' />
					<Skeleton width='50%' variant='text' />
				</Box>
				<Box width='65%' display='flex' flexDirection='column' gap={1} alignItems='center' borderRadius={2} overflow='hidden' height={350} boxShadow={2}>
					<Skeleton height={250} width='100%' variant='rectangular' />
					<Skeleton width='50%' variant='text' />
				</Box>
				<Box width='47%' display='flex' flexDirection='column' gap={1} alignItems='center' borderRadius={2} overflow='hidden' height={350} boxShadow={2} mt={2}>
					<Skeleton height={250} width='100%' variant='rectangular' />
					<Skeleton width='50%' variant='text' />
				</Box>
				<Box width='47%' display='flex' flexDirection='column' gap={1} alignItems='center' borderRadius={2} overflow='hidden' height={350} boxShadow={2} mt={2}>
					<Skeleton height={250} width='100%' variant='rectangular' />
					<Skeleton width='50%' variant='text' />
					<Skeleton width='50%' variant='text' />
				</Box>
			</Box>
		</Container>
	);
}
