import {Assignment, MoreHoriz} from '@mui/icons-material';
import {Alert, Avatar, Button, IconButton, Skeleton, TablePagination, Typography} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import {makeStyles} from '@mui/styles';
import {Box, styled} from '@mui/system';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import {useAuth} from '../../../../AuthContext/AuthContext';
import {stringToColor} from '../../../../util/commonFunctions';
import Env from '../../../../util/Env';
import SharesPopper from '../../../Fragments/SharesPopper';
import OptionsMenu from './OptionsMenu';
import QuestionDialog from './QuestionDialog';
import useAxios from 'axios-hooks';

const useStyles = makeStyles(() => ({
	hoveredRow: {
		background: grey[50],
		cursor: 'pointer',
		transition: '0.2s ease-in-out',
	},
	card: {
		border: '1px solid #ccc',
		borderRadius: '10px !important',
	},
	actions: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		textTransform: 'none !important',
	},
	shareCell: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	nameCell: {
		display: 'flex',
	},
}));

const grey = {
	50: '#F3F6F9',
	100: '#E7EBF0',
	200: '#E0E3E7',
	300: '#CDD2D7',
	400: '#B2BAC2',
	500: '#A0AAB4',
	600: '#6F7E8C',
	700: '#3E5060',
	800: '#2D3843',
	900: '#1A2027',
};

const Root = styled('div')(
	({theme}) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
    }
    `
);

export default function SharedQuestions({userId, currentQuiz, fetchUserQuestions, setTabsValue, fetchSharedQuestionsData, sharedQuestionsData, sharedQuestionsDataLoading, sharedQuestionsDataFetchError, onApplyFetchQuestions, questionsFetch}) {
	const classes = useStyles();
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [, deleteShareUser] = useAxios({
		method: 'DELETE',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [page, setPage] = useState(0);
	const [cloneLoading, setCloneLoading] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [hoveredRow, setHoveredRow] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [openSharesPopper, setOpenSharesPopper] = useState(false);
	const [anchorSharesPopper, setAnchorSharesPopper] = useState('');
	const [hoveredUsers, setHoveredUsers] = useState([]);
	const [emails, setEmails] = useState({});
	const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
	const [anchorOptionsMenu, setAnchorOptionsMenu] = useState('');
	const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState('');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [revokeLoading, setRevokeLoading] = useState(false);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sharedQuestionsData?.rows?.length) : 0;

	const handleSetCurrentQuestion = useCallback(
		question => {
			setCurrentQuestion(question);
		},
		[setCurrentQuestion]
	);

	const handleChangePage = useCallback((event, newPage) => {
		setPage(newPage);
	}, []);

	const handleChangeRowsPerPage = useCallback(event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}, []);

	const handleClone = () => {
		setCloneLoading(true);
		let tempQuestion = {...currentQuestion, category: 'none'};
		axios
			.post(Env().url + '/questions', tempQuestion, {
				headers: {Authorization: 'Bearer ' + token},
			})
			.then(res => {
				setCloneLoading(false);
				setOpenOptionsMenu(false);
				fetchUserQuestions();
				setTabsValue(0);
				enqueueSnackbar('question is cloned!', {variant: 'success'});
			})
			.catch(err => {
				setCloneLoading(false);
				enqueueSnackbar("couldn't add question", {variant: 'error'});
			});
	};
	const handleOpenQuestionDialog = question => {
		handleSetCurrentQuestion(question);
		setOpenQuestionDialog(true);
	};
	const handleOpenSheresPopper = (e, users) => {
		e.stopPropagation();
		e.preventDefault();
		let ownerUser = {email: emails[users[0].ownerId]};
		setHoveredUsers([ownerUser]);
		setAnchorSharesPopper(e.currentTarget);
		setOpenSharesPopper(true);
	};
	const handleCloseSheresPopper = useCallback(e => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorSharesPopper('');
		setOpenSharesPopper(false);
	}, []);

	const handleOpenOptionsMenu = (e, question, row, index) => {
		setSelectedRow(row);
		setCurrentIndex(index + 1);
		handleSetCurrentQuestion(question);
		setOpenOptionsMenu(true);
		setAnchorOptionsMenu(e.currentTarget);
	};
	const handleCloseOptionsMenu = () => {
		setSelectedRow('');
		setCurrentIndex(0);
		handleSetCurrentQuestion('');
		setOpenOptionsMenu(false);
		setAnchorOptionsMenu('');
	};
	const handleRevoke = () => {
		setRevokeLoading(true);
		let resource = selectedRow.share[currentIndex - 1];
		deleteShareUser({url: Env().url + '/usersaccess/rbac/deleteResource/' + resource.id})
			.then(() => {
				setRevokeLoading(false);
				enqueueSnackbar('Share is revoked!', {variant: 'success'});
				if (selectedRow.share.length === 1) {
					setTabsValue(0);
				}
				fetchSharedQuestionsData();
				fetchUserQuestions();
			})
			.catch(err => {
				setRevokeLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};
	const getUserEmailByUid = id => {
		axios.get(Env().url + '/usersaccess/getUserEmail/' + id, {headers: {Authorization: 'Bearer ' + token}}).then(res => {
			setEmails(emails => ({...emails, [id]: res.data}));
		});
	};

	useEffect(() => {
		sharedQuestionsData.rows.forEach(question => {
			getUserEmailByUid(question.share[0].ownerId);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sharedQuestionsData]);

	return (
		<>
			{openSharesPopper && <SharesPopper placement='left' arrow={true} users={hoveredUsers} anchor={anchorSharesPopper} />}
			{openOptionsMenu && <OptionsMenu revokeLoading={revokeLoading} anchorEl={anchorOptionsMenu} onClose={handleCloseOptionsMenu} isUserQuestions={false} handleRevoke={handleRevoke} handleClone={handleClone} cloneLoading={cloneLoading} />}
			{openQuestionDialog && <QuestionDialog isUserQuestion={false} question={currentQuestion} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchSharedQuestionsData} handleClose={() => setOpenQuestionDialog(false)} />}
			<Root sx={{maxWidth: '100%'}}>
				<table>
					<thead>
						<tr>
							<th>Title</th>
						</tr>
					</thead>
					<tbody>
						{!sharedQuestionsDataLoading || sharedQuestionsData ? (
							sharedQuestionsDataFetchError ? (
								<td colSpan={4}>
									<Alert
										severity='error'
										action={
											<Button color='inherit' size='small' sx={{textTransform: 'none'}} onClick={() => window.location.reload()}>
												Reload
											</Button>
										}
									>
										Something went wrong in fetching data
									</Alert>
								</td>
							) : sharedQuestionsData?.rows?.length === 0 || emptyRows > 0 ? (
								<td colSpan={4}>
									<Typography align='center'>No questions</Typography>
								</td>
							) : (
								(rowsPerPage > 0 ? sharedQuestionsData?.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sharedQuestionsData?.rows)?.map((question, index) => (
									<tr key={question?.question?.id} onMouseEnter={() => setHoveredRow(index + 1)} onMouseLeave={() => setHoveredRow(0)} onClick={() => handleOpenQuestionDialog(question.question)} className={hoveredRow === index + 1 ? classes.hoveredRow : ''}>
										<td>
											<Box display='flex' alignItems='center' justifyContent='space-between'>
												<Box display='flex' alignItems='center' gap={1}>
													<Assignment fontSize='small' color='primary' />
													<Box display='flex' flexDirection='column'>
														{question.question && question.question.title}
														{question.question && (
															<Typography fontSize={10} marginTop='-2px' color='#1776D2'>
																{new Date(question.question.utime).getDate() +
																	'/' +
																	(new Date(question.question.utime).getMonth() + 1) +
																	'/' +
																	new Date(question.question.utime).getFullYear() +
																	' ' +
																	new Date(question.question.utime).getHours().toString().padStart(2, '0') +
																	':' +
																	new Date(question.question.utime).getMinutes().toString().padStart(2, '0') +
																	':' +
																	new Date(question.question.utime).getSeconds().toString().padStart(2, '0')}
															</Typography>
														)}
													</Box>
												</Box>
												<Box display='flex' alignItems='center' gap={2}>
													{question.share?.length > 0 && (
														<Box onMouseEnter={e => handleOpenSheresPopper(e, question.share)} onMouseLeave={handleCloseSheresPopper}>
															<Avatar title={emails[question.share[0].ownerId]} sx={{background: Object.keys(emails).length > 0 && stringToColor(emails[question.share[0].ownerId]?.split('@')[0]), width: 20, height: 20}} variant='circular'>
																{emails[question.share[0].ownerId]?.toUpperCase().charAt(0)}
															</Avatar>
														</Box>
													)}
													<IconButton
														size='small'
														sx={{width: 20, height: 20}}
														color='primary'
														onClick={e => {
															e.stopPropagation();
															handleOpenOptionsMenu(e, question.question, question, index);
														}}
													>
														<MoreHoriz fontSize='small' sx={{fontSize: 20}} />
													</IconButton>
												</Box>
											</Box>
										</td>
									</tr>
								))
							)
						) : (
							Array.from(new Array(3)).map((_, index) => (
								<tr key={index}>
									<td colSpan={1}>
										<Skeleton height={20} width='100%' animation='wave' variant='rectangular' />
									</td>
								</tr>
							))
						)}
					</tbody>
					<tfoot>
						<tr>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
								colSpan={4}
								count={sharedQuestionsData?.rows?.length || 0}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</tr>
					</tfoot>
				</table>
			</Root>
		</>
	);
}
