import {Close} from '@mui/icons-material';
import {Alert, IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Box} from '@mui/system';

export default function AlertBox({content, ext, expiryDate, handleClose}) {
	const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};
	const theme = useTheme();
	const isNotTablet = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<Alert
			severity={ext && JSON.parse(ext).type}
			action={
				<Box display='flex' alignItems='center' gap={2}>
					{isNotTablet && (
						<Typography fontSize={12} color='#aaa'>
							expires on: {new Date(JSON.parse(expiryDate)).toLocaleString('en-EN', dateOptions)}
						</Typography>
					)}
					<IconButton size='small' onClick={handleClose} color='inherit'>
						<Close fontSize='small' />
					</IconButton>
				</Box>
			}
			sx={{maxHeight: 50, overflow: 'auto'}}
		>
			<Box dangerouslySetInnerHTML={{__html: content}} />
		</Alert>
	);
}
