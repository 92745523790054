import {Button} from '@mui/material';
import {Box} from '@mui/system';
import {useSnackbar} from 'notistack';
import firebase from 'firebase';

export default function FooterUiCandidate({excaliRef, roomId}) {
	const roomRef = firebase.app('linearPad').database().ref().child(roomId);

	const {enqueueSnackbar} = useSnackbar();

	const handleSaveToRealtimeDB = () => {
		roomRef.child('RealtimeDraw').set({elements: excaliRef.current.getSceneElements(), state: excaliRef.current.getAppState()});
		enqueueSnackbar('saved!', {variant: 'success'});
	};
	const handleLoadFromRealtimeDB = () => {
		roomRef
			.child('RealtimeDraw')
			.get()
			.then(snapshot => {
				excaliRef?.current?.updateScene(snapshot.val());
			});
	};
	return (
		<>
			<Box display='flex' gap={1} flexWrap='wrap'>
				<Button onClick={handleSaveToRealtimeDB} size='small' variant='contained' color='primary' sx={{textTransform: 'none', color: '#0e0e0e'}}>
					Save to RTDB
				</Button>
				<Button onClick={handleLoadFromRealtimeDB} size='small' variant='contained' color='primary' sx={{textTransform: 'none', color: '#0e0e0e'}}>
					Load from RTDB
				</Button>
			</Box>
		</>
	);
}
