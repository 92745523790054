import {Box} from '@mui/system';
import {Divider, Typography, InputAdornment, OutlinedInput} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {memo, useCallback, useEffect, useState} from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactCodeMirror from '@uiw/react-codemirror';
import axios from 'axios';
import useAxios from 'axios-hooks';
import {makeStyles} from '@mui/styles';
import Env from '../../util/Env';
import {useAuth} from '../../AuthContext/AuthContext';
import {connect} from 'react-redux';
import {languages} from '../../util/languages';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles(() => ({
	progress: {
		width: '20px !important',
		height: '20px !important',
		cursor: 'progress',
	},
	codemirrorError: {
		color: '#d11a2a',
	},
}));

function OutputSection({isRunning, input, language: currentLanguage, handleRun, onApplyPushToAlerts}) {
	const classes = useStyles();
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [, getOutput] = useAxios({method: 'GET'}, {manual: true});

	const [error, setError] = useState(false);
	const [content, setContent] = useState('Press run to see the output... ');
	const [loading, setLoading] = useState(false);

	function recursionOutput(result) {
		getOutput({
			url: Env().url + '/examples/submissions/' + result,
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
			.then(res => {
				if (res.data.status.description === 'In Queue' || res.data.status.description === 'Processing') {
					setTimeout(() => {
						return recursionOutput(result);
					}, 500);
				} else if (res.data.status.description === 'Compilation Error') {
					setError(true);
					setLoading(false);
					handleRun(false);
					setContent('Compilation Error!');
				} else if (res.data.stderr) {
					setError(true);
					setLoading(false);
					handleRun(false);
					setContent(res.data.stderr);
				} else {
					setLoading(false);
					setContent((res.data.stdout ? res.data.stdout + '\n' : '') + 'executed in ' + res.data.time + ' second');
					handleRun(false);
					return;
				}
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
				setLoading(false);
				handleRun(false);
				setContent('Press run to see the output... ');
			});
	}
	const handleClick = useCallback(() => {
		setError(false);
		setLoading(true);
		let value = window.ace.edit('firepad-ace-container').getValue().trim();
		if (!value) {
			setLoading(false);
			setContent('Nothing.');
		} else {
			onApplyPushToAlerts({
				content: <Typography>Code is running...</Typography>,
				type: 'info',
			});
			setContent('loading...');
			try {
				let judgeLanguage = languages.find(language => language.value === currentLanguage);
				axios
					.post(
						Env().url + '/examples/submissions',
						{
							source_code: window.ace.edit('firepad-ace-container').getValue(),
							language_id: judgeLanguage.id,
							stdin: input,
						},
						{
							headers: {
								Authorization: 'Bearer ' + token,
							},
						}
					)
					.then(res => {
						if (res) {
							recursionOutput(res.data.token);
						} else {
							setLoading(false);
							setError(true);
							setContent('Something went wrong!');
							handleRun(false);
						}
					})
					.catch(err => {
						setError(true);
						setLoading(false);
						setContent(err.message);
						handleRun(false);
					});
			} catch (err) {
				setError(true);
				setLoading(false);
				setContent(err.message);
				handleRun(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLanguage, input, token]);

	useEffect(() => {
		if (isRunning) handleClick();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRunning]);

	return (
		<Box width='100%' sx={{overflowX: 'auto'}}>
			<Box display='flex' alignItems='center' pl={1} gap={1} bgcolor='#EEE' id='OutputContainer'>
				<Typography variant='h6' color='#1776D2'>
					Output
				</Typography>
				<OutlinedInput
					disabled
					sx={{width: '50%', cursor: loading ? 'progress' : 'auto'}}
					size='small'
					value={currentLanguage}
					endAdornment={
						<>
							<InputAdornment position='end'>
								{loading ? (
									<CircularProgress className={classes.progress} />
								) : (
									<Box display='flex' flexDirection='column' justifyContent='center'>
										<PlayArrowIcon
											onClick={() => handleRun(true)}
											sx={{
												cursor: 'pointer',
											}}
											color='success'
											fontSize='medium'
										/>
									</Box>
								)}
							</InputAdornment>
						</>
					}
					onChange={() => null}
					onClick={() => null}
				/>
			</Box>
			<Divider />
			<ReactCodeMirror editable={false} value={content} className={error && classes.codemirrorError} />
		</Box>
	);
}

const mapDispatchToProps = dispatch => ({
	onApplyPushToAlerts: alert => dispatch({type: 'ALERTS_PUSH', alert}),
});

export default connect(null, mapDispatchToProps)(memo(OutputSection));
