import {useState} from 'react';
import {Button, Checkbox, ClickAwayListener, List, ListItemButton, Paper, Typography} from '@mui/material';
import {Arrow, Popper} from '../../common/PopperFragments';

export default function CopyTimesPopper({onConfirm, onClose, anchor, placement, arrow, confirm, dayIndex}) {
	const [arrowRef, setArrowRef] = useState(null);

	const [selectAll, setSelectAll] = useState(false);
	const [weekDays, setWeekDays] = useState([
		{name: 'Sunday', checked: false},
		{name: 'Monday', checked: false},
		{name: 'Tuesday', checked: false},
		{name: 'Wednesday', checked: false},
		{name: 'Thursday', checked: false},
		{name: 'Friday', checked: false},
		{name: 'Saturday', checked: false},
	]);

	const handleSelectCheckAll = () => {
		let tempDays = [...weekDays];
		tempDays.forEach((day, index) => {
			if (index !== dayIndex) tempDays[index] = {...day, checked: !selectAll};
		});
		setWeekDays(tempDays);
		setSelectAll(!selectAll);
	};

	const handleCheck = (index, day) => {
		let tempDays = [...weekDays];
		tempDays[index] = {...day, checked: !day.checked};
		setWeekDays(tempDays);
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Paper elevation={5} sx={{borderRadius: '10px', padding: 1}}>
					<Typography sx={{userSelect: 'none'}} color='#AAA' fontSize={10} p={0.5}>
						Copy times to days ...
					</Typography>
					<List dense disablePadding>
						<ListItemButton onClick={handleSelectCheckAll} sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '10px'}}>
							<Typography>Select All</Typography>
							<Checkbox size='small' checked={selectAll} />
						</ListItemButton>
						{weekDays.map((day, index) => (
							<ListItemButton key={index} onClick={() => handleCheck(index, day)} disabled={index === dayIndex} sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '10px'}}>
								<Typography>{day.name}</Typography>
								<Checkbox size='small' checked={index === dayIndex || day.checked} />
							</ListItemButton>
						))}
					</List>
					<Button fullWidth color='primary' size='small' variant='contained' onClick={() => onConfirm(dayIndex, weekDays)} sx={{fontFamily: 'Fredoka', textTransform: 'none', borderRadius: '10px'}}>
						{confirm}
					</Button>
				</Paper>
			</Popper>
		</ClickAwayListener>
	);
}
