import {Avatar, Card, Checkbox, Container, Grid, List, ListItemButton, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {ScheduleMeeting, timeSlotDifference} from 'react-schedule-meeting';
import {Box} from '@mui/system';
import {useParams} from 'react-router-dom';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {makeStyles} from '@mui/styles';
import Env from '../../../util/Env';
import useAxios from 'axios-hooks';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../ErrorBoundary/InternalErrorBoudaryPage';
import TimezoneSelect from 'react-timezone-select';
import ReactCornerRibbon from './CornerRibbon';
import ConfirmationCard from './ConfirmationCard';
import {convertFromUTCStringToZone, convertFromZoneToUTCString} from '../../../util/commonFunctions';
import moment from 'moment-timezone';

const useStyles = makeStyles(() => ({
	timeZone: {
		zIndex: '99 !important',
		fontFamily: 'Fredoka',
	},
}));

export default function NewReservation() {
	const {userId, username} = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [{data: availability}] = useAxios({url: Env().url + '/userschedule/availability/' + userId});
	const [{data: reservations}, fetchReservations] = useAxios({url: Env().url + '/userschedule/reservation/' + userId});

	const availableTimeSlotsTemp = [
		{
			startTime: moment('2023-05-16T09:00:00.000Z').tz('America/Los_Angeles').format(),
			endTime: moment('2023-05-16T17:00:00.000Z').tz('America/Los_Angeles').format(),
		},
	];

	const unavailableTimeSlots = [
		{
			startTime: moment('2023-05-16T09:15:00.000Z').tz('America/Los_Angeles').format(),
			endTime: moment('2023-05-16T09:45:00.000Z').tz('America/Los_Angeles').format(),
		},
		{
			startTime: moment('2023-05-16T11:00:00.000Z').tz('America/Los_Angeles').format(),
			endTime: moment('2023-05-16T11:30:00.000Z').tz('America/Los_Angeles').format(),
		},
	];
	console.log(availableTimeSlotsTemp);
	console.log(unavailableTimeSlots);

	// const availableTimeSlotsLessUnavailableTimeSlots = timeSlotDifference(availableTimeSlotsTemp, unavailableTimeSlots);

	// console.log(availableTimeSlotsLessUnavailableTimeSlots);

	// const [availableTimeslots, setAvailableTimeSlots] = useState([]);
	const [isDialogBugged, setIsDialogBugged] = useState(false);
	const [currentReservation, setCurrentReservation] = useState('');
	const [selectDurationDisable, setSelectDurationDisable] = useState(false);
	// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
	const [confirmation, setConfirmation] = useState(false);
	const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
	const [durations, setDurations] = useState();
	const [selectedDuration, setSelectedDuration] = useState(0);

	const handleSelectDuration = index => {
		setSelectedDuration(index);
	};
	const handleChangeTimeZone = value => {
		setTimeZone(value);
	};

	const handleCompleteReservation = timeslot => {
		let scheduleComp = document.getElementById('ScheduleComp');
		scheduleComp.style.pointerEvents = 'none';
		setTimeout(() => {
			isMobile && document.getElementById('confirm').scrollIntoView({behavior: 'smooth'});
		}, 100);

		setCurrentReservation(timeslot);
		setConfirmation(true);
		setSelectDurationDisable(true);
		// setOpenDialog(true);
	};
	useEffect(() => {
		if (availability && reservations) {
			const reservationsMap = {};
			let timeZoneStr = typeof timeZone === 'object' ? timeZone.value : timeZone;
			reservations.forEach(reserv => {
				let key = new Date(convertFromUTCStringToZone(reserv.startTime, timeZoneStr)).toLocaleString('en-EN', {day: '2-digit', month: '2-digit', year: 'numeric'});
				if (reservationsMap[key]) {
					reservationsMap[key].push({startTime: convertFromUTCStringToZone(reserv.startTime, timeZoneStr), endTime: convertFromUTCStringToZone(reserv.endTime, timeZoneStr)});
				} else {
					reservationsMap[key] = [{startTime: convertFromUTCStringToZone(reserv.startTime, timeZoneStr), endTime: convertFromUTCStringToZone(reserv.endTime, timeZoneStr)}];
				}
			});

			const parsedAvailabilityDateRange = JSON.parse(availability.availabilityDateRange);
			const parsedAvailability = JSON.parse(availability.availability).availability;
			const countOfDays = Math.ceil((new Date(parsedAvailabilityDateRange.endDate) - new Date(parsedAvailabilityDateRange.startDate)) / (1000 * 60 * 60 * 24)) + 1;
			var startDiff = Math.floor((new Date(parsedAvailabilityDateRange.startDate) - new Date()) / (1000 * 60 * 60 * 24));
			startDiff = startDiff >= 0 ? startDiff : 0;
			var availableTimeSlots = [];

			let time = new Date();
			let timeToUtc = convertFromZoneToUTCString(time, Intl.DateTimeFormat().resolvedOptions().timeZone);
			let zonedTime = convertFromUTCStringToZone(timeToUtc, timeZoneStr);
			Array(countOfDays)
				.fill()
				.map((_, idx) => {
					let dayIndex = (new Date(zonedTime).getDay() + startDiff + idx) % 7;
					if (parsedAvailability[dayIndex][dayIndex].checked) {
						return startDiff + idx;
					}
					return false;
				})
				.forEach(id => {
					if (id || id === 0) {
						let currentDate = new Date(new Date(zonedTime).setDate(new Date(zonedTime).getDate() + id));
						let dayIndex = new Date(currentDate).getDay();
						let dayList = [];
						parsedAvailability[dayIndex][dayIndex].intervals.forEach(interval => {
							let from = convertFromUTCStringToZone(interval.from, timeZoneStr);
							let to = convertFromUTCStringToZone(interval.to, timeZoneStr);
							let startTime = new Date(new Date(new Date(zonedTime).setDate(new Date(zonedTime).getDate() + id)).setHours(new Date(from).getHours(), new Date(from).getMinutes(), 0, 0));
							let endTime = new Date(new Date(new Date(zonedTime).setDate(new Date(zonedTime).getDate() + id)).setHours(new Date(to).getHours(), new Date(to).getMinutes(), 0, 0));
							dayList = [...dayList, {id, startTime, endTime}];
						});
						let currentDayDateString = new Date(new Date(zonedTime).setDate(new Date(zonedTime).getDate() + id)).toLocaleString('en-EN', {day: '2-digit', month: '2-digit', year: 'numeric'});
						if (reservationsMap[currentDayDateString]) {
							var availableTimeSlotsLessUnavailableTimeSlots = timeSlotDifference(dayList, reservationsMap[currentDayDateString]);
							dayList = availableTimeSlotsLessUnavailableTimeSlots;
						}
						availableTimeSlots = availableTimeSlots.concat(dayList);
					}
				});

			setDurations(JSON.parse(availability.availability).durations.durations);
			console.log(availableTimeSlots);
			setAvailableTimeSlots(availableTimeSlots);
		}
	}, [availability, reservations, timeZone]);

	useEffect(() => {
		if (availability) setTimeZone(JSON.parse(availability.timeZone));
	}, [availability]);

	return (
		<Container maxWidth='xl' sx={{mt: 4, paddingLeft: isMobile ? '4px' : '', paddingRight: isMobile ? '4px' : ''}}>
			<Grid container direction='column' spacing={2} justifyContent='center'>
				<Grid item container xs={12} justifyContent='stretch'>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Card sx={{position: 'relative', margin: isMobile ? '8px 8px' : '16px 16px', padding: 2, borderRadius: '15px', overflow: 'visible', boxShadow: 3}}>
							<ReactCornerRibbon />
							<Grid container direction='row' justifyContent='space-between'>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<Box display='flex' alignItems='center' gap={2} padding={0.5}>
										<Avatar sx={{width: 40, height: 40}} src={'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media'} />
										<Box display='flex' alignItems='center' gap={0.5}>
											<Typography fontSize={20}>{username}</Typography>
										</Box>
									</Box>
									<Box mt={1} mb={2}>
										<Typography fontSize={12} color='#555'>
											Time zone
										</Typography>
										<TimezoneSelect value={timeZone} onChange={handleChangeTimeZone} className={classes.timeZone} />
									</Box>
								</Grid>
								{durations ? (
									<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
										<Typography fontSize={12} color='#555'>
											Meeting length
										</Typography>

										<List dense disablePadding component={Stack} direction='column'>
											{durations?.map(
												(duration, index) =>
													duration.checked && (
														<ListItemButton dense key={index} sx={{display: 'flex', padding: '0px 2px', borderRadius: '5px', gap: 1}} onClick={() => handleSelectDuration(index)} disabled={selectDurationDisable}>
															<Checkbox checked={selectedDuration === index} icon={<CircleOutlinedIcon />} checkedIcon={<CheckCircleIcon />} />
															<Typography fontFamily='Fredoka'>{duration.name}</Typography>
														</ListItemButton>
													)
											)}
										</List>
									</Grid>
								) : (
									<></>
								)}
							</Grid>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={confirmation ? 7 : 12}
						lg={confirmation ? 7 : 12}
						xl={confirmation ? 7 : 12}
						style={{
							transition: theme.transitions.create('all', {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
						}}
						id='ScheduleComp'
					>
						<ScheduleMeeting
							startTimeListStyle='scroll-list'
							borderRadius={15}
							primaryColor='#4ACF50'
							backgroundColor='#FFF'
							eventDurationInMinutes={durations && durations[selectedDuration]?.value}
							availableTimeslots={availableTimeSlots}
							onStartTimeSelect={handleCompleteReservation}
							emptyListContentEl={
								<Typography color='#bbb' fontFamily='Fredoka'>
									No times available
								</Typography>
							}
						/>
					</Grid>
					<Grid
						item
						zeroMinWidth={true}
						xs={confirmation ? 12 : true}
						sm={confirmation ? 12 : true}
						md={confirmation ? 5 : true}
						lg={confirmation ? 5 : true}
						xl={confirmation ? 5 : true}
						style={{
							transition: theme.transitions.create('all', {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
						}}
						id='confirm'
					>
						<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsDialogBugged(false)} resetKeys={[isDialogBugged]}>
							<Card sx={{display: confirmation ? '' : 'none', padding: 1, borderRadius: '15px', margin: isMobile ? '8px 8px' : '16px 16px', boxShadow: 3}}>
								<ConfirmationCard
									fetchReservations={fetchReservations}
									durations={durations}
									currentReservation={currentReservation}
									setCurrentReservation={setCurrentReservation}
									selectedDuration={selectedDuration}
									setConfirmation={setConfirmation}
									setSelectDurationDisable={setSelectDurationDisable}
									buttonColor='#4ACF50'
									borderRadius='15px'
									timeZone={timeZone}
								/>
							</Card>
						</ErrorBoundary>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}
