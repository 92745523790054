import {DragIndicator, MoreHoriz} from '@mui/icons-material';
import {Avatar, AvatarGroup, Box, Card, CardActions, Divider, IconButton, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {stringToColor} from '../../../../../util/commonFunctions';
import ShareDialog from '../../../../ShareDialog';
import OptionsMenu from '../OptionsMenu';
import QuestionDialog from '../QuestionDialog';
import SharesPopper from '../../../../Fragments/SharesPopper';
import ReactGA from 'react-ga';
import axios from 'axios';
import Env from '../../../../../util/Env';
import {useAuth} from '../../../../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import ConfirmationPopper from '../../../../Fragments/ConfirmationPopper';
import {useAccount} from '../../../../../AccountContext/AccountContext';

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		padding: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cardText: {
		display: 'flex',
		gap: 3,
		alignItems: 'center',
		marginLeft: theme.spacing(0.5),
	},
}));

export default function Question({category, question, index, userId, currentQuiz, fetchUserQuestions, fetchSharedQuestions}) {
	const classes = useStyles();
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const {planPermissions} = useAccount();

	const [openQuestionDialog, setOpenQuestionDialog] = React.useState(false);
	const [openShareDialog, setOpenShareDialog] = React.useState(false);
	const [openOptionsMenu, setOpenOptionsMenu] = React.useState(false);
	const [anchorOptionsMenu, setAnchorOptionsMenu] = React.useState('');
	const [openSharesPopper, setOpenSharesPopper] = React.useState(false);
	const [anchorSharesPopper, setAnchorSharesPopper] = React.useState('');
	const [openConfirmationPopper, setOpenConfirmationPopper] = React.useState(false);
	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = React.useState('');

	const handleOpenQuestionDialog = () => {
		setOpenQuestionDialog(true);
	};

	const handleOpenOptionsMenu = e => {
		e.stopPropagation();
		setAnchorOptionsMenu(e.currentTarget);
		setOpenOptionsMenu(true);
	};

	const handleCloseOptionsMenu = () => {
		setOpenOptionsMenu(false);
		setAnchorOptionsMenu(false);
	};

	const handleShare = () => {
		handleCloseOptionsMenu();
		setOpenShareDialog(true);
	};

	const handleOpenSharesPopper = e => {
		setAnchorSharesPopper(e.currentTarget);
		setOpenSharesPopper(true);
	};
	const handleCloseSharesPopper = () => {
		setOpenSharesPopper(false);
	};
	const handleDelete = e => {
		setAnchorConfirmationPopper(e.currentTarget);
		setOpenConfirmationPopper(true);
	};
	const handleCloseConfirmationPopper = () => {
		setOpenConfirmationPopper(false);
		setAnchorConfirmationPopper('');
	};

	const handleDeleteQuestion = React.useCallback(
		(e, id) => {
			e.stopPropagation();
			ReactGA.event({
				category: 'User Questions',
				action: 'delete question',
			});
			axios
				.delete(Env().url + '/questions/' + id, {
					headers: {Authorization: 'Bearer ' + token},
				})
				.then(res => {
					setOpenConfirmationPopper(false);
					enqueueSnackbar('question is deleted!', {
						variant: 'success',
					});
					fetchUserQuestions();
					handleCloseConfirmationPopper();
				})
				.catch(err => {
					handleCloseConfirmationPopper();
					enqueueSnackbar("couldn't deleted question!", {
						variant: 'error',
					});
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[question.id, token]
	);

	if (!question) return null;

	return (
		<>
			{openConfirmationPopper && (
				<ConfirmationPopper
					operation='Delete'
					name='Question'
					placement='bottom'
					arrow={true}
					anchor={anchorConfirmationPopper}
					cancel='cancel'
					confirm='Delete'
					message='Are you sure?'
					onCancel={handleCloseConfirmationPopper}
					onClose={handleCloseConfirmationPopper}
					onConfirm={e => handleDeleteQuestion(e, question.question.id)}
				/>
			)}
			{openSharesPopper && <SharesPopper anchor={anchorSharesPopper} arrow={true} placement='bottom' users={question.share} />}
			{openOptionsMenu && <OptionsMenu isUserQuestions={true} anchorEl={anchorOptionsMenu} onClose={handleCloseOptionsMenu} handleShare={handleShare} handleDelete={handleDelete} />}
			{openShareDialog && <ShareDialog fetchSharedQuestions={fetchSharedQuestions} question={question.question} isQuestion={true} isCandidate={false} handleClose={() => setOpenShareDialog(false)} isUserQuestion={true} />}
			{openQuestionDialog && <QuestionDialog isUserQuestion={true} question={question.question} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchUserQuestions} handleClose={() => setOpenQuestionDialog(false)} />}
			<Draggable draggableId={question.question.id} index={index} isDragDisabled={!(planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.drag')))}>
				{(provided, snapshot) => (
					<div className={classes.root} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} key={index}>
						<Card
							sx={{
								opacity: snapshot.isDragging ? 0.9 : 1,
								transform: snapshot.isDragging ? 'rotate(-2deg)' : '',
							}}
							elevation={snapshot.isDragging ? 3 : 1}
							onClick={() => handleOpenQuestionDialog(question.question)}
						>
							<Box className={classes.cardContent}>
								<Box className={classes.cardText}>
									<Avatar sx={{width: 25, height: 25, display: 'flex', alignItems: 'center', background: '#1776D2'}}>{category.charAt(0).toUpperCase()}</Avatar>
									<Typography variant='body2'>{question.question.title}</Typography>
								</Box>
								<DragIndicator htmlColor='#00000090' />
							</Box>
							<Divider />
							<CardActions sx={{display: 'flex', alignItems: 'center', justifyContent: question.share?.length > 0 ? 'space-between' : 'flex-end'}}>
								{question.share?.length > 0 && (
									<AvatarGroup onMouseEnter={handleOpenSharesPopper} onMouseLeave={handleCloseSharesPopper} total={question.share.length} componentsProps={{additionalAvatar: {sx: {marginLeft: '-5px !important', width: 20, height: 20, fontSize: 15}}}} max={4}>
										{question.share.map(user => (
											<Avatar alt={user.email} key={user.id} title={user.email} sx={{background: stringToColor(user.email.split('@')[0]), width: 20, height: 20, border: 'none !important'}} variant='circular'>
												{user.email.toUpperCase().charAt(0)}
											</Avatar>
										))}
									</AvatarGroup>
								)}
								<Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-end'>
									<IconButton onClick={handleOpenOptionsMenu} size='small' sx={{width: 20, height: 20}}>
										<MoreHoriz color='primary' fontSize='small' />
									</IconButton>
								</Box>
							</CardActions>
						</Card>
					</div>
				)}
			</Draggable>
		</>
	);
}
