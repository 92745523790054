import {Router, Switch, Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ErrorPage from './components/ErrorPage';
import Dashboard from './components/Dashboard';
import CodePadController from './components/CodePadController';
import Env from './util/Env';
import ReactGA, {ga} from 'react-ga';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import {useEffect} from 'react';
import {ThemeProvider} from '@mui/styles';
import {Container, createTheme} from '@mui/material';
import NewDashboard from './components/Dashboard/NewDashboard';
import Availability from './components/Schedule/Availability/Availability';
import PageLayout from './components/PageLayout';
import ExamplesTab from './components/ActionCenter/Tabs/Examples/ExamplesTab';
import CandidatesTab from './components/ActionCenter/Tabs/Candidates/CandidatesTab';
import UserQuestions from './components/ActionCenter/Tabs/UserQuestions/UserQuestions';
import {RealtimeProvider} from './RealtimeContext/RealtimeContext';
import DrawTab from './components/ActionCenter/Tabs/DrawTab';
import {AccountProvider} from './AccountContext/AccountContext';
import {Worker} from '@react-pdf-viewer/core';
import NewReservation from './components/Schedule/Reservation/NewReservation';

const App = () => {
	const baseTheme = createTheme();
	ReactGA.initialize(Env().gaKey);
	const newHistory = createBrowserHistory();
	const initializeGoogleAnalytics = () => {
		if (window.matchMedia('(display-mode: standalone)').matches) {
			ReactGA.event({
				category: 'App Mode',
				action: 'Progressive Web App',
			});
		} else {
			ReactGA.event({
				category: 'App Mode',
				action: 'Web App',
			});
		}
	};
	const logAppVersion = () => {
		ReactGA.pageview(window.location.pathname + window.location.search);
		ga('set', 'appVersion', Env().appVersion);
	};

	useEffect(() => {
		initializeGoogleAnalytics();
		logAppVersion();
	}, []);
	return (
		<Worker workerUrl='https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js'>
			<ThemeProvider theme={baseTheme}>
				<Router history={newHistory}>
					<RealtimeProvider>
						<Switch>
							<Route exact path='/' component={Dashboard} />
							<Route exact path='/LinearPad/' component={() => <CodePadController isCandidate={true} />} />

							<Route
								exact
								path='/Reservation/:userId/:username'
								component={() => (
									<PageLayout header={false} nav={false} backgroundColor='#E8EFFF'>
										<NewReservation />
									</PageLayout>
								)}
							/>
							<AccountProvider>
								<PageLayout header={true} nav={true}>
									<Switch>
										<PrivateRoute exact path='/LinearPad/Ace' component={CodePadController} />
										<PrivateRoute exact path='/Dashboard' component={NewDashboard} />
										<PrivateRoute exact path='/Availability' component={Availability} />
										<PrivateRoute exact path='/Draw' component={() => <DrawTab height='95%' />} />
										<PrivateRoute
											exact
											path='/Simple01'
											component={() => (
												<Container>
													<ExamplesTab />
												</Container>
											)}
										/>
										<PrivateRoute
											exact
											path='/Candidates'
											component={() => (
												<Container>
													<CandidatesTab />
												</Container>
											)}
										/>
										<PrivateRoute
											exact
											path='/Questions'
											component={() => (
												<Container>
													<UserQuestions />
												</Container>
											)}
										/>
										<Route path='*' component={ErrorPage} />
									</Switch>
								</PageLayout>
							</AccountProvider>
						</Switch>
					</RealtimeProvider>
				</Router>
			</ThemeProvider>
		</Worker>
	);
};

export default App;
