import {Box} from '@mui/system';
import axios from 'axios';
import useAxios from 'axios-hooks';
import {memo, useState} from 'react';
import Env from '../../../../util/Env';
import ExamplesTable from '../../../Fragments/ExamplesTable';
import Filters from './Filters';
import SearchContainer from './Search/SearchContainer';
import ReactGA from 'react-ga';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../../../AuthContext/AuthContext';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../../ErrorBoundary/InternalErrorBoudaryPage';
import {connect} from 'react-redux';

function ExamplesTab({questionsFetch, onApplyFetchQuestions}) {
	const {enqueueSnackbar} = useSnackbar();
	const {loggedInUser, token} = useAuth();

	const [{data: examples, loading: fetchExamplesLoading}, fetchExamples] = useAxios({
		url: Env().url + '/examples?_start=0&_end=10&published=true',
		headers: {
			Authorization: 'Bearer ' + token,
		},
	});

	const [tagFilterString, setTagFilterString] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isSearchBugged, setIsSearchBugged] = useState(false);
	const [isTableBugged, setIsTableBugged] = useState(false);
	const [isFilterBugged, setIsFilterBugged] = useState(false);

	const handleAddExampleToUser = example => {
		ReactGA.event({
			category: 'Pad data',
			action: 'add example to current user examples',
		});
		axios
			.post(
				Env().url + '/questions',
				{
					userId: loggedInUser.uid,
					title: example.title,
					problem: example.problem,
					testCases: example.testCases,
					difficultyLevel: example.difficultyLevel,
					tagList: example.tagList,
					href: 'id=' + example.id + '&topicId=' + example.targetId,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			)
			.then(res => {
				enqueueSnackbar('example is added to user questions!', {
					variant: 'success',
				});
				onApplyFetchQuestions(!questionsFetch);
			})
			.catch(err => {
				enqueueSnackbar("couldn't add example to user!", {
					variant: 'error',
				});
			});
	};

	return (
		<>
			<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsSearchBugged(false)} resetKeys={[isSearchBugged]}>
				<SearchContainer fetchExamples={fetchExamples} tagFilterString={tagFilterString} searchQuery={searchTerm} rowsPerPage={rowsPerPage} setSearchQuery={setSearchTerm} />
			</ErrorBoundary>
			<Box pt={1} display='flex' gap={2}>
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsFilterBugged(false)} resetKeys={[isFilterBugged]}>
					<Filters tags={examples?.tags} fetchExamples={fetchExamples} tagFilterString={tagFilterString} searchQuery={searchTerm} setTagFilterString={setTagFilterString} rowsPerPage={rowsPerPage} />
				</ErrorBoundary>
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsTableBugged(false)} resetKeys={[isTableBugged]}>
					<ExamplesTable
						totalCountOfExamples={examples ? examples.totalCount : 0}
						userId={loggedInUser.uid}
						fetchExamplesLoading={fetchExamplesLoading}
						examples={examples?.rows}
						handleAddExampleToUser={handleAddExampleToUser}
						searchQuery={searchTerm}
						fetchExamples={fetchExamples}
						tagFilterString={tagFilterString}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				</ErrorBoundary>
			</Box>
		</>
	);
}

const mapDispatchToProps = dispatch => ({
	onApplyFetchQuestions: questionsFetch => dispatch({type: 'QUESTIONS_FETCH', questionsFetch}),
});
const mapStateToProps = state => ({
	questionsFetch: state.questionsFetchState.questionsFetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ExamplesTab));
