import {Button, CircularProgress, ClickAwayListener, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import axios from 'axios';
import {useState} from 'react';
import {useAccount} from '../../../../AccountContext/AccountContext';
import {useAuth} from '../../../../AuthContext/AuthContext';
import Env from '../../../../util/Env';
import {Arrow, Popper} from '../../../common/PopperFragments';
import GenericCard from '../../../Fragments/GenericCard';

const useStyles = makeStyles(theme => ({
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
	},
}));

export default function SavePopper({onClose, anchor, placement, arrow, excaliRef}) {
	const [arrowRef, setArrowRef] = useState();
	const {token} = useAuth();
	const {userObj, setUserObj} = useAccount();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState('');

	const handleSaveDraw = () => {
		setLoading(true);
		let data = JSON.stringify({elements: excaliRef.current.getSceneElements(), state: excaliRef.current.getAppState()});
		var blob = new Blob([data]);
		var formData = new FormData();
		formData.append('file', blob);
		axios
			.post(Env().url + '/sketch/uploadExcali', formData, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.then(res => {
				let userTmp = {...userObj};
				if (userTmp.designs) {
					let designs = JSON.parse(userTmp.designs);
					designs.push({id: res.data.id, description: description});
					userTmp.designs = designs;
				} else {
					userTmp.designs = [{id: res.data.id, description: description}];
				}
				userTmp.designs = JSON.stringify(userTmp.designs);
				axios
					.put(Env().url + '/users', userTmp, {
						headers: {
							Authorization: 'Bearer ' + token,
						},
					})
					.then(res => {
						setUserObj(res.data);
						setLoading(false);
						onClose();
					});
			});
	};

	const Actions = () => (
		<>
			<Button variant='contained' startIcon={loading && <CircularProgress sx={{color: '#fff'}} size={20} />} color='primary' size='small' className={classes.button} disabled={!description} onClick={handleSaveDraw}>
				Save
			</Button>
			<Button variant='outlined' color='primary' size='small' className={classes.button} onClick={onClose}>
				Cancel
			</Button>
		</>
	);
	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<GenericCard title='save draw data' actions={<Actions />}>
					<TextField fullWidth size='small' variant='outlined' label='Description' value={description} onChange={e => setDescription(e.target.value)} />
				</GenericCard>
			</Popper>
		</ClickAwayListener>
	);
}
