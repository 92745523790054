import React, {memo, useCallback, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Search as SearchIcon, CloseOutlined as CloseOutlinedIcon} from '@mui/icons-material';
import {Box, InputBase, IconButton, ClickAwayListener} from '@mui/material';
import Env from '../../../../../util/Env';
import ReactGA from 'react-ga';

const useStyles = makeStyles(() => ({
	search: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '100%',
		borderRadius: 10,
	},
	searchIcon: {
		width: 50,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		flex: 1,
		height: 45,
		alignItems: 'center',
	},
	inputInput: {
		width: '100%',
	},
}));

export default memo(function SearchContainer({fetchExamples, tagFilterString, rowsPerPage, setSearchQuery}) {
	const classes = useStyles();

	const [isFocussed, setFocussed] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const onSearchCancel = useCallback(() => {
		setSearchTerm('');
		setSearchQuery('');
		setFocussed(false);
		fetchExamples({
			url: Env().url + '/examples?_start=0&_end=' + rowsPerPage + (tagFilterString ? '&' + tagFilterString : ''),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagFilterString]);

	const makeSearch = useCallback(
		value => {
			ReactGA.event({
				category: 'Search',
				action: 'searching for ' + value,
			});
			setSearchTerm(value);
			setSearchQuery('q=' + value);
			if (!value) {
				fetchExamples();
				setSearchQuery('');
			} else {
				if (value.length > 1) {
					fetchExamples({
						url: Env().url + '/examples?_start=0&_end=' + rowsPerPage + (tagFilterString ? '&' + tagFilterString : '') + '&q=' + value,
					});
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tagFilterString, setSearchQuery]
	);

	const handleClick = useCallback(() => {
		ReactGA.event({
			category: 'Search',
			action: 'clicked on search bar',
		});
		setFocussed(true);
	}, []);

	const onFocusLoss = useCallback(() => {
		ReactGA.event({
			category: 'Search',
			action: 'close search by clicking out side the search bar',
		});
		setFocussed(false);
	}, []);

	return (
		<ClickAwayListener onClickAway={() => isFocussed && onFocusLoss()}>
			<Box className={classes.search} boxShadow={isFocussed ? 2 : 0} bgcolor={isFocussed ? '#fff' : '#EEE'}>
				<div className={classes.searchIcon}>
					<SearchIcon htmlColor={isFocussed ? '#1776D2' : '#7F7F7F'} />
				</div>
				<InputBase
					placeholder='Search examples'
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					value={searchTerm}
					onClick={handleClick}
					inputProps={{'aria-label': 'search'}}
					onChange={event => makeSearch(event.target.value)}
				/>
				{isFocussed ? (
					<IconButton onClick={onSearchCancel} size='medium'>
						<CloseOutlinedIcon fontSize='small' htmlColor={'#1776D2'} />
					</IconButton>
				) : null}
			</Box>
		</ClickAwayListener>
	);
});
