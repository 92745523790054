import {Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, Tooltip} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {FcAddRow, FcDeleteRow, FcAddColumn, FcDeleteColumn, FcGrid} from 'react-icons/fc';
import {MdOutlineTableView} from 'react-icons/md';
import {AiOutlineMergeCells, AiOutlineSplitCells} from 'react-icons/ai';

const useStyles = makeStyles({
	popper: {
		zIndex: 1,
	},
	icon: {
		width: 35,
		height: 35,
	},
});

export const TableStyles = ({editor}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup size='small' ref={anchorRef} aria-label='small button group'>
				<Tooltip title='insert table'>
					<Button onClick={() => editor.chain().focus().insertTable({rows: 2, cols: 3, withHeaderRow: true}).run()}>
						<FcGrid className={classes.icon} />
					</Button>
				</Tooltip>
				<Button size='small' aria-controls={open ? 'split-button-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-label='select merge strategy' aria-haspopup='menu' onClick={handleToggle}>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={classes.popper}>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper
							sx={{
								height: 35,
								borderRadius: 2,
								mt: 0.5,
								background: '#fff',
								zIndex: 10,
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<ButtonGroup size='small'>
									<Tooltip title='add column next'>
										<Button sx={{overflow: 'hidden'}} onClick={() => editor.chain().focus().addColumnAfter().run()}>
											<FcAddColumn className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='add row below'>
										<Button onClick={() => editor.chain().focus().addRowAfter().run()}>
											<FcAddRow className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='delete column'>
										<Button variant='outlined' disableRipple={true} size='small' onClick={() => editor.chain().focus().deleteColumn().run()}>
											<FcDeleteColumn className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='delete row'>
										<Button variant='outlined' disableRipple={true} size='small' onClick={() => editor.chain().focus().deleteRow().run()}>
											<FcDeleteRow className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='merge cells'>
										<Button variant='outlined' disableRipple={true} size='small' onClick={() => editor.chain().focus().mergeCells().run()}>
											<AiOutlineMergeCells className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='split cells'>
										<Button variant='outlined' disableRipple={true} size='small' onClick={() => editor.chain().focus().splitCell().run()}>
											<AiOutlineSplitCells className={classes.icon} />
										</Button>
									</Tooltip>
									<Tooltip title='toggle header'>
										<Button sx={{padding: 0, overflow: 'hidden'}} variant='outlined' disableRipple={true} size='small' onClick={() => editor.chain().focus().toggleHeaderCell().run()}>
											<MdOutlineTableView className={classes.icon} />
										</Button>
									</Tooltip>
								</ButtonGroup>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
