import {Gesture} from '@mui/icons-material';
import {CircularProgress, ClickAwayListener, List, ListItemButton, Typography} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useAccount} from '../../../../AccountContext/AccountContext';
import {useAuth} from '../../../../AuthContext/AuthContext';
import Env from '../../../../util/Env';
import {Arrow, Popper} from '../../../common/PopperFragments';
import GenericCard from '../../../Fragments/GenericCard';

export default function DrawingsPopper({onClose, anchor, placement, arrow, excaliRef}) {
	const [arrowRef, setArrowRef] = useState();
	const [loading, setLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const {userObj} = useAccount();
	const {token} = useAuth();

	const handleLoadDraw = (id, index) => {
		setLoading(index);
		axios
			.get(Env().url + '/sketch/getExcali/' + id, {headers: {Authorization: 'Bearer ' + token}})
			.then(res => {
				if (res.data) {
					excaliRef?.current?.updateScene(res.data);
					setLoading(false);
					onClose();
				}
			})
			.catch(err => {
				setLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};
	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<GenericCard
					title={
						<Typography fontFamily='Fredoka' fontSize={18}>
							Select draw data
						</Typography>
					}
					minWidth={100}
				>
					<List dense>
						{userObj?.designs &&
							JSON.parse(userObj.designs)?.map((draw, index) => (
								<ListItemButton onClick={() => handleLoadDraw(draw.id, index)} disabled={loading || loading === 0} key={index}>
									{(loading || loading === 0) && loading === index ? <CircularProgress size={20} /> : <Gesture />}
									<Typography ml={2}>{draw.description}</Typography>
								</ListItemButton>
							))}
					</List>
				</GenericCard>
			</Popper>
		</ClickAwayListener>
	);
}
