import {Dialog} from '@mui/material';
import {useState} from 'react';
import ResourceShare from './ResourceShare';

export default function ShareDialog({isUserCandidate, candidate, question, handleClose, isCandidate, isQuestion, isUserQuestion, fetchSharedQuestions}) {
	const [closeCounter, setCloseCounter] = useState(0);

	const handleCloseDialog = () => {
		if (closeCounter === 1) {
			handleClose();
		} else {
			setCloseCounter(1);
		}
	};
	return (
		<Dialog open={true} onClose={handleCloseDialog} fullWidth sx={{overflow: 'auto'}}>
			<ResourceShare candidate={candidate} question={question} isCandidate={isCandidate} isQuestion={isQuestion} handleClose={handleClose} isUserCandidate={isUserCandidate} isUserQuestion={isUserQuestion} fetchSharedQuestions={fetchSharedQuestions} />
		</Dialog>
	);
}
