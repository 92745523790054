export const languages = [
	{
		id: 50,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fb5a5c236-cbcf-4c84-a30e-a61e779dde88.svg?generation=1663284601277867&alt=media',
		value: 'c',
		name: 'C',
	},
	{
		id: 54,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fd89ded72-97a1-4a3b-a086-f6acf5c21f70.svg?generation=1663284590726007&alt=media',
		value: 'c++',
		name: 'C++',
	},
	{
		id: 51,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F7372ec0e-e58e-4ef9-91a8-45d2b4d32bbf.svg?generation=1663284934664553&alt=media',
		value: 'c#',
		name: 'C#',
	},
	{
		id: 60,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F821916ca-5487-4d23-aab4-97656be03220.svg?generation=1663285139160571&alt=media',
		value: 'golang',
		name: 'Go',
	},
	{
		id: 61,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F7e26ff2f-07dc-41a0-9f03-ebe6fc5a4f72.svg?generation=1663285144969190&alt=media',
		value: 'haskell',
		name: 'Haskell',
	},
	{
		id: 62,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F842511c9-114f-4dff-af66-9ba87904ff79.svg?generation=1663284609149767&alt=media',
		value: 'java',
		name: 'Java',
	},
	{
		id: 63,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F23292119-a06e-4770-b010-30aa42e59198.svg?generation=1663285217360872&alt=media',
		value: 'javascript',
		name: 'JavaScript',
	},
	{
		id: 78,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F6ccf6606-c755-4fa0-b938-7693407d0875.svg?generation=1663285374775309&alt=media',
		value: 'kotlin',
		name: 'Kotlin',
	},
	{
		id: 68,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F05cb17eb-4888-49e6-8fdc-4ab6edb4fb53.svg?generation=1663285702149381&alt=media',
		value: 'php',
		name: 'PHP',
	},
	{
		id: 70,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fa4529268-3a37-45be-9657-6cd01a3f83cb.svg?generation=1663285319150232&alt=media',
		value: 'python2.7',
		name: 'Python (2.7.17)',
	},
	{
		id: 71,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fa4529268-3a37-45be-9657-6cd01a3f83cb.svg?generation=1663285319150232&alt=media',
		value: 'python3.8',
		name: 'Python (3.8.1)',
	},
	{
		id: 84,
		icon: 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2F87ced194-e79f-4f4b-8319-ca90129a32b8.svg?generation=1663285664750410&alt=media',
		value: '.net',
		name: 'Visual Basic.Net',
	},
];

export const samples = {
	haskell: `putStrLn "Hello World!"`,
	kotlin: `// Hello World Program

	fun main(args : Array<String>) {
		println("Hello, World!")
	}`,
	'.net': `Console.WriteLine("Hello, World!");`,
	php: `echo "Hello World!";`,
	java: `//print "Hello World!"
class Main {
public static void main(String[] args) {
System.out.println("Hello World!");
}
}`,
	javascript: `//print "Hello World!"
console.log("Hello World!")`,
	'python2.7': `#print "Hello World!"
print "Hello World!"`,
	'python3.8': `#print "Hello World!"
print("Hello World!")`,
	golang: `//print "Hello World!"
package main

import "fmt"

func main() {
fmt.Println("Hello World!")
}`,
	'c#': `// Hello World! program
namespace HelloWorld
{
class Hello {         
	static void Main(string[] args)
	{
		System.Console.WriteLine("Hello World!");
	}
}
}`,
	'c++': `#include <iostream>

int main() {
    std::cout << "Hello World!";
    return 0;
}`,
	c: `#include <stdio.h>
int main() {
   // printf() displays the string inside quotation
   printf("Hello, World!");
   return 0;
}`,
};
