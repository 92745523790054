import {combineReducers} from 'redux';
import alertsReducer from './alerts';
import candidatesFetchReducer from './candidates';
import notificationsReducer from './notifications';
import questionsFetchReducer from './questions';

const rootReducer = combineReducers({
	questionsFetchState: questionsFetchReducer,
	candidatesFetchState: candidatesFetchReducer,
	alertsState: alertsReducer,
	notificationsState: notificationsReducer,
});

export default rootReducer;
