import {Alert, Box, CircularProgress} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {memo, useCallback, useEffect, useState} from 'react';
import {useAuth} from '../../../../AuthContext/AuthContext';
import Env from '../../../../util/Env';
import {Categories} from './Categories';
import NewQuestionDialog from './NewQuestionDialog';
import {connect} from 'react-redux';
import useAxios from 'axios-hooks';
import ReactGA from 'react-ga';
import {TabContext, TabPanel} from '@mui/lab';
import {StyledTab, StyledTabs} from '../../../common/CustomTabs';
import {AssignmentInd, FolderShared} from '@mui/icons-material';
import SharedQuestions from './SharedQuestions';
import {makeStyles} from '@mui/styles';
import {useRealtime} from '../../../../RealtimeContext/RealtimeContext';
import InternalErrorBoundaryPage from '../../../ErrorBoundary/InternalErrorBoudaryPage';
import {ErrorBoundary} from 'react-error-boundary';
import {useAccount} from '../../../../AccountContext/AccountContext';

const useStyles = makeStyles(() => ({
	tabPanel: {
		padding: '5px !important',
	},
}));

function UserQuestions({questionsFetch}) {
	const {loggedInUser, token} = useAuth();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {realtime} = useRealtime();
	const {planPermissions} = useAccount();

	const [categories, setCategories] = useState({});
	const [addNewQuestionLoading, setAddNewQuestionLoading] = useState(false);
	const [openNewQuestionDialog, setOpenNewQuestionDialog] = useState(false);
	const [tabsValue, setTabsValue] = useState(0);
	const [isUserQuestionsBugged, setIsUserQuestionsBugged] = useState(false);
	const [isSharedQuestionsBugged, setIsSharedQuestionsBugged] = useState(false);

	const [{data: questions, error: fetchUserQuestionsError, loading: fetchUserQuestionsLoading}, fetchUserQuestions] = useAxios({
		url: Env().url + '/questions/myQuestions',
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + token,
		},
	});
	const [{data: sharedQuestions, error: fetchSharedQuestionsError, loading: fetchSharedQuestionsLoading}, fetchSharedQuestions] = useAxios(
		{
			url: Env().url + '/questions/myShare',
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + token,
			},
		},
		{manual: planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.share')) ? false : true}
	);

	const handleAddNewQuestion = useCallback(
		(title, problem, testCases) => {
			setAddNewQuestionLoading(true);
			var tempQuestion = {
				title: title,
				category: 'none',
				problem: problem,
				testCases: testCases,
			};

			axios
				.post(Env().url + '/questions', tempQuestion, {
					headers: {Authorization: 'Bearer ' + token},
				})
				.then(res => {
					fetchUserQuestions();
					enqueueSnackbar('question is created!', {variant: 'success'});
					setAddNewQuestionLoading(false);
					setOpenNewQuestionDialog(false);
				})
				.catch(err => {
					setAddNewQuestionLoading(false);
					setOpenNewQuestionDialog(false);
					enqueueSnackbar("couldn't add question", {variant: 'error'});
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token, loggedInUser.uid]
	);
	const handleChangeTab = useCallback((event, newValue) => {
		ReactGA.event({
			category: 'authorized user action',
			action: 'change tabs between user and shared questions',
			value: newValue,
		});
		setTabsValue(newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (questions) {
			var tempCategories = {};
			Object.keys(questions?.category).forEach(category => {
				if ((category !== 'none' && questions.category[category] !== 0) || category === 'none') tempCategories[category] = {id: category, name: category, list: []};
			});
			setCategories({...tempCategories});
		}
	}, [questions]);

	useEffect(() => {
		fetchUserQuestions();
	}, [fetchUserQuestions, questionsFetch]);

	return (
		<Box width='100%'>
			<TabContext value={tabsValue.toString()}>
				<StyledTabs value={tabsValue} onChange={handleChangeTab} variant='scrollable' scrollButtons='auto'>
					<StyledTab icon={<AssignmentInd color='primary' />} iconPosition='start' label={'Mine'} wrapped />
					{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.share')) && sharedQuestions?.rows?.length > 0 && (
						<StyledTab icon={<FolderShared color='primary' />} iconPosition='start' label={'Shared'} wrapped />
					)}
				</StyledTabs>
				<TabPanel value='0' className={classes.tabPanel}>
					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsUserQuestionsBugged(false)} resetKeys={[isUserQuestionsBugged]}>
						{!fetchUserQuestionsLoading || questions ? (
							fetchUserQuestionsError ? (
								<Alert severity='error'>Something went wrong please try again!</Alert>
							) : (
								<>
									{openNewQuestionDialog && <NewQuestionDialog loading={addNewQuestionLoading} onClose={() => setOpenNewQuestionDialog(false)} handleAddNewQuestion={handleAddNewQuestion} />}
									{Object.keys(categories).length > 0 && (
										<Categories
											fetchQuestions={fetchUserQuestions}
											fetchSharedQuestions={fetchSharedQuestions}
											handleAddNewQuestion={() => setOpenNewQuestionDialog(true)}
											questionsData={questions?.rows}
											questionsCategories={categories}
											questionDataLoading={fetchUserQuestionsLoading}
											userId={loggedInUser.uid}
											currentQuiz={realtime.currentQuiz}
											fetchUserQuestions={fetchUserQuestions}
										/>
									)}
								</>
							)
						) : (
							<Box width='100%' justifyContent='center'>
								<CircularProgress />
							</Box>
						)}
					</ErrorBoundary>
				</TabPanel>
				{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.share')) && sharedQuestions?.rows?.length > 0 && (
					<TabPanel value='1' className={classes.tabPanel}>
						<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsSharedQuestionsBugged(false)} resetKeys={[isSharedQuestionsBugged]}>
							<SharedQuestions
								currentQuiz={realtime.currentQuiz}
								setTabsValue={setTabsValue}
								fetchUserQuestions={fetchUserQuestions}
								fetchSharedQuestionsData={fetchSharedQuestions}
								sharedQuestionsData={sharedQuestions}
								sharedQuestionsDataFetchError={fetchSharedQuestionsError}
								sharedQuestionsDataLoading={fetchSharedQuestionsLoading}
								userId={loggedInUser.uid}
							/>
						</ErrorBoundary>
					</TabPanel>
				)}
			</TabContext>
		</Box>
	);
}

const mapStateToProps = state => ({
	questionsFetch: state.questionsFetchState.questionsFetch,
});

export default connect(mapStateToProps, null)(memo(UserQuestions));
