import * as React from 'react';
import {useState, useEffect} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';
import Env from '../../../../../util/Env';
import {useAuth} from '../../../../../AuthContext/AuthContext';
import CategoryColumn from './CategoryColumn';
import axios from 'axios';
import {Box, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@mui/styles';
import {usePrevious} from '../../../../../util/CustomHooks';
import {useAccount} from '../../../../../AccountContext/AccountContext';

const useStyles = makeStyles({
	droppable: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 10,
		padding: 5,
		'&.isDraggingOver': {
			backgroundColor: '#dadadf',
		},
	},
	newCategory: {
		minHeight: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export const Categories = ({fetchQuestions, fetchSharedQuestions, questionsData, questionsCategories, questionDataLoading, userId, currentQuiz, fetchUserQuestions, handleAddNewQuestion}) => {
	const {token} = useAuth();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {planPermissions} = useAccount();
	const [categories, setCategories] = useState(questionsCategories);

	const prevQuestionsData = usePrevious(questionsData);

	// update deals by columns when the dataProvider response updates
	useEffect(() => {
		if (isEqual(questionsData, prevQuestionsData)) return;
		let tempCategories = {...questionsCategories};
		questionsData.forEach(question => {
			if (!question.question.category) {
				tempCategories.none = {...tempCategories.none, list: tempCategories.none.list.concat(question)};
			} else {
				if (Object.keys(tempCategories).includes(question.question.category)) {
					tempCategories[question.question.category] = {...tempCategories[question.question.category], list: tempCategories[question.question.category].list.concat(question)};
				} else {
					tempCategories[question.question.category] = {id: question.question.category, name: question.question.category, list: [question]};
				}
			}
		});
		Object.keys(tempCategories).forEach(key => {
			if (tempCategories[key].list.length === 0 && key !== 'none') {
				delete tempCategories[key];
			}
		});
		setCategories(tempCategories);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionsData, questionsCategories, questionDataLoading]);

	const onDragEnd = async result => {
		const {destination, source} = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const start = categories[source.droppableId];
		const end = categories[destination.droppableId];

		// If start is the same as end, we're in the same column
		if (start === end) {
			// Move the item within the list
			// Start by making a new list without the dragged item

			const newList = start.list.filter((_, idx) => idx !== source.index);

			// Then insert the item at the right location
			newList.splice(destination.index, 0, start.list[source.index]);

			// Then create a new copy of the column object
			const newCol = {
				...start,
				list: newList,
			};

			// Update the state
			setCategories(categories => ({...categories, [newCol.id]: newCol}));
			return null;
		} else {
			let newCategoryName;
			if (destination.droppableId === 'newCategory') {
				newCategoryName = prompt('Enter category name');

				if (!newCategoryName) return;
			}

			let {ctime, utime, ...tempQuestionObject} = start.list[source.index].question;
			tempQuestionObject.category = destination.droppableId === 'newCategory' ? newCategoryName : destination.droppableId;
			axios
				.post(Env().url + '/questions', tempQuestionObject, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
				})
				.then(res => {
					fetchQuestions();
				})
				.catch(err => {
					enqueueSnackbar(err.message + "\nData isn't saved", {variant: 'error'});
				});
			const newStartList = start.list.filter((_, idx) => idx !== source.index);

			// Create a new start column
			const newStartCol = {
				...start,
				list: newStartList,
			};

			// Make a new end list array
			const newEndList = destination.droppableId === 'newCategory' ? [] : end.list;

			start.list[source.index].category = destination.droppableId === 'newCategory' ? newCategoryName : destination.droppableId;
			// Insert the item into the end list
			newEndList.splice(destination.index, 0, start.list[source.index]);

			// Create a new end column
			const newEndCol =
				destination.droppableId === 'newCategory'
					? {id: newCategoryName, name: newCategoryName, list: newEndList}
					: {
							...end,
							list: newEndList,
					  };

			if (newStartList.length === 0 && source.droppableId !== 'none') {
				setCategories(categories => {
					delete categories[newStartCol.id];
					return {...categories, [newEndCol.id]: newEndCol};
				});
			} else {
				// Update the state
				setCategories(categories => ({
					...categories,
					[newStartCol.id]: newStartCol,
					[newEndCol.id]: newEndCol,
				}));
			}

			return null;
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Box>
				<CategoryColumn category='none' fetchSharedQuestions={fetchSharedQuestions} handleAddNewQuestion={handleAddNewQuestion} categories={categories} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchUserQuestions} />
				{questionsData.length !== 0 && (
					<Box display='flex' flexWrap='wrap' gap='1px'>
						{Object.keys(categories).map(category => category !== 'none' && <CategoryColumn fetchSharedQuestions={fetchSharedQuestions} category={category} categories={categories} key={category} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchUserQuestions} />)}
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.drag')) && (
							<Box flex='1 0 21%' display='flex' justifyContent='center' alignItems='center' bgcolor='#eaeaee' border='1px dashed #00000080' borderRadius='10px' margin='1px 0px'>
								<Droppable droppableId='newCategory'>
									{(droppableProvided, snapshot) => (
										<Box ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={classes.droppable + ' ' + classes.newCategory + (snapshot.isDraggingOver ? ' isDraggingOver' : '')}>
											<Typography align='center' fontFamily={600} color='#00000080' component='span'>
												Drag question here to create a new category
											</Typography>
										</Box>
									)}
								</Droppable>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</DragDropContext>
	);
};
