const INITIAL_STATE = {
	alerts: 0,
};

const applyIncrementAlerts = state => {
	return {...state, alerts: state.alerts + 1};
};

function alertsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'ALERTS_INCREMENT': {
			return applyIncrementAlerts(state);
		}
		default:
			return state;
	}
}

export default alertsReducer;
