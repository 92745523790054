import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebaseConfig from './util/firebaseConfig';
import firebase from 'firebase';
import {BrowserRouter} from 'react-router-dom';
import usersDbConfig from './util/usersDbConfig';
import {SnackbarProvider} from 'notistack';
import {Slide} from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Provider} from 'react-redux';
import store from './store';
import {AuthProvider} from './AuthContext/AuthContext';

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig, 'linearPad');
	firebase.initializeApp(usersDbConfig, 'usersFirebase');
}
const autoHideDuration = 2000;
const maxSnacks = 3;
const reCaptchaKey = '6LdFbhIhAAAAAKMOV8Hw-1qwUfgJgPuktY5Xqg41';

const reCaptchaLanguage = 'english';

const ReCaptchaProvider = ({children}) => (
	<GoogleReCaptchaProvider
		reCaptchaKey={reCaptchaKey}
		language={reCaptchaLanguage}
		scriptProps={{
			async: false,
			defer: false,
			appendTo: 'head',
			nonce: undefined,
		}}
	>
		{children}
	</GoogleReCaptchaProvider>
);
const SnacksProvider = ({children}) => (
	<SnackbarProvider
		preventDuplicate
		maxSnack={maxSnacks}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}
		TransitionComponent={Slide}
		autoHideDuration={autoHideDuration}
	>
		{children}
	</SnackbarProvider>
);

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<AuthProvider>
				<SnacksProvider>
					<ErrorBoundary>
						<ReCaptchaProvider>
							<App />
						</ReCaptchaProvider>
					</ErrorBoundary>
				</SnacksProvider>
			</AuthProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();
