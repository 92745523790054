import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Editor } from "@tiptap/react";

export const TextStyles = ({ editor }: Editor) => {
  const fonts = [
    { name: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
    { name: "Inter", value: "Inter" },
    { name: "Serif", value: "serif" },
    { name: "Monospace", value: "monospace" },
    { name: "Cursive", value: "cursive" },
  ];
  const fontSizes = ["12px", "14px", "16px", "18px", "20px"];

  return (
    <Box display="flex" gap={0.5}>
      <FormControl
        size="small"
        sx={{
          width: 100,
          height: 35,
        }}
        color="primary"
      >
        <Select
          sx={{ height: 35 }}
          value={
            editor.isActive("textStyle", {
              fontFamily: "Comic Sans MS, Comic Sans",
            })
              ? "Comic Sans MS, Comic Sans"
              : editor.isActive("textStyle", { fontFamily: "Inter" })
              ? "Inter"
              : editor.isActive("textStyle", { fontFamily: "serif" })
              ? "serif"
              : editor.isActive("textStyle", { fontFamily: "monospace" })
              ? "monospace"
              : editor.isActive("textStyle", { fontFamily: "cursive" })
              ? "cursive"
              : "/"
          }
          onChange={(e) =>
            editor.chain().focus().setFontFamily(e.target.value).run()
          }
        >
          <MenuItem value="/">Normal</MenuItem>
          {fonts.map((font, index) => (
            <MenuItem value={font.value} key={index}>
              {font.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        size="small"
        sx={{
          width: 100,
          height: 35,
        }}
        color="primary"
      >
        <Select
          sx={{ height: 35 }}
          value={
            editor.isActive("textStyle", {
              fontSize: "12px",
            })
              ? "12px"
              : editor.isActive("textStyle", { fontSize: "14px" })
              ? "14px"
              : editor.isActive("textStyle", { fontSize: "18px" })
              ? "18px"
              : editor.isActive("textStyle", { fontSize: "20px" })
              ? "20px"
              : "16px"
          }
          onChange={(e) =>
            editor
              .chain()
              .focus()
              .setMark("textStyle", {
                fontSize: e.target.value,
              })
              .run()
          }
        >
          {fontSizes.map((fontSize, index) => (
            <MenuItem value={fontSize} key={index}>
              {fontSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
