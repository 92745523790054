import {ClickAwayListener, Paper} from '@mui/material';
import ConfirmationPopper from '../Fragments/ConfirmationPopper';
import {useState} from 'react';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {useAuth} from '../../AuthContext/AuthContext';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Arrow, Popper} from '../common/PopperFragments';
import PopperContent from './PopperContent';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';

function NotificationsPopper({data, onApplyDecrementNotifications, anchor, placement, arrow, onClose}) {
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [, deleteNotification] = useAxios({url: Env().url + '/notifications/notify', method: 'POST', headers: {Authorization: 'Bearer ' + token}}, {manual: true});

	const [arrowRef, setArrowRef] = useState(null);
	const [anchorElConfirmationPopper, setAnchorElConfirmationPopper] = useState('');
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [currentNotification, setCurrentNotification] = useState('');
	const [isNotificationsBugged, setIsNotificationsBugged] = useState(false);

	const handleDeleteNotification = () => {
		deleteNotification({data: {...currentNotification, expiresAt: new Date().getTime()}}).then(() => {
			enqueueSnackbar('deleted!', {variant: 'success'});
			onApplyDecrementNotifications();
			handleCloseConfirmationPopper();
		});
	};

	const handleOpenConfirmationPopper = (e, notification) => {
		setCurrentNotification(notification);
		setAnchorElConfirmationPopper(e.currentTarget);
		setOpenConfirmationPopper(true);
	};
	const handleCloseConfirmationPopper = () => {
		setOpenConfirmationPopper(false);
		setAnchorElConfirmationPopper('');
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
				sx={{m: '16px 0px 0px 0px !important'}}
			>
				{openConfirmationPopper && (
					<ConfirmationPopper
						arrow={true}
						placement='left'
						anchor={anchorElConfirmationPopper}
						operation='End'
						name='Notification'
						onCancel={handleCloseConfirmationPopper}
						onClose={handleCloseConfirmationPopper}
						onConfirm={handleDeleteNotification}
						cancel='Cancel'
						confirm='Delete'
						message='Are you sure?'
					/>
				)}
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Paper elevation={5} sx={{borderRadius: '15px', minHeight: 300, minWidth: 280, p: 1, mr: 1}}>
					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsNotificationsBugged(false)} resetKeys={[isNotificationsBugged]}>
						<PopperContent data={data} handleOpenConfirmationPopper={handleOpenConfirmationPopper} />
					</ErrorBoundary>
				</Paper>
			</Popper>
		</ClickAwayListener>
	);
}
const mapDispatchToProps = dispatch => ({
	onApplyDecrementNotifications: () => dispatch({type: 'NOTIFICATIONS_DECREMENT'}),
});

export default connect(null, mapDispatchToProps)(NotificationsPopper);
