import {Component} from 'react';
import StyledPage from './StyledPage';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	componentDidCatch(error, info) {
		this.setState({hasError: true});
	}

	render() {
		if (this.state.hasError) {
			return <StyledPage />;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
