import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, IconButton, Box, Typography, Skeleton, TableHead, Tooltip} from '@mui/material';

import {useCallback, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Add} from '@mui/icons-material';
import TablePaginationActions from '../common/TablePaginationActions';
import ExampleContentPopper from './QuizContentPopper';
import ReactGA from 'react-ga';
import Env from '../../util/Env';
import HardnessLevelLegend from '../Fragments/HardnessLevelLegend';

const useStyles = makeStyles(() => ({
	content: {
		width: '100%',
		maxHeight: '70vh',
	},
	title: {
		paddingBottom: '0.5em',
	},
	container: {
		margin: 0,
		padding: 0,
	},
	table: {
		overflow: 'hidden',
	},
	tableHeader: {
		backgroundColor: '#EEEEEE',
	},
	tableHeadText: {
		fontWeight: 600,
	},
	borderedCell: {
		borderLeft: '1px solid #DDD',
	},
	easy: {
		color: '#2CCF4F',
	},
	medium: {
		color: '#FFC107',
	},
	hard: {
		color: '#D11A2A',
	},
	linkRow: {
		cursor: 'pointer',
		'&:hover': {
			background: '#EEEEEE',
		},
		transition: 'background 0.3s ease-in-out',
	},
	selectedRow: {
		background: '#EEEEEE',
	},
	descriptionCard: {
		padding: '0px 10px 10px 10px',
	},
	addIcon: {
		position: 'absolute',
		right: 5,
		top: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	iconButton: {
		zIndex: 1,
		borderRadius: '5px !important',
	},
	exampleCell: {
		display: 'flex !important',
		gap: 5,
		position: 'relative',
		borderLeft: '1px solid #DDD',
		alignItems: 'center',
	},
	emoji: {
		fontSize: 24,
	},
	lastCell: {
		display: 'flex',
	},
}));

export default function ExamplesTable({userId, fetchExamplesLoading, examples, handleAddExampleToUser, totalCountOfExamples, fetchExamples, tagFilterString, pageNumber, setPageNumber, rowsPerPage, setRowsPerPage, searchQuery}) {
	const classes = useStyles();
	const [openExampleContentPopper, setOpenExampleContentPopper] = useState(false);
	const [chosenExample, setChosenExample] = useState({});
	const [anchorExampleContentPopper, setAnchorExampleContentPopper] = useState('');

	const handleChangePage = useCallback(
		(event, newPage) => {
			ReactGA.event({
				category: 'Examples table',
				action: 'change page from pagination bar',
				value: newPage,
			});
			let start = newPage * rowsPerPage;
			let end = start + rowsPerPage > totalCountOfExamples ? totalCountOfExamples : start + rowsPerPage;
			fetchExamples({
				url: Env().url + '/examples?_start=' + start + '&_end=' + end + '&published=true' + (tagFilterString ? '&' + tagFilterString : '') + (searchQuery ? '&' + searchQuery : ''),
			});
			setPageNumber(newPage);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rowsPerPage, tagFilterString, totalCountOfExamples]
	);

	const handleChangeRowsPerPage = useCallback(
		event => {
			ReactGA.event({
				category: 'Examples table',
				action: 'change number of rows per page',
				value: parseInt(event.target.value, 10),
			});
			fetchExamples({
				url: Env().url + '/examples?_start=0&_end=' + parseInt(event.target.value, 10) + '&published=true' + (tagFilterString ? '&' + tagFilterString : '') + (searchQuery ? '&' + searchQuery : ''),
			});
			setRowsPerPage(parseInt(event.target.value, 10));
			setPageNumber(0);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tagFilterString]
	);

	const handleCloseExampleContentPopper = useCallback(() => {
		ReactGA.event({
			category: 'Examples description',
			action: 'close the example description popper',
		});
		setOpenExampleContentPopper(false);
		setAnchorExampleContentPopper('');
		setChosenExample('');
	}, []);

	const handleOpenExampleContentPopper = useCallback((event, example) => {
		ReactGA.event({
			category: 'Example description',
			action: 'open the example description popper',
		});
		setAnchorExampleContentPopper(event.currentTarget);
		setChosenExample(example);
		setOpenExampleContentPopper(true);
	}, []);

	return (
		<>
			{openExampleContentPopper && <ExampleContentPopper anchor={anchorExampleContentPopper} arrow={true} placement='left' quiz={chosenExample} onClose={handleCloseExampleContentPopper} userId={userId} />}
			<Box className={classes.content}>
				<Grid container>
					<TableContainer component={Paper} elevation={5} className={classes.table}>
						<Table className={classes.table} size='small' aria-label='a dense table'>
							<TableHead className={classes.tableHeader}>
								<TableRow>
									<TableCell align='center' className={classes.borderedCell}>
										<Typography className={classes.tableHeadText}>Simple-01 Examples</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!fetchExamplesLoading ? (
									examples?.length > 0 ? (
										examples.map((example, index) => (
											<TableRow key={index} className={chosenExample.id === example.id ? classes.selectedRow : classes.linkRow} onClick={e => handleOpenExampleContentPopper(e, example)}>
												<TableCell align='left' className={classes.exampleCell}>
													<Tooltip title={example.difficultyLevel === 25 ? 'easy' : example.difficultyLevel === 50 ? 'medium' : 'hard'} arrow placement='top' enterDelay={500}>
														<Box display='flex' width={25} height={25} borderRadius='50%' color='#fff' alignItems='center' justifyContent='center' boxShadow={5}>
															{example.difficultyLevel === 25 ? <span className={classes.emoji}>&#128526;</span> : example.difficultyLevel === 50 ? <span className={classes.emoji}>&#128528;</span> : <span className={classes.emoji}>&#128545;</span>}
														</Box>
													</Tooltip>
													<Typography component='span' sx={{display: 'flex'}}>
														{example.title}
													</Typography>

													<Box className={classes.addIcon}>
														<IconButton
															size='small'
															className={classes.iconButton}
															onClick={e => {
																e.stopPropagation();
																handleAddExampleToUser(example);
															}}
														>
															<Add htmlColor='#1776D2' />
														</IconButton>
													</Box>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell align='center' colSpan={2}>
												no data found
											</TableCell>
										</TableRow>
									)
								) : (
									/* Array.from(new Array(pageNumber * rowsPerPage + rowsPerPage > totalCountOfExamples ? (totalCountOfExamples - pageNumber * rowsPerPage === 0 ? 3 : totalCountOfExamples - pageNumber * rowsPerPage) : rowsPerPage)).map((_, index) => ( */
									Array.from(new Array(5)).map((_, index) => (
										<TableRow key={index}>
											<TableCell sx={{padding: 0.1, maxHeight: 30}} colSpan={2}>
												<Skeleton height={34} variant='rectangular' animation='wave' />
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<HardnessLevelLegend />
								</TableRow>
								<TableRow>
									<TablePagination rowsPerPageOptions={[5, 10, 25]} colSpan={3} count={totalCountOfExamples} rowsPerPage={rowsPerPage} page={pageNumber} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} ActionsComponent={TablePaginationActions} />
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Box>
		</>
	);
}
