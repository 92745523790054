import {Button, CircularProgress, Dialog, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useCallback, useState} from 'react';
import GenericCard from '../../../Fragments/GenericCard';
import {TextEditor} from '../../../Tiptap/Editor';

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 600,
		'&::first-letter': {
			fontSize: '150%',
		},
	},
	content: {
		fontFamily: 'Times New Roman',
	},
	cardHeader: {
		paddingBottom: '0px !important',
	},
	cardContent: {
		paddingTop: '0px !important',
	},
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
	},
	cardActions: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

export default function NewQuestionDialog({loading, onClose, handleAddNewQuestion}) {
	const classes = useStyles();

	const [title, setTitle] = useState('');
	const [problem, setProblem] = useState('');
	const [testCases, setTestCases] = useState('');
	const [titleError, setTitleError] = useState(false);
	const [chars, setChars] = useState(0);
	const [words, setWords] = useState(0);

	const handleChangeTitle = useCallback(e => {
		if (!e.target.value) setTitleError(true);
		else setTitleError(false);
		setTitle(e.target.value);
	}, []);

	const Actions = () => (
		<>
			<Button variant='contained' startIcon={loading && <CircularProgress sx={{color: '#fff'}} size={20} />} color='primary' size='small' className={classes.button} disabled={titleError || !title} onClick={() => handleAddNewQuestion(title, problem, testCases)}>
				{'Add'}
			</Button>
			{chars !== 0 && <Typography color='#AAA'>{`${chars} ${chars > 1 ? 'Characters' : 'Character'} / ${words} ${words > 1 ? 'Words' : 'Word'}`}</Typography>}
			<Button variant='outlined' color='primary' size='small' className={classes.button} onClick={onClose}>
				Close
			</Button>
		</>
	);

	return (
		<Dialog open={true} maxWidth='lg' scroll='body'>
			<GenericCard
				handleClose={onClose}
				actions={<Actions />}
				title={
					<Typography variant='h6' fontWeight={600} fontFamily='Fredoka'>
						Add new question
					</Typography>
				}
			>
				<TextField fullWidth size='small' variant='outlined' label='Title' error={titleError} value={title} onChange={handleChangeTitle} />
				<Box className={classes.content} mt={1}>
					<Typography fontSize={12} color='#aaa'>
						Problem
					</Typography>
					<Box border='1px solid #EEE' pl={1} pr={1} pb={1} mb={1} height={300} maxHeight={400} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
						<TextEditor notes={problem} setNotes={setProblem} setCharacters={setChars} setWords={setWords} editable={true} />
					</Box>
					<Typography fontSize={12} color='#aaa'>
						Test cases
					</Typography>
					<Box border='1px solid #EEE' pl={1} pr={1} pb={1} height={250} maxHeight={250} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
						<TextEditor notes={testCases} setNotes={setTestCases} setCharacters={setChars} setWords={setWords} editable={true} />
					</Box>
				</Box>
			</GenericCard>
		</Dialog>
	);
}
