import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {Assignment, Code, Gesture, People, Quiz} from '@mui/icons-material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {useRealtime} from '../../RealtimeContext/RealtimeContext';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import StartedQuiz from '../Fragments/StartedQuiz';
import CurrentCandidateSideSection from '../Fragments/CandidateSideSection/CandidateSideSection';
import {ListSubheader, Typography} from '@mui/material';
import {useAccount} from '../../AccountContext/AccountContext';

export const MainListItems = () => {
	const history = useHistory();

	const handleDashboard = () => {
		history.push('/Dashboard');
	};

	const handleCodePad = () => {
		history.push('/LinearPad/Ace');
	};

	return (
		<React.Fragment>
			<ListItemButton onClick={handleDashboard} selected={history.location.pathname === '/Dashboard'} sx={{padding: '2px 4px 2px 8px'}}>
				<ListItemIcon>
					<DashboardIcon htmlColor='#1776D2' />
				</ListItemIcon>
				<ListItemText primary='Dashboard' />
			</ListItemButton>
			<ListItemButton onClick={handleCodePad} selected={history.location.pathname === '/LinearPad/Ace'} sx={{padding: '2px 4px 2px 8px'}}>
				<ListItemIcon>
					<Code htmlColor='#1776D2' />
				</ListItemIcon>
				<ListItemText primary='CodePad' />
			</ListItemButton>
		</React.Fragment>
	);
};

export const SecondaryListItems = () => {
	const {planPermissions} = useAccount();
	const history = useHistory();

	const handleSimple01 = () => {
		history.push('/Simple01');
	};
	const handleCandidates = () => {
		history.push('/Candidates');
	};
	const handleQuestions = () => {
		history.push('/Questions');
	};
	const handleDraw = () => {
		history.push('/Draw');
	};
	const handleAvailability = () => {
		history.push('/Availability');
	};

	return (
		<>
			<ListSubheader component='div' inset sx={{lineHeight: '26px', paddingLeft: 7}}>
				Action Center
			</ListSubheader>
			{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('draw') || planPermissions.permissions.includes('draw.accessable')) && (
				<ListItemButton onClick={handleDraw} selected={history.location.pathname === '/Draw'} sx={{padding: '2px 4px 2px 8px'}}>
					<ListItemIcon>
						<Gesture color='primary' />
					</ListItemIcon>
					<ListItemText primary='Draw' />
				</ListItemButton>
			)}
			<ListItemButton onClick={handleSimple01} selected={history.location.pathname === '/Simple01'} sx={{padding: '2px 4px 2px 8px'}}>
				<ListItemIcon>
					<Assignment color='primary' />
				</ListItemIcon>
				<ListItemText primary='Simple-01' />
			</ListItemButton>
			{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.accessable')) && (
				<ListItemButton onClick={handleCandidates} selected={history.location.pathname === '/Candidates'} sx={{padding: '2px 4px 2px 8px'}}>
					<ListItemIcon>
						<People color='primary' />
					</ListItemIcon>
					<ListItemText primary='Candidates' />
				</ListItemButton>
			)}
			{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.accessable')) && (
				<ListItemButton onClick={handleQuestions} selected={history.location.pathname === '/Questions'} sx={{padding: '2px 4px 2px 8px'}}>
					<ListItemIcon>
						<Quiz color='primary' />
					</ListItemIcon>
					<ListItemText primary='Questions' />
				</ListItemButton>
			)}
			{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('schedule') || planPermissions.permissions.includes('schedule.availability')) && (
				<ListItemButton onClick={handleAvailability} selected={history.location.pathname === '/Availability'} sx={{padding: '2px 4px 2px 8px'}}>
					<ListItemIcon>
						<EventNoteIcon htmlColor='#1776D2' />
					</ListItemIcon>
					<ListItemText primary='Availability' />
				</ListItemButton>
			)}
		</>
	);
};

export const RealtimeItems = () => {
	const {realtime} = useRealtime();
	const [isCurrentQuizSectionBugged, setIsCurrentQuizSectionBugged] = React.useState(false);
	const [isCurrentCandidateSectionBugged, setIsCurrentCandidateSectionBugged] = React.useState(false);

	return realtime.currentCandidateId || realtime.currentQuiz ? (
		<>
			{realtime.currentCandidateId && (
				<>
					<Typography align='center' color='#aaa'>
						current candidate
					</Typography>

					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCurrentCandidateSectionBugged(false)} resetKeys={[isCurrentCandidateSectionBugged]}>
						<CurrentCandidateSideSection currentCandidateId={realtime.currentCandidateId} currentQuiz={realtime.currentQuiz} />
					</ErrorBoundary>
				</>
			)}
			{realtime.currentQuiz && (
				<>
					<Typography align='center' color='#aaa'>
						current quiz
					</Typography>
					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCurrentQuizSectionBugged(false)} resetKeys={[isCurrentQuizSectionBugged]}>
						<StartedQuiz candidateId={realtime.currentCandidateId} currentQuiz={realtime.currentQuiz} />
					</ErrorBoundary>
				</>
			)}
		</>
	) : null;
};
