const INITIAL_STATE = {
	questionsFetch: true,
};

const applyFetchQuestions = (state, action) => ({
	...state,
	questionsFetch: action.questionsFetch,
});

function questionsFetchReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'QUESTIONS_FETCH': {
			return applyFetchQuestions(state, action);
		}
		default:
			return state;
	}
}

export default questionsFetchReducer;
