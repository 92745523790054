import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#2496FF",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    paddingLeft: 5,
    paddingRight:5,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    minHeight: "auto",
    marginRight: 1,
    borderTopRightRadius: 10,
    color: "rgba(0, 0, 0, 0.5)",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
