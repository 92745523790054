import { Button, ButtonGroup, CircularProgress } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useCallback, useState } from "react";
import axios from "axios";
import Env from "../../../util/Env";
import { useSnackbar } from "notistack";

export const Image = ({ editor }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleUploadImage = useCallback(
    (event) => {
      setLoading(true);
      if (event.target.files[0]) {
        var formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("target", "123");
        axios
          .post(Env().url + "/images/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.url) {
              editor.chain().focus().setImage({ src: res.data.url }).run();
              setLoading(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar("something went wrong", { variant: "error" });
            setLoading(false);
          });
      } else {
        enqueueSnackbar("no file is selected", { variant: "error" });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  return (
    <>
      <ButtonGroup size="small">
        <Button variant="outlined" component="label">
          {loading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <ImageIcon fontSize="small" />
          )}
          <input
            accept="image/*"
            type="file"
            onChange={handleUploadImage}
            hidden
          />
        </Button>
      </ButtonGroup>
    </>
  );
};
