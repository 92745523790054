import {Avatar, IconButton, Typography, Menu, List, ListItemButton, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../AuthContext/AuthContext';
import GenericDrawer from '../GenericDrawer';
import firebase from 'firebase';
import SettingsIcon from '@mui/icons-material/Settings';
import AddAlertDialog from './AddAlertDialog';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {ErrorBoundary} from 'react-error-boundary';
import AddNotificationDialog from './AddNotificationDialog';
import AddPlanUserDialog from './AddPlanUserDialog';
import {useAccount} from '../../AccountContext/AccountContext';
import Lottie from '../Fragments/Lottie';
import Profile from '../Profile';

const useStyles = makeStyles(() => ({
	button: {
		textTransform: 'none !important',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between !important',
		gap: 10,
		paddingRight: '16px !important',
	},
}));

export default function LoggedUser({userType, nav, isDrawerOpen}) {
	const classes = useStyles();
	const history = useHistory();
	const {loggedInUser, logout} = useAuth();
	const {account, userObj} = useAccount();
	const {enqueueSnackbar} = useSnackbar();
	var roomRef = firebase.app('linearPad').database().ref().child(loggedInUser.uid);

	const [menuAnchor, setMenuAnchor] = useState('');
	const [planMenuAnchor, setPlanMenuAnchor] = useState('');
	const [openMenu, setOpenMenu] = useState(false);
	const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
	const [openPlanMenu, setOpenPlanMenu] = useState(false);
	const [openAlertDialog, setOpenAlertDialog] = useState(false);
	const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
	const [openAddPlanUserDialog, setOpenAddPlanUserDialog] = useState(false);
	const [isAlertBugged, setIsAlertBugged] = useState(false);
	const [isNotificationBugged, setIsNotificationBugged] = useState(false);
	const [isAddPlanUserBugged, setIsAddPlanUserBugged] = useState(false);
	const [adminAccount, setAdminAccount] = useState(false);

	const handleOpenPlanMenu = useCallback(e => {
		ReactGA.event({
			category: userType + ' action',
			action: "open plan's menu",
		});
		setOpenPlanMenu(true);
		setPlanMenuAnchor(e.currentTarget);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenMenu = useCallback(e => {
		ReactGA.event({
			category: userType + ' action',
			action: "open user's menu",
		});
		setOpenMenu(true);
		setMenuAnchor(e.currentTarget);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClosePlanMenu = useCallback(() => {
		ReactGA.event({
			category: userType + ' action',
			action: "close plan's menu",
		});
		setPlanMenuAnchor('');
		setOpenPlanMenu(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseMenu = useCallback(() => {
		ReactGA.event({
			category: userType + ' action',
			action: "close user's menu",
		});
		setMenuAnchor('');
		setOpenMenu(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenAlertDialog = () => {
		setOpenMenu(false);
		setOpenAlertDialog(true);
	};
	const handleOpenAddNotificationDialog = () => {
		setOpenMenu(false);
		setOpenNotificationDialog(true);
	};
	const handleOpenAddPlanUserDialog = isAdmin => {
		setAdminAccount(isAdmin);
		setOpenPlanMenu(false);
		setOpenAddPlanUserDialog(true);
	};

	const handleOpenProfileDrawer = () => {
		setOpenProfileDrawer(true);
		setOpenMenu(false);
	};

	const handleLogOut = async () => {
		try {
			history.push({pathname: '/'});
			await logout();
			roomRef && roomRef.remove();
			enqueueSnackbar('Logged out! see you soon');
			ReactGA.event({
				category: 'Logout',
				action: userType + ' logged out',
			});
		} catch (err) {
			enqueueSnackbar(err.message, {variant: 'error'});
		}
	};

	return (
		<>
			{openNotificationDialog && (
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsNotificationBugged(false)} resetKeys={[isNotificationBugged]}>
					<AddNotificationDialog handleClose={() => setOpenNotificationDialog(false)} />
				</ErrorBoundary>
			)}
			{openAlertDialog && (
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsAlertBugged(false)} resetKeys={[isAlertBugged]}>
					<AddAlertDialog handleClose={() => setOpenAlertDialog(false)} />
				</ErrorBoundary>
			)}
			{openAddPlanUserDialog && (
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsAddPlanUserBugged(false)} resetKeys={[isAddPlanUserBugged]}>
					<AddPlanUserDialog handleClose={() => setOpenAddPlanUserDialog(false)} isAdmin={adminAccount} />
				</ErrorBoundary>
			)}
			<GenericDrawer open={openProfileDrawer} title='Profile' position='right' handleClose={() => setOpenProfileDrawer(false)} titleBackground='#0E0E0E' titleColor='#FFF'>
				<Profile />
			</GenericDrawer>
			<Menu
				open={openPlanMenu}
				anchorEl={planMenuAnchor}
				onClose={handleClosePlanMenu}
				anchorOrigin={{vertical: 'center', horizontal: 'right'}}
				transformOrigin={{vertical: 'center', horizontal: 'left'}}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							bottom: 15,
							left: -5,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(135deg)',
							zIndex: 0,
						},
					},
				}}
			>
				<List dense disablePadding={true}>
					{userObj && userObj.accountRole === 'Admin' ? (
						<>
							<ListItemButton dense onClick={() => handleOpenAddPlanUserDialog(true)}>
								Add user to plan
							</ListItemButton>
						</>
					) : (
						<ListItemButton dense onClick={() => handleOpenAddPlanUserDialog(false)}>
							Plan users
						</ListItemButton>
					)}
				</List>
			</Menu>
			<Menu
				open={openMenu}
				anchorEl={menuAnchor}
				onClose={handleCloseMenu}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{vertical: 'center', horizontal: 'left'}}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							bottom: 10,
							left: -5,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(135deg)',
							zIndex: 0,
						},
					},
				}}
			>
				<List dense disablePadding={true}>
					<ListItemButton dense onClick={handleOpenProfileDrawer}>
						Profile
					</ListItemButton>
					<ListItemButton dense onClick={handleOpenAlertDialog}>
						Add Alert
					</ListItemButton>
					<ListItemButton dense onClick={handleOpenAddNotificationDialog}>
						Add Notification
					</ListItemButton>
					<ListItemButton dense onClick={handleLogOut}>
						Logout
					</ListItemButton>
				</List>
			</Menu>
			<Box width='100%' display='flex' flexDirection='column'>
				{nav && !isDrawerOpen ? (
					<IconButton onClick={handleOpenMenu} sx={{padding: 0, border: '0px !important'}} title={loggedInUser.displayName + ', ' + loggedInUser.email}>
						<Avatar sx={{width: 35, height: 35}} src={userType === 'authUser' ? (userObj?.photo ? userObj?.photo : 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media') : ''}>
							{userType !== 'authUser' && 'A'}
						</Avatar>
					</IconButton>
				) : (
					<>
						{account && account.accountType && (
							<Button fullWidth centerRipple={false} size='small' title='Plan config' sx={{display: 'flex', justifyContent: 'center', textTransform: 'none'}} onClick={handleOpenPlanMenu}>
								<Box display='flex' alignItems='center' gap={2}>
									<Typography fontSize='0.9rem' overflow='hidden' textOverflow='ellipsis' fontFamily='Fredoka' fontWeight={600}>
										{account.accountType} Plan
									</Typography>
								</Box>
								<Lottie url='https://assets7.lottiefiles.com/packages/lf20_it8t8omj.json' width='40px' height='40px' />
							</Button>
						)}
						<Button fullWidth centerRipple={false} className={classes.button} size='small' onClick={handleOpenMenu}>
							<Box display='flex' alignItems='center' gap={2}>
								<Avatar
									sx={{width: 30, height: 30}}
									src={userType === 'authUser' ? (userObj?.photo ? userObj?.photo : 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media') : ''}
								>
									{userType !== 'authUser' && 'A'}
								</Avatar>
								<Box>
									<Box display='flex' alignItems='center' gap={0.5}>
										<Box width={5} height={5} borderRadius='50%' bgcolor='#2ccf4f' />
										<Typography color='#000' fontSize='0.7rem' align='center' maxWidth={90} overflow='hidden' textOverflow='ellipsis'>
											{loggedInUser.displayName}
										</Typography>
									</Box>
									<Typography color='InfoText' fontSize='0.5rem' maxWidth={100} overflow='hidden' textOverflow='ellipsis'>
										{loggedInUser.email ? loggedInUser.email : 'Anonymous'}
									</Typography>
								</Box>
							</Box>
							<IconButton component='span' sx={{display: 'flex', border: 'none !important', float: 'right'}} edge='end' disabled={true} size='small'>
								<SettingsIcon color='action' />
							</IconButton>
						</Button>
					</>
				)}
			</Box>
		</>
	);
}
