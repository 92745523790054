import {Stop} from '@mui/icons-material';
import {IconButton, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {memo, useCallback, useState} from 'react';
import QuizContentPopper from './QuizContentPopper';
import ReactGA from 'react-ga';
import firebase from 'firebase';
import ConfirmationPopper from './ConfirmationPopper';
import {useAuth} from '../../AuthContext/AuthContext';

export default memo(function StartedQuiz({currentQuiz}) {
	const {loggedInUser} = useAuth();

	const ref = firebase.app('linearPad').database().ref();
	const roomRef = ref.child(loggedInUser.uid);
	const currentQuizRef = roomRef.child('currentQuiz');

	const [openQuizContentPopper, setOpenQuizContentPopper] = useState(false);
	const [chosenQuiz, setChosenQuiz] = useState({});
	const [anchorQuizContentPopper, setAnchorQuizContentPopper] = useState('');
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = useState('');

	const handleQuizClick = useCallback((event, example) => {
		ReactGA.event({
			category: 'Example description',
			action: 'open the example description popper',
		});
		setAnchorQuizContentPopper(event.currentTarget);
		setChosenQuiz(example);
		setOpenQuizContentPopper(true);
	}, []);

	const handleCloseQuizContentPopper = useCallback(() => {
		ReactGA.event({
			category: 'Examples description',
			action: 'close the example description popper',
		});
		setOpenQuizContentPopper(false);
		setAnchorQuizContentPopper('');
		setChosenQuiz({});
	}, []);

	const handleStopQuiz = useCallback(e => {
		e.stopPropagation();
		currentQuizRef.set('');
		setOpenConfirmationPopper(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenConfirmationPopper = useCallback(e => {
		e.stopPropagation();
		setAnchorConfirmationPopper(e.currentTarget);
		setOpenConfirmationPopper(true);
	}, []);

	const handleCloseConfirmationPopper = useCallback(() => {
		setOpenConfirmationPopper(false);
	}, []);

	return (
		<>
			{openQuizContentPopper && <QuizContentPopper anchor={anchorQuizContentPopper} arrow={true} placement='right' quiz={chosenQuiz} onClose={handleCloseQuizContentPopper} hideMoreDetails={true} />}
			{openConfirmationPopper && (
				<ConfirmationPopper
					operation='Stop'
					name='Quiz'
					placement='bottom'
					arrow={true}
					anchor={anchorConfirmationPopper}
					cancel='Close'
					confirm='Stop'
					message='Are you sure?'
					onCancel={handleCloseConfirmationPopper}
					onClose={handleCloseConfirmationPopper}
					onConfirm={handleStopQuiz}
				/>
			)}
			<Box display={currentQuiz ? 'flex' : 'none'} flexDirection='column' justifyContent='space-between' bgcolor='#fff'>
				<Box display='flex' flexDirection='column' gap={10}>
					<ListItemButton sx={{padding: 1}} onClick={e => handleQuizClick(e, currentQuiz)}>
						<ListItemIcon>
							<IconButton size='small' onClick={handleOpenConfirmationPopper} color='error'>
								<Stop fontSize='small' />
							</IconButton>
						</ListItemIcon>
						<ListItemText sx={{ml: -3}}>
							<Typography fontSize={12}>{currentQuiz.title}</Typography>
						</ListItemText>
					</ListItemButton>
				</Box>
			</Box>
		</>
	);
});
