import React from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
	link: {
		color: '#0476BD',
		textTransform: 'none',
		textDecoration: 'underline',
		fontWeight: 'normal',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	footer: {
		paddingTop: 5,
		width: '100%',
		background: '#0E0E0E',
		color: '#fff',
		paddingBottom: 2,
		marginTop: 'auto',
	},
}));

export default function Footer() {
	const classes = useStyles();

	return (
		<Box className={classes.footer}>
			<Typography variant='h3' align='center' gutterBottom>
				Our mission
			</Typography>
			<Typography variant='h6' align='center' component='p'>
				To help you learn the skills you need to achieve your full potential.
			</Typography>
			<Typography variant='body1' align='center'>
				{'Copyright © '}
				<a href='/' className={classes.link}>
					Linearbits
				</a>{' '}
				{new Date().getFullYear()}
			</Typography>
		</Box>
	);
}
