import {Delete, Share, Upload} from '@mui/icons-material';
import {CircularProgress, ListItemIcon, Menu, MenuItem, MenuList} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useAccount} from '../../../../../AccountContext/AccountContext';
import {useAuth} from '../../../../../AuthContext/AuthContext';
import Env from '../../../../../util/Env';
import ConfirmationPopper from '../../../../Fragments/ConfirmationPopper';

export default function OptionsMenu({candidate, revokeLoading, isUserCandidates, anchorEl, handleDelete, fetchCandidates, handleShare, handleRevoke, onClose}) {
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = useState('');
	const [uploadLoading, setUploadLoading] = useState(false);
	const {planPermissions} = useAccount();
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const handleOpenConfirmationPopper = e => {
		setOpenConfirmationPopper(true);
		setAnchorConfirmationPopper(e.currentTarget);
	};
	const handleConfirmDelete = () => {
		handleDelete();
		handleCloseConfirmationPopper();
		onClose();
	};

	const handleUploadCV = event => {
		if (event.target.files[0]) {
			setUploadLoading(true);
			if (event.target.files[0].name.split('.').pop() === 'pdf') {
				let file = event.target.files[0];
				var formData = new FormData();
				formData.append('file', file);
				axios
					.post(Env().url + '/sketch/uploadCV', formData, {
						headers: {
							Authorization: 'Bearer ' + token,
						},
					})
					.then(res => {
						setUploadLoading(false);
						axios
							.post(
								Env().url + '/pad',
								{...candidate, cv: res.data.id, config: JSON.stringify(candidate.config)},
								{
									headers: {
										Authorization: 'Bearer ' + token,
									},
								}
							)
							.then(res => {
								setUploadLoading(false);
								fetchCandidates();
								onClose();
							})
							.catch(err => {
								setUploadLoading(false);
								enqueueSnackbar(err.message, {variant: 'error'});
							});
					})
					.catch(err => {
						setUploadLoading(false);
						enqueueSnackbar(err.message, {variant: 'error'});
					});
			} else {
				setUploadLoading(false);
				enqueueSnackbar('Only accept pdf files', {variant: 'error'});
			}
		}
	};

	const handleCloseConfirmationPopper = () => {
		setOpenConfirmationPopper(false);
		setAnchorConfirmationPopper('');
	};
	return (
		<>
			{openConfirmationPopper && (
				<ConfirmationPopper
					arrow={true}
					anchor={anchorConfirmationPopper}
					message='Are you sure?'
					operation='Delete'
					name='Candidate'
					cancel='Cancel'
					confirm='Delete'
					placement='left'
					onCancel={handleCloseConfirmationPopper}
					onClose={handleCloseConfirmationPopper}
					onConfirm={handleConfirmDelete}
				/>
			)}
			<Menu open={true} anchorEl={anchorEl} onClose={onClose}>
				{isUserCandidates ? (
					<MenuList dense disablePadding>
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.share')) && (
							<MenuItem onClick={handleShare}>
								<ListItemIcon>
									<Share color='primary' fontSize='small' />
								</ListItemIcon>
								Share
							</MenuItem>
						)}
						<MenuItem component='label'>
							<input accept='application/pdf' type='file' onChange={handleUploadCV} hidden />
							<ListItemIcon>{uploadLoading ? <CircularProgress size={20} /> : <Upload color='primary' fontSize='small' />}</ListItemIcon>
							{candidate.cv ? 'Upload new CV' : 'Upload CV'}
						</MenuItem>
						<MenuItem onClick={handleOpenConfirmationPopper}>
							<ListItemIcon>
								<Delete color='error' fontSize='small' />
							</ListItemIcon>
							Delete
						</MenuItem>
					</MenuList>
				) : (
					<MenuItem onClick={handleRevoke}>
						{revokeLoading && (
							<ListItemIcon>
								<CircularProgress size={20} />
							</ListItemIcon>
						)}
						Revoke Share
					</MenuItem>
				)}
			</Menu>
		</>
	);
}
