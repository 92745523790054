import {CheckCircle, CircleOutlined} from '@mui/icons-material';
import {Button, Card, CardActions, CardContent, Checkbox, CircularProgress, ClickAwayListener, ListItemButton, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import {Arrow, Popper} from '../../common/PopperFragments';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function RecommendationPopper({notes, setNotes, onCancel, onConfirm, onClose, anchor, placement, arrow, confirm, cancel, loading}) {
	const [arrowRef, setArrowRef] = useState(null);

	const handleChangeMessage = e => {
		setNotes(notes => ({...notes, message: e.target.value}));
	};

	const handleChangeRecommendation = type => {
		if (type === 'recommended') {
			setNotes(notes => ({...notes, recommended: true}));
		} else if (type === 'notRecommended') {
			setNotes(notes => ({...notes, recommended: false}));
		}
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card elevation={5} sx={{borderRadius: '15px'}}>
					<CardContent>
						<ListItemButton sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '4px'}} onClick={() => handleChangeRecommendation('recommended')}>
							<Typography fontFamily='Fredoka'>Recommended</Typography>
							<Checkbox checked={notes && notes.recommended === true} icon={<CircleOutlined />} checkedIcon={<CheckCircle />} />
						</ListItemButton>
						<ListItemButton sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '4px'}} onClick={() => handleChangeRecommendation('notRecommended')}>
							<Typography fontFamily='Fredoka'>Not Recommended</Typography>
							<Checkbox checked={notes && notes.recommended === false} icon={<CircleOutlined />} checkedIcon={<CheckCircle />} />
						</ListItemButton>
						<Box mt={1} width='100%'>
							<TextareaAutosize minRows={3} placeholder='Write message ...' value={notes && notes.message ? notes.meassage : ''} onChange={handleChangeMessage} style={{width: '100%', maxHeight: '100px'}} />
						</Box>
					</CardContent>
					<CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button color='error' size='small' variant='outlined' onClick={onCancel} sx={{textTransform: 'none'}}>
							{cancel}
						</Button>
						<Button
							color='error'
							size='small'
							variant='contained'
							sx={{textTransform: 'none'}}
							startIcon={loading ? <CircularProgress size={20} sx={{color: '#fff'}} /> : ''}
							onClick={onConfirm}
							disabled={!notes || !notes.message || (notes.recommended !== true && notes.recommended !== false)}
						>
							{confirm}
						</Button>
					</CardActions>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
}
