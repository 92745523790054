import {Slider, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box, styled} from '@mui/system';

const CustomSlider = styled(Slider)(() => ({
	color: '#0E0E0E',
	height: 2,
	padding: '15px 0',
	'& .MuiSlider-thumb': {
		height: 0,
		width: 0,
	},
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-rail': {
		opacity: 0.5,
		backgroundColor: '#0E0E0E',
	},
	'& .MuiSlider-mark': {
		backgroundColor: '#0E0E0E',
		height: 10,
		width: 10,
		borderRadius: '50%',
		'&.MuiSlider-markActive': {
			opacity: 1,
			backgroundColor: '#0E0E0E',
		},
	},
}));

const useStyles = makeStyles(() => ({
	emoji: {
		fontSize: 20,
	},
}));

export default function HardnessLevelLegend() {
	const classes = useStyles();

	return (
		<Box width='100%' display='flex' justifyContent='center' alignItems='center' component='td'>
			<Box width='50%'>
				<Box width='100%' display='flex' justifyContent='space-between' mb={-1}>
					<Box className={classes.emoji}>
						<Box ml='-35%'>&#128526;</Box>
					</Box>
					<Box className={classes.emoji}>
						<Box ml='15%'>&#128528;</Box>
					</Box>
					<Box className={classes.emoji}>
						<Box ml='65%'>&#128545;</Box>
					</Box>
				</Box>
				<CustomSlider
					min={0}
					max={2}
					defaultValue={2}
					marks={[
						{
							value: 0,
							label: (
								<Typography component='span' fontFamily='Poppins' fontWeight={600} ml={1.5} fontSize={12} mt={-1}>
									Easy
								</Typography>
							),
						},
						{
							value: 1,
							label: (
								<Typography component='span' fontFamily='Poppins' fontWeight={600} ml={1.5} fontSize={12} mt={-1}>
									Medium
								</Typography>
							),
						},
						{
							value: 2,
							label: (
								<Typography component='span' fontFamily='Poppins' fontWeight={600} ml={1.5} fontSize={12} mt={-1}>
									Hard
								</Typography>
							),
						},
					]}
					color='primary'
					sx={{pointerEvents: 'none'}}
				></CustomSlider>
			</Box>
		</Box>
	);
}
