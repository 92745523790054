import React, {useContext, useState, useEffect} from 'react';
import firebase from 'firebase';
import firebaseConfig from '../util/firebaseConfig';
import usersDbConfig from '../util/usersDbConfig';
import Cookies from 'universal-cookie';

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig, 'linearPad');
	firebase.initializeApp(usersDbConfig, 'usersFirebase');
}

const auth = firebase.app('usersFirebase').auth();
const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({children}) {
	const cookies = new Cookies();
	const provider = new firebase.auth.GithubAuthProvider();

	const [loggedInUser, setLoggedInUser] = useState('');
	const [token, setToken] = useState('');
	const [loading, setLoading] = useState(true);

	function githubLogin() {
		return auth.signInWithPopup(provider);
	}
	function signup(email, password) {
		return auth.createUserWithEmailAndPassword(email, password);
	}

	function login(email, password) {
		return auth.signInWithEmailAndPassword(email, password);
	}

	function getIdToken() {
		return auth.currentUser.getIdToken(true);
	}

	function logout() {
		return auth.signOut();
	}

	function resetPassword(email) {
		return auth.sendPasswordResetEmail(email);
	}

	function updateEmail(email) {
		return loggedInUser.updateEmail(email);
	}

	function updatePassword(password) {
		return loggedInUser.updatePassword(password);
	}

	function signInAnonymously() {
		return auth.signInAnonymously();
	}

	useEffect(() => {
		setToken(cookies.get('token'));
		const unsubscribe = auth.onAuthStateChanged(user => {
			setLoggedInUser(user);
			if (user) {
				user.getIdToken(true).then(token => {
					cookies.set('token', token, {path: '/', maxAge: 3600});
					setToken(token);
				});
			}
			setLoading(false);
		});

		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = {
		loggedInUser,
		token,
		login,
		getIdToken,
		signup,
		githubLogin,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		signInAnonymously,
	};

	return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
