import {Close} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, Paper, Slide, Typography, useMediaQuery, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import Cookies from 'universal-cookie';

const useStyles = makeStyles(() => ({
	div: {
		position: 'fixed',
		margin: '0px 0px 25px 20px',
		zIndex: 2000,
		width: 400,
	},

	grid: {
		outline: 'none',
	},

	paper: {
		position: 'relative !important',
		maxWidth: '85% !important',
		borderRadius: '20px !important',
		padding: '10px !important',
	},

	typographyGridItem: {
		display: 'flex !important',
		flexDirection: 'column !important',
		margin: '0px 15px 0px 15px !important',
	},

	button: {
		borderRadius: '20px !important',
		margin: '0px 15px 10px 0px !important',
	},
	typography: {
		fontFamily: 'Quicksand, sans-serif !important',
	},
	close: {
		position: 'absolute !important',
		top: 10,
		right: 10,
		'&:hover .closeIcon': {
			transform: 'rotate(90deg)',
		},
	},
	icon: {
		transition: 'transform 0.2s ease-in-out !important',
	},
	dialogContent: {
		position: 'relative !important',
	},
}));

const CookiesBanner = () => {
	const cookies = new Cookies();
	const [isVisible, setIsVisible] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		if (cookies.get('cookieConsentAccepted')) {
			setIsVisible(false);
		} else {
			setIsVisible(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const classes = useStyles();

	const handleCloseWithNoAccept = useCallback(() => {
		setIsVisible(false);
	}, []);

	const handleClose = useCallback(() => {
		setIsVisible(false);

		cookies.set('cookieConsentAccepted', true, {path: '/', maxAge: 300000000});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const desktopBanner = (
		<div role='dialog' className={classes.div} style={{bottom: 10, left: 10}}>
			<Slide direction='up' in={isVisible} mountOnEnter unmountOnExit>
				<Grid data-testid='cookiesConsent' className={classes.grid} container>
					<Paper className={classes.paper} elevation={7}>
						<Grid item className={classes.typographyGridItem} xs={12}>
							<Typography component='span' align='justify' variant='h4' gutterBottom={true} className={classes.typography}>
								Cookie Consent
							</Typography>
							<Typography component='span' align='justify' className={classes.typography}>
								We don't save your data! It stays encrypted inside of your device and belongs only to you. We only use cookies to analyze our traffic and user experience. By using this website, you accept that we use cookies.
							</Typography>
						</Grid>
						<Grid container justifyContent='flex-end'>
							<Button data-testid='cookieConsentCloseButton' onClick={() => handleClose()} className={classes.button} variant='contained' color='primary'>
								Accept
							</Button>
						</Grid>
						<IconButton className={classes.close} size='small' onClick={handleCloseWithNoAccept}>
							<Close className={[classes.icon, 'closeIcon'].join(' ')} />
						</IconButton>
					</Paper>
				</Grid>
			</Slide>
		</div>
	);

	const mobileDialog = (
		<Dialog data-testid='cookiesConsent' open={isVisible} onClose={handleClose}>
			<DialogContent className={classes.dialogContent}>
				<IconButton className={classes.close} size='small' onClick={handleCloseWithNoAccept}>
					<Close />
				</IconButton>
				<DialogContentText id='alert-dialog-description' style={{display: 'flex', flexDirection: 'column'}}>
					<Typography component='span' align='justify' variant='h5' gutterBottom={true} style={{fontFamily: 'Quicksand, sans-serif'}}>
						Cookie Consent
					</Typography>
					<Typography component='span' align='justify' style={{fontFamily: 'Quicksand, sans-serif'}}>
						We don't save your data! It stays encrypted inside of your device and belongs only to you. We only use cookies to analyze our traffic and user experience. By using this website, you accept that we use cookies.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button data-testid='cookieConsentCloseButton' className={classes.button} onClick={handleClose} variant='contained' color='primary'>
					Understood
				</Button>
			</DialogActions>
		</Dialog>
	);

	if (isMobile) {
		return mobileDialog;
	}
	return desktopBanner;
};

export default CookiesBanner;
