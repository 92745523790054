import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import {useTheme, useMediaQuery} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {MainListItems, RealtimeItems, SecondaryListItems} from '../Dashboard/listItems';
import {ChevronLeft, PaidRounded} from '@mui/icons-material';
import LoggedUser from '../Fragments/LoggedUser';
import {useAuth} from '../../AuthContext/AuthContext';
import {Divider, ListItemButton, ListItemIcon, Slide, Typography} from '@mui/material';
import AlertBox from '../Fragments/AlertBox';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useRealtime} from '../../RealtimeContext/RealtimeContext';
import NotificationsPopper from '../NotificationsPopper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import CookiesBanner from '../CookiesBanner';
import PricingDialog from './PricingDialog';

const drawerWidth = 200;

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(6)} + 1px)`,
	},
});

const useStyles = makeStyles(() => ({
	logo: {
		display: 'flex',
		textDecoration: 'none',
		alignItems: 'center',
		gap: 5,
	},
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})(({theme}) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const mdTheme = createTheme();

function PageLayout({children, header, nav, backgroundColor, alerts, notifications}) {
	const {loggedInUser, token} = useAuth();
	const history = useHistory();
	const classes = useStyles();
	const {realtime} = useRealtime();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [{data: notificationsData}, fetchNotifications] = useAxios({url: Env().url + '/notifications/notify', method: 'GET', headers: {Authorization: 'Bearer ' + token}}, {manual: true});
	const [, fetchAlerts] = useAxios({url: Env().url + '/notifications/alert', method: 'GET', headers: {Authorization: 'Bearer ' + token}}, {manual: true});

	const secondaryListViewers = ['/LinearPad/Ace', '/Simple01', '/Candidates', '/Questions', '/Draw'];
	const [open, setOpen] = React.useState(true);
	const [openPricingDialog, setOpenPricingDialog] = React.useState(false);
	const [filteredAlerts, setFilteredAlerts] = React.useState([]);
	const [anchorNotificationsPopper, setAnchorNotificationsPopper] = React.useState('');
	const [openNotificationsPopper, setOpenNotificationsPopper] = React.useState(false);
	const [isAlertBugged, setIsAlertBugged] = React.useState(false);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		let tempAlerts = [...filteredAlerts];
		tempAlerts.pop();
		setFilteredAlerts(tempAlerts);
	};

	const handleOpenNotificationsPopper = e => {
		setAnchorNotificationsPopper(e.currentTarget);
		setOpenNotificationsPopper(true);
	};
	const handleCloseNotificationsPopper = e => {
		setOpenNotificationsPopper(false);
		setAnchorNotificationsPopper('');
	};

	React.useEffect(() => {
		fetchAlerts().then(res => {
			setFilteredAlerts(res.data);
		});
	}, [alerts, fetchAlerts]);

	React.useEffect(() => {
		fetchNotifications();
	}, [fetchNotifications, notifications]);

	return (
		<>
			<PricingDialog open={openPricingDialog} handleClose={() => setOpenPricingDialog(false)} />
			{openNotificationsPopper && <NotificationsPopper anchor={anchorNotificationsPopper} placement='bottom' arrow={true} data={notificationsData} onClose={handleCloseNotificationsPopper} />}
			<ThemeProvider theme={mdTheme}>
				<Box sx={{display: 'flex'}}>
					<CssBaseline />
					<CookiesBanner />
					{history.location.pathname !== '/404' && header && (
						<AppBar position='fixed' open={open} sx={{background: '#3A82D5'}}>
							<Toolbar
								sx={{
									minHeight: '45px !important',
									pr: '24px', // keep right padding when drawer closed
									pl: '8px !important',
								}}
							>
								{nav && (
									<IconButton
										edge='start'
										color='inherit'
										aria-label='open drawer'
										onClick={toggleDrawer}
										size='small'
										sx={{
											marginRight: '18px',
										}}
									>
										{open ? <ChevronLeft fontSize='small' /> : <MenuIcon fontSize='small' />}
									</IconButton>
								)}
								<Box mr={2}>
									<Slide in={true} direction='right'>
										<Link to={loggedInUser ? '/Dashboard' : '/'} className={classes.logo}>
											<img src='/images/LinearpadLogo.png' alt='LinearPad' height={40} />
											<Typography fontWeight={600} variant='h6' fontFamily='Fredoka' color='#fff'>
												Linearpad
											</Typography>
										</Link>
									</Slide>
								</Box>
								<Box flexGrow={1} />
								{loggedInUser && (
									<IconButton color='inherit' size='small' onClick={() => setOpenPricingDialog(true)} sx={{mr: 1}}>
										<PaidRounded fontSize='small' />
									</IconButton>
								)}
								{loggedInUser && (
									<IconButton color='inherit' size='small' onClick={handleOpenNotificationsPopper}>
										<Badge badgeContent={notificationsData && notificationsData.length > 0 ? notificationsData.length : 0} color='error'>
											<NotificationsIcon fontSize='small' />
										</Badge>
									</IconButton>
								)}
							</Toolbar>
						</AppBar>
					)}
					{history.location.pathname !== '/404' && nav && (
						<Drawer variant='permanent' open={open}>
							<Toolbar sx={{minHeight: '45px !important'}} />
							<List component='nav' sx={{height: '100%'}}>
								<Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
									<Box>
										<MainListItems />
										<Divider sx={{my: 1}} />
										<SecondaryListItems />
										<>
											{secondaryListViewers.includes(history.location.pathname) &&
												(realtime.currentCandidateId || realtime.currentQuiz) &&
												(open ? (
													<>
														<Divider sx={{my: 1}} />
														<RealtimeItems />
													</>
												) : (
													<>
														<Divider sx={{my: 1}} />
														{realtime.currentCandidateId && (
															<ListItemButton sx={{padding: '8px 4px 8px 8px'}} onClick={toggleDrawer}>
																<ListItemIcon>
																	<AccountCircleIcon htmlColor='#0e0e0e' />
																</ListItemIcon>
															</ListItemButton>
														)}
														{realtime.currentQuiz && (
															<ListItemButton sx={{padding: '8px 4px 8px 8px'}} onClick={toggleDrawer}>
																<ListItemIcon>
																	<ContactSupportIcon />
																</ListItemIcon>
															</ListItemButton>
														)}
													</>
												))}
										</>
									</Box>
									{loggedInUser && <LoggedUser userType='authUser' nav={true} isDrawerOpen={open} />}
								</Box>
							</List>
						</Drawer>
					)}
					<Box
						component='main'
						sx={{
							backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							minHeight: '100vh',
							overflow: 'auto',
							background: backgroundColor ? backgroundColor : '',
							backgroundSize: 'cover',
						}}
					>
						{history.location.pathname !== '/404' && header && <Toolbar sx={{minHeight: '45px !important'}} />}
						{history.location.pathname !== '/404' && !isMobile && filteredAlerts.length > 0 && (
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsAlertBugged(false)} resetKeys={[isAlertBugged]}>
								<AlertBox content={filteredAlerts[filteredAlerts.length - 1].msg} expiryDate={filteredAlerts[filteredAlerts.length - 1].expiresAt} handleClose={handleClose} ext={filteredAlerts[filteredAlerts.length - 1].ext} />
							</ErrorBoundary>
						)}
						<Divider />
						{children}
					</Box>
				</Box>
			</ThemeProvider>
		</>
	);
}

const mapStateToProps = state => ({
	alerts: state.alertsState.alerts,
	notifications: state.notificationsState.notifications,
});

export default connect(mapStateToProps, null)(PageLayout);
