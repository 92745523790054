const usersDbConfig = {
  apiKey: "AIzaSyC2uDFoWU5WYpyqJj96byjuuhJFafoX_Es",
  authDomain: "simpleeasylearning-5607d.firebaseapp.com",
  databaseURL: "https://simpleeasylearning-5607d-default-rtdb.firebaseio.com",
  projectId: "simpleeasylearning-5607d",
  storageBucket: "simpleeasylearning-5607d.appspot.com",
  messagingSenderId: "985982221346",
  appId: "1:985982221346:web:440b26be44754157d35e7b",
  measurementId: "G-Z4GXZHPGL5"
};

export default usersDbConfig;
