import {Box, Button, CircularProgress, Divider, Typography, useMediaQuery, useTheme} from '@mui/material';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useAccount} from '../../AccountContext/AccountContext';
import {useAuth} from '../../AuthContext/AuthContext';
import Env from '../../util/Env';
import Stripe from '../Stripe/Stripe';

export default function Pricing() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const {account, userObj, setAccount, planPermissions} = useAccount();
	const [loading, setLoading] = useState({});
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

	const [, updateUser] = useAxios(
		{
			url: Env().url + '/users',
			method: 'PUT',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);
	const [, updateAccount] = useAxios(
		{
			url: Env().url + '/accounts',
			method: 'PUT',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);
	const [, addAccount] = useAxios(
		{
			url: Env().url + '/accounts',
			method: 'POST',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);

	const plans = [
		{name: 'Free', description: 'Single-user plan to collaborate with candidates on writing working code', price: 0, interviews: 20, accounts: 1, features: ['Free example questions']},
		{name: 'Starter', description: '2 members team plan to collaborate with candidates on writing working code', price: 100, interviews: 50, accounts: 2, features: ['All code recorded', 'Drawing/whiteboard mode']},
		{name: 'Professional', description: 'Collaborate with your team on questions, files and databases', price: 150, interviews: 100, accounts: 50, bestChoice: true, features: ['Role-based permissions', 'Free example questions']},
		{name: 'Organization', description: 'Level up your hiring with a plan geared to high-volume interviewing', price: 250, interviews: 'Unlimited', accounts: 100, features: ['Free example questions']},
	];

	const handleBuyPlan = (name, index) => {
		setLoading({[index]: true});
		if (account.id) {
			let tmpAccount = {...account};
			tmpAccount.accountType = name;
			tmpAccount.accountStatus = 'valid';
			updateAccount({data: tmpAccount})
				.then(res => {
					setAccount(res.data);
					setLoading({[index]: false});
					enqueueSnackbar('subscribed to ' + name + ' plan', {variant: 'success'});
				})
				.catch(() => {
					setLoading({[index]: false});
					enqueueSnackbar("couldn't update account data!", {variant: 'error'});
				});
		} else {
			addAccount({data: {accountType: name, accountStatus: 'valid'}})
				.then(res => {
					updateUser({data: {...userObj, accountId: res.data.id, accountRole: 'Admin'}})
						.then(() => {
							setAccount(res.data);
							setLoading({[index]: false});
							enqueueSnackbar('subscribed to ' + name + ' plan', {variant: 'success'});
						})

						.catch(() => {
							setLoading({[index]: false});
							enqueueSnackbar("couldn't update user data!", {variant: 'error'});
						});
				})
				.catch(() => {
					setLoading({[index]: false});
					enqueueSnackbar("couldn't add new account data!", {variant: 'error'});
				});
		}
	};
	const handleCancelSubscription = index => {
		setLoading({[index]: true});
		updateUser({data: {...userObj, accountId: '', accountRole: ''}})
			.then(() => {
				setAccount({accountType: '', accountStatus: ''});
				setLoading({[index]: false});
				enqueueSnackbar('subscriptions is canceled!', {variant: 'success'});
			})

			.catch(() => {
				setLoading({[index]: false});
				enqueueSnackbar("couldn't cancel subscription!", {variant: 'error'});
			});
	};

	useEffect(() => {}, [account, userObj, planPermissions]);

	return (
		<>
			<Stripe open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)} />
			<Typography align='center' variant='h3' fontFamily='Fredoka' fontWeight={600} fontSize={!isMobile ? '3.5vw' : '6vw'}>
				Subscription plans
			</Typography>
			<Typography align='center' variant='h6' fontFamily='Fredoka' color='#aaa' fontSize={!isMobile ? '2.5vw' : '5vw'} mb={5}>
				Select suitable plan
			</Typography>
			<Box width='100%' gap={1} display='flex' flexDirection={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : ''} alignItems='center' flexWrap='wrap'>
				{/*Personal*/}
				{plans.map((plan, index) => (
					<Box
						key={index}
						bgcolor='#fff'
						width={isMobile ? '100%' : index * 15 + 269}
						minWidth={isMobile ? 290 : index * 15 + 269}
						mt={isMobile && plan.bestChoice ? 1 : 0}
						height={!isMobile && plan.bestChoice ? index * 20 + 460 : index * 20 + 430}
						padding='40px 20px'
						boxShadow={!isMobile && plan.bestChoice ? 3 : 1}
						position='relative'
					>
						{plan.bestChoice && (
							<Box position='absolute' top={-10} left={0} width='100%' display='flex' justifyContent='center'>
								<Box width={'60%'} bgcolor='#2ccf4f' display='flex' justifyContent='center' alignItems='center' height={40} boxShadow={1} borderRadius='2px 2px 15px 15px'>
									<Typography color='#fff' fontFamily='Poppins' fontWeight={600}>
										Best choice
									</Typography>
								</Box>
							</Box>
						)}
						<Box display='flex' justifyContent='space-between' alignItems='center'>
							<Typography align='center' fontFamily='Poppins' variant='h6' fontWeight={600}>
								{plan.name}
							</Typography>
							<Typography component={Box} fontFamily='Poppins' display='flex' alignItems='center' gap={1} flexWrap='nowrap'>
								<Typography component='p' fontFamily='Poppins' variant='h5' fontWeight={600}>
									{plan.price}$
								</Typography>
								/
								<Typography component='p' fontFamily='Poppins' color='#aaa'>
									month
								</Typography>
							</Typography>
						</Box>
						<Box height='100%' display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
							<Box mt={2} mb={3} height='100%' display='flex' flexDirection='column' justifyContent='space-between'>
								<Typography fontFamily='Poppins' fontSize={16} color='#999'>
									{plan.description}
								</Typography>
								<Box>
									<Typography fontFamily='Poppins' fontSize={16} fontWeight={600}>
										{plan.interviews} interviews
									</Typography>
									{plan.interviews !== 'Unlimited' && (
										<Typography fontFamily='Poppins' fontSize={16} color='#999'>
											$15/for extra interview
										</Typography>
									)}
								</Box>
								<Box>
									{index !== 0 && (
										<Typography component={Box} fontFamily='Poppins' fontSize={18} mb={2} display='flex' alignItems='center' gap={1}>
											All {plans[index - 1].name} features{' '}
											<Typography component='p' color='#2ccf4f' fontSize={18} fontWeight={600}>
												+
											</Typography>
										</Typography>
									)}
									<Typography fontFamily='Poppins' fontSize={16} gutterBottom mt={0.5}>
										{plan.accounts} users account per company
									</Typography>
									<Divider />
									{plan.features &&
										plan.features.map((feature, index) => (
											<Box key={index}>
												<Typography fontFamily='Poppins' fontSize={16} gutterBottom mt={0.5}>
													{feature}
												</Typography>
												{index !== plan.features.length - 1 && <Divider />}
											</Box>
										))}
								</Box>
							</Box>
							{index !== 0 && (
								<Box display='flex' width='100%' justifyContent='center' padding='8px 20px'>
									{account.accountType === plan.name ? (
										<Button startIcon={loading[index] ? <CircularProgress size={18} color='error' /> : null} fullWidth variant='outlined' color='error' sx={{textTransform: 'none', fontFamily: 'Poppins'}} onClick={() => handleCancelSubscription(index)}>
											Cancel Subscription
										</Button>
									) : (
										<Button startIcon={loading[index] ? <CircularProgress size={18} sx={{color: '#fff'}} /> : null} fullWidth variant='contained' sx={{textTransform: 'none', fontFamily: 'Poppins'}} onClick={() => handleBuyPlan(plan.name, index)}>
											Buy {plan.name} plan
										</Button>
									)}
								</Box>
							)}
						</Box>
					</Box>
				))}
			</Box>
		</>
	);
}
