import {Box, Divider} from '@mui/material';
import {CodeStyles} from './Extensions/CodeStyles';
import {Heading} from './Extensions/Heading';
import {Image} from './Extensions/Image';
import {ListStyles} from './Extensions/ListsStyles';
import {OtherExtensions} from './Extensions/OtherExtensions';
import {TableStyles} from './Extensions/TableStyles';
import {TextStyles} from './Extensions/TextStyles';

export const Extensions = ({editor, extensions}) => {
	if (!editor) {
		return null;
	}

	return (
		<Box className='rich-text-options-wrapper' mb={2}>
			{(!extensions || extensions.includes('textStyles')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<TextStyles editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('heading')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<Heading editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('table')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<TableStyles editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('lists')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<ListStyles editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('code')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<CodeStyles editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('image')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<Image editor={editor} />
					</Box>
					<Divider orientation='vertical' flexItem sx={{mr: 0.5, ml: 0.5}} />
				</>
			)}
			{(!extensions || extensions.includes('other')) && (
				<>
					<Box mb={1} display='flex' alignItems='stretch' height={35}>
						<OtherExtensions editor={editor} />
					</Box>
					<Divider sx={{width: '100%', mb: '-15px'}} />
				</>
			)}
		</Box>
	);
};
