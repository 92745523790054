import {Delete, ThumbDown, ThumbUp} from '@mui/icons-material';
import {Box, Button, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Typography} from '@mui/material';

export default function PopperContent({data, handleOpenConfirmationPopper}) {
	const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

	return data && data.length > 0 ? (
		data.map((notification, index) => (
			<Card key={index} sx={{margin: 1}} elevation={5}>
				<CardHeader
					title={
						<Box display='flex' justifyContent='space-between' alignItems='center'>
							<Box>
								<Chip sx={{background: JSON.parse(notification.ext).typeBackground, color: JSON.parse(notification.ext).typeColor}} variant='filled' label={JSON.parse(notification.ext).type} size='small' />
								<Typography fontSize={12} color='#aaa'>
									{new Date(JSON.parse(notification.ext).date).toLocaleString('en-EN', dateOptions)}
								</Typography>
							</Box>
							<IconButton sx={{border: 'none !important'}} size='small' onClick={e => handleOpenConfirmationPopper(e, notification)} color='error'>
								<Delete />
							</IconButton>
						</Box>
					}
				/>
				<CardContent>
					<Box dangerouslySetInnerHTML={{__html: notification.msg}} />
				</CardContent>
				<CardActions sx={{gap: 0.5}}>
					<Button startIcon={<ThumbUp />} sx={{textTransform: 'none'}}>
						Like
					</Button>
					<Button startIcon={<ThumbDown />} sx={{textTransform: 'none'}}>
						Dislike
					</Button>
				</CardActions>
			</Card>
		))
	) : (
		<Typography align='center' color='#aaa'>
			No notifications added
		</Typography>
	);
}
