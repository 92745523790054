import React, {useState} from 'react';
import {Box, Button, Checkbox, ClickAwayListener, Collapse, FormHelperText, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography} from '@mui/material';
import {Arrow, Popper} from '../../common/PopperFragments';
import {useSnackbar} from 'notistack';
import {Add, Delete, ExpandMore} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import validator from 'validator';

const useStyles = makeStyles(() => ({
	expand: {
		transform: 'rotate(0deg)',
		transition: 'transform 0.3s ease-in-out !important',
	},
	collapse: {
		transform: 'rotate(180deg)',
		transition: 'transform 0.3s ease-in-out !important',
	},
	customTextField: {
		'& input::placeholder': {
			fontSize: '10px',
		},
	},
}));

export default function DurationsPopper({durations, setDurations, onClose, anchor, placement, arrow, dayIndex}) {
	const classes = useStyles();
	const [arrowRef, setArrowRef] = React.useState(null);
	const {enqueueSnackbar} = useSnackbar();
	const [durationError, setDurationError] = useState(false);
	const [openCustomDuration, setOpenCustomDuration] = useState(false);
	const [customDuration, setCustomDuration] = useState('');
	const [type, setType] = useState('Minutes');

	const handleOpenCustomDuration = () => {
		setOpenCustomDuration(true);
	};
	const handleCloseCustomDuration = () => {
		setOpenCustomDuration(false);
	};

	const handleChangeCustomDuration = e => {
		let value = e.target.value;
		if (!value || (validator.isNumeric(value) && parseInt(value) > 0 && parseInt(value) <= 120)) {
			if (type === 'Hour' && parseInt(value) > 2) {
				setDurationError(true);
				setTimeout(() => {
					setDurationError(false);
				}, 1000);
			} else {
				setDurationError(false);
				setCustomDuration(value);
			}
		} else {
			setDurationError(true);
			setTimeout(() => {
				setDurationError(false);
			}, 1000);
		}
	};

	const handleChangeType = value => {
		if (value === 'Hour' && parseInt(customDuration) > 2) {
			setDurationError(true);
			setTimeout(() => {
				setDurationError(false);
			}, 1000);
		} else {
			setType(value);
		}
	};

	const handleCheck = (index, duration) => {
		let tempDurationsState = {...durations};
		let tempDurations = [...durations.durations];
		let available = tempDurationsState.available;

		if (duration.checked && available === 1) {
			enqueueSnackbar('at least select one slot', {variant: 'error'});
		} else {
			if (duration.checked) {
				available = available - 1;
			} else {
				available = available + 1;
			}
			tempDurationsState.available = available;
			tempDurations[index] = {...duration, checked: !duration.checked};
			setDurations({...tempDurationsState, durations: tempDurations});
		}
	};

	const handleAddCustomDuration = () => {
		let newDuration = {name: customDuration + ' ' + type, value: parseInt(type === 'Hour' ? customDuration * 60 : customDuration), checked: false};
		let tempDurationsState = {...durations};
		let tempDurations = [...durations.durations];
		tempDurations.splice(tempDurations.length, 0, newDuration);
		tempDurations = tempDurations.sort((a, b) => a.value < b.value);
		setDurations({...tempDurationsState, durations: tempDurations});
		setOpenCustomDuration(false);
	};

	const handleDeleteDuration = (e, index) => {
		e.stopPropagation();
		let tempDurationsState = {...durations};
		let tempDurations = [...durations.durations];
		let available = tempDurationsState.available;
		if (available === 1 && tempDurations[index].checked) {
			enqueueSnackbar("Can't delete the only checked duration", {variant: 'error'});
		} else {
			tempDurations[index].checked && (available = available - 1);
			tempDurationsState.available = available;
			tempDurations.splice(index, 1);
			setDurations({...tempDurationsState, durations: tempDurations});
		}
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Paper elevation={5} sx={{borderRadius: '10px', padding: 1, maxHeight: 400, overflow: 'auto'}}>
					<Typography sx={{userSelect: 'none'}} color='#AAA' fontSize={10} p={0.5}>
						Select available slots ...
					</Typography>
					<List dense disablePadding>
						{durations.durations.map((duration, index) => (
							<ListItemButton key={index} onClick={() => handleCheck(index, duration)} disabled={index === dayIndex} sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '10px'}}>
								<Typography>{duration.name} meeting</Typography>
								<Box>
									<Checkbox size='small' checked={index === dayIndex || duration.checked} />
									<IconButton size='small' color='error' onClick={e => handleDeleteDuration(e, index)}>
										<Delete fontSize='small' />
									</IconButton>
								</Box>
							</ListItemButton>
						))}
						<Box border='0.5px solid #EEE'>
							<ListItemButton onClick={openCustomDuration ? handleCloseCustomDuration : handleOpenCustomDuration}>
								<ListItemIcon>
									<Add />
								</ListItemIcon>
								<ListItemText>
									<Typography fontFamily='Fredoka'>Custom</Typography>
								</ListItemText>
								{openCustomDuration ? <ExpandMore className={classes.collapse} /> : <ExpandMore className={classes.expand} />}
							</ListItemButton>
							<Collapse in={openCustomDuration} timeout='auto' unmountOnExit>
								<Box mb={1} pl={0.5} pr={0.5} mt={1} display='flex' flexDirection='column' alignItems='center'>
									<Box display='flex' gap={2} mt={1} mb={1}>
										<Box>
											<TextField sx={{width: 120}} size='small' label='Duration' value={typeof customDuration === 'number' ? customDuration.toString() : customDuration} onChange={handleChangeCustomDuration} classes={{root: classes.customTextField}} placeholder='Between 0 and 2hr' />
											<FormHelperText sx={{visibility: durationError ? 'visible' : 'hidden', fontSize: 9}} error={durationError}>
												enter valid number 1min to 2hr
											</FormHelperText>
										</Box>
										<Box>
											<Box display='flex' alignItems='center' onClick={() => handleChangeType('Minutes')} sx={{cursor: 'pointer'}}>
												<Checkbox size='small' checked={type === 'Minutes'} sx={{m: 0, p: 0}} />
												<Typography fontSize={10}>Min</Typography>
											</Box>
											<Box display='flex' alignItems='center' onClick={() => handleChangeType('Hour')} sx={{cursor: 'pointer'}}>
												<Checkbox size='small' checked={type === 'Hour'} sx={{m: 0, p: 0}} />
												<Typography fontSize={10}>Hr</Typography>
											</Box>
										</Box>
									</Box>
									<Button variant='contained' disabled={!customDuration || !type} onClick={handleAddCustomDuration}>
										Add
									</Button>
								</Box>
							</Collapse>
						</Box>
					</List>
				</Paper>
			</Popper>
		</ClickAwayListener>
	);
}
