import React, {useContext, useState} from 'react';

const RealtimeContext = React.createContext();

export function useRealtime() {
	return useContext(RealtimeContext);
}

export function RealtimeProvider({children}) {
	const [realtime, setRealtime] = useState({currentCandidate: '', currentCandidateId: '', currentQuiz: '', candidateClicked: false});

	const value = {
		realtime,
		setRealtime,
	};

	return <RealtimeContext.Provider value={value}>{children}</RealtimeContext.Provider>;
}
