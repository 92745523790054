import {Avatar, List, ListItemButton, Paper, Typography} from '@mui/material';
import {useState} from 'react';
import {stringToColor} from '../../util/commonFunctions';
import { Arrow, Popper } from '../common/PopperFragments';

export default function SharesPopper({anchor, placement, arrow, users}) {
	const [arrowRef, setArrowRef] = useState(null);

	return (
		<Popper
			open={true}
			anchorEl={anchor}
			placement={placement}
			modifiers={[
				{
					name: 'arrow',
					enabled: arrow,
					options: {
						element: arrowRef,
					},
				},
			]}
		>
			{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
			<Paper>
				<List>
					{users?.map((user, index) => (
						<ListItemButton key={index}>
							<Avatar variant='rounded' sx={{background: stringToColor(user?.email?.split('@')[0]), width: 35, height: 35, marginRight: 1}}>
								{user?.email?.toUpperCase().charAt(0)}
							</Avatar>
							<Typography>
								{user?.email} {user.id ? '' : '(owner)'}
							</Typography>
						</ListItemButton>
					))}
				</List>
			</Paper>
		</Popper>
	);
}
