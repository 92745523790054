import {Backdrop} from '@mui/material';
import firebase from 'firebase';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'universal-cookie';
import Lottie from '../Fragments/Lottie';
import CandidatePad from './CandidatePad';

export default function CandidateRoomConfirmation() {
	const history = useHistory();
	const cookies = new Cookies();
	const {enqueueSnackbar} = useSnackbar();

	const [sessionId, setSessionId] = useState('');
	const [passCode, setPassCode] = useState('');
	const [openBackdrop, setOpenBackdrop] = useState(true);

	useEffect(() => {
		if (window.location.hash) {
			let query = {};
			var hash = window.location.hash.replace(/^#/, '');
			if (hash.match(/=/)) {
				hash
					.split('&')
					.filter(Boolean)
					.forEach(function (pair) {
						let newPair = pair.split('=');
						query[newPair[0]] = newPair.slice(1).join('=');
					});
				if (query.room) {
					firebase
						.app('linearPad')
						.database()
						.ref()
						.child(query.room)
						.on('value', snapshot => {
							try {
								if (!snapshot.exists()) {
									throw new Error('Session maybe ended or not exist');
								} else {
									try {
										if (query.passCode) {
											firebase
												.app('linearPad')
												.database()
												.ref()
												.child(query.room)
												.child('passCode')
												.on('value', snapshot => {
													try {
														if (snapshot.val() !== query.passCode) throw new Error('Invalid Pass Code');
														else {
															setSessionId(query.room);
															setPassCode(query.passCode);
															setOpenBackdrop(false);
															cookies.set('userSessionId', query.room, {path: '/', maxAge: 31536000});
														}
													} catch (err) {
														history.push({
															pathname: '/404',
															state: {
																message: err.message,
															},
														});
													}
												});
										} else throw new Error('No pass code provided');
									} catch (err) {
										history.push({
											pathname: '/404',
											state: {
												message: err.message,
											},
										});
									}
								}
							} catch (error) {
								history.push({
									pathname: '/404',
									state: {
										message: error.message,
									},
								});
							}
						});
				} else {
					enqueueSnackbar('no room provided', {variant: 'error'});
					history.push('/');
				}
			} else {
				enqueueSnackbar('invalid url, make sure and try again!', {
					variant: 'error',
				});
				history.push('/');
			}
		} else {
			enqueueSnackbar('invalid url, make sure and try again!', {
				variant: 'error',
			});
			history.push('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return !sessionId || !passCode ? (
		<Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={openBackdrop}>
			<Lottie url='https://assets4.lottiefiles.com/packages/lf20_usmfx6bp.json' width='150px' height='150px' />
		</Backdrop>
	) : (
		<CandidatePad sessionId={sessionId} passCode={passCode} />
	);
}
