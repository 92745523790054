import {Divider, Typography} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import {Box} from '@mui/system';
import {makeStyles} from '@mui/styles';
import {memo} from 'react';

const useStyles = makeStyles(() => ({
	codemirror: {
		'&:active': {
			border: 'none !important',
		},
	},
}));

export default memo(function InputSection({input, handleChangeInput}) {
	const classes = useStyles();

	return (
		<Box>
			<Box display='flex' alignItems='center' pl={1} gap={2} bgcolor='#EEE' height={40}>
				<Typography variant='h6' color='#1776D2'>
					Input
				</Typography>
			</Box>
			<Divider />
			<CodeMirror
				className={classes.codemirror}
				placeholder='enter inputs'
				value={input}
				onChange={(value, viewUpdate) => {
					handleChangeInput(value);
				}}
			/>
		</Box>
	);
});
