/* eslint-disable react-hooks/exhaustive-deps */

import {Allotment} from 'allotment';
import {useEffect, useState} from 'react';
import {useEditor} from '../../EditorContext/EditorContext';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {ErrorBoundary} from 'react-error-boundary';
import firebase from 'firebase';
import InputSection from '../InputSection';
import OutputSection from '../OutputSection';
import {useSnackbar} from 'notistack';
import {Box} from '@mui/system';
import ActionCenter from '../ActionCenter';
import {useAuth} from '../../AuthContext/AuthContext';
import AuthorizedUserCodePad from './AuthorizedUserCodePad';
import {Close} from '@mui/icons-material';
import {IconButton, useMediaQuery, useTheme} from '@mui/material';

import 'allotment/dist/style.css';
import '../../UserList/firepad-userlist.css';
import {useRealtime} from '../../RealtimeContext/RealtimeContext';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
	actionCenter: {
		overflow: 'auto !important',
	},
}));

export default function Main() {
	const classes = useStyles();
	const {editor, setEditor} = useEditor();
	const {enqueueSnackbar} = useSnackbar();
	const {loggedInUser} = useAuth();
	const {setRealtime} = useRealtime();
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	var inputRef;
	var editorLanguageRef;
	var isRunningRef;
	var currentQuizRef;
	var currentCandidateIdRef;

	const realtimeDatabaseReference = firebase.app('linearPad').database().ref();
	const sessionReference = realtimeDatabaseReference.child(loggedInUser.uid);

	inputRef = sessionReference.child('input');
	editorLanguageRef = sessionReference.child('language');
	isRunningRef = sessionReference.child('isRunning');
	currentQuizRef = sessionReference.child('currentQuiz');
	currentCandidateIdRef = sessionReference.child('currentCandidateId');

	const [input, setInput] = useState('');
	const [editorLanguage, setEditorLanguage] = useState('');
	const [isCodeRunning, setIsCodeRunning] = useState(false);
	const [tabsValue, setTabsValue] = useState(0);
	const [currentQuiz, setCurrentQuiz] = useState('');
	const [isInputSectionBugged, setIsInputSectionBugged] = useState(false);
	const [isOutputSectionBugged, setIsOutputSectionBugged] = useState(false);
	const [isActionCenterBugged, setIsActionCenterBugged] = useState(false);
	const [sections, setSections] = useState(['input', 'output']);
	const [showTabsSection, setShowTabsSection] = useState(true);

	const handleChangeInput = value => {
		inputRef.set(value);
	};

	const handleRunEditorCode = status => {
		status && enqueueSnackbar('Code is running...', {variant: 'info'});
		isRunningRef.set(status);
	};

	useEffect(() => {
		if (editor) {
			var aceSession = editor.getSession();
			aceSession.setUseWrapMode(true);
			aceSession.setUseWorker(true);
			aceSession.setMode('ace/mode/' + editorLanguage ? editorLanguage : 'javascript');

			realtimeDatabaseReference.on('value', snapshot => {
				if (snapshot.hasChild(loggedInUser.uid)) {
					currentCandidateIdRef.once('value', dataSnapshot => {
						if (dataSnapshot.val()) setRealtime(realtime => ({...realtime, currentCandidateId: dataSnapshot.val()}));
					});
					inputRef.once('value', function (dataSnapshot) {
						var value = dataSnapshot.val();
						if (value) {
							setInput(value);
							inputRef = sessionReference.child('input');
						} else {
							setInput('');
							inputRef.set('');
							inputRef = sessionReference.child('input');
						}
					});
					editorLanguageRef.once('value', function (dataSnapshot) {
						var value = dataSnapshot.val();
						if (value) {
							setEditorLanguage(value);
							editorLanguageRef = sessionReference.child('language');
							aceSession?.setMode('ace/mode/' + value);
						} else {
							setEditorLanguage('javascript');
							editorLanguageRef.set('javascript');
							editorLanguageRef = sessionReference.child('language');
							aceSession?.setMode('ace/mode/javascript');
						}
					});
					isRunningRef.once('value', function (dataSnapshot) {
						var value = dataSnapshot.val();
						if (value) {
							setIsCodeRunning(value);
							isRunningRef = sessionReference.child('isRunning');
						} else {
							setIsCodeRunning(false);
							isRunningRef.set(false);
							isRunningRef = sessionReference.child('isRunning');
						}
					});
					currentQuizRef.once('value', function (dataSnapshot) {
						var value = dataSnapshot.val();
						if (value) {
							setCurrentQuiz(value);
						} else setCurrentQuiz('');
					});
				}
			});
		}
	}, [editor]);

	useEffect(() => {
		setEditor(window.ace.edit('firepad-ace-container'));
	}, []);

	return (
		<Box height='calc(100vh - 46px)' width='100%'>
			<Allotment>
				<Allotment.Pane minSize={300} snap preferredSize={700}>
					<Allotment vertical>
						<Allotment.Pane minSize={700}>
							<Box width='100%'>
								<AuthorizedUserCodePad setTabsValue={setTabsValue} />
							</Box>
						</Allotment.Pane>
						{(!isMobile || isTablet) && sections.length > 0 && (
							<Allotment.Pane>
								<Allotment>
									{sections.map((section, index) =>
										section === 'input' ? (
											<Allotment.Pane snap key={index}>
												<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsInputSectionBugged(false)} resetKeys={[isInputSectionBugged]}>
													<InputSection input={input} handleChangeInput={handleChangeInput} />
												</ErrorBoundary>
												<Box position='absolute' top={0} width='100%'>
													<Box position='absolute' top={0} right={0}>
														<IconButton
															onClick={() =>
																setSections(sections => {
																	const newSections = [...sections];
																	newSections.splice(index, 1);
																	return newSections;
																})
															}
															size='small'
														>
															<Close fontSize='small' />
														</IconButton>
													</Box>
												</Box>
											</Allotment.Pane>
										) : (
											section === 'output' && (
												<Allotment.Pane snap key={index}>
													<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsOutputSectionBugged(false)} resetKeys={[isOutputSectionBugged]}>
														<OutputSection input={input} language={editorLanguage} isRunning={isCodeRunning} handleRun={handleRunEditorCode} />
													</ErrorBoundary>
													<Box position='absolute' top={0} width='100%'>
														<Box position='absolute' top={0} right={0}>
															<IconButton
																onClick={() =>
																	setSections(sections => {
																		const newSections = [...sections];
																		newSections.splice(index, 1);
																		return newSections;
																	})
																}
																size='small'
															>
																<Close fontSize='small' />
															</IconButton>
														</Box>
													</Box>
												</Allotment.Pane>
											)
										)
									)}
								</Allotment>
							</Allotment.Pane>
						)}
					</Allotment>
				</Allotment.Pane>
				{!isMobile && !isTablet && showTabsSection && (
					<Allotment.Pane minSize={550} snap className={classes.actionCenter}>
						<Box width='100%' height='100%' overflow='visible'>
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsActionCenterBugged(false)} resetKeys={[isActionCenterBugged]}>
								<ActionCenter currentQuiz={currentQuiz} user={loggedInUser} tabsValue={tabsValue} setTabsValue={setTabsValue} sessionReference={sessionReference} />
							</ErrorBoundary>
						</Box>
						<Box position='absolute' top={0} width='100%'>
							<Box position='absolute' top={0} right={0}>
								<IconButton onClick={() => setShowTabsSection(false)} size='small'>
									<Close fontSize='small' />
								</IconButton>
							</Box>
						</Box>
					</Allotment.Pane>
				)}
			</Allotment>
		</Box>
	);
}
