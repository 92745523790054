import {Button, Card, CardActions, CardContent, Dialog, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../../AuthContext/AuthContext';
import Lottie from '../../Fragments/Lottie';

export default function ConfirmedDialog({openConfirmedDialog, handleClose, fetchReservations}) {
	const history = useHistory();
	const {loggedInUser} = useAuth();

	const handleGoHome = () => {
		history.push(loggedInUser ? '/Dashboard' : '/');
	};

	const handleCloseDialog = () => {
		handleClose();
		fetchReservations();
	};
	return (
		<Dialog open={openConfirmedDialog} maxWidth='sm'>
			<Card>
				<CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<Lottie url='https://assets5.lottiefiles.com/private_files/lf30_9mxfay17.json' width={200} height={200} />
					<Typography fontFamily='Fredoka' variant='h6' align='center' fontWeight={600} fontSize={24}>
						Your reservation is saved
					</Typography>
				</CardContent>
				<CardActions sx={{justifyContent: 'space-between'}}>
					<Button size='small' variant='contained' sx={{textTransform: 'none'}} onClick={handleCloseDialog}>
						Make another reservation
					</Button>
					<Button size='small' variant='contained' sx={{textTransform: 'none'}} onClick={handleGoHome}>
						Go home
					</Button>
				</CardActions>
			</Card>
		</Dialog>
	);
}
