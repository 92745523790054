import {Avatar, Button, CardHeader, Chip, CircularProgress, Container, Dialog, FormControl, InputLabel, MenuItem, Select, Table, TableCell, TableRow, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import GenericCard from './GenericCard';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {useAuth} from '../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import Validator from 'validator';
import UserSearchBox from './UserSearchBox';
import {useAccount} from '../../AccountContext/AccountContext';
import {stringToColor} from '../../util/commonFunctions';
import {Delete} from '@mui/icons-material';

function AddPlanUserDialog({handleClose, isAdmin}) {
	const {loggedInUser, token} = useAuth();
	const {account} = useAccount();

	const [{data: usersWithTheSameAccount, loading: fetchUsersWithSameAccountLoading}, fetchUsersWithSameAccount] = useAxios({
		url: Env().url + '/users/account/' + account.id,
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [{data: permissions}] = useAxios({
		url: Env().url + '/configurations/83f3d639-9103-4d51-9a14-9ac7ea420207',
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [, fetchUserById] = useAxios({
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [, updateUser] = useAxios(
		{
			url: Env().url + '/users',
			method: 'PUT',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);

	const {enqueueSnackbar} = useSnackbar();
	const [selectedUserEmail, setSelectedUserEmail] = useState('');
	const [selectedRole, setSelectedRole] = useState('');
	const [selectedUser, setSelectedUser] = useState();
	const [loading, setLoading] = useState(false);
	const [currentDeleting, setCurrentDeleting] = useState(0);

	const handleSelectUsers = (e, selectedValue, results) => {
		let email = selectedValue;
		let selectedObj = results.some(obj => {
			return obj.first === selectedValue;
		});
		let id = selectedObj.second;
		results.forEach(item => {
			if (item.first === selectedValue) {
				id = item.second;
			}
		});
		if ((selectedValue && Validator.isEmail(email)) || !selectedValue) {
			setSelectedUserEmail(email);
			fetchUserById({url: Env().url + '/users/' + id}).then(res => {
				setSelectedUser(res.data);
			});
		} else {
			enqueueSnackbar('invalid email address', {variant: 'error'});
		}
	};

	const handleSelectedUserRole = e => {
		setSelectedRole(e.target.value);
	};

	const handleAddPlanUser = () => {
		setLoading(true);
		updateUser({data: {...selectedUser, accountId: account.id, accountRole: selectedRole}})
			.then(() => {
				enqueueSnackbar('added!', {variant: 'success'});
				setLoading(false);
				fetchUsersWithSameAccount();
			})
			.catch(err => {
				setLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	const handleDeleteUserFromPlan = (user, index) => {
		setCurrentDeleting(index + 1);
		updateUser({data: {...user, accountId: '', accountRole: ''}})
			.then(() => {
				enqueueSnackbar('deleted!', {variant: 'success'});
				setCurrentDeleting(0);
				fetchUsersWithSameAccount();
			})
			.catch(err => {
				setCurrentDeleting(0);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	return (
		<Dialog open={true} onClose={() => null} maxWidth='sm' fullWidth>
			<GenericCard
				handleClose={handleClose}
				title={
					<Typography variant='h6' fontFamily='Fredoka' fontWeight={600}>
						{isAdmin ? 'Add User to Plan' : 'Plan users'}
					</Typography>
				}
			>
				{isAdmin && (
					<Box display='flex' gap={1} alignItems='flex-start'>
						<UserSearchBox handleSelectUsers={handleSelectUsers} selectedUser={selectedUserEmail} />
						<FormControl size='small' variant='outlined' sx={{width: '30%'}}>
							<InputLabel>Role</InputLabel>

							<Select value={selectedRole} onChange={handleSelectedUserRole} label='Role'>
								<MenuItem value=''>None</MenuItem>
								{permissions &&
									permissions.config &&
									Object.keys(JSON.parse(permissions.config)?._roles).map((role, index) => (
										<MenuItem value={role} key={index}>
											{role}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<Button
							startIcon={loading && <CircularProgress size={20} sx={{color: '#fff'}} />}
							variant='contained'
							onClick={handleAddPlanUser}
							sx={{width: 120, borderRadius: 5, background: (!selectedUser || !selectedRole || loading) && '#1776D280 !important', textTransform: 'none', fontFamily: 'Fredoka'}}
							disabled={!selectedUser || !selectedRole || loading}
						>
							Add
						</Button>
					</Box>
				)}
				{usersWithTheSameAccount?.length > 0 ? (
					<Box>
						{isAdmin && (
							<CardHeader
								title={
									<Typography variant='h6' component='span'>
										Plan users
									</Typography>
								}
							/>
						)}
						<Container>
							<Table sx={{overflow: 'auto'}}>
								{usersWithTheSameAccount.map((user, index) => (
									<TableRow key={user.id} sx={{alignItems: 'center'}}>
										<TableCell sx={{border: 'none', padding: 0.5}}>
											<Box display='flex' alignItems='center'>
												<Avatar variant='rounded' sx={{background: stringToColor(user.email.split('@')[0]), width: 35, height: 35, marginRight: 1}}>
													{user.username.toUpperCase().charAt(0)}
												</Avatar>
												<Typography>{user.username}</Typography>
												{loggedInUser.uid === user.id && <Typography ml={1}>{'(Me)'}</Typography>}
											</Box>
										</TableCell>
										<TableCell sx={{border: 'none', padding: 0.5}} align='center'>
											<Chip label={user.accountRole} color='primary' />
										</TableCell>
										{loggedInUser.uid !== user.id && user.accountRole !== 'Admin' && isAdmin && (
											<TableCell sx={{border: 'none', padding: 0.5, verticalAlign: 'bottom'}}>{currentDeleting === index + 1 ? <CircularProgress size={24} /> : <Delete sx={{cursor: 'pointer'}} htmlColor='#d11a2a' onClick={() => handleDeleteUserFromPlan(user, index)} />}</TableCell>
										)}
									</TableRow>
								))}
							</Table>
						</Container>
					</Box>
				) : (
					fetchUsersWithSameAccountLoading && (
						<Box display='flex' justifyContent='center'>
							<CircularProgress />
						</Box>
					)
				)}
			</GenericCard>
		</Dialog>
	);
}

export default AddPlanUserDialog;
