import {CheckCircle, CircleOutlined} from '@mui/icons-material';
import {Button, Card, CardActions, CardContent, Checkbox, ClickAwayListener, ListItemButton, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {Arrow, Popper} from '../../common/PopperFragments';
import firebase from 'firebase';
import {useAuth} from '../../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';

export default function CandidatePageConfigPopper({onClose, anchor, placement, arrow}) {
	const {loggedInUser} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [arrowRef, setArrowRef] = useState(null);
	const [options, setOptions] = useState();

	const roomRef = firebase.app('linearPad').database().ref().child(loggedInUser.uid);

	const handleCheckOption = key => {
		let tempOptions = {...options};
		tempOptions[key].value = !tempOptions[key].value;
		setOptions(tempOptions);
	};

	const applyChangeOptions = () => {
		try {
			roomRef.child('candidatePageConfigs').set(options);
			enqueueSnackbar('options are applied!', {variant: 'success'});
		} catch (err) {
			enqueueSnackbar(err.message, {variant: 'error'});
		}
	};
	useEffect(() => {
		let tempOptions = {
			draw: {name: 'Draw', value: false},
			question: {name: 'Question', value: false},
			IO: {name: 'Input & Output', value: false},
		};

		roomRef
			.child('candidatePageConfigs')
			.get()
			.then(value => {
				if (value.exists()) {
					tempOptions = value.val();
				} else {
					roomRef.child('candidatePageConfigs').set(tempOptions);
				}
				setOptions(tempOptions);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card elevation={5} sx={{borderRadius: '15px'}}>
					<CardContent>
						{options &&
							Object.keys(options).map((key, index) => (
								<ListItemButton sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 5px', borderRadius: '4px'}} onClick={() => handleCheckOption(key)} key={index}>
									<Typography fontFamily='Fredoka'>{options[key].name}</Typography>
									<Checkbox checked={options[key].value} icon={<CircleOutlined />} checkedIcon={<CheckCircle />} />
								</ListItemButton>
							))}
					</CardContent>
					<CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button size='small' fullWidth variant='contained' sx={{textTransform: 'none'}} onClick={applyChangeOptions}>
							Apply
						</Button>
					</CardActions>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
}
