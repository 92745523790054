import {Assignment, Gesture} from '@mui/icons-material';
import {TabContext, TabPanel} from '@mui/lab';
import {CardContent, Divider, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useCallback, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {StyledTab, StyledTabs} from '../../common/CustomTabs';
import InternalErrorBoundaryPage from '../../ErrorBoundary/InternalErrorBoudaryPage';
import StyledQuestionContent from '../../Fragments/StyledQuestionContent';
import DrawTab from '../Tabs/DrawTab';

const useStyles = makeStyles(() => ({
	tabPanel: {
		padding: '5px !important',
	},
	button: {
		textTransform: 'none !important',
	},
	customQuestionPanel: {
		position: 'relative',
		display: 'flex',
	},
	content: {
		fontFamily: 'Times New Roman',
	},
	cardContent: {
		paddingTop: '0px !important',
		height: '98%',
		overflow: 'auto',
	},
}));

export default function CandidateTabsSection({currentQuiz, roomId, pageConfig}) {
	const classes = useStyles();

	const [tabsValue, setTabsValue] = useState(0);
	const [isCurrentQuizTabBugged, setIsCurrentQuizTabBugged] = useState(false);
	const [isDrawTabBugged, setIsDrawTabBugged] = useState(false);

	const handleChange = useCallback((event, newValue) => {
		setTabsValue(newValue);
	}, []);

	useEffect(() => {
		setTabsValue(0);
	}, [currentQuiz]);

	return (
		<TabContext value={tabsValue.toString()}>
			<StyledTabs value={tabsValue} onChange={handleChange} aria-label='styled tabs example' variant='scrollable' scrollButtons='auto'>
				{pageConfig.question.value && currentQuiz && <StyledTab label={'Quiz'} icon={<Assignment color='primary' />} iconPosition='start' wrapped />}
				{pageConfig.draw.value && <StyledTab icon={<Gesture color='primary' />} iconPosition='start' label={'Draw'} wrapped />}
			</StyledTabs>
			<Divider />
			{pageConfig.question.value && currentQuiz && (
				<TabPanel value='0' className={classes.tabPanel}>
					<Box width='100%'>
						<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCurrentQuizTabBugged(false)} resetKeys={[isCurrentQuizTabBugged]}>
							<CardContent className={classes.cardContent} id='candidateQSection'>
								<StyledQuestionContent problem={currentQuiz.problem} testCases={currentQuiz.testCases} />
							</CardContent>
						</ErrorBoundary>
					</Box>
				</TabPanel>
			)}
			{pageConfig.draw.value && (
				<TabPanel value={pageConfig.question.value && currentQuiz ? '1' : '0'} className={classes.tabPanel}>
					<Box width='100%'>
						<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsDrawTabBugged(false)} resetKeys={[isDrawTabBugged]}>
							<DrawTab height='850px' isCandidate={true} roomId={roomId} />
						</ErrorBoundary>
					</Box>
				</TabPanel>
			)}
			<Typography className={classes.padding} />
		</TabContext>
	);
}
