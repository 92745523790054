import {Button, CircularProgress, ClickAwayListener, Typography} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../../AuthContext/AuthContext';
import Env from '../../../util/Env';
import {Arrow, Popper} from '../../common/PopperFragments';
import InternalErrorBoundaryPage from '../../ErrorBoundary/InternalErrorBoudaryPage';
import GenericCard from '../../Fragments/GenericCard';
import EventPopperContent from './EventPopperContent';

export default function EventPopper({event, handleClose, anchor, placement, arrow}) {
	const history = useHistory();
	const {enqueueSnackbar} = useSnackbar();
	const {token} = useAuth();
	const [arrowRef, setArrowRef] = useState(null);
	const [isContentBugged, setIsContentBugged] = useState(false);
	const [loading, setLoading] = useState(false);
	const content = JSON.parse(event.reservation);

	const handleAddToCandidates = () => {
		setLoading(true);
		axios
			.post(
				Env().url + '/pad',
				{
					anonymous: false,
					name: content.name,
					email: content.email,
					phone: '',
					cv: content.cv,
					notes: '',
					content: '',
					status: 'open',
					config: JSON.stringify({}),
				},
				{headers: {Authorization: 'Bearer ' + token}}
			)
			.then(res => {
				enqueueSnackbar('Added to candidates!', {variant: 'success'});
				setLoading(false);
				history.push('/Candidates');
			})
			.catch(err => {
				enqueueSnackbar("Couldn't add to candidates, try again!", {variant: 'error'});
				setLoading(false);
			});
	};

	const Actions = () => (
		<>
			<Button color='error' variant='outlined' sx={{textTransform: 'none'}} onClick={handleClose} size='small'>
				Close
			</Button>
			<Button
				size='small'
				color='primary'
				variant='contained'
				sx={{background: loading ? '#1776D2 !important' : '', color: loading ? '#fff' : '', textTransform: 'none'}}
				disabled={loading}
				startIcon={loading ? <CircularProgress size={20} sx={{color: '#fff'}} /> : null}
				onClick={handleAddToCandidates}
			>
				Add as candidate
			</Button>
		</>
	);

	return (
		<ClickAwayListener
			onClickAway={e => {
				e.stopPropagation(false);
				handleClose();
			}}
		>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<GenericCard
					handleClose={handleClose}
					actions={<Actions />}
					title={
						<Typography variant='h6' align='center' fontWeight={600} fontFamily='Fredoka'>
							Event information
						</Typography>
					}
				>
					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsContentBugged(false)} resetKeys={[isContentBugged]}>
						<EventPopperContent event={event} />
					</ErrorBoundary>
				</GenericCard>
			</Popper>
		</ClickAwayListener>
	);
}
