/* eslint-disable react-hooks/exhaustive-deps */
import {IconButton, Box, CircularProgress, Tooltip, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {FirepadUserList} from '../../UserList/firepad-userlist';
import firebase from 'firebase';
import 'firepad/dist/firepad.css';
import {CopyAllRounded} from '@mui/icons-material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Options from '../Fragments/Options';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import axios from 'axios';
import Env from '../../util/Env';
import SaveIcon from '@mui/icons-material/Save';
import ReactGA from 'react-ga';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../AuthContext/AuthContext';
import Cookies from 'universal-cookie';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {connect} from 'react-redux';
import {useEditor} from '../../EditorContext/EditorContext';
import {useRealtime} from '../../RealtimeContext/RealtimeContext';
import {debounce} from 'lodash';
import {useAccount} from '../../AccountContext/AccountContext';
import {samples} from '../../util/languages';

const useStyles = makeStyles(() => ({
	logoBox: {
		background: 'linear-gradient(to right, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%)',
	},
	editor: {
		display: 'flex',
		height: 'calc(100% - 45px)',
	},
	options: {
		background: '#fff',
		zIndex: 1,
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		width: 48,
		top: 0,
		right: 0,
		gap: 5,
		paddingBottom: 5,
		borderLeft: '1px solid #ddd',
		borderBottom: '1px solid #ddd',
		alignItems: 'center',
		borderBottomLeftRadius: 40,
	},
	bottomOptions: {
		zIndex: 10,
		position: 'absolute',
		display: 'flex',
		width: '100%',
		bottom: 2,
		left: 0,
		gap: 5,
		alignItems: 'center',
	},
	chip: {
		position: 'absolute',
		zIndex: 1,
		right: 2,
		bottom: 5,
		display: 'flex',
		gap: 2,
	},
	usersListComponent: {
		position: 'absolute',
		zIndex: 1,
		left: 5,
		bottom: 5,
	},
	divider: {
		borderRight: '2px solid #ddd',
	},
	topBorder: {
		borderTop: '2px solid #ddd',
	},
}));

const themes = [
	{name: 'Terminal', value: 'terminal'},
	{name: 'Text Mate', value: 'textmate'},
	{name: 'Tomorrow', value: 'tomorrow'},
	{name: 'Tomorrow Night', value: 'tomorrow_night'},
	{name: 'Twilight', value: 'twilight'},
	{name: 'Vibrant', value: 'vibrant_ink'},
	{name: 'X Code', value: 'xcode'},
	{name: 'Monokai', value: 'monokai'},
	{name: 'Eclipse', value: 'eclipse'},
	{name: 'Dreamweaver', value: 'dreamweaver'},
	{name: 'Crimson', value: 'crimson_editor'},
];

const fontSizes = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px'];

function AuthorizedUserCodePad({onApplyFetchCandidates, candidatesFetch, setTabsValue}) {
	const cookies = new Cookies();
	const {loggedInUser} = useAuth();
	const {setRealtime} = useRealtime();
	const classes = useStyles();
	const {token} = useAuth();
	const {editor} = useEditor();
	const FirePad = require('firepad');
	const {enqueueSnackbar} = useSnackbar();
	const {setPlanPermissions} = useAccount();

	const [editorCopiedValueAsText, setEditorCopiedValueAsText] = useState(false);
	const [editorCopiedValueAsHtml, setEditorCopiedValueAsHtml] = useState(false);
	const [editorTheme, setEditorTheme] = useState('');
	const [editorLanguage, setEditorLanguage] = useState('');
	const [editorFontSize, setEditorFontSize] = useState('');
	const [isDataSavedWithCandidate, setIsDataSavedWithCandidate] = useState(false);
	const [saveDataWithCandidateLoading, setSaveDataWithCandidateLoading] = useState(false);
	const [isOptionsSectionBugged, setIsOptionsSectionBugged] = useState(false);
	const [isPadSectionBugged, setIsPadSectionBugged] = useState(false);

	var firepadRef = window?.firepadRef;
	const setUsernameFunction = (nameKey, val) => {
		cookies.set(nameKey, val, {path: '/', maxAge: 31536000});
	};
	const generatePassCode = () => {
		var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
		var codeLength = 8;
		var code = '';
		for (var i = 0; i < codeLength; i++) {
			var randomNumber = Math.floor(Math.random() * chars.length);
			code += chars.substring(randomNumber, randomNumber + 1);
		}
		return code;
	};

	const handlePassCodeChange = () => {
		sessionReference.child('passCode').set(generatePassCode());
	};

	const handleChangeEditorFontSize = e => {
		ReactGA.event({
			category: 'Authorized user pad action',
			action: 'change font size to ' + e.target.value,
		});
		sessionReference.child('fontSize').set(e.target.value);
	};

	const handleChangeEditorTheme = e => {
		ReactGA.event({
			category: 'Authorized user pad action',
			action: 'change theme to ' + e.target.value,
		});
		sessionReference.child('theme').set(e.target.value);
	};

	const handleChangeEditorLanguage = language => {
		ReactGA.event({
			category: 'Authorized user pad action',
			action: 'change language to ' + language,
		});
		enqueueSnackbar('Language is changed to ' + language, {
			variant: 'info',
		});
		sessionReference.child('language').set(language);
	};

	const realtimeDatabaseReference = firebase.app('linearPad').database().ref();
	const sessionReference = realtimeDatabaseReference.child(loggedInUser.uid);

	useEffect(() => {
		if (editor) {
			var getEditorValueAsHTMLButton = document.getElementById('getDataHTML');
			var getEditorValueAsTextButton = document.getElementById('getDataText');
			var loadSampleButton = document.getElementById('generateCode');
			var saveDataWithCandidate = document.getElementById('save');
			var usersListComponent = document.getElementById('userlist');

			if (usersListComponent) {
				usersListComponent.innerHTML = '';
			}

			try {
				sessionReference.child('anonymous').set(false);
				sessionReference.child('updateTimestamp').set(new Date().getTime());

				let usernameKey = sessionReference.key + loggedInUser.uid;
				let name = cookies.get(usernameKey);
				editor.setTheme('ace/theme/' + editorTheme ? editorTheme : 'textmate');
				editor.setOptions({
					fontSize: editorFontSize ? editorFontSize : '14px',
				});
				const saveData = debounce(
					debounce => {
						sessionReference
							.child('currentCandidateId')
							.get()
							.then(value => {
								try {
									if (value.val()) {
										setSaveDataWithCandidateLoading(true);
										axios
											.get(Env().url + '/pad/' + value.val(), {
												headers: {
													Authorization: 'Bearer ' + token,
												},
											})
											.then(res => {
												let {creationTime, refreshTime, updateTime, ...padTemp} = res.data;
												sessionReference
													.child('language')
													.get()
													.then(modeValue => {
														sessionReference
															.child('theme')
															.get()
															.then(themeValue => {
																sessionReference
																	.child('fontSize')
																	.get()
																	.then(fontSizeValue => {
																		padTemp.config = JSON.stringify({
																			mode: modeValue.val(),
																			theme: themeValue.val(),
																			fontSize: fontSizeValue.val(),
																		});
																		padTemp.content = editor.getValue();
																		sessionReference
																			.child('currentQuiz')
																			.get()
																			.then(currentExampleValue => {
																				let tempPlanPermissions;
																				setPlanPermissions(planPermissions => {
																					tempPlanPermissions = planPermissions;
																					return planPermissions;
																				});
																				if (tempPlanPermissions && tempPlanPermissions.permissions && (tempPlanPermissions.permissions.includes('linearpad') || tempPlanPermissions.permissions.includes('linearpad.record')) && currentExampleValue.val()) {
																					sessionReference
																						.child('history')
																						.get()
																						.then(history => {
																							let index = (padTemp.examples || []).findIndex(item => JSON.parse(item).id === currentExampleValue.val().id);
																							if (index !== -1) {
																								padTemp.examples[index] = JSON.stringify({
																									...JSON.parse(padTemp.examples[index]),
																									code: editor.getValue(),
																									language: modeValue.val(),
																									history: history.val(),
																								});
																							} else {
																								if (!padTemp.examples || padTemp.examples?.length === 0) {
																									padTemp.examples = [
																										JSON.stringify({
																											...currentExampleValue.val(),
																											code: editor.getValue(),
																											language: modeValue.val(),
																											history: history.val(),
																										}),
																									];
																								} else {
																									padTemp.examples.push(
																										JSON.stringify({
																											...currentExampleValue.val(),
																											code: editor.getValue(),
																											language: modeValue.val(),
																											history: history.val(),
																										})
																									);
																								}
																							}
																							axios
																								.post(Env().url + '/pad', padTemp, {headers: {Authorization: 'Bearer ' + token}})
																								.then(res => {
																									setIsDataSavedWithCandidate(true);
																									candidatesFetch = !candidatesFetch;
																									onApplyFetchCandidates(candidatesFetch);
																									setSaveDataWithCandidateLoading(false);
																									setTimeout(() => {
																										setIsDataSavedWithCandidate(false);
																									}, 1000);
																								})
																								.catch(err => {
																									setSaveDataWithCandidateLoading(false);
																								});
																						});
																				} else {
																					axios
																						.post(Env().url + '/pad', padTemp, {headers: {Authorization: 'Bearer ' + token}})
																						.then(res => {
																							setIsDataSavedWithCandidate(true);
																							candidatesFetch = !candidatesFetch;
																							onApplyFetchCandidates(candidatesFetch);
																							setSaveDataWithCandidateLoading(false);
																							setTimeout(() => {
																								setIsDataSavedWithCandidate(false);
																							}, 1000);
																						})
																						.catch(err => {
																							setSaveDataWithCandidateLoading(false);
																						});
																				}
																			});
																	});
															});
													});
											})
											.catch(err => {
												setSaveDataWithCandidateLoading(false);
											});
									} else if (debounce) {
									} else {
										setSaveDataWithCandidateLoading(false);
										enqueueSnackbar('make a candidate active first', {
											variant: 'error',
										});
									}
								} catch (err) {
									enqueueSnackbar(err.message, {variant: 'error'});
								}
							});
					},
					[5000]
				);
				FirepadUserList.fromDiv(sessionReference.child('users'), document.getElementById('userlist'), loggedInUser.uid, name || loggedInUser.displayName, usernameKey, setUsernameFunction);
				firebase
					.app('linearPad')
					.database()
					.ref()
					.on('value', snapshot => {
						if (snapshot.hasChild(loggedInUser.uid)) {
							sessionReference.child('passCode').once('value', async function (dataSnapshot) {
								var value = dataSnapshot.val();
								if (value) {
									cookies.set('passCode', value);
								} else {
									let code = generatePassCode();
									sessionReference.child('passCode').set(code);
									cookies.set('passCode', code);
								}
							});
							sessionReference.child('currentCandidateId').once('value', dataSnapshot => {
								if (dataSnapshot.val()) setRealtime(realtime => ({...realtime, currentCandidateId: dataSnapshot.val()}));
								else {
									setRealtime(realtime => ({...realtime, currentCandidateId: ''}));
								}
							});
							sessionReference.child('currentQuiz').once('value', function (dataSnapshot) {
								var value = dataSnapshot.val();
								if (value) {
									setRealtime(realtime => ({...realtime, currentQuiz: value}));
								} else setRealtime(realtime => ({...realtime, currentQuiz: ''}));
							});
							sessionReference.child('history').once('value', dataSnapshot => {
								if (dataSnapshot.val()) {
									if (saveData) saveData(true);
								}
							});
							sessionReference.child('language').once('value', function (dataSnapshot) {
								var value = dataSnapshot.val();
								if (value) {
									setEditorLanguage(value);
									aceSession?.setMode('ace/mode/' + value);
								} else {
									setEditorLanguage('javascript');
									aceSession?.setMode('ace/mode/javascript');
								}
							});

							sessionReference.child('fontSize').once('value', function (dataSnapshot) {
								var value = dataSnapshot.val();
								if (value) {
									setEditorFontSize(value);
									editor?.setOptions({
										fontSize: dataSnapshot.val(),
									});
								} else {
									setEditorFontSize('14px');
									editor?.setOptions({
										fontSize: '14px',
									});
								}
							});

							sessionReference.child('theme').once('value', function (dataSnapshot) {
								var value = dataSnapshot.val();
								if (value) {
									setEditorTheme(value);
									editor?.setTheme('ace/theme/' + value);
								} else {
									setEditorTheme('textmate');
									editor?.setTheme('ace/theme/textmate');
									editor?.setShowPrintMargin(false);
								}
							});
						}
					});
				editor.setShowPrintMargin(false);

				var aceSession = editor.getSession();
				aceSession.setUseWrapMode(true);
				aceSession.setUseWorker(true);
				aceSession.setMode('ace/mode/' + editorLanguage ? editorLanguage : 'javascript');

				getEditorValueAsHTMLButton.addEventListener('click', () => {
					ReactGA.event({
						category: 'Authorized user pad action',
						action: 'copy the content in HTML format',
					});
					enqueueSnackbar('Copied as HTML', {
						variant: 'success',
					});
					let template =
						'<p>' +
						editor
							.getValue()
							.replace(/\n{2,}/g, '</p><p>')
							.replace(/\n/g, '<br>') +
						'</p>';
					setEditorCopiedValueAsHtml(true);
					setTimeout(() => {
						setEditorCopiedValueAsHtml(false);
					}, 1500);
					navigator.clipboard.writeText(template);
				});

				saveDataWithCandidate.addEventListener('click', () => {
					ReactGA.event({
						category: 'Authorized user pad action',
						action: 'save pad data to the current pad',
					});
					saveData();
				});

				getEditorValueAsTextButton.addEventListener('click', () => {
					ReactGA.event({
						category: 'Authorized user pad action',
						action: 'copy the content in text format',
					});
					enqueueSnackbar('Copied as text!', {variant: 'success'});
					setEditorCopiedValueAsText(true);
					setTimeout(() => {
						setEditorCopiedValueAsText(false);
					}, 1500);
					navigator.clipboard.writeText(editor.getValue());
				});
				loadSampleButton.addEventListener('click', () => {
					ReactGA.event({
						category: 'Authorized user pad action',
						action: 'generate ' + editorLanguage + ' code',
					});
					sessionReference
						.child('language')
						.get()
						.then(dataSnapshot => {
							enqueueSnackbar(dataSnapshot.val() + ' code is generated', {
								variant: 'success',
							});
							window?.firepadRef?.setText(editor.getValue() + samples[dataSnapshot.val()]);
						});
				});
				firepadRef = FirePad.fromACE(sessionReference, editor, {
					userId: loggedInUser.uid,
				});
				window.firepadRef = firepadRef;
				firepadRef.on('ready', function () {
					if (firepadRef.isHistoryEmpty()) {
						firepadRef.setText(
							`const x = 5;
	const y = 7;
					
	const func = () => {
		return x*y;
	}
	console.log(func());
	`
						);
					}
				});
			} catch (error) {
				enqueueSnackbar(error.message, {variant: 'error'});
			}
		}
	}, [editor]);

	return (
		<>
			<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsOptionsSectionBugged(false)} resetKeys={[isOptionsSectionBugged]}>
				<Options
					user={loggedInUser}
					fontSize={editorFontSize}
					language={editorLanguage}
					theme={editorTheme}
					sessionReference={sessionReference}
					realtimeDatabaseReference={realtimeDatabaseReference}
					handlePassCodeChange={handlePassCodeChange}
					handleChangeFontSize={handleChangeEditorFontSize}
					handleLanguageChange={handleChangeEditorLanguage}
					handleThemeChange={handleChangeEditorTheme}
					setTabsValue={setTabsValue}
				/>
			</ErrorBoundary>
			<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsPadSectionBugged(false)} resetKeys={[isPadSectionBugged]}>
				<Box width='100%' height='100%' position='relative'>
					<Box minHeight='100vh' className='padElement' id='firepad-ace-container' overflow='auto' />
					<Box className={classes.options}>
						<Tooltip title={editorCopiedValueAsHtml ? 'copied' : 'copy as HTML'}>
							<IconButton id='getDataHTML' color={editorCopiedValueAsHtml ? 'success' : 'primary'} size='small'>
								<CopyAllRounded fontSize='small' />
							</IconButton>
						</Tooltip>
						<Tooltip title={editorCopiedValueAsText ? 'copied' : 'copy as text'}>
							<IconButton size='small' id='getDataText' color={editorCopiedValueAsText ? 'success' : 'primary'}>
								<ContentPasteIcon fontSize='small' />
							</IconButton>
						</Tooltip>
						<Tooltip title={saveDataWithCandidateLoading ? 'loading' : isDataSavedWithCandidate ? 'saved' : 'save'}>
							<IconButton size='small' id='save' color={isDataSavedWithCandidate ? 'success' : 'primary'}>
								{saveDataWithCandidateLoading ? <CircularProgress size={20} /> : <SaveIcon fontSize='small' />}
							</IconButton>
						</Tooltip>
						<Tooltip title='load code sample'>
							<IconButton id='generateCode' color='primary' size='small'>
								<IntegrationInstructionsIcon fontSize='small' />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Box className={classes.bottomOptions}>
					<FormControl size='small' variant='outlined'>
						<InputLabel>Theme</InputLabel>

						<Select value={editorTheme} onChange={handleChangeEditorTheme} label='Theme' sx={{height: 34}}>
							{themes.map((theme, index) => (
								<MenuItem value={theme.value} key={index}>
									{theme.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size='small' variant='outlined'>
						<InputLabel>Font Size</InputLabel>

						<Select value={editorFontSize} onChange={handleChangeEditorFontSize} label='Font Size' size='small' sx={{height: 34}}>
							{fontSizes.map((size, index) => (
								<MenuItem value={size} key={index * 37 + 1}>
									{size}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			</ErrorBoundary>
		</>
	);
}
const mapDispatchToProps = dispatch => ({
	onApplyFetchCandidates: candidatesFetch => dispatch({type: 'CANDIDATES_FETCH', candidatesFetch}),
});
const mapStateToProps = state => ({
	candidatesFetch: state.candidatesFetchState.candidatesFetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedUserCodePad);
