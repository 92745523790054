import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import LiveCodePad from './LiveCodePad';
import MyCalendar from '../Schedule/Calendar/Calendar';
import {Card} from '@mui/material';
import Availability from '../Schedule/Availability/Availability';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {useAccount} from '../../AccountContext/AccountContext';

function DashboardContent() {
	const {planPermissions} = useAccount();

	const [isChartBugged, setIsChartBugged] = React.useState(false);
	const [isAvailabilityBugged, setIsAvailabilityBugged] = React.useState(false);
	const [isCalendarBugged, setIsCalendarBugged] = React.useState(false);

	return (
		<>
			<Container maxWidth='xl' sx={{mt: 4, mb: 4}}>
				<Grid container spacing={3}>
					{/* Live Code Pad */}
					<Grid item xs={12} md={4} lg={3}>
						<Paper
							sx={{
								p: 2,
								display: 'flex',
								flexDirection: 'column',
								height: 300,
							}}
						>
							<LiveCodePad />
						</Paper>
					</Grid>
					{/* Chart */}
					<Grid item xs={12} md={8} lg={9}>
						<Paper
							sx={{
								p: 2,
								display: 'flex',
								flexDirection: 'column',
								height: 300,
							}}
						>
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsChartBugged(false)} resetKeys={[isChartBugged]}>
								<Chart />
							</ErrorBoundary>
						</Paper>
					</Grid>
					{/* Available times */}
					{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('schedule') || planPermissions.permissions.includes('schedule.availability')) && (
						<Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsAvailabilityBugged(false)} resetKeys={[isAvailabilityBugged]}>
								<Availability />
							</ErrorBoundary>
						</Grid>
					)}
					{/* Calendar */}
					{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('schedule') || planPermissions.permissions.includes('schedule.calendar')) && (
						<Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
							<Card sx={{padding: '16px 16px 16px', display: 'flex', flexDirection: 'column', overflow: 'visible'}}>
								<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCalendarBugged(false)} resetKeys={[isCalendarBugged]}>
									<Typography component='h2' variant='h6' color='primary' gutterBottom>
										Calendar
									</Typography>
									<MyCalendar />
								</ErrorBoundary>
							</Card>
						</Grid>
					)}
				</Grid>

				{/* <Copyright sx={{pt: 4}} /> */}
			</Container>
		</>
	);
}

export default function NewDashboard() {
	return <DashboardContent />;
}
