import {Close} from '@mui/icons-material';
import {Card, CardActions, CardContent, CardHeader, Divider, IconButton} from '@mui/material';
import {Box} from '@mui/system';

export default function GenericCard({children, title, handleClose, actions, minWidth}) {
	return (
		<Card sx={{position: 'relative', borderRadius: '15px', minWidth: minWidth ? minWidth : 400}} elevation={5}>
			{handleClose && (
				<IconButton sx={{border: 'none !important', position: 'absolute', right: 5, top: 5}} size='small' onClick={handleClose}>
					<Close htmlColor='#fff' />
				</IconButton>
			)}
			<CardHeader
				title={
					<Box width='100%' display='flex' justifyContent='center' alignItems='center'>
						{title}
					</Box>
				}
				sx={{paddingTop: 1, paddingBottom: 1, background: '#3A82D5', color: '#fff'}}
			/>
			<Divider />
			<CardContent sx={{overflow: 'auto !important', mt: 0.5, mb: 0.5, pt: 1, pb: '8px !important'}}>{children}</CardContent>
			{actions && (
				<>
					<Divider />
					<CardActions sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>{actions}</CardActions>
				</>
			)}
		</Card>
	);
}
