import {useState} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {Avatar, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery, useTheme} from '@mui/material';
import {EditOutlined} from '@mui/icons-material';
import {useAccount} from '../../AccountContext/AccountContext';
import {useAuth} from '../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import {Box} from '@mui/system';
import axios from 'axios';
import Env from '../../util/Env';

const StyledPaper = withStyles(theme => ({
	root: {
		marginTop: 10,
		borderRadius: 0,
		background: theme.palette.primary.main,
	},
}))(Paper);
const useStyles = makeStyles(() => ({
	image: {
		height: 100,
		width: 100,
		'&:hover': {
			opacity: 0.9,
		},
		opacity: 1,
		transition: 'opacity .2s ease-in-out',
	},
	imageContainer: {
		position: 'relative',
		justifyContent: 'center',
	},
	iconContainer: {
		position: 'absolute',
		top: 110,
		right: 0,
		bottom: 0,
		left: 90,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 1,
		transition: 'opacity .2s ease-in-out',
	},
}));

function Profile() {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const {enqueueSnackbar} = useSnackbar();
	const {loggedInUser, token} = useAuth();
	const {userObj, setUserObj} = useAccount();
	const {lastLoginTimestamp, updateTimestamp, creationTimestamp, id, designs, photo, accountId, accountRole, ...authUser} = userObj;
	const defaultPic = 'https://www.w3schools.com/howto/img_avatar.png';
	const [hovered, setHovered] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleUploadPic = async event => {
		var selectedPic = event.target.files[0];
		if (selectedPic) {
			setLoading(true);
			var formData = new FormData();
			formData.append('file', selectedPic);
			formData.append('target', loggedInUser.uid);

			axios
				.post(Env().url + '/images/upload', formData, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
				})
				.then(res => {
					let data = {...userObj, photo: res.data.url};
					axios
						.put(Env().url + '/users', data, {
							headers: {
								Authorization: 'Bearer ' + token,
							},
						})
						.then(res => {
							setLoading(false);
							setUserObj(res.data);
						})
						.catch(error => {
							setLoading(false);
							enqueueSnackbar(error.message, {variant: 'error'});
						});
				})
				.catch(err => {
					setLoading(false);
					enqueueSnackbar(err.message, {variant: 'error'});
				});
		}
	};

	return (
		<Box sx={{background: `-webkit-linear-gradient(top, #0E0E0E 79px, #FFF 2%)`}} height='100%' display='flex' flexDirection='column' alignItems='center'>
			<Box className={classes.imageContainer} onMouseEnter={e => setHovered(true)} onMouseLeave={e => setHovered(false)}>
				<Avatar variant='circle' sx={{width: 100, height: 100, marginTop: '20px'}}>
					<img src={userObj.photo ? userObj.photo : defaultPic} alt='' className={classes.image} />
				</Avatar>
				{(hovered || loading || isMobile) && (
					<Box className={classes.iconContainer}>
						{loading ? (
							<CircularProgress size={25} sx={{color: '#0e0e0e'}} />
						) : (
							<Button component='label' sx={{background: 'transparent !important'}} disableRipple color='inherit'>
								<EditOutlined />
								<input accept='image/*' type='file' onChange={handleUploadPic} hidden />
							</Button>
						)}
					</Box>
				)}
			</Box>
			<Typography fontWeight={1000}>{authUser.username}</Typography>
			<Typography color='#aaa' fontStyle='italic'>
				{authUser.email}
			</Typography>
			<TableContainer component={StyledPaper}>
				<Table className={classes.table} aria-label='caption table'>
					<TableBody>
						{Object.keys(authUser).map((key, index) => (
							<TableRow key={index} sx={{borderTop: index !== 0 ? '' : 'solid 1px black', maxWidth: 280}}>
								<TableCell align='center' sx={{maxWidth: 100, textOverflow: 'ellipsis', overflow: 'hidden'}}>
									<Typography component='span' fontWeight={1000}>
										{key}
									</Typography>
								</TableCell>
								<TableCell align='center' sx={{maxWidth: 180, borderLeft: 'solid 1px black'}}>
									<Typography component='span'>{authUser[key]?.toString()}</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}

export default Profile;
