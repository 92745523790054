import {Assignment, Close, Delete, Pause, PlayArrow} from '@mui/icons-material';
import {Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Collapse, IconButton, Slider, SliderThumb, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {memo, useCallback, useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import Env from '../../../../../../util/Env';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {materialLight} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import ConfirmationPopper from '../../../../../Fragments/ConfirmationPopper';
import {styled} from '@mui/system';
import {useAuth} from '../../../../../../AuthContext/AuthContext';

function AirbnbThumbComponent(props) {
	const {children, ...other} = props;
	return (
		<SliderThumb {...other}>
			{children}
			<span className='airbnb-bar' />
			<span className='airbnb-bar' />
			<span className='airbnb-bar' />
		</SliderThumb>
	);
}

const CustomSlider = styled(Slider)(() => ({
	color: '#3880ff',
	height: 2,
	padding: '15px 0',
	'& .MuiSlider-thumb': {
		height: 27,
		width: 27,
		backgroundColor: '#fff',
		border: '1px solid currentColor',
		'&:hover': {
			boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
		},
		'& .airbnb-bar': {
			height: 9,
			width: 1,
			backgroundColor: 'currentColor',
			marginLeft: 1,
			marginRight: 1,
		},
	},
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-rail': {
		opacity: 0.5,
		backgroundColor: '#bfbfbf',
	},
	'& .MuiSlider-mark': {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		'&.MuiSlider-markActive': {
			opacity: 1,
			backgroundColor: 'currentColor',
		},
	},
}));

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 600,
		'&::first-letter': {
			fontSize: '150%',
		},
	},
	cardHeader: {
		paddingBottom: '0px !important',
	},
	cardContent: {
		paddingTop: '0px !important',
	},
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
	},
}));

export default memo(function Solutions({currentCandidate, fetchCandidatesData, setTabsValue}) {
	const classes = useStyles();
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [chosenExample, setChosenExample] = useState('');
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = useState(false);
	const [exampleIndex, setExampleIndex] = useState('');
	const [timeStamp, setTimeStamp] = useState(0);
	const [play, setPlay] = useState(false);

	const handleClickOnSolution = (event, example, index) => {
		ReactGA.event({
			category: 'Example description',
			action: 'open the example description popper',
		});
		if (chosenExample.id !== example.id) {
			setChosenExample(example);
			textForRevision(0);
		}
	};
	const textForRevision = time => {
		const Firepad = require('firepad');
		let history = chosenExample.history;
		var revisionsByTimestamp = {};
		for (var key in history) {
			const revision = history[key];
			const operation = Firepad.TextOperation.fromJSON(revision.o);
			revisionsByTimestamp[`${revision.t}`] = operation;
		}
		var document = new Firepad.TextOperation();
		const keys = Object.keys(revisionsByTimestamp).sort();
		for (let key of keys) {
			const operation = revisionsByTimestamp[key];
			document = document.compose(operation);
			if (key === Object.keys(revisionsByTimestamp)[time]) {
				break;
			}
		}
		return document.ops.length ? document.toJSON().slice(-1).pop() : null;
	};

	const handleDeleteCandidateSolution = useCallback(() => {
		let last = currentCandidate.examples.length === 1;
		ReactGA.event({
			category: 'Pad data',
			action: 'delete solution from current candidate solutions',
		});
		let {creationTime, refreshTime, updateTime, ...padTemp} = currentCandidate;

		padTemp.examples.splice(exampleIndex, 1);
		padTemp.config = JSON.stringify(padTemp.config);
		axios
			.post(Env().url + '/pad', padTemp, {headers: {Authorization: 'Bearer ' + token}})
			.then(res => {
				enqueueSnackbar('solution is deleted!', {
					variant: 'success',
				});
				setExampleIndex('');
				setOpenConfirmationPopper(false);
				setAnchorConfirmationPopper('');
				last && setTabsValue(0);
				handleCollapse();
				fetchCandidatesData();
			})
			.catch(err => {
				enqueueSnackbar("couldn't delete solution!", {
					variant: 'error',
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exampleIndex]);

	const handleCollapse = useCallback(() => {
		setChosenExample('');
	}, []);

	const handleOpenConfirmationPopper = useCallback((e, id, index) => {
		e.stopPropagation();
		setAnchorConfirmationPopper(e.currentTarget);
		setExampleIndex(index);
		setOpenConfirmationPopper(true);
	}, []);

	const handleClosePopper = useCallback(() => {
		setOpenConfirmationPopper(false);
	}, []);

	useEffect(() => {
		if (play) {
			if (timeStamp === Object.keys(chosenExample?.history).length - 1) {
				setTimeStamp(0);
			}
			window.playInterval = setInterval(() => {
				setTimeStamp(timeStamp => {
					if (timeStamp !== Object.keys(chosenExample?.history).length - 1) {
						return timeStamp + 1;
					} else {
						setPlay(false);
						return timeStamp;
					}
				});
			}, 200);
		} else {
			clearInterval(window.playInterval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [play]);

	useEffect(() => {
		setPlay(false);
		setTimeStamp(0);
		clearInterval(window.playInterval);
	}, [chosenExample]);

	return currentCandidate?.examples?.length > 0 ? (
		<>
			{openConfirmationPopper && (
				<ConfirmationPopper
					placement='bottom'
					arrow={true}
					anchor={anchorConfirmationPopper}
					operation='Delete'
					name='Solution'
					cancel='cancel'
					confirm='Delete'
					message='Are you sure?'
					onCancel={handleClosePopper}
					onClose={handleClosePopper}
					onConfirm={handleDeleteCandidateSolution}
				/>
			)}
			<Box display='flex' flexDirection='column' gap={1}>
				<Box display='flex' gap={1} mb={2} flexWrap='wrap'>
					{currentCandidate?.examples?.map((item, index) => (
						<Chip
							key={index}
							size='small'
							color='primary'
							label={JSON.parse(item).title}
							clickable
							onClick={e => handleClickOnSolution(e, JSON.parse(item), index)}
							avatar={
								<Avatar>
									<Assignment fontSize='small' />
								</Avatar>
							}
							onDelete={e => handleOpenConfirmationPopper(e, JSON.parse(item).id, index)}
							deleteIcon={<Delete />}
						/>
					))}
				</Box>
			</Box>

			<Collapse in={chosenExample ? true : false} timeout={{enter: 300, exit: 0}} unmountOnExit>
				<Card sx={{overflow: 'auto'}}>
					<CardHeader
						title={
							<Box display='flex' alignItems='center' justifyContent='space-between'>
								<Typography variant='h6' className={classes.title} align='center'>
									{chosenExample.title}
								</Typography>
								<Button sx={{borderRadius: 15}} variant='outlined' size='small' onClick={handleCollapse} endIcon={<Close />}>
									close
								</Button>
							</Box>
						}
					/>
					{chosenExample.history && (
						<CardContent className={classes.cardContent}>
							<Box
								sx={{
									display: 'flex',
									width: '100%',
									alignItems: 'center',
									gap: 2,
								}}
							>
								<IconButton onClick={() => setPlay(play => !play)}>{play ? <Pause /> : <PlayArrow color='primary' />}</IconButton>
								<CustomSlider
									min={0}
									max={chosenExample ? Object.keys(chosenExample.history).length - 1 : 20}
									step={1}
									value={timeStamp}
									marks
									onChange={(_, value) => {
										setTimeStamp(value);
									}}
									components={{Thumb: AirbnbThumbComponent}}
								/>
							</Box>
							<Box className='customScroll' mb={2} mt={1} overflow='auto'>
								<SyntaxHighlighter
									language={chosenExample.language}
									style={materialLight}
									showLineNumbers={true}
									wrapLines={true}
									customStyle={{
										margin: 0,
										paddingLeft: 0,
										borderRadius: 10,
									}}
								>
									{textForRevision(timeStamp, 0)}
								</SyntaxHighlighter>
							</Box>
						</CardContent>
					)}
				</Card>
			</Collapse>
		</>
	) : null;
});
