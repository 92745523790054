import {Box, Button, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import ReactGA from 'react-ga';
import Lottie from '../Fragments/Lottie';
import {useAuth} from '../../AuthContext/AuthContext';

export default function ErrorPage() {
	const location = useLocation();
	const history = useHistory();
	const {loggedInUser} = useAuth();

	return (
		<Box height='100%' minHeight='100vh' bgcolor='#E8EFFF' display='flex' flexDirection='column' alignItems='center' justifyContent='flex-start' width='100%'>
			<Lottie url='https://assets10.lottiefiles.com/packages/lf20_tjnaltsv.json' width='600px' height='400px' />
			<Typography gutterBottom align='center' variant='h2' fontFamily='Fredoka'>
				{location && location.state && location.state.message ? location.state.message : 'Something went wrong'}
			</Typography>
			<Button
				sx={{textTransform: 'none'}}
				variant='outlined'
				onClick={() => {
					ReactGA.event({
						category: 'Error page',
						action: 'click on home button after an error',
					});
					history.push(loggedInUser ? '/Dashboard' : '/');
				}}
				autoCapitalize='none'
			>
				{loggedInUser ? 'Back to Dashboard' : 'Back to Home'}
			</Button>
		</Box>
	);
}
