import React, {memo} from 'react';
import {makeStyles} from '@mui/styles';
import {Button, ClickAwayListener, Typography} from '@mui/material';
import {Arrow, Popper} from '../common/PopperFragments';
import GenericCard from './GenericCard';
import StyledQuestionContent from './StyledQuestionContent';

const useStyles = makeStyles(theme => ({
	title: {
		'&::first-letter': {
			fontSize: '150%',
		},
	},
	content: {
		fontFamily: 'Times New Roman',
	},
	card: {
		position: 'relative',
		maxWidth: 600,
		borderRadius: '15px !important',
	},
	cardHeader: {
		paddingBottom: '0px !important',
	},
	cardContent: {
		paddingTop: '0px !important',
	},
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
	},
}));

export default memo(function QuizContentPopper({quiz, onClose, anchor, placement, arrow, hideMoreDetails}) {
	const classes = useStyles();
	const [arrowRef, setArrowRef] = React.useState(null);

	const Actions = () => (
		<>
			<Button color='error' variant='outlined' onClick={onClose} size='small'>
				Close
			</Button>
			{!hideMoreDetails && (
				<Button size='small' color='primary' variant='contained' LinkComponent='a' href={'https://simple01.linearbits.com/Examples/?id=' + quiz.id + '&topicId=' + quiz.targetId} target='_blank'>
					See more ❯
				</Button>
			)}
		</>
	);
	return (
		<ClickAwayListener
			onClickAway={e => {
				e.stopPropagation(false);
				onClose();
			}}
		>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<GenericCard
					handleClose={onClose}
					actions={<Actions />}
					title={
						<Typography variant='h6' className={classes.title} align='center' fontWeight={600} fontFamily='Fredoka'>
							{quiz.title}
						</Typography>
					}
				>
					<StyledQuestionContent problem={quiz.problem} testCases={quiz.testCases} />
				</GenericCard>
			</Popper>
		</ClickAwayListener>
	);
});
