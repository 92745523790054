import React, {useContext, useEffect, useState} from 'react';
import {useAuth} from '../AuthContext/AuthContext';
import useAxios from 'axios-hooks';
import Env from '../util/Env';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const AccountContext = React.createContext();

export function useAccount() {
	return useContext(AccountContext);
}

export function AccountProvider({children}) {
	const history = useHistory();

	const [account, setAccount] = useState({accountType: '', accountStatus: ''});
	const [userObj, setUserObj] = useState();
	const [planPermissions, setPlanPermissions] = useState();
	const {loggedInUser, token} = useAuth();

	const [, fetchUserData] = useAxios(
		{
			method: 'GET',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);
	const [{loading: planLoading}, fetchPlansPermissions] = useAxios(
		{
			url: Env().url + '/configurations/c89dd21c-aa14-4151-895c-86a02398a364',
			method: 'GET',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);
	const [, fetchAccountData] = useAxios(
		{
			method: 'GET',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData({url: Env().url + '/users/' + loggedInUser.uid}).then(res => {
				setUserObj(res.data);
				if (res.data.accountId) {
					fetchAccountData({url: Env().url + '/accounts/' + res.data.accountId}).then(res => {
						setAccount(res.data);
					});
				} else {
					setAccount({accountStatus: '', accountType: ''});
				}
			});
		}
	}, [fetchAccountData, fetchUserData, loggedInUser, history]);

	useEffect(() => {
		fetchPlansPermissions()
			.then(res => {
				let plansPermissions = res.data.config;
				if (plansPermissions) {
					if (account.accountType) {
						setPlanPermissions(JSON.parse(plansPermissions)._roles[account.accountType]);
					} else {
						setPlanPermissions(JSON.parse(plansPermissions)._roles['Free']);
					}
				}
			})
			.catch(() => {
				setPlanPermissions('');
			});
	}, [account, fetchPlansPermissions, history]);

	const value = {
		userObj,
		setUserObj,
		account,
		planLoading,
		planPermissions,
		setPlanPermissions,
		setAccount,
	};

	return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}
