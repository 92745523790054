import {Button, Input, TextField} from '@mui/material';
import {Box} from '@mui/system';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import GenericCard from '../Fragments/GenericCard';

const CARD_OPTIONS = {
	iconStyle: 'solid',
	style: {
		base: {
			iconColor: '#c4f0ff',
			fontWeight: 500,
			fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
			fontSize: '16px',
			fontSmoothing: 'antialiased',
			':-webkit-autofill': {color: '#fce883'},
			'::placeholder': {color: '#87bbfd'},
		},
		invalid: {
			iconColor: '#ffc7ee',
			color: '#ffc7ee',
		},
	},
};

export default function PaymentForm({handleClose}) {
	const [success, setSuccess] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const [name, setName] = useState('');
	const {enqueueSnackbar} = useSnackbar();

	const handleSubmit = async e => {
		e.preventDefault();
		const {error, paymentMethod} = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		});

		if (!error) {
			try {
				console.log(elements.getElement(CardElement));
				const {id} = paymentMethod;
				const response = await axios.post('http://localhost:4000/payment', {
					name,
					amount: 1000,
					id,
				});

				if (response.data.success) {
					console.log('Successful payment');
					setSuccess(true);
					// handleSuccess();
					handleClose();
				}
			} catch (error) {
				enqueueSnackbar(error.message, {variant: 'error'});
			}
		} else {
			enqueueSnackbar(error.message, {variant: 'error'});
		}
	};

	return (
		<GenericCard handleClose={handleClose} title='Payment info'>
			<form onSubmit={handleSubmit}>
				{/* <Box mb={1} border='1px solid #ddd' p={1} borderRadius={2}> */}
				<TextField value={name} onChange={e => setName(e.target.value)} sx={{mb: 1, borderRadius: '10px !important'}} label='Name on card' size='small' fullWidth />
				{/* </Box> */}
				<Box mb={2} border='1px solid #ddd' p={1} borderRadius={2}>
					<CardElement options={CARD_OPTIONS} />
				</Box>
				<Box width='100%' display='flex' justifyContent='center'>
					<Button variant='contained' type='submit' size='small' sx={{textTransform: 'none'}}>
						Pay
					</Button>
				</Box>
			</form>
		</GenericCard>
	);
}
