export const isProduction = () => {
	let host = '';
	try {
		host = window.location.href;
	} catch (err) {}
	let prod = true;
	if (host.valueOf().includes('localhost')) {
		prod = false;
	}

	return prod;
};
