import {useEffect} from 'react';
import uuid from 'react-uuid';
import lottie from 'lottie-web';

export default function Lottie({url, width = '', height = ''}) {
	const id = uuid();

	useEffect(() => {
		document.getElementById(`lottie${id}`).innerHTML = '';
		lottie.loadAnimation({
			container: document.querySelector(`#lottie${id}`),
			path: url,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	return <div id={`lottie${id}`} style={{width: width || '', height: height || ''}} />;
}
