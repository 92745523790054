import {isProduction} from './prodCheck';

export default function Env() {
	const config = {
		url: 'https://simpleeasylearning-5607d.uc.r.appspot.com/api',
		gaKey: 'UA-195602519-1',
		appVersion: '1.0',
	};
	if (!isProduction()) {
		// config.url = 'http://localhost:8080/api';
		// config.url = 'http://73.36.107.125:8080/api';
		config.url = "https://simpleeasylearning-5607d.uc.r.appspot.com/api";
	}
	return config;
}
