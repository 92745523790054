import {Check} from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Card, ListItem, List, ListItemText, Typography, CardContent, CardHeader} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useCallback, useState} from 'react';
import Env from '../../../../util/Env';
import ReactGA from 'react-ga';

const useStyles = makeStyles(() => ({
	cardHeader: {
		padding: '5px 5px 5px !important',
	},
	cardContent: {
		padding: '5px 5px 5px !important',
	},
}));

export default function Filters({fetchExamples, setTagFilterString, tags, rowsPerPage, searchQuery}) {
	const classes = useStyles();
	const [selectedTag, setSelectedTag] = useState('');

	const handleTagClick = useCallback(
		tag => {
			if (selectedTag === tag) {
				ReactGA.event({
					category: 'Filter Examples',
					action: 'disable filter examples by difficulty level',
					value: tag,
				});
				setSelectedTag('');
				setTagFilterString('');
				fetchExamples({
					url: Env().url + '/examples?published=true&_start=0&_end=' + rowsPerPage + (searchQuery ? '&' + searchQuery : ''),
				});
			} else {
				ReactGA.event({
					category: 'Filter Examples',
					action: 'filter examples by difficulty level',
					value: tag,
				});
				setSelectedTag(tag);
				setTagFilterString('tag=' + tag);
				fetchExamples({
					url: Env().url + '/examples?published=true&_start=0&_end=' + rowsPerPage + '&tag=' + tag + (searchQuery ? '&' + searchQuery : ''),
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rowsPerPage, selectedTag, searchQuery]
	);

	return (
		<Box width='50%'>
			<Card variant='outlined'>
				<CardHeader avatar={<FilterListIcon />} title={<Typography fontWeight={600}>Filter Examples</Typography>} className={classes.cardHeader} />
				<CardContent className={classes.cardContent}>
					<List dense disablePadding>
						{tags &&
							Object.keys(tags)?.map((tag, index) => (
								<ListItem key={index} button sx={{height: 20, py: 0}} size='small' selected={selectedTag === tag} onClick={() => handleTagClick(tag)} secondaryAction={selectedTag === tag ? <Check /> : null}>
									<ListItemText
										primary={
											<Box display='flex' justifyContent='space-between'>
												<Box display='flex' gap={2}>
													<Typography color='#7F7F7F' fontWeight={600}>
														#
													</Typography>
													<Typography>{tag}</Typography>
												</Box>
												<Typography>{(tags && tags[tag]) || 0}</Typography>
											</Box>
										}
									/>
								</ListItem>
							))}
					</List>
				</CardContent>
			</Card>
		</Box>
	);
}
