import {Refresh} from '@mui/icons-material';
import {Alert, IconButton, Typography} from '@mui/material';
import {Box} from '@mui/system';

export default function InternalErrorBoundaryPage({error, resetErrorBoundary}) {
	return (
		<Box width='100%'>
			<Alert
				severity='error'
				action={
					<IconButton color='inherit' size='small' onClick={resetErrorBoundary}>
						<Refresh />
					</IconButton>
				}
			>
				Something went wrong
			</Alert>
			<Typography component='pre' align='center' color='tomato'>
				{error.message}
			</Typography>
		</Box>
	);
}
