import {Button} from '@mui/material';
import {Box} from '@mui/system';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useAccount} from '../../../../AccountContext/AccountContext';
import {useAuth} from '../../../../AuthContext/AuthContext';
import {useRealtime} from '../../../../RealtimeContext/RealtimeContext';
import Env from '../../../../util/Env';
import firebase from 'firebase';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import OptionsButtonPopper from './OptionsButtonPopper';
import {Settings, VerifiedUserOutlined} from '@mui/icons-material';

export default function FooterUI({excaliRef}) {
	const [openDrawingsPopper, setOpenDrawingsPopper] = useState(false);
	const [drawingsPopperAnchor, setDrawingsPopperAnchor] = useState('');
	const [openSavePopper, setOpenSavePopper] = useState(false);
	const [savePopperAnchor, setSavePopperAnchor] = useState('');
	const [openRealtimePopper, setOpenRealtimePopper] = useState(false);
	const [realtimePopperAnchor, setRealtimePopperAnchor] = useState('');
	const [openUserSketchOptionsPopper, setOpenUserSketchOptionsPopper] = useState(false);
	const [userSketchOptionsPopperAnchor, setUserSketchOptionsPopperAnchor] = useState('');
	const [openCandidateOptionsPopper, setOpenCandidateOptionsPopper] = useState(false);
	const [candidateOptionsPopperAnchor, setCandidateOptionsPopperAnchor] = useState('');
	const {realtime, setRealtime} = useRealtime();
	const {loggedInUser, token} = useAuth();
	const roomRef = firebase.app('linearPad').database().ref().child(loggedInUser.uid);
	const [saveLoading, setSaveLoading] = useState(false);
	const [loadLoading, setLoadLoading] = useState(false);

	const {userObj} = useAccount();
	const {enqueueSnackbar} = useSnackbar();

	const handleOpenCandidateOptionsPopper = e => {
		setOpenCandidateOptionsPopper(true);
		setCandidateOptionsPopperAnchor(e.currentTarget);
	};
	const handleCloseCandidateOptionsPopper = () => {
		setCandidateOptionsPopperAnchor('');
		setOpenCandidateOptionsPopper(false);
	};
	const handleOpenUserSketchOptionsPopper = e => {
		handleCloseDrawingsPopper();
		handleCloseSavePopper();
		setUserSketchOptionsPopperAnchor(e.currentTarget);
		setOpenUserSketchOptionsPopper(true);
	};
	const handleCloseUserSketchOptionsPopper = () => {
		setOpenUserSketchOptionsPopper(false);
		setUserSketchOptionsPopperAnchor('');
	};
	const handleOpenSavePopper = e => {
		handleCloseDrawingsPopper();
		setSavePopperAnchor(e.currentTarget);
		setOpenSavePopper(true);
	};
	const handleCloseSavePopper = () => {
		setOpenSavePopper(false);
		setSavePopperAnchor('');
	};
	const handleOpenRealtimePopper = e => {
		setRealtimePopperAnchor(e.currentTarget);
		setOpenRealtimePopper(true);
	};
	const handleCloseRealtimePopper = () => {
		setOpenRealtimePopper(false);
		setRealtimePopperAnchor('');
	};
	const handleOpenDrawingsPopper = e => {
		handleCloseSavePopper();
		setDrawingsPopperAnchor(e.currentTarget);
		setOpenDrawingsPopper(true);
	};
	const handleCloseDrawingsPopper = () => {
		setOpenDrawingsPopper(false);
		setDrawingsPopperAnchor('');
	};

	const handleSaveForCandidate = () => {
		setSaveLoading(true);
		let data = JSON.stringify({elements: excaliRef.current.getSceneElements(), state: excaliRef.current.getAppState()});
		var blob = new Blob([data]);
		var formData = new FormData();
		formData.append('file', blob);
		axios
			.post(Env().url + '/sketch/uploadExcali', formData, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.then(res => {
				let candidateTmp = {...realtime.currentCandidate};
				candidateTmp.design = res.data.id;
				candidateTmp.config = JSON.stringify(candidateTmp.config);
				axios
					.put(Env().url + '/pad', candidateTmp, {
						headers: {
							Authorization: 'Bearer ' + token,
						},
					})
					.then(res => {
						setSaveLoading(false);
						setRealtime({...realtime, currentCandidate: res.data});
					})
					.catch(err => {
						enqueueSnackbar(err.message, {variant: 'error'});
						setSaveLoading(false);
					});
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
				setSaveLoading(false);
			});
	};
	const handleLoadCandidateDraw = () => {
		setLoadLoading(true);
		axios
			.get(Env().url + '/sketch/getExcali/' + realtime.currentCandidate.design, {headers: {Authorization: 'Bearer ' + token}})
			.then(res => {
				if (res.data) {
					excaliRef?.current?.updateScene(res.data);
					setLoadLoading(false);
				}
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
				setLoadLoading(false);
			});
	};
	const handleSaveToRealtimeDB = () => {
		let data = JSON.stringify({elements: excaliRef.current.getSceneElements(), state: excaliRef.current.getAppState()});

		roomRef.child('RealtimeDraw').set(data);
		enqueueSnackbar('saved!', {variant: 'success'});
	};
	const handleLoadFromRealtimeDB = () => {
		roomRef
			.child('RealtimeDraw')
			.get()
			.then(snapshot => {
				let state = JSON.parse(snapshot.val());
				excaliRef?.current?.updateScene(state);
			});
	};
	return (
		<>
			{openCandidateOptionsPopper && (
				<OptionsButtonPopper
					anchor={candidateOptionsPopperAnchor}
					arrow={true}
					placement='top'
					onClose={handleCloseCandidateOptionsPopper}
					handleSave={handleSaveForCandidate}
					handleLoad={handleLoadCandidateDraw}
					loadButton={realtime?.currentCandidate?.design}
					saveLoading={saveLoading}
					loadLoading={loadLoading}
				/>
			)}
			{openUserSketchOptionsPopper && (
				<OptionsButtonPopper
					anchor={userSketchOptionsPopperAnchor}
					arrow={true}
					placement='top'
					onClose={handleCloseUserSketchOptionsPopper}
					handleSave={handleOpenSavePopper}
					handleLoad={handleOpenDrawingsPopper}
					loadButton={userObj.designs}
					excaliRef={excaliRef}
					drawingsPopperAnchor={drawingsPopperAnchor}
					handleCloseDrawingsPopper={handleCloseDrawingsPopper}
					handleCloseSavePopper={handleCloseSavePopper}
					openDrawingsPopper={openDrawingsPopper}
					openSavePopper={openSavePopper}
					savePopperAnchor={savePopperAnchor}
				/>
			)}
			{openRealtimePopper && <OptionsButtonPopper anchor={realtimePopperAnchor} arrow={true} onClose={handleCloseRealtimePopper} placement='top' handleSave={handleSaveToRealtimeDB} handleLoad={handleLoadFromRealtimeDB} loadButton={true} />}

			<Box display='flex' gap={1} flexWrap='wrap'>
				<Button startIcon={<Settings />} onClick={handleOpenUserSketchOptionsPopper} color='primary' sx={{textTransform: 'none', paddingRight: '10px !important', paddingLeft: '10px !important'}}>
					Options
				</Button>
				<Button startIcon={<CompareArrowsIcon />} onClick={handleOpenRealtimePopper} color='primary' sx={{textTransform: 'none', paddingRight: '10px !important', paddingLeft: '10px !important'}}>
					Realtime
				</Button>
				{realtime?.currentCandidate && (
					<Button startIcon={<VerifiedUserOutlined />} onClick={handleOpenCandidateOptionsPopper} color='primary' sx={{textTransform: 'none', paddingRight: '10px !important', paddingLeft: '10px !important'}}>
						Candidate drawings
					</Button>
				)}
			</Box>
		</>
	);
}
