import {FormControl, InputLabel, MenuItem, Select, Box, Button, useMediaQuery, Badge, TextField, InputAdornment} from '@mui/material';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {ArrowDropDown, ArrowDropUp, Autorenew, PeopleOutlineRounded, Share} from '@mui/icons-material';
import ReactGA from 'react-ga';
import {makeStyles, useTheme} from '@mui/styles';
import ConfirmationPopper from './ConfirmationPopper';
import SharePadPopper from '../Fragments/SharePadPopper';
import UsersPopper from './UsersPopper';
import firebase from 'firebase';
import LanguagesPopper from './LanguagesPopper';

const useStyles = makeStyles(() => ({
	button: {
		textTransform: 'none !important',
	},
}));

const themes = [
	{name: 'Terminal', value: 'terminal'},
	{name: 'Text Mate', value: 'textmate'},
	{name: 'Tomorrow', value: 'tomorrow'},
	{name: 'Tomorrow Night', value: 'tomorrow_night'},
	{name: 'Twilight', value: 'twilight'},
	{name: 'Vibrant', value: 'vibrant_ink'},
	{name: 'X Code', value: 'xcode'},
	{name: 'Monokai', value: 'monokai'},
	{name: 'Eclipse', value: 'eclipse'},
	{name: 'Dreamweaver', value: 'dreamweaver'},
	{name: 'Crimson', value: 'crimson_editor'},
];

const fontSizes = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px'];

export default memo(function Options({user, isCandidate, theme, handleThemeChange, language, handleLanguageChange, fontSize, handleChangeFontSize, handlePassCodeChange, setTabsValue}) {
	const classes = useStyles();
	const appTheme = useTheme();
	const usersListRef = useRef(null);
	const isMobile = useMediaQuery(appTheme.breakpoints.down('md'));
	const roomRef = firebase.app('linearPad').database().ref().child(user.uid);

	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = useState('');
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [openSharePadPopper, setOpenSharePadPopper] = useState(false);
	const [anchorSharePadPopper, setAnchorSharePadPopper] = useState(false);
	const [openUsersPopper, setOpenUsersPopper] = useState(false);
	const [expand, setExpand] = useState(false);
	const [anchorLanguagesPopper, setAnchorLanguagesPopper] = useState('');
	const [numOfUsers, setNumOfUsers] = useState(0);

	const handleOpenConfirmationPopper = useCallback(event => {
		ReactGA.event({
			category: 'Authorized user action',
			action: 'trying to refresh the session pass code',
		});
		setAnchorConfirmationPopper(event.currentTarget);
		setOpenConfirmationPopper(true);
	}, []);

	const handleOpenSharePadPopper = e => {
		setAnchorSharePadPopper(e.currentTarget);
		setOpenSharePadPopper(true);
	};
	const handleCloseSharePadPopper = () => {
		setOpenSharePadPopper(false);
		setAnchorSharePadPopper('');
	};

	const handleOpenLanguagesPopper = e => {
		setExpand(true);
		setAnchorLanguagesPopper(e.currentTarget);
	};
	const handleCloseLanguagesPopper = () => {
		setAnchorLanguagesPopper('');
		setExpand(!expand);
	};

	const handleCloseConfirmationPopper = useCallback(() => {
		ReactGA.event({
			category: 'Authorized user action',
			action: 'closed the popper by clicking outside',
		});
		setAnchorConfirmationPopper('');
		setOpenConfirmationPopper(false);
	}, []);

	const handleOpenUsersPopper = e => {
		if (!openUsersPopper) {
			e.stopPropagation();
			setOpenUsersPopper(true);
		}
	};

	const handleCloseUsersPopper = e => {
		setOpenUsersPopper(false);
	};

	const handleConfirmOrCancel = useCallback(type => {
		if (type === 'confirm') {
			ReactGA.event({
				category: 'Authorized user action',
				action: 'changed the pass code',
			});
			roomRef.child('currentCandidateId').set('');
			roomRef.child('currentQuiz').set('');
			handlePassCodeChange();
			window.location.reload();
		} else {
			ReactGA.event({
				category: 'Authorized user action',
				action: 'cancel pass code refresh',
			});
		}
		handleCloseConfirmationPopper();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		roomRef.on('value', () => {
			roomRef.child('users').once('value', datasnapshot => {
				if (datasnapshot.val()) {
					setNumOfUsers(Object.keys(datasnapshot.val()).length);
				}
			});
		});
	}, [roomRef]);

	return (
		<>
			{!isCandidate && <UsersPopper open={openUsersPopper} anchor={usersListRef.current} arrow={true} placement='bottom' onClose={handleCloseUsersPopper} setTabsValue={setTabsValue} />}
			{!isCandidate && <SharePadPopper anchor={anchorSharePadPopper} open={openSharePadPopper} arrow={true} placement='bottom' onClose={handleCloseSharePadPopper} />}
			{openConfirmationPopper && (
				<ConfirmationPopper
					operation='Refresh'
					name='Session'
					onCancel={() => handleConfirmOrCancel('cancel')}
					onConfirm={() => handleConfirmOrCancel('confirm')}
					cancel='Cancel'
					confirm='Sure'
					placement='top'
					arrow={false}
					anchor={anchorConfirmationPopper}
					message='Are you sure?'
					onClose={handleCloseConfirmationPopper}
				/>
			)}
			{expand && <LanguagesPopper operation='Select' name='Language' placement='bottom' arrow={false} anchor={anchorLanguagesPopper} onClose={handleCloseLanguagesPopper} onChange={handleLanguageChange} />}
			<Box id='options' display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' gap={!isMobile ? 1 : 0} p={1}>
				{(!isMobile || isCandidate) && (
					<Box display='flex' gap={1} flexWrap='wrap'>
						{isCandidate && (
							<FormControl size='small' variant='outlined'>
								<InputLabel>Theme</InputLabel>

								<Select value={theme} onChange={handleThemeChange} label='Theme' sx={{height: 34}}>
									{themes.map((theme, index) => (
										<MenuItem value={theme.value} key={index}>
											{theme.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						<TextField
							variant='outlined'
							label='Language'
							color='primary'
							onFocus={() => null}
							onMouseDown={e => {
								e.preventDefault();
							}}
							value={language}
							onClick={expand ? handleCloseLanguagesPopper : handleOpenLanguagesPopper}
							sx={{cursor: 'pointer !important'}}
							InputProps={{
								sx: {height: 34, cursor: 'pointer !important'},
								endAdornment: <InputAdornment position='end'>{expand ? <ArrowDropUp /> : <ArrowDropDown />}</InputAdornment>,
							}}
							size='small'
						/>
						{isCandidate && (
							<FormControl size='small' variant='outlined'>
								<InputLabel>Font Size</InputLabel>

								<Select value={fontSize} onChange={handleChangeFontSize} label='Font Size' size='small' sx={{height: 34}}>
									{fontSizes.map((size, index) => (
										<MenuItem value={size} key={index * 37 + 1}>
											{size}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					</Box>
				)}
				<Box display='flex' gap={1}>
					{!isCandidate && (
						<Badge badgeContent={numOfUsers} color='error'>
							<Button size='small' variant='outlined' color='success' onClick={handleOpenUsersPopper} ref={usersListRef} startIcon={<PeopleOutlineRounded />} className={classes.button}>
								Users
							</Button>
						</Badge>
					)}
					{!isCandidate && (
						<>
							<Button size='small' variant='outlined' color='success' onClick={handleOpenSharePadPopper} startIcon={<Share />} className={classes.button}>
								Share
							</Button>
							<Button size='small' variant='outlined' color='success' onClick={handleOpenConfirmationPopper} startIcon={<Autorenew />} className={classes.button}>
								Refresh
							</Button>
						</>
					)}
				</Box>
			</Box>
		</>
	);
});
