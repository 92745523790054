import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
} from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FloatingMenu } from "@tiptap/react";

const useStyles = makeStyles(() => ({
  floatPaper: {
    background: "#fff",
  },
  buttonGroup: {
    flexWrap: "wrap",
    display: "flex",
    maxWidth: 200,
  },
}));

export const FloatMenu = ({ editor }) => {
  const classes = useStyles();

  return (
    <FloatingMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      className={classes.floatPaper}
    >
      <ButtonGroup size="small">
        <Button
          size="small"
          disableRipple={true}
          variant={editor.isActive("bold") ? "contained" : "outlined"}
          onClick={() => {
            editor.chain().focus().toggleBold().run();
          }}
        >
          <b>B</b>
        </Button>

        <Button
          variant={editor.isActive("italic") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleItalic().run();
          }}
        >
          <em>I</em>
        </Button>

        <Button
          variant={editor.isActive("underline") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleUnderline().run();
          }}
        >
          <span style={{ textDecoration: "underline" }}>U</span>
        </Button>
        <Button
          variant={editor.isActive("strike") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleStrike().run();
          }}
        >
          <span style={{ textDecoration: "line-through" }}>S</span>
        </Button>
        <Button
          size="small"
          disableRipple={true}
          variant={
            editor.isActive({ textAlign: "left" }) ? "contained" : "outlined"
          }
          onClick={() => {
            editor.chain().focus().setTextAlign("left").run();
          }}
        >
          <FormatAlignLeft fontSize="small" />
        </Button>
        <Button
          variant={
            editor.isActive({ textAlign: "center" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("center").run();
          }}
        >
          <FormatAlignCenter fontSize="small" />
        </Button>

        <Button
          variant={
            editor.isActive({ textAlign: "right" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("right").run();
          }}
        >
          <FormatAlignRight fontSize="small" />
        </Button>
        <Button
          variant={
            editor.isActive({ textAlign: "justify" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("justify").run();
          }}
        >
          <FormatAlignJustify fontSize="small" />
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 1 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          H<sub>1</sub>
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 2 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          H<sub>2</sub>
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 3 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          H<sub>3</sub>
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 4 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        >
          H<sub>4</sub>
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 5 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
        >
          H<sub>5</sub>
        </Button>
        <Button
          variant={
            editor.isActive("heading", { level: 6 }) ? "contained" : "outlined"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
        >
          H<sub>6</sub>
        </Button>
      </ButtonGroup>
    </FloatingMenu>
  );
};
