import React, {useRef} from 'react';
import {Excalidraw} from '@excalidraw/excalidraw';

import './index.css';
import {Box} from '@mui/material';
import FooterUI from './FooterUI';
import FooterUiCandidate from './FooterUiCandidate';

const resolvablePromise = () => {
	let resolve;
	let reject;
	const promise = new Promise((_resolve, _reject) => {
		resolve = _resolve;
		reject = _reject;
	});
	promise.resolve = resolve;
	promise.reject = reject;
	return promise;
};

export default function ExcalidrawComp({height, roomId, isCandidate}) {
	const excalidrawRef = useRef(null);

	const initialStatePromiseRef = useRef({promise: null});
	if (!initialStatePromiseRef.current.promise) {
		initialStatePromiseRef.current.promise = resolvablePromise();
	}

	// useEffect(() => {
	// 	const onHashChange = () => {
	// 		const hash = new URLSearchParams(window.location.hash.slice(1));
	// 		const libraryUrl = hash.get('addLibrary');
	// 		if (libraryUrl) {
	// 			excalidrawRef.current.importLibrary(libraryUrl, hash.get('token'));
	// 		}
	// 	};
	// 	window.addEventListener('hashchange', onHashChange, false);
	// 	return () => {
	// 		window.removeEventListener('hashchange', onHashChange);
	// 	};
	// }, [excalidrawRef]);

	// const onLinkOpen = useCallback((element, event) => {
	// 	const link = element.link;
	// 	const {nativeEvent} = event.detail;
	// 	const isNewTab = nativeEvent.ctrlKey || nativeEvent.metaKey;
	// 	const isNewWindow = nativeEvent.shiftKey;
	// 	const isInternalLink = link.startsWith('/') || link.includes(window.location.origin);
	// 	if (isInternalLink && !isNewTab && !isNewWindow) {
	// 		// signal that we're handling the redirect ourselves
	// 		event.preventDefault();
	// 		// do a custom redirect, such as passing to react-router
	// 		// ...
	// 	}
	// }, []);

	return (
		<Box height={height}>
			<Excalidraw
				ref={excalidrawRef}
				name='Custom name of drawing'
				UIOptions={{canvasActions: {loadScene: false}}}
				// onLinkOpen={onLinkOpen}
				renderFooter={!isCandidate ? () => <FooterUI excaliRef={excalidrawRef} /> : () => <FooterUiCandidate excaliRef={excalidrawRef} roomId={roomId} />}
			/>
		</Box>
	);
}
