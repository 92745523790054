import {Button, Dialog, Divider, TextField, Typography, Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link, useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';

const useStyles = makeStyles(() => ({
	dialog: {
		background: 'rgba(43, 44, 46, 0.9)',
	},
	dialogPaper: {
		background: 'transparent !important',
		boxShadow: 'none !important',
	},
	root: {
		background: 'transparent',
		color: '#fff',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	header: {
		marginBottom: '20px !important',
	},
	subHeader: {
		marginBottom: '20px !important',
		fontSize: '14px !important',
		width: 245,
	},
	goButton: {
		textTransform: 'none !important',
		borderRadius: '0px 5px 5px 0px !important',
	},
	link: {
		color: '#2496FF',
		textDecoration: 'none',
	},
	loginButton: {
		textTransform: 'none !important',
		width: 100,
	},
	textBox: {
		'&:hover': {
			border: 'none !important',
		},
	},
	noBorder: {
		border: 'none !important',
	},
	terms: {
		color: '#2496FF',
		textDecoration: 'none',
		width: '100%',
		paddingRight: 10,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	privacy: {
		color: '#2496FF',
		textDecoration: 'none',
		width: '100%',
		paddingLeft: 10,
		display: 'flex',
		justifyContent: 'flex-start',
	},
}));

export default function CandidateDialog({openDialog, username, setUsername, handleCloseDialog}) {
	const classes = useStyles();
	const history = useHistory();

	const handleLogin = () => {
		ReactGA.event({
			category: 'Change login type',
			action: 'Candidate click on login to login with his credentials',
		});
		history.push('/LinearPad/Ace');
	};

	return (
		<Dialog open={openDialog.status} onClose={() => null} className={classes.dialog} classes={{paper: classes.dialogPaper}}>
			<Box className={classes.root}>
				<Box width='100%' display='flex' justifyContent='center' mb={1}>
					<img src='/images/LinearpadLogo.png' alt='logo' width={200} height={200} />
				</Box>
				<Box display='flex' flexDirection='column' alignItems='center' width={250}>
					<Typography gutterBottom variant='h6' className={classes.header}>
						Joining as a candidate?
					</Typography>
					<Typography gutterBottom align='center' className={classes.subHeader}>
						Let us know what your name is and we'll get your interview started
					</Typography>
					<form onSubmit={handleCloseDialog}>
						<Box display='flex' mb={2} mt={1}>
							<TextField
								className={classes.textBox}
								size='small'
								value={username}
								InputProps={{
									classes: {notchedOutline: classes.noBorder},
								}}
								onChange={e => {
									setUsername(e.target.value);
								}}
								sx={{background: '#fff', borderRadius: '5px 0px 0px 5px'}}
								variant='outlined'
								placeholder='Your Name'
							/>
							<Button variant='contained' className={classes.goButton} sx={{background: !username && '#1776D280 !important'}} color='primary' type='submit' disabled={!username}>
								Go!
							</Button>
						</Box>
					</form>
					<Typography sx={{fontSize: 14}} color='#ccc' fontFamily='sans-serif'>
						First time here? Read our{' '}
						<Link href='#' to='' className={classes.link}>
							quick guide
						</Link>
						.
					</Typography>
				</Box>
				<Divider
					orientation='vertical'
					sx={{
						'&.MuiDivider-root': {
							'&::before': {
								borderLeft: `thin solid #BBB`,
							},
							'&::after': {
								borderLeft: `thin solid #BBB`,
							},
						},
					}}
					flexItem
				>
					or
				</Divider>
				<Box display='flex' flexDirection='column' alignItems='center' width={250}>
					<Typography variant='h6' className={classes.header} gutterBottom>
						Have an account?
					</Typography>
					<Typography className={classes.subHeader} align='center'>
						Log in to your account
					</Typography>
					<Button variant='contained' color='primary' className={classes.loginButton} onClick={handleLogin}>
						Log in
					</Button>
				</Box>
				<Box mt={3} width='100%' display='flex' justifyContent='center'>
					<Typography
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'space-evenly',
						}}
					>
						<Link href='#' to='' className={classes.terms}>
							Terms of Service
						</Link>
						<Typography color='#ddd' sx={{marginBottom: 1}}>
							{'  '}.{'  '}
						</Typography>
						<Link href='#' to='' className={classes.privacy}>
							Privacy Policy
						</Link>
					</Typography>
				</Box>
			</Box>
		</Dialog>
	);
}
