import {
	Button,
	Card,
	CardActions,
	CardHeader,
	Checkbox,
	CircularProgress,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import {useEffect, useState} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker';
import TimezoneSelect from 'react-timezone-select';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Add, Delete} from '@mui/icons-material';
import CopyTimesPopper from './CopyTimesPopper';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {useSnackbar} from 'notistack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useAuth} from '../../../AuthContext/AuthContext';
import {makeStyles} from '@mui/styles';
import axios from 'axios';
import Env from '../../../util/Env';
import CopyToClipboard from 'react-copy-to-clipboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import DurationsPopper from './DurationsPopper';
import {useAccount} from '../../../AccountContext/AccountContext';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker} from 'react-dates';
import moment from 'moment-timezone';
import {convertFromUTCStringToZone, convertFromZoneToUTCString} from '../../../util/commonFunctions';

const useStyles = makeStyles(() => ({
	image: {
		width: 40,
		height: 40,
		borderRadius: '50%',
	},
	timeZone: {
		zIndex: '99 !important',
		fontFamily: 'Fredoka',
	},
	dateRange: {
		width: '100%',
		'&& .rdrMonth': {
			width: 'auto !important',
		},
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: 'transform 0.3s ease-in-out !important',
	},
	collapse: {
		transform: 'rotate(180deg)',
		transition: 'transform 0.3s ease-in-out !important',
	},
}));

export default function Availability() {
	const classes = useStyles();
	const {loggedInUser, token} = useAuth();
	const {userObj} = useAccount();
	const {enqueueSnackbar} = useSnackbar();

	const [range, setRange] = useState([]);
	const [allowCopy, setAllowCopy] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [focusedInputState, setFocusedInputState] = useState();
	const [currentDayIndex, setCurrentDayIndex] = useState('');
	const [openCopyTimesPopper, setOpenCopyTimesPopper] = useState(false);
	const [openDurationsPopper, setOpenDurationsPopper] = useState(false);
	const [anchorCopyTimesPopper, setAnchorCopyTimesPopper] = useState('');
	const [anchorDurationsPopper, setAnchorDurationsPopper] = useState('');
	const [errors, setErrors] = useState({});
	const [username, setUsername] = useState(loggedInUser.email.split('@')[0]);
	const [addLoading, setAddLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [weeklyHours, setWeeklyHours] = useState([]);
	const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
	const [openAvailableWeeklyHoursSelector, setOpenAvailableWeeklyHoursSelector] = useState(false);
	const [rangeError, setRangeError] = useState(false);
	const [weeklyHoursError, setWeeklyHoursError] = useState(false);
	const [durations, setDurations] = useState({
		available: 1,
		durations: [
			{name: '15 Minutes', value: 15, checked: true},
			{name: '30 Minutes', value: 30, checked: false},
			{name: '45 Minutes', value: 45, checked: false},
			{name: '1 Hour', value: 60, checked: false},
		],
	});
	const [selectAll, setSelectAll] = useState(false);
	const [breakTime, setBreakTime] = useState(0);

	var initialWeeklyHours = [
		{0: {checked: false, name: 'Sunday', intervals: [{from: null, to: null}]}},
		{1: {checked: false, name: 'Monday', intervals: [{from: null, to: null}]}},
		{2: {checked: false, name: 'Tuesday', intervals: [{from: null, to: null}]}},
		{3: {checked: false, name: 'Wednesday', intervals: [{from: null, to: null}]}},
		{4: {checked: false, name: 'Thursday', intervals: [{from: null, to: null}]}},
		{5: {checked: false, name: 'Friday', intervals: [{from: null, to: null}]}},
		{6: {checked: false, name: 'Saturday', intervals: [{from: null, to: null}]}},
	];
	var initialRange = [
		{
			startDate: '',
			endDate: '',
			key: 'selection',
		},
	];

	const handleDateRangeChange = range => {
		setRangeError(false);
		setStartDate(range.startDate);
		setEndDate(range.endDate);
		setRange([{startDate: range.startDate ? range.startDate.toDate() : '', endDate: range.endDate ? range.endDate.toDate() : ''}]);
	};

	const handleChangeTimeZone = value => {
		setTimeZone(value);
	};
	const handleChangeBreakTime = e => {
		setBreakTime(e.target.value);
	};

	const handleOpenDurationsPopper = e => {
		setAnchorDurationsPopper(e.currentTarget);
		setOpenDurationsPopper(true);
	};
	const handleCloseDurationsPopper = () => {
		setOpenDurationsPopper(false);
		setAnchorDurationsPopper('');
	};

	const handleOpenCopyTimesPopper = (e, index) => {
		setCurrentDayIndex(index);
		setAnchorCopyTimesPopper(e.currentTarget);
		setOpenCopyTimesPopper(true);
	};
	const handleCloseCopyTimesPopper = () => {
		setOpenCopyTimesPopper(false);
		setAnchorCopyTimesPopper('');
		setCurrentDayIndex('');
	};
	const handleCopyTimes = (index, days) => {
		let tempWeeklyHours = [...weeklyHours];
		days.forEach((day, loopIndex) => {
			if (day.checked && index !== loopIndex) {
				tempWeeklyHours[loopIndex][loopIndex] = {...tempWeeklyHours[loopIndex][loopIndex], intervals: tempWeeklyHours[index][index].intervals, checked: true};
			}
		});
		setWeeklyHours(tempWeeklyHours);
		handleCloseCopyTimesPopper();
	};
	const handleDeleteInterval = (day, index, intervalIndex) => {
		let tempWeeklyHours = [...weeklyHours];
		let tempIntervals = [...day[index].intervals];
		tempIntervals.splice(intervalIndex, 1);
		tempWeeklyHours[index] = {[index]: {...day[index], intervals: tempIntervals}};
		if (errors[index]) {
			if (errors[`${index}`][`${intervalIndex},from`]) {
				delete errors[`${index}`][`${intervalIndex},from`];
			}
			if (errors[`${index}`][`${intervalIndex},to`]) {
				delete errors[`${index}`][`${intervalIndex},to`];
			}
			if (errors[index] && Object.keys(errors[index]).length === 0) {
				delete errors[index];
			}
		}
		setWeeklyHours(tempWeeklyHours);
	};
	const handleAddInterval = (day, index) => {
		let tempWeeklyHours = [...weeklyHours];
		let tempIntervals = [...day[index].intervals];
		if (tempIntervals.length > 0) {
			tempIntervals.push({
				from: new Date().setHours(new Date(tempIntervals[tempIntervals.length - 1].to).getHours() + 1, new Date(tempIntervals[tempIntervals.length - 1].to).getMinutes(), 0, 0),
				to: new Date().setHours(new Date(tempIntervals[tempIntervals.length - 1].to).getHours() + 2, new Date(tempIntervals[tempIntervals.length - 1].to).getMinutes(), 0, 0),
			});
		} else {
			tempIntervals.push({from: new Date().setHours(9, 0, 0, 0), to: new Date().setHours(17, 0, 0, 0)});
		}

		tempWeeklyHours[index] = {[index]: {...day[index], intervals: sortIntervals(tempIntervals)}};

		setWeeklyHours(tempWeeklyHours);
	};
	const handleToggleAvailableWeeklyHoursSelector = () => {
		setOpenAvailableWeeklyHoursSelector(openAvailableWeeklyHoursSelector => !openAvailableWeeklyHoursSelector);
	};

	const handleSelectAll = () => {
		let tempWeeklyHours = [...weeklyHours];
		tempWeeklyHours.forEach((day, index) => {
			tempWeeklyHours[index] = {[index]: {...day[index], intervals: [{from: selectAll ? null : new Date().setHours(9, 0, 0, 0), to: selectAll ? null : new Date().setHours(17, 0, 0, 0)}], checked: !selectAll}};
			if (errors[index]) {
				delete errors[index];
			}
		});
		setWeeklyHours(tempWeeklyHours);
		setWeeklyHoursError(false);
		setSelectAll(!selectAll);
	};

	const handleCheck = (day, index) => {
		setWeeklyHoursError(false);
		let tempWeeklyHours = [...weeklyHours];
		tempWeeklyHours[index] = {[index]: {...day[index], intervals: [{from: !day[index].checked ? new Date().setHours(9, 0, 0, 0) : null, to: !day[index].checked ? new Date().setHours(17, 0, 0, 0) : null}], checked: !day[index].checked}};
		if (errors[index]) {
			delete errors[index];
		}
		setWeeklyHours(tempWeeklyHours);
	};
	const handleReset = () => {
		setWeeklyHours(initialWeeklyHours);
		setRange(initialRange);
		setStartDate();
		setEndDate();
		setErrors({});
		setSelectAll(false);
		setRangeError(false);
		setWeeklyHoursError(false);
	};

	const handleChangeFromTimeOrTo = (event, type, day, index, intervalIndex) => {
		let baseIntervals = weeklyHours[index][index].intervals.slice(0, intervalIndex);
		let tempWeeklyHours = [...weeklyHours];
		let tempIntervals = [...day[index].intervals];

		tempIntervals[intervalIndex] = {...tempIntervals[intervalIndex], [type]: event};
		tempWeeklyHours[index] = {[index]: {...day[index], intervals: tempIntervals}};

		if (!event) {
			setErrors({...errors, [`${index}`]: {...errors[`${index}`], [`${intervalIndex},${type}`]: true}});
			setTimeout(() => {
				setErrors({...errors, [`${index}`]: {...errors[`${index}`], [`${intervalIndex},${type}`]: true}});
			}, 1);
		} else if (errors[index] && errors[`${index}`][`${intervalIndex},${type}`]) {
			delete errors[index][`${intervalIndex},${type}`];
			if (Object.keys(errors[index]).length === 0) {
				delete errors[index];
			}
		}
		if (!isValidRange(baseIntervals, tempIntervals[intervalIndex])) {
			setErrors({...errors, [`${index}`]: {[`${intervalIndex},to`]: true, [`${intervalIndex},from`]: true}});
		} else {
			if (errors[index]) {
				delete errors[index][`${intervalIndex},to`];
				delete errors[index][`${intervalIndex},from`];
				delete errors[index];
			}
		}

		setWeeklyHours(tempWeeklyHours);
	};
	const isValidRange = (intervals, selectedRange) => {
		var isValid = true;
		var minStart = intervals.length > 0 ? new Date(new Date(new Date(new Date(intervals[0].from).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate())).getTime() : 0;
		var maxEnd = intervals.length > 0 ? new Date(new Date(new Date(new Date(intervals[intervals.length - 1].to).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate())).getTime() : 0;

		if (new Date(selectedRange.from).getTime() < new Date(selectedRange.to).getTime() && ((new Date(selectedRange.from).getTime() < minStart && new Date(selectedRange.to).getTime() < minStart) || new Date(selectedRange.from).getTime() > maxEnd)) {
			for (var i = 0; i < intervals.length; i++) {
				if (
					(new Date(selectedRange.from).getTime() >= new Date(intervals[i].from).getTime() && new Date(selectedRange.from).getTime() <= new Date(intervals[i].to).getTime()) ||
					(new Date(selectedRange.to).getTime() >= new Date(intervals[i].from).getTime() && new Date(selectedRange.to).getTime() <= new Date(intervals[i].to).getTime())
				) {
					isValid = false;
					break;
				} else if (i !== intervals.length - 1) {
					if (new Date(selectedRange.from).getTime() > new Date(intervals[i].to).getTime() && new Date(selectedRange.from).getTime() < new Date(intervals[i + 1].from).getTime()) {
						if (new Date(selectedRange.to).getTime() < new Date(intervals[i + 1].start).getTime()) {
							break;
						} else {
							isValid = false;
							break;
						}
					}
				}
			}
		} else {
			isValid = false;
		}
		return isValid;
	};

	const checkIfDayAvailable = (data = undefined) => {
		const weeklyHoursData = data ? data : weeklyHours;

		for (let i = 0; i < weeklyHoursData.length; i++) {
			if (weeklyHoursData[i][i].checked) {
				return true;
			}
		}
		return false;
	};
	const checkIfRangeAvailable = (data = undefined) => {
		const rangeData = data ? data : range;

		if (rangeData[0].startDate && rangeData[0].endDate) return true;

		return false;
	};

	const handleSave = () => {
		setAddLoading(true);
		let ifRangeAvailable = checkIfRangeAvailable();
		let ifDayAvailable = checkIfDayAvailable();
		if ((ifRangeAvailable && ifDayAvailable) || (!ifRangeAvailable && !ifDayAvailable)) {
			let timezoneStr = typeof timeZone === 'object' ? timeZone.value : timeZone;
			let utcWeeklyHours = JSON.parse(JSON.stringify(weeklyHours));
			utcWeeklyHours.forEach((day, index) => {
				let tempIntervals = [...day[index].intervals];
				tempIntervals.forEach((interval, intervalIndex) => {
					if (day[index].checked) {
						utcWeeklyHours[index][index].intervals[intervalIndex].from = convertFromZoneToUTCString(interval.from, timezoneStr);
						utcWeeklyHours[index][index].intervals[intervalIndex].to = convertFromZoneToUTCString(interval.to, timezoneStr);
					}
				});
			});
			axios
				.post(
					Env().url + '/userschedule/availability',
					{availability: JSON.stringify({availability: utcWeeklyHours, durations: durations, break: breakTime}), availabilityDateRange: JSON.stringify({...range[0]}), username: username, timeZone: JSON.stringify({value: timezoneStr})},
					{headers: {Authorization: 'Bearer ' + token}}
				)
				.then(res => {
					setAddLoading(false);
					enqueueSnackbar('saved!', {variant: 'success'});
					if (res.data) {
						localStorage.setItem('weeklyTime', JSON.stringify({weeklyHours: weeklyHours, range: {...range[0]}}));
						let tempWeeklyHours = [...JSON.parse(res.data.availability).availability];
						tempWeeklyHours.forEach((day, index) => {
							let tempIntervals = [...day[index].intervals];
							tempIntervals.forEach((interval, intervalIndex) => {
								tempWeeklyHours[index][index].intervals[intervalIndex].from = convertFromUTCStringToZone(interval.from, timezoneStr);
								tempWeeklyHours[index][index].intervals[intervalIndex].to = convertFromUTCStringToZone(interval.to, timezoneStr);
							});
						});
						if (JSON.parse(res.data.availability).durations) {
							setDurations(JSON.parse(res.data.availability).durations);
						}
						setUsername(res.data.username);
						setWeeklyHours(tempWeeklyHours);
						let parsedRange = JSON.parse(res.data.availabilityDateRange);
						setRange([{...parsedRange, startDate: parsedRange.startDate ? new Date(parsedRange.startDate) : null, endDate: parsedRange.endDate ? new Date(parsedRange.endDate) : null}]);
						if (range[0].startDate && range[0].endDate && checkIfDayAvailable()) setAllowCopy(true);
						else setAllowCopy(false);
					}
				})
				.catch(err => {
					setAddLoading(false);
					enqueueSnackbar("couldn't save data!", {variant: 'error'});
				});
		} else {
			if (!checkIfRangeAvailable()) {
				setRangeError(true);
				enqueueSnackbar('Please select available date range before save', {variant: 'error'});
				setAddLoading(false);
			}
			if (!checkIfDayAvailable()) {
				setWeeklyHoursError(true);
				enqueueSnackbar('Please select available weekly hours before save', {variant: 'error'});
				setAddLoading(false);
			}
		}
	};

	const handleError = (e, index, intervalIndex, type) => {
		if (e) {
			setErrors({...errors, [index]: {...errors[index], [`${intervalIndex},${type}`]: true}});
		} else {
			let tempErrors = {...errors};
			if (tempErrors[index] && tempErrors[index][`${intervalIndex},${type}`]) {
				delete tempErrors[index][`${intervalIndex},${type}`];
				if (Object.keys(tempErrors[index]).length === 0) {
					delete tempErrors[index];
				}
			}

			setErrors(tempErrors);
		}
	};
	const sortIntervals = intervals => {
		intervals.sort((objA, objB) => {
			let aFrom = new Date(new Date(new Date(new Date(objA.from).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate()));
			let aTo = new Date(new Date(new Date(new Date(objA.to).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate()));
			let bFrom = new Date(new Date(new Date(new Date(objB.from).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate()));
			let bTo = new Date(new Date(new Date(new Date(objB.to).setFullYear(new Date().getFullYear())).setMonth(new Date().getMonth())).setDate(new Date().getDate()));
			return Number(new Date(aFrom)) - Number(new Date(bFrom)) && Number(new Date(aTo)) - Number(new Date(bTo));
		});
		return intervals;
	};

	const fetchData = () => {
		setLoading(true);
		axios
			.get(Env().url + '/userschedule/availability', {headers: {Authorization: 'Bearer ' + token}})
			.then(res => {
				if (res.data) {
					let tempWeeklyHours = [...JSON.parse(res.data.availability).availability];
					let timezone = JSON.parse(res.data.timeZone);
					timezone && setTimeZone(timezone.value);
					tempWeeklyHours.forEach((day, index) => {
						let tempIntervals = [...day[index].intervals];
						tempIntervals.forEach((interval, intervalIndex) => {
							if (day[index].checked) {
								tempWeeklyHours[index][index].intervals[intervalIndex].from = convertFromUTCStringToZone(interval.from, timezone.value);
								tempWeeklyHours[index][index].intervals[intervalIndex].to = convertFromUTCStringToZone(interval.to, timezone.value);
							}
						});
					});
					setUsername(res.data.username);
					setWeeklyHours(tempWeeklyHours);
					if (JSON.parse(res.data.availability).durations) {
						setDurations(JSON.parse(res.data.availability).durations);
					}
					JSON.parse(res.data.availability).break && setBreakTime(JSON.parse(res.data.availability).break);
					let parsedRange = JSON.parse(res.data.availabilityDateRange);
					setRange([{...parsedRange, startDate: parsedRange.startDate ? new Date(parsedRange.startDate) : null, endDate: parsedRange.endDate ? new Date(parsedRange.endDate) : null}]);
					setStartDate(parsedRange.startDate ? moment(parsedRange.startDate) : undefined);
					setEndDate(parsedRange.endDate ? moment(parsedRange.endDate) : undefined);
					setLoading(false);
					if (checkIfRangeAvailable([parsedRange]) && checkIfDayAvailable(tempWeeklyHours)) setAllowCopy(true);
					else setAllowCopy(false);
				} else {
					setWeeklyHours(initialWeeklyHours);
					setRange(initialRange);
					setStartDate();
					setEndDate();
					setLoading(false);
				}
			})
			.catch(err => {
				setWeeklyHours(initialWeeklyHours);
				setRange(initialRange);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{openDurationsPopper && <DurationsPopper durations={durations} setDurations={setDurations} anchor={anchorDurationsPopper} arrow={true} placement='bottom' onClose={handleCloseDurationsPopper} />}
			{openCopyTimesPopper && <CopyTimesPopper anchor={anchorCopyTimesPopper} arrow={true} placement='bottom' confirm='Apply' dayIndex={currentDayIndex} onClose={handleCloseCopyTimesPopper} onConfirm={handleCopyTimes} />}
			{!loading || weeklyHours.length > 0 ? (
				<Card sx={{overflow: 'visible', minWidth: 550}}>
					<CardHeader
						title={
							<>
								<Typography variant='h6' color='primary' gutterBottom>
									Available time
								</Typography>
								<Box display='flex' alignItems='center' justifyContent='flex-start' gap={1}>
									<img src={userObj?.photo ? userObj?.photo : 'https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media'} alt={loggedInUser.displayName} className={classes.image} />
									<TextField value={username} onChange={e => setUsername(e.target.value)} variant='outlined' label='username' size='small' />
									<Tooltip title={allowCopy ? 'Link to book' : 'You should save data first!'} placement='top' arrow>
										<IconButton disabled={!allowCopy} LinkComponent='a' href={'https://linearcalendar.linearbits.com/' + loggedInUser.uid + '/' + username} target='_blank' rel='noreferrer' color='success'>
											<OfflineShareIcon fontSize='small' />
										</IconButton>
									</Tooltip>
									<Tooltip title={allowCopy ? 'Link to book' : 'You should save data first!'} placement='top' arrow>
										<Typography
											sx={{textDecoration: 'none'}}
											color={allowCopy ? 'inherit' : 'GrayText'}
											component={allowCopy ? 'a' : 'p'}
											href={allowCopy ? 'https://linearcalendar.linearbits.com/' + loggedInUser.uid + '/' + username : undefined}
											target={allowCopy ? '_blank' : undefined}
											rel={allowCopy ? 'noreferrer' : undefined}
										>
											Book
										</Typography>
									</Tooltip>
									<CopyToClipboard text={'https://linearcalendar.linearbits.com/' + loggedInUser.uid + '/' + username} onCopy={() => enqueueSnackbar('copied!', {variant: 'success'})}>
										<Tooltip title='Copy reservation link' placement='top' arrow>
											<IconButton color='success' disabled={!allowCopy}>
												<CopyAllIcon fontSize='small' />
											</IconButton>
										</Tooltip>
									</CopyToClipboard>

									<CopyToClipboard text={'https://linearcalendar.linearbits.com/' + loggedInUser.uid + '/' + username} onCopy={allowCopy ? () => enqueueSnackbar('copied!', {variant: 'success'}) : undefined}>
										<Tooltip title={allowCopy ? 'Copy reservation link' : 'You should save data first!'} placement='top' arrow>
											<Typography sx={{cursor: allowCopy ? 'pointer' : 'auto'}} color={allowCopy ? 'inherit' : 'GrayText'}>
												Copy
											</Typography>
										</Tooltip>
									</CopyToClipboard>

									<Button color='inherit' startIcon={<AvTimerIcon color='success' />} sx={{textTransform: 'none', borderColor: '#ddd'}} onClick={handleOpenDurationsPopper}>
										Slots
									</Button>
								</Box>
							</>
						}
						sx={{padding: '8px 16px'}}
					/>
					<Divider />
					<Box width='100%' display='flex' mb={1} p={1} gap={1} alignItems='flex-end'>
						<Box width='100%'>
							<Typography fontSize={12} color='#555'>
								Time zone
							</Typography>
							<TimezoneSelect value={timeZone} onChange={handleChangeTimeZone} className={classes.timeZone} />
						</Box>
						<FormControl size='small' sx={{minWidth: 120}}>
							<InputLabel>Break</InputLabel>
							<Select value={breakTime} label='Break' onChange={handleChangeBreakTime}>
								<MenuItem value={0}>None</MenuItem>
								<MenuItem value={5}>5 mins</MenuItem>
								<MenuItem value={10}>10 mins</MenuItem>
								<MenuItem value={15}>15 mins</MenuItem>
								<MenuItem value={30}>30 mins</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<Typography ml={1} fontSize={12} color={rangeError ? 'tomato' : '#555'}>
						Available date range
					</Typography>
					<Divider />
					<Box width='100%' border={rangeError ? '0.5px solid tomato' : '0.5px solid transparent'}>
						<DateRangePicker
							startDate={startDate} // momentPropTypes.momentObj or null,
							startDateId='#1' // PropTypes.string.isRequired,
							endDate={endDate} // momentPropTypes.momentObj or null,
							endDateId='#2' // PropTypes.string.isRequired,
							onDatesChange={handleDateRangeChange} // PropTypes.func.isRequired,
							focusedInput={focusedInputState} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
							onFocusChange={focusedInput => setFocusedInputState(focusedInput)} // PropTypes.func.isRequired,
							showClearDates
							showDefaultInputIcon
							block
							noBorder
							anchorDirection='left'
							openDirection='down'
							hideKeyboardShortcutsPanel
							daySize={30}
						/>
					</Box>
					<Divider />
					<Box border={weeklyHoursError ? '0.5px solid tomato' : '0.5px solid transparent'}>
						<ListItemButton onClick={handleToggleAvailableWeeklyHoursSelector}>
							<ListItemIcon>
								<AccessAlarmsIcon />
							</ListItemIcon>
							<ListItemText>
								<Typography fontFamily='Fredoka' color={weeklyHoursError ? 'tomato' : ''}>
									Select Available weekly hours
								</Typography>
							</ListItemText>
							{openAvailableWeeklyHoursSelector ? <ExpandMoreIcon className={classes.collapse} /> : <ExpandMoreIcon className={classes.expand} />}
						</ListItemButton>
						<Collapse in={openAvailableWeeklyHoursSelector} timeout='auto' unmountOnExit>
							<Box sx={{mb: 1, p: 1}}>
								<FormControlLabel
									control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
									label={
										<Typography fontFamily='Fredoka' fontWeight={600}>
											Select All
										</Typography>
									}
									labelPlacement='end'
								/>
								{weeklyHours.map((day, index) => (
									<Box key={index}>
										<Box key={index} width='100%' display='flex' gap={2} mt={1} mb={1} alignItems='flex-start'>
											<FormControlLabel
												sx={{width: 100, marginRight: 0}}
												control={<Checkbox checked={day[index].checked} onChange={() => handleCheck(day, index)} />}
												label={
													<Typography fontFamily='Fredoka' fontWeight={600}>
														{day[index].name.toUpperCase().substring(0, 3)}
													</Typography>
												}
												labelPlacement='end'
											/>
											{day[index].checked ? (
												<Box display='flex' justifyContent='space-between' alignItems='flex-start' width='100%'>
													<Box display='flex' flexDirection='column' gap={1}>
														{sortIntervals(day[index].intervals).map((interval, intervalIndex) => (
															<Box key={intervalIndex} gap={2} width='100%' display='flex' alignItems='flex-start'>
																<Box>
																	<LocalizationProvider dateAdapter={AdapterDateFns}>
																		<DesktopTimePicker
																			label='From'
																			disableIgnoringDatePartForTimeValidation={true}
																			ampm={true}
																			value={interval.from}
																			minutesStep={15}
																			onError={e => handleError(e, index, intervalIndex, 'from')}
																			onChange={e => handleChangeFromTimeOrTo(e, 'from', day, index, intervalIndex)}
																			renderInput={params => (
																				<TextField
																					{...params}
																					size='small'
																					disabled
																					sx={{width: 150}}
																					InputProps={{
																						endAdornment: (
																							<InputAdornment position='end' sx={{mr: -1}}>
																								<IconButton {...params.InputProps.endAdornment.props.children.props} color='primary' size='small'>
																									<AccessTimeIcon fontSize='small' />
																								</IconButton>
																							</InputAdornment>
																						),
																					}}
																					error={errors[`${index}`] && errors[`${index}`][`${intervalIndex},from`]}
																				/>
																			)}
																		/>
																	</LocalizationProvider>
																	{errors[`${index}`] && errors[`${index}`][`${intervalIndex},from`] && <FormHelperText error={true}>invalid time</FormHelperText>}
																</Box>
																<Box>
																	<LocalizationProvider dateAdapter={AdapterDateFns}>
																		<DesktopTimePicker
																			label='To'
																			value={interval.to}
																			minutesStep={15}
																			disableIgnoringDatePartForTimeValidation={true}
																			ampm={true}
																			minTime={new Date(interval.from)}
																			onError={e => handleError(e, index, intervalIndex, 'to')}
																			onChange={e => handleChangeFromTimeOrTo(e, 'to', day, index, intervalIndex)}
																			renderInput={params => (
																				<TextField
																					{...params}
																					size='small'
																					disabled
																					sx={{width: 150}}
																					error={errors[`${index}`] && errors[`${index}`][`${intervalIndex},to`]}
																					InputProps={{
																						endAdornment: (
																							<InputAdornment position='end' sx={{mr: -1}}>
																								<IconButton {...params.InputProps.endAdornment.props.children.props} color='primary' size='small'>
																									<AccessTimeIcon fontSize='small' />
																								</IconButton>
																							</InputAdornment>
																						),
																					}}
																				/>
																			)}
																		/>
																	</LocalizationProvider>
																	{errors[`${index}`] && errors[`${index}`][`${intervalIndex},to`] && <FormHelperText error={true}>invalid time</FormHelperText>}
																</Box>
																{day[index].intervals.length > 1 && (
																	<IconButton onClick={() => handleDeleteInterval(day, index, intervalIndex)} size='small' sx={{borderRadius: 10}}>
																		<Delete color='error' />
																	</IconButton>
																)}
															</Box>
														))}
													</Box>
													<Box display='flex' gap={0.5}>
														<IconButton onClick={() => handleAddInterval(day, index)} size='small' sx={{borderRadius: 10, cursor: errors[index] ? 'not-allowed !important' : '', pointerEvents: errors[index] ? 'all !important' : ''}} disabled={errors[index]}>
															<Add />
														</IconButton>
														<IconButton onClick={e => handleOpenCopyTimesPopper(e, index)} size='small' sx={{borderRadius: 10, cursor: errors[index] ? 'not-allowed !important' : '', pointerEvents: errors[index] ? 'all !important' : ''}} disabled={errors[index]}>
															<ContentCopyIcon />
														</IconButton>
													</Box>
												</Box>
											) : (
												<Typography fontFamily='Fredoka' color='#AAA' sx={{userSelect: 'none', display: 'flex', alignItems: 'center', height: 40}}>
													Not available
												</Typography>
											)}
										</Box>
										{index < weeklyHours.length - 1 && <Divider />}
									</Box>
								))}
							</Box>
						</Collapse>
					</Box>
					<Divider />
					{Object.keys(errors).length !== 0 && (
						<Box width='100%'>
							<Typography align='center' color='error'>
								fix errors first to save
							</Typography>
						</Box>
					)}
					<CardActions sx={{gap: 1, justifyContent: 'center'}}>
						<Button
							sx={{textTransform: 'none', fontFamily: 'Fredoka', width: 100}}
							variant='contained'
							size='small'
							color={Object.keys(errors).length !== 0 || rangeError || weeklyHoursError ? 'error' : 'primary'}
							startIcon={addLoading && <CircularProgress size={20} sx={{color: '#fff'}} />}
							onClick={handleSave}
							disabled={Object.keys(errors).length !== 0}
						>
							Save
						</Button>
						<Button sx={{textTransform: 'none', fontFamily: 'Fredoka', width: 80}} variant='outlined' size='small' onClick={handleReset}>
							Reset
						</Button>
					</CardActions>
				</Card>
			) : (
				<CircularProgress />
			)}
		</>
	);
}
