import {Button, Dialog, TextField, Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import {useAuth} from '../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import Env from '../../util/Env';
import ReactGA from 'react-ga';
import FaceIcon from '@mui/icons-material/Face';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LockResetIcon from '@mui/icons-material/LockReset';
import GitHubIcon from '@mui/icons-material/GitHub';

const useStyles = makeStyles(() => ({
	DialogPaper: {
		borderRadius: '15px !important',
		maxWidth: '300px !important',
	},
	root: {
		position: 'relative',
		background: 'transparent',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexWrap: 'wrap',
		padding: 10,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		gap: 1,
		marginBottom: '20px !important',
	},
	subHeader: {
		marginBottom: '20px !important',
		fontSize: '14px !important',
		width: 245,
	},
	goButton: {
		textTransform: 'none !important',
		borderRadius: '0px 5px 5px 0px !important',
	},
	link: {
		color: '#2496FF',
		textDecoration: 'none',
	},
	divider: {
		color: '#bbb !important',
		marginRight: '10px !important',
		marginLeft: '10px !important',
	},
	loginButton: {
		textTransform: 'none !important',
		width: 100,
	},
	textBox: {
		borderRadius: 5,
		'&:hover': {
			border: 'none !important',
		},
	},
	noBorder: {
		borderRadius: '5px 0px 0px 5px !important',
		border: '1px solid #ddd !important',
	},
	githubButton: {
		background: '#24292F !important',
		color: '#fff !important',
		textTransform: 'none !important',
		width: '100%',
		'&:hover': {
			background: '#24292F !important',
		},
	},
}));

export default function AccessDialog({open, handleCloseDialog}) {
	ReactGA.initialize(Env().gaKey);
	const classes = useStyles();
	const {login, signup, resetPassword, token, logout, githubLogin} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [loginEmail, setLoginEmail] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [loginLoading, setLoginLoading] = useState(false);
	const [registerUsername, setRegisterUsername] = useState('');
	const [registerEmail, setRegisterEmail] = useState('');
	const [registerPassword, setRegisterPassword] = useState('');
	const [registerLoading, setRegisterLoading] = useState(false);
	const [forgetEmail, setForgetEmail] = useState('');
	const [forgetLoading, setForgetLoading] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const [isRegister, setIsRegister] = useState(false);
	const [isForget, setIsForget] = useState(false);

	const handleChangeFormToLogin = useCallback(() => {
		setIsLogin(true);
		setIsRegister(false);
		setIsForget(false);
	}, []);

	const handleChangeFormToRegister = useCallback(() => {
		setIsLogin(false);
		setIsRegister(true);
		setIsForget(false);
	}, []);

	const handleChangeFormToForget = useCallback(() => {
		setIsLogin(false);
		setIsRegister(false);
		setIsForget(true);
	}, []);

	const handleChangeRegisterUsername = useCallback(e => {
		setRegisterUsername(e.target.value);
	}, []);
	const handleChangeRegisterEmail = useCallback(e => {
		setRegisterEmail(e.target.value);
	}, []);
	const handleChangeRegisterPassword = useCallback(e => {
		setRegisterPassword(e.target.value);
	}, []);

	const handleChangeLoginEmail = useCallback(e => {
		setLoginEmail(e.target.value);
	}, []);
	const handleChangeLoginPassword = useCallback(e => {
		setLoginPassword(e.target.value);
	}, []);

	const handleChangeForgetEmail = useCallback(e => {
		setForgetEmail(e.target.value);
	}, []);

	const handleLogin = useCallback(
		async e => {
			e.preventDefault();
			setLoginLoading(true);
			try {
				await login(loginEmail, loginPassword).then(user => {
					if (!user.user.emailVerified) {
						logout();
						throw new Error('please verify your email and try again!');
					}
				});
				setLoginLoading(false);
				handleCloseDialog(true);
				ReactGA.event({
					category: 'Login',
					action: 'Logged in successfully',
				});
			} catch (err) {
				setLoginLoading(false);
				logout();
				enqueueSnackbar(err.message, {variant: 'error'});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loginEmail, loginPassword]
	);

	const handleGithubLogin = async e => {
		e.preventDefault();
		try {
			await githubLogin()
				.then(user => {
					user.user.updateProfile({
						displayName: user.additionalUserInfo.username,
					});
					axios.post(
						Env().url + '/users',
						{
							id: user.user.uid,
							emailVerified: user.user.emailVerified,
							username: user.additionalUserInfo.username,
							email: user.user.email,
							phoneNumber: '',
							photoUrl: '',
							disabled: false,
							admin: false,
							accessLevel: 0,
						},
						{headers: {Authorization: 'Bearer ' + token}}
					);
				})
				.catch(err => {
					throw new Error(err.message);
				});
			handleCloseDialog(true);
		} catch (err) {
			enqueueSnackbar(err.message, {variant: 'error'});
		}
	};

	const handleForget = useCallback(
		async e => {
			setForgetLoading(true);
			e.preventDefault();
			try {
				await resetPassword(forgetEmail);
				setForgetLoading(false);
				enqueueSnackbar('An email is sent to your email to reset your password', {
					variant: 'warning',
				});
				setForgetEmail('');
				handleChangeFormToLogin();
			} catch (err) {
				setForgetLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[forgetEmail]
	);

	const handleRegister = useCallback(
		async e => {
			setRegisterLoading(true);
			e.preventDefault();
			await signup(registerEmail, registerPassword)
				.then(user => {
					user.user.updateProfile({
						displayName: registerUsername,
					});
					axios.post(
						Env().url + '/users',
						{
							id: user.user.uid,
							emailVerified: false,
							username: registerUsername,
							email: registerEmail,
							password: registerPassword,
							phoneNumber: '',
							photoUrl: '',
							disabled: false,
							admin: false,
							accessLevel: 0,
						},
						{headers: {Authorization: 'Bearer ' + token}}
					);
				})
				.then(() => {
					logout();
					ReactGA.event({
						category: 'Register',
						action: 'Registered successfully',
					});
					return firebase.app('usersFirebase').auth().currentUser.sendEmailVerification({
						url: 'https://linearpad.linearbits.com/',
					});
				})
				.then(() => {
					enqueueSnackbar('verification mail has been sent to your email!', {
						variant: 'warning',
					});
					handleChangeFormToLogin();
					setLoginEmail(registerEmail);
					setLoginPassword(registerPassword);
					setRegisterUsername('');
					setRegisterEmail('');
					setRegisterPassword('');
					setRegisterLoading(false);
				})
				.catch(error => {
					ReactGA.event({
						category: 'Register',
						action: "couldn't register",
					});
					setRegisterLoading(false);
					enqueueSnackbar(error.message, {
						variant: 'error',
					});
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[registerUsername, registerEmail, registerPassword]
	);

	return (
		<Dialog classes={{paper: classes.DialogPaper}} open={open} onClose={() => handleCloseDialog(false)} maxWidth='xs'>
			<Box className={classes.root}>
				{isLogin && (
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' width={250}>
						<Box display='flex' flexDirection='column' alignItems='center'>
							<FaceIcon color='primary' sx={{width: 80, height: 80}} />
						</Box>
						<form onSubmit={handleLogin}>
							<Box display='flex' flexDirection='column' mb={2} mt={1} gap={1} alignItems='center'>
								<Button className={classes.githubButton} startIcon={<GitHubIcon />} onClick={handleGithubLogin}>
									Login with Github
								</Button>
								<TextField fullWidth className={classes.textBox} size='small' value={loginEmail} onChange={handleChangeLoginEmail} variant='filled' label='Email' />
								<TextField fullWidth className={classes.textBox} size='small' type='password' value={loginPassword} onChange={handleChangeLoginPassword} variant='filled' label='Password' />
								<Box mt={1} mb={1}>
									<Typography sx={{cursor: 'pointer', color: '#1776D2'}} onClick={handleChangeFormToForget}>
										forget your password?
									</Typography>
								</Box>
								<Box display='flex' gap={1}>
									<Button
										startIcon={loginLoading && <CircularProgress size={20} color='info' />}
										variant='contained'
										color='primary'
										type='submit'
										sx={{
											width: 100,
											background: (!loginEmail || !loginPassword) && '#1776D280 !important',
											textTransform: 'none',
										}}
										disabled={!loginEmail || !loginPassword}
									>
										Login
									</Button>
									<Button
										variant='outlined'
										color='primary'
										onClick={handleChangeFormToRegister}
										sx={{
											width: 100,
											textTransform: 'none',
										}}
									>
										Register
									</Button>
								</Box>
							</Box>
						</form>
					</Box>
				)}
				{isRegister && (
					<Box display='flex' flexDirection='column' alignItems='center' width={250}>
						<AppRegistrationIcon color='primary' sx={{width: 80, height: 80}} />
						<form onSubmit={handleRegister}>
							<Box display='flex' flexDirection='column' mb={2} mt={1} gap={1} alignItems='center'>
								<TextField fullWidth className={classes.textBox} size='small' value={registerUsername} onChange={handleChangeRegisterUsername} variant='filled' label='Username' />
								<TextField fullWidth className={classes.textBox} size='small' value={registerEmail} onChange={handleChangeRegisterEmail} variant='filled' label='Email' />
								<TextField fullWidth className={classes.textBox} size='small' type='password' value={registerPassword} onChange={handleChangeRegisterPassword} variant='filled' label='Password' />
								<Box display='flex' gap={1}>
									<Button
										startIcon={registerLoading && <CircularProgress size={20} color='info' />}
										variant='contained'
										color='primary'
										type='submit'
										sx={{
											width: 120,
											background: (!registerEmail || !registerPassword || !registerUsername) && '#1776D280 !important',
											textTransform: 'none',
										}}
										disabled={!registerEmail || !registerPassword || !registerUsername}
									>
										Register
									</Button>
									<Button
										variant='outlined'
										color='primary'
										onClick={handleChangeFormToLogin}
										sx={{
											width: 100,
											textTransform: 'none',
										}}
									>
										Login
									</Button>
								</Box>
							</Box>
						</form>
					</Box>
				)}
				{isForget && (
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='space-between'>
						<Box display='flex' flexDirection='column' alignItems='center'>
							<LockResetIcon color='primary' sx={{width: 80, height: 80}} />
						</Box>
						<form onSubmit={handleForget}>
							<Box display='flex' flexDirection='column' mb={2} mt={1} gap={1} alignItems='center'>
								<TextField fullWidth className={classes.textBox} size='small' value={forgetEmail} onChange={handleChangeForgetEmail} variant='filled' label='Email' />
								<Box display='flex' gap={1} alignItems='center' justifyContent='center' flexWrap='wrap'>
									<Button
										startIcon={forgetLoading && <CircularProgress size={20} color='info' />}
										variant='contained'
										color='primary'
										type='submit'
										sx={{
											background: !forgetEmail && '#1776D280 !important',
											textTransform: 'none',
										}}
										disabled={!forgetEmail}
									>
										Reset Password
									</Button>
									<Button
										variant='outlined'
										color='primary'
										onClick={handleChangeFormToLogin}
										sx={{
											width: 100,
											textTransform: 'none',
										}}
									>
										Login
									</Button>
								</Box>
							</Box>
						</form>
					</Box>
				)}
				<Box mt={3} width='100%' display='flex' justifyContent='center'>
					<Typography
						component='span'
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'space-evenly',
						}}
					>
						<Link
							href='#'
							to=''
							className={classes.link}
							style={{
								width: '100%',
								paddingRight: 10,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							Terms of Service
						</Link>
						<Typography component='span' color='#ddd' sx={{marginBottom: 1}}>
							{'  '}.{'  '}
						</Typography>
						<Link
							href='#'
							to=''
							className={classes.link}
							style={{
								width: '100%',
								paddingLeft: 10,
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							Privacy Policy
						</Link>
					</Typography>
				</Box>
			</Box>
		</Dialog>
	);
}
