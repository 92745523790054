import {Button, Dialog, FormControl, InputLabel, MenuItem, Select, Slide, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import {connect} from 'react-redux';
import {TextEditor} from '../Tiptap/Editor';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import GenericCard from './GenericCard';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {useAuth} from '../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import Validator from 'validator';
import UserSearchBox from './UserSearchBox';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
	showOpacityBox: {
		opacity: 1,
		maxHeight: 100,
		transition: '0.3s ease-in-out !important',
	},
	hideOpacityBox: {
		opacity: 0,
		maxHeight: 0,
		transition: '0.3s ease-in-out !important',
	},
}));

function AddAlertDialog({handleClose, onApplyIncrementAlerts}) {
	const classes = useStyles();
	const types = ['success', 'error', 'warning', 'info'];
	const statuses = [
		{name: 'Global', value: 'global'},
		{name: 'Targeted', value: 'targeted'},
	];
	const {enqueueSnackbar} = useSnackbar();
	const {token} = useAuth();
	const [type, setType] = useState('');
	const [status, setStatus] = useState('global');
	const [alertContent, setAlertContent] = useState('');
	const [expiryDate, setExpiryDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
	const [selectedUserEmail, setSelectedUserEmail] = useState('');
	const [selectedUserId, setSelectedUserId] = useState('');

	const [, addNewAlert] = useAxios({url: Env().url + '/notifications/alert', method: 'POST', headers: {Authorization: 'Bearer ' + token}}, {manual: true});

	const handleSelectUsers = (e, selectedValue, results) => {
		let email = selectedValue;
		let selectedObj = results.some(obj => {
			return obj.first === selectedValue;
		});
		let id = selectedObj.second;
		results.forEach(item => {
			if (item.first === selectedValue) {
				id = item.second;
			}
		});
		if ((selectedValue && Validator.isEmail(email)) || !selectedValue) {
			setSelectedUserEmail(email);
			setSelectedUserId(id);
		} else {
			enqueueSnackbar('invalid email address', {variant: 'error'});
		}
	};

	const handleChangeType = e => {
		setType(e.target.value);
	};
	const handleChangeStatus = e => {
		setStatus(e.target.value);
	};

	const handleAddAlert = () => {
		let data = {msg: alertContent, expiresAt: expiryDate, ext: JSON.stringify({type: type})};
		if (selectedUserId) {
			data.userId = selectedUserId;
		}

		addNewAlert({data: data})
			.then(() => {
				enqueueSnackbar('added!', {variant: 'success'});
				onApplyIncrementAlerts();
				handleClose();
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
				handleClose();
			});
	};

	const handleReset = () => {
		setType('');
		setAlertContent('');
	};

	const Actions = () => (
		<>
			<Button variant='contained' onClick={handleAddAlert} sx={{textTransform: 'none'}} disabled={!alertContent}>
				Add
			</Button>
			<Button variant='outlined' onClick={handleReset} sx={{textTransform: 'none'}}>
				Reset
			</Button>
		</>
	);

	return (
		<Dialog open={true} onClose={() => null} maxWidth='md'>
			<GenericCard
				handleClose={handleClose}
				actions={<Actions />}
				title={
					<Typography variant='h6' fontFamily='Fredoka' fontWeight={600}>
						Add Alert
					</Typography>
				}
			>
				<>
					<Box display='flex' gap={2} flexWrap='wrap'>
						<FormControl size='small' variant='outlined'>
							<InputLabel>Status</InputLabel>

							<Select value={status} onChange={handleChangeStatus} label='Status'>
								{statuses.map((status, index) => (
									<MenuItem value={status.value} key={index} dense>
										{status.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl size='small' variant='outlined' sx={{minWidth: 120}}>
							<InputLabel>Alert Type</InputLabel>

							<Select value={type} onChange={handleChangeType} label='Alert Type'>
								{types.map((type, index) => (
									<MenuItem
										value={type}
										key={index}
										sx={{
											color: type === 'error' ? '#6A302E' : type === 'success' ? '#1E4620' : type === 'info' ? '#004361' : type === 'warning' ? '#663B00' : '',
											background: type === 'error' ? '#FDEDED !important' : type === 'success' ? '#ECF7ED !important' : type === 'info' ? '#E5F6FD !important' : type === 'warning' && '#FFF4E5 !important',
										}}
									>
										<Typography>{type}</Typography>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								renderInput={props => <TextField size='small' {...props} />}
								label='Expiry time'
								value={expiryDate}
								onChange={newValue => {
									setExpiryDate(newValue);
								}}
							/>
						</LocalizationProvider>
						<Slide in={status === 'targeted'} direction='right'>
							<Box className={status === 'targeted' ? classes.showOpacityBox : classes.hideOpacityBox} width='100%' display='flex' height={status === 'targeted' ? 'auto' : 0}>
								<UserSearchBox handleSelectUsers={handleSelectUsers} selectedUser={selectedUserEmail} />
							</Box>
						</Slide>
					</Box>
					<Box border='1px solid #EEE' pl={1} pr={1} pb={1} mt={1} height={300} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
						<TextEditor notes={alertContent} setNotes={setAlertContent} editable={true} extensions={['textStyles', 'heading', 'lists', 'image']} />
					</Box>
				</>
			</GenericCard>
		</Dialog>
	);
}

const mapDispatchToProps = dispatch => ({
	onApplyIncrementAlerts: () => dispatch({type: 'ALERTS_INCREMENT'}),
});

export default connect(null, mapDispatchToProps)(AddAlertDialog);
