import React, {useContext, useState} from 'react';

const EditorContext = React.createContext();

export function useEditor() {
	return useContext(EditorContext);
}

export function EditorProvider({children}) {
	const [editor, setEditor] = useState();

	const value = {
		editor,
		setEditor,
	};

	return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}
