import Env from '../../../../../util/Env';
import {memo, useCallback, useState} from 'react';
import {Box, Button, Card, CircularProgress, Typography, Divider, CardActions, IconButton} from '@mui/material';
import axios from 'axios';
import {makeStyles} from '@mui/styles';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {ArrowBack, Download, QuestionMark, Save} from '@mui/icons-material';
import firebase from 'firebase';
import {TabContext, TabPanel} from '@mui/lab';
import {StyledTab, StyledTabs} from '../../../../common/CustomTabs';
import InfoIcon from '@mui/icons-material/Info';
import ReactGA from 'react-ga';
import ReactToPrint from 'react-to-print';
import {useSnackbar} from 'notistack';
import Solutions from './Tabs/Solutions';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../../../ErrorBoundary/InternalErrorBoudaryPage';
import Notes from './Notes';
import {useAuth} from '../../../../../AuthContext/AuthContext';
import {useRealtime} from '../../../../../RealtimeContext/RealtimeContext';
import {useAccount} from '../../../../../AccountContext/AccountContext';
import ConfirmationPopper from '../../../../Fragments/ConfirmationPopper';

const useStyles = makeStyles(() => ({
	current: {
		display: 'flex',
		gap: 5,
	},
	padding: {
		padding: 1,
	},
	hoveredRow: {
		background: '#F3F6F9',
		cursor: 'pointer',
		transition: '0.2s ease-in-out',
	},
	card: {
		border: '1px solid #ccc',
		borderRadius: '10px !important',
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		textTransform: 'none !important',
	},
	tabPanel: {
		padding: '5px !important',
		maxHeight: 700,
		overflowX: 'hidden',
	},
}));

export default memo(function Candidate({isUserCandidate, fetchCandidatesData, setCurrentCandidate, currentCandidate}) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {loggedInUser, token} = useAuth();
	const {planPermissions} = useAccount();
	const {realtime, setRealtime} = useRealtime();
	var firepadRef = window.firepadRef;

	const [loadingUpdateCandidateData, setLoadingUpdateCandidateData] = useState(false);
	const [tabsValue, setTabsValue] = useState(0);
	const [candidateNotes, setCandidateNotes] = useState(JSON.parse(currentCandidate.stickyNotes || '[]') || []);
	const [isInfoTabBugged, setIsInfoTabBugged] = useState(false);
	const [isSolutionsTabBugged, setIsSolutionsTabBugged] = useState(false);
	const [openConfirmationPopper, setOpenConfirmationPopper] = useState(false);
	const [anchorConfirmationPopper, setAnchorConfirmationPopper] = useState('');

	var ref = firebase.app('linearPad').database().ref();
	var roomRef = ref.child(loggedInUser.uid);

	const handleLoadDataInEditor = (e, force) => {
		if (realtime.currentCandidateId && !force) {
			handleOpenConfirmationPopper(e);
		} else {
			ReactGA.event({
				category: 'Editor state',
				action: 'load the pad data to the editor',
			});
			setRealtime({...realtime, currentCandidateId: currentCandidate.id});
			roomRef.child('currentCandidateId').set(currentCandidate.id);
			roomRef.child('language').set(currentCandidate.config?.mode || 'javascript');
			roomRef.child('theme').set(currentCandidate.config?.theme || 'textmate');
			roomRef.child('fontSize').set(currentCandidate.config?.fontSize || '14px');
			if (currentCandidate.status !== 'open') {
				handleReOpenInterview();
			}
			if (firepadRef)
				firepadRef.setText(
					currentCandidate.content ||
						`//print "Hello World!"
	console.log("Hello World!");`
				);
			if (force) {
				handleCloseConfirmationPopper();
			}
			enqueueSnackbar('data is loaded successfully', {variant: 'success'});
		}
	};
	const handleOpenConfirmationPopper = e => {
		setAnchorConfirmationPopper(e.currentTarget);
		setOpenConfirmationPopper(true);
	};
	const handleCloseConfirmationPopper = () => {
		setOpenConfirmationPopper(false);
		setAnchorConfirmationPopper('');
	};

	const handleBackButtonClick = useCallback(() => {
		setCurrentCandidate('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleReOpenInterview = () => {
		let newNotes = [];
		currentCandidate.stickyNotes &&
			Array.isArray(currentCandidate.stickyNotes) &&
			currentCandidate.stickyNotes.forEach(note => {
				let {editorState, ...pureNote} = note;
				newNotes.push(pureNote);
			});
		axios
			.post(Env().url + '/pad', {...currentCandidate, status: 'open', stickyNotes: JSON.stringify(newNotes), config: JSON.stringify(currentCandidate.config)}, {headers: {Authorization: 'Bearer ' + token}})
			.then(() => {
				fetchCandidatesData();
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	const handleSaveCandidateData = useCallback(
		notes => {
			ReactGA.event({
				category: 'Candidate info',
				action: 'trying to save candidate info to the current pad',
			});
			setLoadingUpdateCandidateData(true);
			let newNotes = [];
			if (Array.isArray(notes)) {
				notes.forEach(note => {
					let {editorState, ...pureNote} = note;
					newNotes.push(pureNote);
				});
			} else {
				candidateNotes.forEach(note => {
					let {editorState, ...pureNote} = note;
					newNotes.push(pureNote);
				});
			}

			axios
				.post(
					Env().url + '/pad',
					{
						...currentCandidate,
						name: currentCandidate.name,
						stickyNotes: JSON.stringify(newNotes),
						config: JSON.stringify(currentCandidate.config),
					},
					{headers: {Authorization: 'Bearer ' + token}}
				)
				.then(res => {
					enqueueSnackbar('data is saved successfully!', {
						variant: 'success',
					});
					ReactGA.event({
						category: 'Candidate info',
						action: 'save candidate info to the current pad',
					});
					fetchCandidatesData();
					setLoadingUpdateCandidateData(false);
				})
				.catch(err => {
					setLoadingUpdateCandidateData(false);
					enqueueSnackbar("couldn't save data!", {variant: 'error'});
					ReactGA.event({
						category: 'Candidate info',
						action: 'failed to save candidate info to the current pad due to an error',
					});
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[candidateNotes, currentCandidate]
	);

	const handleChangeTab = useCallback((event, newValue) => {
		ReactGA.event({
			category: 'Candidate tab',
			action: 'change tabs of candidate tab',
		});
		setTabsValue(newValue);
	}, []);

	return (
		<>
			{openConfirmationPopper && (
				<ConfirmationPopper onCancel={handleCloseConfirmationPopper} onConfirm={e => handleLoadDataInEditor(e, true)} anchor={anchorConfirmationPopper} placement='top' arrow={true} message='Are you sure?' confirm='Start' cancel='Cancel' name='interview' operation='Start' />
			)}

			<Card elevation={2} className={classes.card}>
				<TabContext value={tabsValue.toString()}>
					<Box display='flex' alignItems='center' pl={1}>
						<IconButton onClick={handleBackButtonClick} size='small' color='primary'>
							<ArrowBack fontSize='small' />
						</IconButton>
						<>
							<StyledTabs value={tabsValue} onChange={handleChangeTab} aria-label='styled tabs example' variant='scrollable' scrollButtons='auto'>
								<StyledTab label={'Notes'} icon={<InfoIcon color='primary' />} iconPosition='start' />
								{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.record')) && (
									<StyledTab label={'Solutions'} icon={<QuestionMark color='primary' />} iconPosition='start' disabled={!currentCandidate?.examples?.length > 0} />
								)}
							</StyledTabs>
						</>
					</Box>
					<Divider />
					<TabPanel value='0' className={classes.tabPanel}>
						<Box position='relative' display='flex'>
							<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsInfoTabBugged(false)} resetKeys={[isInfoTabBugged]}>
								<Box width='100%'>
									<Box>
										<Notes notes={candidateNotes} setNotes={setCandidateNotes} editable={isUserCandidate} handleSave={handleSaveCandidateData} />
									</Box>
								</Box>
							</ErrorBoundary>
						</Box>
					</TabPanel>
					{planPermissions && (
						<>
							<TabPanel value='1' className={classes.tabPanel}>
								<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsSolutionsTabBugged(false)} resetKeys={[isSolutionsTabBugged]}>
									<Solutions currentCandidate={currentCandidate} fetchCandidatesData={fetchCandidatesData} setTabsValue={setTabsValue} />
								</ErrorBoundary>
							</TabPanel>
						</>
					)}
					<Typography className={classes.padding} />
				</TabContext>
				<Divider />
				{tabsValue === 0 && (
					<CardActions className={classes.actions}>
						<Box display='flex' gap={1}>
							{realtime.currentCandidateId !== currentCandidate.id && (
								<Button variant='outlined' size='small' startIcon={<KeyboardDoubleArrowLeftIcon />} className={classes.button} onClick={handleLoadDataInEditor}>
									Start interview
								</Button>
							)}
							{isUserCandidate && (
								<>
									<Button variant='outlined' size='small' onClick={handleSaveCandidateData} endIcon={loadingUpdateCandidateData ? <CircularProgress size={20} /> : <Save fontSize='small' />} className={classes.button}>
										Save
									</Button>
									<ReactToPrint
										content={() => document.getElementById('candidateData')}
										trigger={() => (
											<Button size='small' variant='outlined' endIcon={<Download fontSize='small' />} className={classes.button}>
												PDF
											</Button>
										)}
									/>
								</>
							)}
						</Box>
					</CardActions>
				)}
			</Card>
		</>
	);
});
