import {ArrowDropDown} from '@mui/icons-material';
import {Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper} from '@mui/material';
import {Editor} from '@tiptap/react';
import {useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
	popper: {
		zIndex: 1,
	},
});

export const Heading = ({editor}: Editor) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup ref={anchorRef}>
				<Button variant={editor.isActive('heading', {level: 1}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}>
					H<sub>1</sub>
				</Button>
				<Button size='small' aria-controls={open ? 'split-button-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-label='select merge strategy' aria-haspopup='menu' onClick={handleToggle}>
					<ArrowDropDown />
				</Button>
			</ButtonGroup>
			<Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={classes.popper}>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper
							sx={{
								height: 35,
								borderRadius: 3,
								mt: 0.5,
								background: '#fff',
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<ButtonGroup sx={{height: 35}}>
									<Button variant={editor.isActive('heading', {level: 2}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}>
										H<sub>2</sub>
									</Button>
									<Button variant={editor.isActive('heading', {level: 3}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}>
										H<sub>3</sub>
									</Button>
									<Button variant={editor.isActive('heading', {level: 4}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 4}).run()}>
										H<sub>4</sub>
									</Button>
									<Button variant={editor.isActive('heading', {level: 5}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 5}).run()}>
										H<sub>5</sub>
									</Button>
									<Button variant={editor.isActive('heading', {level: 6}) ? 'contained' : 'outlined'} onClick={() => editor.chain().focus().toggleHeading({level: 6}).run()}>
										H<sub>6</sub>
									</Button>
								</ButtonGroup>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
