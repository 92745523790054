import * as React from 'react';
import {Droppable} from 'react-beautiful-dnd';
import Question from './Question';
import {Box} from '@mui/system';
import {makeStyles} from '@mui/styles';
import {Card, CardHeader, Typography} from '@mui/material';
import {Add} from '@mui/icons-material';
import {useAccount} from '../../../../../AccountContext/AccountContext';

const useStyles = makeStyles({
	nonRoot: {
		border: '1px solid #eaeaee !important',
		backgroundColor: '#eaeaee !important',
		borderRadius: '10px !important',
		margin: 1,
		flex: 1,
		paddingBottom: 16,
	},
	root: {
		border: '1px solid #eaeaee !important',
		margin: '1px 0px',
		flex: '1 0 21%',
		paddingBottom: 16,
		backgroundColor: '#eaeaee !important',
		borderRadius: '10px !important',
	},
	droppable: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 10,
		padding: 5,
		'&.isDraggingOver': {
			backgroundColor: '#dadadf',
		},
	},
	droppableRow: {
		display: 'flex',
		gap: 5,
		flexWrap: 'wrap',
		borderRadius: 10,
		padding: 5,
		'&.isDraggingOver': {
			backgroundColor: '#dadadf',
		},
	},
});

export default function CategoryColumn({category, categories, userId, currentQuiz, fetchUserQuestions, handleAddNewQuestion, fetchSharedQuestions}) {
	const classes = useStyles();
	const {planPermissions} = useAccount();

	return category === 'none' ? (
		<Card variant='outlined' className={classes.nonRoot}>
			<CardHeader
				sx={{padding: 0, background: '#1776D2'}}
				title={
					<Box position='relative'>
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.add')) && (
							<Add htmlColor='#fff' sx={{fontSize: 24, cursor: 'pointer', position: 'absolute', top: 1, left: 1}} onClick={handleAddNewQuestion} />
						)}
						<Typography align='center' variant='subtitle1' color='#fff'>
							{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.drag')) ? 'Not Categorized' : 'All Questions'}
						</Typography>
					</Box>
				}
			></CardHeader>
			<Box>
				<Droppable droppableId={category} direction='horizontal'>
					{(droppableProvided, snapshot) => (
						<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={classes.droppableRow + (snapshot.isDraggingOver ? ' isDraggingOver' : '')}>
							{category === 'none' && categories[category].list.length !== 0 ? (
								categories[category].list.map((question, index) => (
									<Question key={question.question.id} fetchSharedQuestions={fetchSharedQuestions} category={category} index={index} question={question} chip={true} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchUserQuestions} />
								))
							) : (
								<Box display='flex' width='100%' justifyContent='center' alignItems='center'>
									<Typography variant='h6' fontSize={24} fontFamily='Fredoka'>
										No questions here
									</Typography>
								</Box>
							)}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
			</Box>
		</Card>
	) : (
		<Card variant='outlined' className={classes.root}>
			<CardHeader
				sx={{padding: 0, background: '#1776D2'}}
				title={
					<Typography align='center' variant='subtitle1' color='#fff'>
						{categories[category].name}
					</Typography>
				}
			/>
			<Box>
				<Droppable droppableId={category}>
					{(droppableProvided, snapshot) => (
						<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={classes.droppable + (snapshot.isDraggingOver ? ' isDraggingOver' : '')}>
							{categories[category].list.map((question, index) => (
								<Question category={category} fetchSharedQuestions={fetchSharedQuestions} key={question.question.id} index={index} question={question} userId={userId} currentQuiz={currentQuiz} fetchUserQuestions={fetchUserQuestions} />
							))}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
			</Box>
		</Card>
	);
}
