import {BiotechRounded, ReportProblemRounded} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {Box} from '@mui/system';
import {TextEditor} from '../Tiptap/Editor';

export default function StyledQuestionContent({problem, testCases}) {
	return (
		<Box>
			<Box display='flex' gap={2} alignItems='center'>
				<ReportProblemRounded color='primary' sx={{fontSize: 40}} />
				<Typography fontFamily='Fredoka' fontWeight={600} variant='h6' color='primary'>
					Problem
				</Typography>
			</Box>
			<Box pl={3} mb={2}>
				<TextEditor notes={problem} editable={false} />
			</Box>
			{testCases && (
				<>
					<Box display='flex' gap={2} alignItems='center'>
						<BiotechRounded color='primary' sx={{fontSize: 40}} />
						<Typography fontFamily='Fredoka' fontWeight={600} variant='h6' color='primary'>
							Test cases
						</Typography>
					</Box>
					<Box pl={3}>
						<TextEditor notes={testCases} editable={false} />
					</Box>
				</>
			)}
		</Box>
	);
}
