import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import {PaymentElement} from '@stripe/react-stripe-js';
import {Dialog} from '@mui/material';
import PaymentForm from './PaymentForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51LOwN7BbWEwvR7YN2K2OIJXXhgnBIAOsBW9TtDjbCsSa7VoNJCzkdljek9klE3jWlUPqXR7b3y8SH8Bi2aW6wjmu000D02CzKL');

// const CheckoutForm = () => {
// 	return (
// 		<form>
// 			<PaymentElement />
// 			<button>Submit</button>
// 		</form>
// 	);
// };

export default function Stripe({open, onClose}) {
	// const options = {
	// 	// passing the client secret obtained from the server
	// 	clientSecret: 'sk_test_51L8VTdDhcYFfxFiM1iy14GcfRUngbLeZdAd4uxcqE8JRQ6oCob6uBNiv6y8WkxrPLUard7A5pPkOgaP9pqnqxaMc00cmrCl2oJ',
	// };

	return (
		<Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
			<Elements stripe={stripePromise}>
				<PaymentForm handleClose={onClose} />
			</Elements>
		</Dialog>
	);
}
