import React, {memo} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Divider, Typography} from '@mui/material';
import {Arrow, Popper} from '../common/PopperFragments';

export default memo(function ConfirmationPopper({onCancel, onConfirm, onClose, anchor, placement, arrow, message, confirm, cancel, name, operation}) {
	const [arrowRef, setArrowRef] = React.useState(null);

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card elevation={5} sx={{borderRadius: '15px'}}>
					<CardHeader
						sx={{background: '#3A82D5', padding: 1}}
						title={
							<Typography align='center' color='#fff' fontFamily='Fredoka' fontWeight={600}>
								{`${operation} ${name}`}
							</Typography>
						}
					/>
					<CardContent>
						<Typography variant='h6'>{message}</Typography>
					</CardContent>
					<Divider />
					<CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Button color='error' size='small' variant='outlined' onClick={onCancel} sx={{fontFamily: 'Fredoka', textTransform: 'none'}}>
							{cancel}
						</Button>
						<Button color='primary' size='small' variant='contained' onClick={onConfirm} sx={{fontFamily: 'Fredoka', textTransform: 'none'}}>
							{confirm}
						</Button>
					</CardActions>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
});
