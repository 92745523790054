import {Add, MoreVert, Search, PictureAsPdf} from '@mui/icons-material';
import {Alert, Avatar, AvatarGroup, Button, CircularProgress, ClickAwayListener, Grid, IconButton, InputAdornment, OutlinedInput, Skeleton, TablePagination, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import {styled} from '@mui/system';
import {Box} from '@mui/system';
import {useCallback, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../../../ErrorBoundary/InternalErrorBoudaryPage';
import axios from 'axios';
import Env from '../../../../../util/Env';
import {useAuth} from '../../../../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import ShareDialog from '../../../../ShareDialog';
import NewCandidatePopper from '../NewCandidatePopper';
import Lottie from '../../../../Fragments/Lottie';
import {stringToColor} from '../../../../../util/commonFunctions';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import SharesPopper from '../../../../Fragments/SharesPopper';
import OptionsMenu from './OptionsMenu';
import {useAccount} from '../../../../../AccountContext/AccountContext';
import useAxios from 'axios-hooks';
import CVDialog from '../../../../Fragments/CVDialog';

const useStyles = makeStyles(() => ({
	hoveredRow: {
		background: grey[50],
		cursor: 'pointer',
		transition: '0.2s ease-in-out',
	},
	card: {
		border: '1px solid #ccc',
		borderRadius: '10px !important',
	},
	actions: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		textTransform: 'none !important',
	},
	shareCell: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	nameCell: {
		display: 'flex',
	},
}));

const grey = {
	50: '#F3F6F9',
	100: '#E7EBF0',
	200: '#E0E3E7',
	300: '#CDD2D7',
	400: '#B2BAC2',
	500: '#A0AAB4',
	600: '#6F7E8C',
	700: '#3E5060',
	800: '#2D3843',
	900: '#1A2027',
};

const Root = styled('div')(
	({theme}) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
    }
    `
);

export default function CandidatesTable({setTabsValue, page, setPage, rowsPerPage, setRowsPerPage, handleDeleteCandidate, isUserCandidates, userId, fetchCandidatesData, candidatesData, candidatesDataLoading, candidatesDataFetchError, realtimeCandidateId, setCurrentCandidate}) {
	const classes = useStyles();
	const usedTheme = useTheme();
	const isMobile = useMediaQuery(usedTheme.breakpoints.down('md'));
	const {token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const {planPermissions} = useAccount();

	const [, deleteShareUser] = useAxios({
		method: 'DELETE',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [hoveredRow, setHoveredRow] = useState(0);
	const [isFocussed, setIsFocussed] = useState(false);
	const [isCandidatesTableBugged, setIsCandidatesTableBugged] = useState(false);
	const [hoveredUsers, setHoveredUsers] = useState([]);
	const [openNewCandidatePopper, setOpenNewCandidatePopper] = useState(false);
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSharesPopper, setOpenSharesPopper] = useState(false);
	const [anchorSharesPopper, setAnchorSharesPopper] = useState('');
	const [anchorNewCanidatePopper, setAnchorNewCandidatePopper] = useState('');
	const [addCandidateLoading, setAddCandidateLoading] = useState(false);
	const [emails, setEmails] = useState({});
	const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
	const [anchorOptionsMenu, setAnchorOptionsMenu] = useState('');
	const [clickedCandidate, setClickedCandidate] = useState('');
	const [selectedRow, setSelectedRow] = useState('');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [revokeLoading, setRevokeLoading] = useState(false);
	const [openCvDialog, setOpenCvDialog] = useState(false);
	const [currentCandidateName, setCurrentCandidateName] = useState('');
	const [currentCandidateCvId, setCurrentCandidateCvId] = useState('');

	const handleRevoke = () => {
		setRevokeLoading(true);
		let resource = selectedRow.share[currentIndex - 1];
		deleteShareUser({url: Env().url + '/usersaccess/rbac/deleteResource/' + resource.id})
			.then(() => {
				setRevokeLoading(false);
				enqueueSnackbar('Share is revoked!', {variant: 'success'});
				if (selectedRow.share.length === 1) {
					setTabsValue(0);
				}
				fetchCandidatesData();
			})
			.catch(err => {
				setRevokeLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};
	const handleOpenCvDialog = (e, name, link) => {
		e.stopPropagation();
		setOpenCvDialog(true);
		setCurrentCandidateName(name);
		setCurrentCandidateCvId(link);
	};
	const handleCloseCvDialog = () => {
		setOpenCvDialog(false);
		setCurrentCandidateName('');
		setCurrentCandidateCvId('');
	};
	const handleClosePopper = useCallback(() => {
		setOpenNewCandidatePopper(false);
		setAnchorNewCandidatePopper('');
	}, []);
	const handleOpenSheresPopper = (e, users) => {
		e.stopPropagation();
		e.preventDefault();
		if (isUserCandidates) {
			setHoveredUsers(users);
		} else {
			let ownerUser = {email: emails[users[0].ownerId]};
			setHoveredUsers([ownerUser]);
		}
		setAnchorSharesPopper(e.currentTarget);
		setOpenSharesPopper(true);
	};
	const handleCloseSheresPopper = useCallback(e => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorSharesPopper('');
		setOpenSharesPopper(false);
	}, []);
	const handleSetCurrentCandidate = useCallback(
		candidate => {
			setCurrentCandidate(candidate);
		},
		[setCurrentCandidate]
	);
	const handleAddNewCandidate = useCallback(name => {
		setAddCandidateLoading(true);
		axios
			.post(
				Env().url + '/pad',
				{
					userId: userId,
					anonymous: false,
					name: name,
					email: '',
					phone: '',
					cv: '',
					notes: '',
					content: '',
					status: 'open',
					config: JSON.stringify({}),
				},
				{headers: {Authorization: 'Bearer ' + token}}
			)
			.then(res => {
				fetchCandidatesData();
				setAddCandidateLoading(false);
				enqueueSnackbar('new candidate is added!', {variant: 'success'});
				handleClosePopper();
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
				setAddCandidateLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenNewCandidatePopper = useCallback(e => {
		setOpenNewCandidatePopper(true);
		setAnchorNewCandidatePopper(e.currentTarget);
	}, []);

	const handleChangePage = useCallback(
		(event, newPage) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		event => {
			setRowsPerPage(parseInt(event.target.value, 10));
			setPage(0);
		},
		[setPage, setRowsPerPage]
	);

	const getUserEmailByUid = id => {
		axios.get(Env().url + '/usersaccess/getUserEmail/' + id, {headers: {Authorization: 'Bearer ' + token}}).then(res => {
			setEmails(emails => ({...emails, [id]: res.data}));
		});
	};

	const handleOpenOptionsMenu = (e, candidate, row, ind) => {
		setClickedCandidate(candidate);
		setCurrentIndex(ind + 1);
		setSelectedRow(row);
		setOpenOptionsMenu(true);
		setAnchorOptionsMenu(e.currentTarget);
	};
	const handleCloseOptionsMenu = () => {
		setOpenSharesPopper(false);
		setCurrentIndex(0);
		setSelectedRow('');
		setClickedCandidate('');
		setOpenOptionsMenu(false);
		setAnchorOptionsMenu('');
	};
	const handleOpenShareDialog = () => {
		setOpenOptionsMenu(false);
		setOpenShareDialog(true);
	};

	useEffect(() => {
		if (!isUserCandidates) {
			candidatesData.rows.forEach(candidate => {
				getUserEmailByUid(candidate.share[0].ownerId);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserCandidates, candidatesData]);

	return (
		<>
			<CVDialog open={openCvDialog} handleClose={handleCloseCvDialog} candidateName={currentCandidateName} id={currentCandidateCvId} />
			{openOptionsMenu && (
				<OptionsMenu
					candidate={clickedCandidate}
					revokeLoading={revokeLoading}
					isUserCandidates={isUserCandidates}
					anchorEl={anchorOptionsMenu}
					fetchCandidates={fetchCandidatesData}
					onClose={handleCloseOptionsMenu}
					handleShare={handleOpenShareDialog}
					handleDelete={() => handleDeleteCandidate(clickedCandidate.id)}
					handleRevoke={handleRevoke}
				/>
			)}
			{openSharesPopper && <SharesPopper placement='bottom' arrow={true} users={hoveredUsers} anchor={anchorSharesPopper} />}
			{openShareDialog && <ShareDialog candidate={clickedCandidate} isUserCandidate={isUserCandidates} isCandidate={true} isQuestion={false} handleClose={() => setOpenShareDialog(false)} />}
			{openNewCandidatePopper && <NewCandidatePopper placement='bottom' arrow={true} anchor={anchorNewCanidatePopper} cancel='cancel' confirm='Add' onCancel={handleClosePopper} onClose={handleClosePopper} onConfirm={handleAddNewCandidate} loading={addCandidateLoading} />}
			<Box display='flex' flexDirection='column' gap={2}>
				{isUserCandidates && (
					<Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
						<Grid item xs={9}>
							<ClickAwayListener onClickAway={() => isFocussed && setIsFocussed(false)}>
								<OutlinedInput
									placeholder='Search candidates'
									fullWidth
									onClick={() => setIsFocussed(true)}
									onChange={e =>
										fetchCandidatesData({
											url: Env().url + '/pad/myLinearpads?' + (e.target.value ? '&q=' + e.target.value : ''),
										})
									}
									size='small'
									startAdornment={
										<InputAdornment position='start'>
											<Search htmlColor={isFocussed ? '#1776D2' : '#7F7F7F'} />
										</InputAdornment>
									}
								/>
							</ClickAwayListener>
						</Grid>
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.add')) && (
							<Grid item sx={{display: 'flex', justifyContent: 'flex-end'}}>
								<Button size='small' variant='contained' startIcon={isMobile ? '' : <Add />} onClick={handleOpenNewCandidatePopper} className={classes.button}>
									{isMobile ? addCandidateLoading ? <CircularProgress size={20} sx={{color: '#fff'}} /> : <Add /> : 'New Candidate'}
								</Button>
							</Grid>
						)}
					</Grid>
				)}
				<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCandidatesTableBugged(false)} resetKeys={[isCandidatesTableBugged]}>
					<Root sx={{maxWidth: '100%'}}>
						<table>
							<thead>
								<tr>
									<th>Candidate name</th>
									<th>Interview status</th>
									<th>Evaluation</th>
								</tr>
							</thead>
							<tbody>
								{!candidatesDataLoading || candidatesData ? (
									candidatesDataFetchError ? (
										<tr>
											<td colSpan={4}>
												<Alert
													severity='error'
													action={
														<Button color='inherit' size='small' sx={{textTransform: 'none'}} onClick={() => window.location.reload()}>
															Reload
														</Button>
													}
												>
													Something went wrong in fetching data
												</Alert>
											</td>
										</tr>
									) : candidatesData?.rows?.length === 0 ? (
										<tr>
											<td colSpan={4}>
												<Typography align='center'>No candidates</Typography>
											</td>
										</tr>
									) : (
										candidatesData?.rows?.map((candidate, index) => (
											<tr
												key={candidate.linearpad.id}
												onMouseEnter={() => setHoveredRow(index + 1)}
												onMouseLeave={() => setHoveredRow(0)}
												onClick={() => {
													handleSetCurrentCandidate(candidate.linearpad);
												}}
												className={hoveredRow === index + 1 ? classes.hoveredRow : ''}
											>
												<td>
													<Box display='flex' alignItems='center' justifyContent='space-between'>
														<Box display='flex' flexDirection='column'>
															{candidate.linearpad.name}
															<Typography fontSize={10} marginTop='-2px' color='#1776D2'>
																{new Date(candidate.linearpad.updateTime).getDate() +
																	'/' +
																	(new Date(candidate.linearpad.updateTime).getMonth() + 1) +
																	'/' +
																	new Date(candidate.linearpad.updateTime).getFullYear() +
																	' ' +
																	new Date(candidate.linearpad.updateTime).getHours().toString().padStart(2, '0') +
																	':' +
																	new Date(candidate.linearpad.updateTime).getMinutes().toString().padStart(2, '0') +
																	':' +
																	new Date(candidate.linearpad.updateTime).getSeconds().toString().padStart(2, '0')}
															</Typography>
														</Box>
														{realtimeCandidateId === candidate.linearpad.id && (
															<Box display='flex' alignItems='center'>
																<Lottie url='https://assets8.lottiefiles.com/packages/lf20_14m6usgd.json' width='15px' height='15px' />
																<Typography color='#6AC808' fontSize={12}>
																	interviewing
																</Typography>
															</Box>
														)}
													</Box>
												</td>
												<td>{candidate.linearpad.status}</td>
												<td>
													<Box display='flex' justifyContent='space-between' alignItems='center'>
														{candidate.linearpad.status !== 'open' && candidate.linearpad.notes ? (
															JSON.parse(candidate.linearpad.notes).recommended ? (
																<Tooltip title={JSON.parse(candidate.linearpad.notes).message} arrow placement='bottom' followCursor>
																	<Typography color='#2CCF4F'>Recommended</Typography>
																</Tooltip>
															) : (
																<Tooltip title={JSON.parse(candidate.linearpad.notes).message} arrow placement='bottom' followCursor>
																	<Typography color='error'>Not Recommended</Typography>
																</Tooltip>
															)
														) : (
															'Interview not conducted'
														)}
														<Box display='flex' alignItems='center' gap={2}>
															{candidate.linearpad.cv && (
																<Tooltip title='CV'>
																	<IconButton color='primary' size='small' onClick={e => handleOpenCvDialog(e, candidate.linearpad.name, candidate.linearpad.cv)}>
																		<PictureAsPdf fontSize='small' />
																	</IconButton>
																</Tooltip>
															)}
															{candidate.share?.length > 0 && (
																<Box onMouseEnter={e => handleOpenSheresPopper(e, candidate.share)} onMouseLeave={handleCloseSheresPopper}>
																	{isUserCandidates ? (
																		<AvatarGroup total={candidate.share.length} componentsProps={{additionalAvatar: {sx: {marginLeft: '-5px !important', width: 20, height: 20, fontSize: 15}}}} max={4}>
																			{candidate.share.map(user => (
																				<Avatar key={user.id} alt={user.email} title={user.email} sx={{background: stringToColor(user.email.split('@')[0]), width: 20, height: 20}} variant='circular'>
																					{user.email.toUpperCase().charAt(0)}
																				</Avatar>
																			))}
																		</AvatarGroup>
																	) : (
																		<Avatar title={emails[candidate.share[0].ownerId]} sx={{background: Object.keys(emails).length > 0 && stringToColor(emails[candidate.share[0].ownerId]?.split('@')[0]), width: 20, height: 20}} variant='circular'>
																			{emails[candidate.share[0].ownerId]?.toUpperCase().charAt(0)}
																		</Avatar>
																	)}
																</Box>
															)}
															<Tooltip title='Options'>
																<IconButton
																	size='small'
																	sx={{width: 20, height: 20}}
																	color='primary'
																	onClick={e => {
																		e.stopPropagation();
																		handleOpenOptionsMenu(e, candidate.linearpad, candidate, index);
																	}}
																>
																	<MoreVert fontSize='small' sx={{fontSize: 20}} />
																</IconButton>
															</Tooltip>
														</Box>
													</Box>
												</td>
											</tr>
										))
									)
								) : (
									Array.from(new Array(3)).map((_, index) => (
										<tr key={index}>
											<td colSpan={1}>
												<Skeleton height={20} width='100%' animation='wave' variant='rectangular' />
											</td>
											<td colSpan={1}>
												<Skeleton height={20} width='100%' animation='wave' variant='rectangular' />
											</td>
											<td colSpan={1}>
												<Skeleton height={20} width='100%' animation='wave' variant='rectangular' />
											</td>
										</tr>
									))
								)}
							</tbody>
							<tfoot>
								<tr>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
										colSpan={4}
										count={candidatesData?.totalCount || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</tr>
							</tfoot>
						</table>
					</Root>
				</ErrorBoundary>
			</Box>
		</>
	);
}
