import {Autocomplete, Avatar, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import useAxios from 'axios-hooks';
import {useAuth} from '../../AuthContext/AuthContext';
import {stringToColor} from '../../util/commonFunctions';
import Env from '../../util/Env';

export default function UserSearchBox({share, handleSelectUsers, selectedUser}) {
	const {token} = useAuth();
	const [{data: emails}, fetchEmails] = useAxios(
		{
			method: 'GET',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);

	const CustomListItem = (props, option) => {
		let {key, ...customProps} = props;
		return (
			<Box component='li' sx={{paddingLeft: 0}} key={props.id} {...customProps}>
				<Avatar variant='rounded' sx={{marginRight: '5px', width: 30, height: 30, background: stringToColor(option.split('@')[0])}}>
					{option.toUpperCase().charAt(0)}
				</Avatar>
				<Typography fontSize={12} textOverflow='ellipsis' whiteSpace='pre-wrap'>
					{option}
				</Typography>
			</Box>
		);
	};

	const handleFetchEmails = word => {
		if (word.length > 2 || word.length === 0) {
			fetchEmails({url: Env().url + (share ? '/usersaccess/auto/' : '/users/auto/') + word});
		}
	};

	return (
		<Autocomplete
			sx={{width: '50%'}}
			options={emails ? (share ? emails : emails.map(item => item.first)) : []}
			value={selectedUser}
			renderOption={CustomListItem}
			freeSolo
			onChange={(e, selectedValue) => handleSelectUsers(e, selectedValue, emails)}
			renderInput={params => <TextField {...params} size='small' variant='outlined' label='Users' onChange={e => handleFetchEmails(e.target.value)} placeholder='Type email then click /Enter' />}
		/>
	);
}
