import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment-timezone';
import momento from 'moment-mini';
import {useState} from 'react';
import {useEffect} from 'react';
import {Box} from '@mui/system';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {stringToColor} from '../../../util/commonFunctions';
import useAxios from 'axios-hooks';
import Env from '../../../util/Env';
import {useAuth} from '../../../AuthContext/AuthContext';
import {CircularProgress, IconButton, Typography} from '@mui/material';
import EventPopper from './EventPopper';
import TimezoneSelect from 'react-timezone-select';
import {MoreVert} from '@mui/icons-material';
import OptionsPopper from './OptionsPopper';

const EventWrapper = ({event, children}) => {
	const {title, className} = children.props;
	const customClass = `${className}`;
	const hourStart = momento(event.start).hour();
	const hourStop = momento(event.end).hour();
	const gridRowStart = hourStart + 1;
	const [openEventPopper, setOpenEventPopper] = useState(false);
	const [anchorEventPopper, setAnchorEventPopper] = useState(false);
	const [currentEvent, setCurrentEvent] = useState('');

	const handleOpenEventPopper = (e, event) => {
		setCurrentEvent(event);
		setAnchorEventPopper(e.currentTarget);
		setOpenEventPopper(true);
	};

	const handleCloseEventPopper = () => {
		setOpenEventPopper(false);
		setAnchorEventPopper('');
		setCurrentEvent('');
	};

	return (
		<>
			{openEventPopper && <EventPopper anchor={anchorEventPopper} arrow={true} placement='top' event={currentEvent} handleClose={handleCloseEventPopper} />}
			<Box
				title={title}
				className={customClass}
				bgcolor={stringToColor(new Date(event.startTime).getTime().toString())}
				color='#fff'
				sx={{cursor: 'pointer'}}
				onClick={e => handleOpenEventPopper(e, event)}
				style={{gridRow: `${gridRowStart} / span ${hourStop - hourStart === 0 ? 1 : hourStop - hourStart + 2}`}}
			>
				{children.props.children}
				<Typography>{JSON.parse(event.reservation).name}</Typography>
				<div dangerouslySetInnerHTML={{__html: JSON.parse(event.reservation).description}} />
			</Box>
		</>
	);
};

export default function MyCalendar(props) {
	const {token} = useAuth();

	const [localizer, setLocalizer] = useState(momentLocalizer(moment));

	const [events, setEvents] = useState([]);
	const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

	const [openEventPopper, setOpenEventPopper] = useState(false);
	const [anchorEventPopper, setAnchorEventPopper] = useState(false);
	const [currentEvent, setCurrentEvent] = useState('');
	const [openOptionsPopper, setOpenOptionsPopper] = useState(false);
	const [anchorOptionsPopper, setAnchorOptionsPopper] = useState(false);
	const [{data: reservations, loading}, fetchReservations] = useAxios({url: Env().url + '/userschedule/sec/reservation', headers: {Authorization: 'Bearer ' + token}});

	const handleOpenEventPopper = (e, event) => {
		setCurrentEvent(event);
		setAnchorEventPopper(e.currentTarget);
		setOpenEventPopper(true);
	};
	const handleOpenOptionsPopper = (e, event) => {
		setCurrentEvent(event);
		setAnchorOptionsPopper(e.currentTarget);
		setOpenOptionsPopper(true);
	};

	const handleCloseEventPopper = () => {
		setOpenEventPopper(false);
		setAnchorEventPopper('');
		setCurrentEvent('');
	};

	const handleCloseOptionsPopper = () => {
		setOpenOptionsPopper(false);
		setAnchorOptionsPopper('');
		setCurrentEvent('');
	};

	const handleChangeTimeZone = value => {
		setTimeZone(value);
	};
	useEffect(() => {
		let initialEvents = [];
		if (reservations) {
			reservations.forEach(reservation => {
				initialEvents.push({
					id: reservation.id,
					startTime: new Date(reservation.startTime),
					endTime: new Date(reservation.endTime),
					timeZone: reservation.timeZone,
					reservation: reservation.reservation,
					title: (
						<Box component='div' zIndex={1} width='100%' display='flex' justifyContent='space-between' alignItems='center'>
							<Typography component='a' onClick={e => handleOpenEventPopper(e, reservation)}>
								{JSON.parse(reservation.reservation).email + ' » ' + JSON.parse(reservation.reservation).name}
							</Typography>
							<IconButton size='small' onClick={e => handleOpenOptionsPopper(e, reservation)}>
								<MoreVert fontSize='small' />
							</IconButton>
						</Box>
					),
				});
			});
		}
		setEvents(initialEvents);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservations]);

	useEffect(() => {
		moment.tz.setDefault(typeof timeZone === 'object' ? timeZone.value : timeZone);
		setLocalizer(momentLocalizer(moment));
		// Setup the localizer by providing the moment (or globalize, or Luxon) Object
		// to the correct localizer.
	}, [timeZone]);
	return (
		<>
			{openEventPopper && <EventPopper anchor={anchorEventPopper} arrow={true} placement='top' event={currentEvent} handleClose={handleCloseEventPopper} />}
			{openOptionsPopper && <OptionsPopper anchor={anchorOptionsPopper} arrow={true} placement='bottom' event={currentEvent} handleClose={handleCloseOptionsPopper} fetchReservations={fetchReservations} />}
			<Box>
				<Box mt={1} mb={1} width='50%'>
					<TimezoneSelect value={timeZone} onChange={handleChangeTimeZone} />
				</Box>
				{!loading || reservations ? <Calendar localizer={localizer} components={{eventWrapper: EventWrapper}} defaultView={Views.AGENDA} views={{week: true, agenda: true}} events={events} startAccessor='startTime' endAccessor='endTime' /> : <CircularProgress />}
			</Box>
		</>
	);
}
