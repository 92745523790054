import {Dialog, LinearProgress} from '@mui/material';
import {Viewer} from '@react-pdf-viewer/core';
import useAxios from 'axios-hooks';
import {useEffect, useState} from 'react';
import {useAuth} from '../../AuthContext/AuthContext';
import Env from '../../util/Env';
import GenericCard from './GenericCard';
import '@react-pdf-viewer/core/lib/styles/index.css';

export default function CVDialog({id, open, handleClose, candidateName}) {
	const {token} = useAuth();
	const [cvFile, setCvFile] = useState('');

	const [{data: cvBinaryData, loading}] = useAxios(
		{
			url: Env().url + '/sketch/getCV/' + id,
			method: 'GET',
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + token,
			},
		},
		{manual: open ? false : true}
	);

	useEffect(() => {
		if (cvBinaryData) {
			var contentType = 'application/pdf';
			const file = new Blob([cvBinaryData], {type: contentType});
			const fileURL = URL.createObjectURL(file);
			setCvFile(fileURL);
		}
	}, [cvBinaryData]);

	return cvFile ? (
		<Dialog open={open} onClose={() => null} maxWidth='lg' fullWidth sx={{overflowY: 'auto !important'}}>
			<GenericCard handleClose={handleClose} title={candidateName + ' CV'}>
				{loading ? <LinearProgress /> : <Viewer fileUrl={cvFile} />}
			</GenericCard>
		</Dialog>
	) : null;
}
