/* eslint-disable react-hooks/exhaustive-deps */
import {IconButton, useMediaQuery, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {FirepadUserList} from '../../UserList/firepad-userlist';
import firebase from 'firebase';
import '../../UserList/firepad-userlist.css';
import 'firepad/dist/firepad.css';
import uuid from 'react-uuid';
import CandidateDialog from '../Fragments/CandidateDialog';
import Options from '../Fragments/Options';
import InputSection from '../InputSection';
import OutputSection from '../OutputSection';
import ReactGA from 'react-ga';
import {useSnackbar} from 'notistack';
import Cookies from 'universal-cookie';
import CandidateTabsSection from '../ActionCenter/CandidateTabs';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {Allotment} from 'allotment';
import {Close} from '@mui/icons-material';

const useStyles = makeStyles(() => ({
	logoBox: {
		background: 'linear-gradient(to right, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%)',
	},
	editor: {
		minHeight: '100vh',
		position: 'relative',
		display: 'flex',
	},
	divider: {
		borderRight: '1px solid #ddd',
	},
}));

function CandidatePad({sessionId, passCode}) {
	const cookies = new Cookies();
	const classes = useStyles();
	const history = useHistory();
	var roomRef = firebase.app('linearPad').database().ref().child(sessionId);
	const muiTheme = useTheme();
	const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
	const isTablet = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));

	const [openDialog, setOpenDialog] = useState({status: false, nameKey: ''});
	const [username, setUsername] = useState('');
	const [fontSize, setFontSize] = useState('');
	const FirePad = require('firepad');
	const [theme, setTheme] = useState('textmate');
	const [language, setLanguage] = useState('javascript');
	const [run, setRun] = useState(false);
	const [input, setInput] = useState('');
	const [currentQuiz, setCurrentQuiz] = useState('');
	const {enqueueSnackbar} = useSnackbar();
	const [isPadSectionBugged, setIsPadSectionBugged] = useState(false);
	const [isOptionsSectionBugged, setIsOptionsSectionBugged] = useState(false);
	const [isInputSectionBugged, setIsInputSectionBugged] = useState(false);
	const [isOutputSectionBugged, setIsOutputSectionBugged] = useState(false);
	const [isCandidateTabsBugged, setIsCandidateTabsBugged] = useState(false);
	const [usersComponent, setUsersComponent] = useState('');
	const [reload, setReload] = useState(false);
	const [sections, setSections] = useState(['input', 'output']);
	const [showTabsSection, setShowTabsSection] = useState(true);
	const [showPadSection, setShowPadSection] = useState(true);
	const [pageConfig, setPageConfig] = useState();

	const setNameFunction = (nameKey, val) => {
		cookies.set(nameKey, val, {path: '/', maxAge: 31536000});
	};
	const handleRun = status => {
		ReactGA.event({
			category: 'Candidate action',
			action: 'run the code',
		});
		enqueueSnackbar('Code is running...', {variant: 'info'});
		roomRef.child('isRunning').set(status);
	};
	const handleChangeInput = value => {
		roomRef.child('input').set(value);
	};
	const handleThemeChange = e => {
		ReactGA.event({
			category: 'Candidate action',
			action: 'change theme to ' + e.target.value,
		});
		roomRef.child('theme').set(e.target.value);
	};
	const handleChangeFontSize = e => {
		ReactGA.event({
			category: 'Candidate action',
			action: 'change font size to ' + e.target.value,
		});
		roomRef.child('fontSize').set(e.target.value);
	};
	const handleModeChange = language => {
		ReactGA.event({
			category: 'Candidate action',
			action: 'change language to ' + language,
		});
		enqueueSnackbar('Language is changed to ' + language, {
			variant: 'info',
		});
		roomRef.child('language').set(language);
	};
	const handleCloseDialog = e => {
		e.preventDefault();
		cookies.set(openDialog.nameKey, username, {path: '/', maxAge: 31536000});
		ReactGA.event({
			category: 'Login',
			action: 'Candidate login',
		});
		setOpenDialog({status: false, nameKey: ''});
	};

	useEffect(() => {
		if (usersComponent) {
			let userId = cookies.get('currentUserId') || uuid();
			cookies.set('currentUserId', userId, {path: '/', maxAge: 31536000});
			let nameKey = roomRef.key + '_' + userId;
			let name = cookies.get(nameKey);
			if (!name) {
				setOpenDialog({status: true, nameKey: nameKey});
			} else if (!openDialog.status) {
				FirepadUserList.fromDiv(roomRef.child('users'), document.getElementById('userlist'), userId, name, nameKey, setNameFunction);

				roomRef.child('isRunning').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						await setRun(dataSnapshot.val());
					} else {
						await setRun(false);
					}
				});
				roomRef.child('input').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						await setInput(dataSnapshot.val());
					} else {
						await setInput('');
					}
				});
				roomRef.child('language').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						await setLanguage(dataSnapshot.val());
						aceSession?.setMode('ace/mode/' + dataSnapshot.val());
					} else {
						await setLanguage('javascript');
						aceSession?.setMode('ace/mode/javascript');
					}
				});
				roomRef.child('fontSize').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						await setFontSize(dataSnapshot.val());
						editor?.setOptions({
							fontSize: dataSnapshot.val(),
						});
					} else {
						await setFontSize('12px');
						editor?.setOptions({
							fontSize: '12px',
						});
					}
				});
				roomRef.child('theme').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						await setTheme(dataSnapshot.val());
						editor?.setTheme('ace/theme/' + dataSnapshot.val());
					} else {
						await setTheme('textmate');
						editor?.setTheme('ace/theme/textmate');
					}
				});
				roomRef.child('passCode').on('value', snapshot => {
					if (snapshot.val() !== passCode) {
						history.push({
							pathname: '/404',
							state: {
								message: 'session pass code has been changed',
							},
						});
					}
				});
				roomRef.child('currentQuiz').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						setCurrentQuiz(value);
					} else setCurrentQuiz('');
				});
				roomRef.child('candidatePageConfigs').on('value', async function (dataSnapshot) {
					var value = dataSnapshot.val();
					if (value) {
						setPageConfig(value);
					} else setPageConfig();
				});

				var editor = window.ace.edit('firepad-ace-container');
				editor.setTheme('ace/theme/textmate');
				editor.setShowPrintMargin(false);
				var aceSession = editor.getSession();
				aceSession.setUseWrapMode(true);
				aceSession.setUseWorker(true);
				aceSession.setMode('ace/mode/' + language ? language : 'javascript');

				FirePad.fromACE(roomRef, editor, {userId: userId});
			}
		} else setReload(!reload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openDialog.status, usersComponent]);

	useEffect(() => {
		var usersListComponent = document.getElementById('userlist');
		if (usersListComponent) {
			setUsersComponent(usersListComponent);
		}
	}, [reload]);

	return (
		<>
			{openDialog && <CandidateDialog username={username} handleCloseDialog={handleCloseDialog} openDialog={openDialog} setUsername={setUsername} />}
			<Box className={classes.editor} flexDirection='row'>
				<Box display={!isMobile && !isTablet ? 'flex' : 'none'} flexDirection='column'>
					<Box display='flex' justifyContent='center' className={classes.logoBox}>
						<a
							href={window.location.origin}
							onClick={() => {
								ReactGA.event({
									category: 'Candidate action',
									action: 'click on logo and went to homepage',
								});
							}}
						>
							<img src='/images/LinearPad1.png' alt='LinearPad' />
						</a>
					</Box>
					<Box id='userlist' position='relative' height='100%' />
				</Box>
				<Box width='100%'>
					<Allotment>
						{showPadSection && (
							<Allotment.Pane minSize={300} snap preferredSize={700}>
								<Allotment vertical>
									{showPadSection && (
										<Allotment.Pane minSize={700}>
											<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsOptionsSectionBugged(false)} resetKeys={[isOptionsSectionBugged]}>
												<Options user={{uid: sessionId}} isCandidate={true} theme={theme} language={language} fontSize={fontSize} handleChangeFontSize={handleChangeFontSize} handleLanguageChange={handleModeChange} handleThemeChange={handleThemeChange} candidate={true} />
											</ErrorBoundary>
											<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsPadSectionBugged(false)} resetKeys={[isPadSectionBugged]}>
												<Box display='relative' borderTop='1px solid #ddd'>
													<Box minHeight='100vh' id='firepad-ace-container' overflow='auto' />
												</Box>
											</ErrorBoundary>
											<Box position='absolute' top={0} width='100%'>
												<Box position='absolute' top={5} right={2}>
													<IconButton onClick={() => setShowPadSection(false)} size='small'>
														<Close fontSize='small' />
													</IconButton>
												</Box>
											</Box>
										</Allotment.Pane>
									)}
									{pageConfig && pageConfig.IO.value && showPadSection && sections.length > 0 && (
										<Allotment.Pane>
											<Allotment>
												{sections.map((section, index) =>
													section === 'input' ? (
														<Allotment.Pane snap key={index}>
															<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsInputSectionBugged(false)} resetKeys={[isInputSectionBugged]}>
																<InputSection input={input} handleChangeInput={handleChangeInput} />
															</ErrorBoundary>
															<Box position='absolute' top={0} width='100%'>
																<Box position='absolute' top={0} right={2}>
																	<IconButton
																		onClick={() =>
																			setSections(sections => {
																				const newSections = [...sections];
																				newSections.splice(index, 1);
																				return newSections;
																			})
																		}
																		size='small'
																	>
																		<Close fontSize='small' />
																	</IconButton>
																</Box>
															</Box>
														</Allotment.Pane>
													) : (
														section === 'output' && (
															<Allotment.Pane snap key={index}>
																<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsOutputSectionBugged(false)} resetKeys={[isOutputSectionBugged]}>
																	<OutputSection input={input} language={language} isRunning={run} handleRun={handleRun} />
																</ErrorBoundary>
																<Box position='absolute' top={0} width='100%'>
																	<Box position='absolute' top={0} right={2}>
																		<IconButton
																			onClick={() =>
																				setSections(sections => {
																					const newSections = [...sections];
																					newSections.splice(index, 1);
																					return newSections;
																				})
																			}
																			size='small'
																		>
																			<Close fontSize='small' />
																		</IconButton>
																	</Box>
																</Box>
															</Allotment.Pane>
														)
													)
												)}
											</Allotment>
										</Allotment.Pane>
									)}
								</Allotment>
							</Allotment.Pane>
						)}
						{!isMobile && !isTablet && pageConfig && ((pageConfig.question.value && currentQuiz) || pageConfig.draw.value) && showTabsSection && (
							<Allotment.Pane minSize={550} snap>
								<Box width='100%' height='100%' overflow='visible'>
									<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCandidateTabsBugged(false)} resetKeys={[isCandidateTabsBugged]}>
										<CandidateTabsSection currentQuiz={currentQuiz} roomId={sessionId} pageConfig={pageConfig} />
									</ErrorBoundary>
								</Box>
								<Box position='absolute' top={0} width='100%'>
									<Box position='absolute' top={5} right={2}>
										<IconButton onClick={() => setShowTabsSection(false)} size='small'>
											<Close fontSize='small' />
										</IconButton>
									</Box>
								</Box>
							</Allotment.Pane>
						)}
					</Allotment>
				</Box>
			</Box>
		</>
	);
}

export default CandidatePad;
