import {Box} from '@mui/system';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';

export default function UsersListComponent({setIsConnectedUsersSectionBugged, isConnectedUsersSectionBugged}) {
	return (
		<>
			<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsConnectedUsersSectionBugged(false)} resetKeys={[isConnectedUsersSectionBugged]}>
				<Box id='userlist' position='relative' height={250} />
			</ErrorBoundary>
		</>
	);
}
