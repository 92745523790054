import {Button, Card, CardContent, ClickAwayListener, Grid, Typography} from '@mui/material';
import {useState} from 'react';
import {languages} from '../../util/languages';
import {Arrow, Popper} from '../common/PopperFragments';

export default function LanguagesPopper({placement, arrow, anchor, onClose, onChange}) {
	const [arrowRef, setArrowRef] = useState(null);

	const handleLanguageClick = lang => {
		onChange(lang);
		onClose();
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card elevation={5} sx={{width: 500}}>
					<CardContent sx={{p: 2}}>
						<Grid container direction='row' sx={{gap: 1}} justifyContent='center'>
							{languages.map((language, index) => (
								<Grid item xs={3.8} Key={index}>
									<Button fullWidth sx={{textTransform: 'none', display: 'flex', gap: 0.5, outline: 'none', alignItems: 'center', justifyContent: 'flex-start'}} color='inherit' onClick={() => handleLanguageClick(language.value)}>
										{language.icon && <img src={language.icon} alt={language.value} width={25} height={25} />}
										<Typography fontFamily='Fredoka' fontSize={13}>
											{language.name}
										</Typography>
									</Button>
								</Grid>
							))}
						</Grid>
					</CardContent>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
}
