import React, {useCallback, useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {Alert, Button, Card, CardContent, ClickAwayListener, Divider, IconButton, InputBase, Tooltip, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {Arrow, Popper} from '../common/PopperFragments';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useSnackbar} from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import {ContentCopy, Textsms} from '@mui/icons-material';
import {useAuth} from '../../AuthContext/AuthContext';
import Cookies from 'universal-cookie';
import QRCode from 'qrcode';

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 600,
		'&::first-letter': {
			fontSize: '150%',
		},
	},
	content: {
		fontFamily: 'Times New Roman',
	},
	card: {
		position: 'relative',
		borderRadius: '15px !important',
	},
	cardHeader: {
		paddingBottom: '0px !important',
	},
	cardContent: {
		paddingTop: '0px !important',
	},
	button: {
		textTransform: 'none !important',
		fontSize: '13px !important',
		boxShadow: '-1px -1px 5px 1px grey',
		borderRadius: '10px',
		width: '100px !important',
		height: 100,
	},
	tooltip: {
		zIndex: '10000 !important',
	},
}));

export default function SharePadPopper({onClose, open, anchor, placement, arrow}) {
	const classes = useStyles();
	const cookies = new Cookies();

	const [arrowRef, setArrowRef] = React.useState(null);
	const [copied, setCopied] = React.useState(false);
	const [qr, setQr] = React.useState('');
	const [error, setError] = React.useState('');
	const {loggedInUser} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const url = window?.location?.origin + '/LinearPad/#room=' + loggedInUser.uid + '&passCode=' + cookies.get('passCode');

	const handleNotIntegrated = () => {
		enqueueSnackbar('under development', {variant: 'info'});
	};

	const handleCopy = () => {
		enqueueSnackbar('Copied', {variant: 'success'});
		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	const GenerateQRCode = useCallback(() => {
		QRCode.toDataURL(
			url,
			{
				errorCorrectionLevel: 'H',
				margin: 2,
				color: {
					dark: '#1776D2',
					light: '#FFF',
				},
			},
			(err, url) => {
				if (err) {
					setError(err);
				} else {
					setError('');
					setQr(url);
				}
			}
		);
	}, [url]);

	useEffect(() => {
		GenerateQRCode();
	});

	return (
		<>
			{open && (
				<ClickAwayListener
					onClickAway={e => {
						e.stopPropagation(false);
						onClose();
					}}
				>
					<Popper
						open={true}
						anchorEl={anchor}
						placement={placement}
						modifiers={[
							{
								name: 'arrow',
								enabled: arrow,
								options: {
									element: arrowRef,
								},
							},
						]}
					>
						{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
						<Card elevation={5} className={classes.card}>
							<Typography component='h3' variant='h6' align='center' fontFamily='Fredoka' pt={1} pb={1} bgcolor='#1776D2' width='100%' gutterBottom color='#fff'>
								Share pad link
							</Typography>
							<CardContent sx={{p: 1}}>
								<Box display='flex' alignItems='center' justifyContent='space-between' gap={1}>
									<Box border='1px solid #aaa' width='100%' borderRadius={1}>
										<InputBase sx={{ml: 1, flex: 1}} value={window?.location?.origin + '/LinearPad/#room=' + loggedInUser.uid + '&passCode=' + cookies.get('passCode')} onChange={() => null} inputProps={{'aria-label': 'search google maps'}} />
									</Box>
									<CopyToClipboard text={window?.location?.origin + '/LinearPad/#room=' + loggedInUser.uid + '&passCode=' + cookies.get('passCode')} onCopy={handleCopy}>
										<Tooltip title={copied ? 'Copied' : 'Copy'} arrow={true} placement='top' classes={{popper: classes.tooltip}}>
											<IconButton size='small' sx={{border: 'none !important'}}>
												{copied ? <CheckCircleIcon color='success' fontSize='small' /> : <ContentCopy fontSize='small' />}
											</IconButton>
										</Tooltip>
									</CopyToClipboard>
								</Box>
								<Box width='100%' display='flex' flexDirection='column' alignItems='center' mt={1}>
									{qr ? (
										<img src={qr} alt='' width='75%' height='75%' />
									) : (
										error && (
											<Box>
												<Alert severity='error'>Something went wrong</Alert>
												<Typography color='error' component='pre'>
													{error}
												</Typography>
											</Box>
										)
									)}
								</Box>
								<Box display='flex' justifyContent='space-between' alignItems='center' mb={-2} mt={1}>
									<Button size='small' startIcon={<EmailIcon />} sx={{textTransform: 'none'}} onClick={handleNotIntegrated}>
										Send email
									</Button>
									<Divider orientation='vertical' flexItem />
									<Button size='small' startIcon={<Textsms />} sx={{textTransform: 'none'}} onClick={handleNotIntegrated}>
										Send text
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Popper>
				</ClickAwayListener>
			)}
		</>
	);
}
