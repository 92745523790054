/* eslint-disable no-dupe-keys */
import {Add} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import React, {useRef, useState} from 'react';
import uuid from 'react-uuid';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Resizable} from 're-resizable';
import {debounce} from 'lodash';
import {makeStyles} from '@mui/styles';
import './Notes.css';

const CORLORS = ['#FF6347', '#FFE1B4', '#FFF9D5', '#2CCF4F', '#A5DEC8', '#CBF5E4', '#ECFAF5', '#FFF'];

const DateFormatter = timestamp => {
	var fullDate = new Date(timestamp);
	var date = fullDate.getFullYear() + '-' + String(fullDate.getMonth() + 1).padStart(2, '0') + '-' + String(fullDate.getDate()).padStart(2, '0');
	var time = String(fullDate.getHours()).padStart(2, '0') + ':' + String(fullDate.getMinutes()).padStart(2, '0') + ':' + String(fullDate.getSeconds()).padStart(2, '0');
	var dateTime = date + ' ' + time;

	return <span>{dateTime}</span>;
};

const useStyles = makeStyles(() => ({
	note: {
		'&:hover': {
			cursor: '-webkit-grab',
			cursor: '-moz-grab',
			cursor: '-o-grab',
			cursor: '-ms-grab',
			cursor: 'grab',
		},
	},
	draggingNote: {
		cursor: '-webkit-grabbing',
		cursor: '-moz-grabbing',
		cursor: '-o-grabbing',
		cursor: '-ms-grabbing',
		cursor: 'grabbing',
	},
}));

const Notes = ({notes, setNotes, editable, handleSave}) => {
	const classes = useStyles();

	const [dragging, setDragging] = useState({index: '', key: '', x: 0, y: 0});
	const [input, setInput] = useState('');
	const [hovered, setHovered] = useState(0);
	const [editTitleMode, setEditTitleMode] = useState({key: '', w: 0, h: 0});
	const [editContentMode, setEditContentMode] = useState({key: '', w: 0, h: 0});
	const noteId = uuid();

	const handleSaveWithDebounce = useRef(
		debounce(newNotes => {
			editable && handleSave(newNotes);
		}, 4000)
	).current;

	const add = () => {
		let notesTemp = [...notes];
		notesTemp.push({
			id: noteId,
			title: 'Title',
			content: 'content here',
			date: new Date(),
			x: Math.floor(Math.random() * (200 - 80) + 80),
			y: Math.floor(Math.random() * (200 - 80) + 80),
			h: 220,
			w: 180,
			c: 7,
		});
		setNotes(notesTemp);
		handleSaveWithDebounce(notesTemp);
	};
	const update = (index, note, date = true) => {
		let notesTemp = [...notes];
		notesTemp[index] = date ? {...note, date: new Date()} : note;

		setNotes(notesTemp);
		handleSaveWithDebounce(notesTemp);
	};

	const remove = index => {
		let notesTemp = [...notes];
		notesTemp.splice(index, 1);
		setNotes(notesTemp);
		handleSaveWithDebounce(notesTemp);
	};
	React.useEffect(() => {
		return () => {
			handleSaveWithDebounce.cancel();
		};
	}, [handleSaveWithDebounce]);

	if (notes?.length === 0) {
		add();
	}

	return (
		<Box
			className='Board'
			onDragOver={e => {
				e.preventDefault();
			}}
			onDrop={e => {
				if (!dragging || !notes) return;
				update(
					dragging.index,
					{
						...notes[dragging.index],
						x: e.clientX - dragging.x,
						y: e.clientY - dragging.y,
					},
					false
				);
				setDragging({index: '', key: '', x: 0, y: 0});
			}}
		>
			{notes.map((note, index) => (
				<Box key={index} position='relative'>
					<Resizable
						enable={{
							top: false,
							right: false,
							bottom: false,
							left: false,
							topRight: false,
							bottomRight: editable ? true : false,
							bottomLeft: false,
							topLeft: false,
						}}
						defaultSize={{
							width: note.w || 180,
							height: note.h || 220,
						}}
						minWidth={180}
						minHeight={220}
						style={{
							position: 'absolute',
							left: `${note.x}px`,
							top: `${note.y}px`,
							background: CORLORS[note.c],
						}}
						className={[editable && (dragging.index === index ? classes.draggingNote : classes.note), 'Card'].join(' ')}
						onResize={e => {
							e.preventDefault();
						}}
						onResizeStop={(e, direction, ref, d) => {
							update(
								index,
								{
									...notes[index],
									h: notes[index].h + d.height,
									w: notes[index].w + d.width,
								},
								false
							);
						}}
						onDragStart={
							editable
								? e => {
										setDragging({
											index: index,
											x: e.clientX - note.x,
											y: e.clientY - note.y,
										});
								  }
								: () => null
						}
						draggable={editable ? true : false}
						handleComponent={{
							bottomRight: hovered === index + 1 && editable && (
								<Box marginTop='-5px' marginLeft='-3px'>
									<FilterListIcon sx={{fontSize: 16, transform: 'rotate(-45deg)'}} />
								</Box>
							),
						}}
						onMouseEnter={editable ? () => setHovered(index + 1) : () => null}
						onMouseLeave={editable ? () => setHovered(0) : () => null}
					>
						{editable && (
							<Box className='addButton'>
								<Add sx={{cursor: 'pointer', fontSize: 25}} onClick={add} />
							</Box>
						)}
						{editable && editTitleMode.key === note.id ? (
							<input
								className='EditableTitle'
								style={{width: editTitleMode.w, height: editTitleMode.h}}
								onChange={e => setInput(e.target.value)}
								defaultValue={note.title}
								autoFocus
								onFocus={e => e.target.select()}
								onBlur={() => {
									setInput('');
									setEditTitleMode({key: '', w: 0, h: 0});
									input && update(index, {...note, title: input});
								}}
							/>
						) : (
							<pre
								className='Title'
								onClick={e =>
									setEditTitleMode({
										key: note.id,
										w: e.currentTarget.clientWidth,
										h: e.currentTarget.clientHeight,
									})
								}
							>
								{note.title}
							</pre>
						)}
						{notes?.length > 1 && <CloseIcon className='DeleteBtn' sx={{fontSize: 10}} onClick={() => remove(index)} />}
						{editable && (
							<Box className='ColorSelector'>
								{CORLORS.map((c, i) => (
									<Box
										key={c}
										className='ColorCircle'
										onClick={() => {
											update(index, {...note, c: i}, false);
										}}
										style={{background: c}}
									/>
								))}
							</Box>
						)}
						{editable && editContentMode.key === note.id ? (
							<textarea
								className='EditableText'
								style={{width: editContentMode.w}}
								onChange={e => setInput(e.target.value)}
								defaultValue={note.content}
								autoFocus
								onFocus={e => e.target.select()}
								onBlur={() => {
									setInput('');
									setEditContentMode({key: '', w: 0, h: 0});
									input && update(index, {...note, content: input});
								}}
							/>
						) : (
							<Typography
								className='Content'
								noWrap={false}
								onClick={e =>
									setEditContentMode({
										key: note.id,
										w: e.currentTarget.clientWidth,
										h: e.currentTarget.clientHeight,
									})
								}
							>
								{note.content}
							</Typography>
						)}
						<Box className='date'>{DateFormatter(note.date ? note.date : new Date())}</Box>
					</Resizable>
				</Box>
			))}
		</Box>
	);
};

export default Notes;
