import {Assignment, Close, Delete} from '@mui/icons-material';
import {Avatar, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Container, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableCell, TableRow, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../AuthContext/AuthContext';
import Validator from 'validator';
import {stringToColor} from '../../util/commonFunctions';
import {connect} from 'react-redux';
import UserSearchBox from '../Fragments/UserSearchBox';

function ResourceShare({candidate, question, isCandidate, isQuestion, onApplyFetchCandidates, candidatesFetch, onApplyFetchQuestions, questionsFetch, handleClose, isUserCandidate, isUserQuestion, fetchSharedQuestions}) {
	const {enqueueSnackbar} = useSnackbar();
	const {token, loggedInUser} = useAuth();

	const [{data: permissions}] = useAxios({
		url: Env().url + '/configurations/83f3d639-9103-4d51-9a14-9ac7ea420207',
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [{data: usersSharesResource, loading}, fetchUsersSharesResource] = useAxios({
		url: Env().url + '/usersaccess/rbac/shareResource/' + (isCandidate ? candidate : isQuestion && question).id,
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});
	const [, deleteShareUser] = useAxios({
		method: 'DELETE',
		headers: {Authorization: 'Bearer ' + token},
	});

	const [{loading: addingLoad}, addShareResource] = useAxios(
		{
			url: Env().url + '/usersaccess/rbac/shareResource',
			method: 'POST',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: true}
	);

	const [selectedUser, setSelectedUser] = useState('');
	const [selectedRole, setSelectedRole] = useState('');
	const [currentDeleting, setCurrentDeleting] = useState(0);

	const handleSelectUsers = (e, selectedValue) => {
		if ((selectedValue && Validator.isEmail(selectedValue)) || !selectedValue) {
			setSelectedUser(selectedValue);
		} else {
			enqueueSnackbar('invalid email address', {variant: 'error'});
		}
	};
	const handleSelectedUserRole = e => {
		setSelectedRole(e.target.value);
	};
	const handleShareResource = () => {
		addShareResource({data: {userId: selectedUser, resourceType: isCandidate ? 'CANDIDATES' : isQuestion && 'QUESTIONS', resourceId: (isCandidate ? candidate : isQuestion && question).id, role: selectedRole}})
			.then(() => {
				fetchUsersSharesResource();
				if (isCandidate) {
					onApplyFetchCandidates(!candidatesFetch);
				} else if (isQuestion) {
					onApplyFetchQuestions(!questionsFetch);
					fetchSharedQuestions();
				}
			})
			.catch(err => {
				enqueueSnackbar(err.response.data.message, {variant: 'error'});
			});
	};
	const handleDeleteShareUser = (id, index) => {
		setCurrentDeleting(index + 1);
		deleteShareUser({url: Env().url + '/usersaccess/rbac/deleteResource/' + id})
			.then(() => {
				setCurrentDeleting(0);
				fetchUsersSharesResource();
				if (isCandidate) {
					onApplyFetchCandidates(!candidatesFetch);
				} else if (isQuestion) {
					onApplyFetchQuestions(!questionsFetch);
					fetchSharedQuestions();
				}
			})
			.catch(err => {
				setCurrentDeleting(0);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	return (
		<Card sx={{position: 'relative'}}>
			<IconButton onClick={handleClose} size='small' sx={{border: 'none !important', position: 'absolute', right: 5, top: 5}}>
				<Close />
			</IconButton>
			<CardHeader
				sx={{paddingTop: 1, paddingBottom: 1}}
				title={
					<Box width='100%' display='flex' flexDirection='column' alignItems='center'>
						<Typography component='span' variant='h6' fontWeight={600} fontFamily='Fredoka'>
							Share {isCandidate ? 'Candidate' : isQuestion ? 'Question' : 'Resource'}
						</Typography>
						<Box display='flex' alignItems='center' color='#aaa'>
							{isCandidate ? <AccountBoxIcon sx={{fontSize: 15}} /> : isQuestion && <Assignment sx={{fontSize: 15}} />}
							<Typography fontSize={13}>{isCandidate ? candidate.name : isQuestion && question.title}</Typography>
						</Box>
					</Box>
				}
			/>
			<Divider />
			<CardContent sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Box display='flex' gap={1} alignItems='flex-start'>
					<UserSearchBox share={true} handleSelectUsers={handleSelectUsers} selectedUser={selectedUser} />
					<FormControl size='small' variant='outlined' sx={{width: '30%'}}>
						<InputLabel>Role</InputLabel>

						<Select value={selectedRole} onChange={handleSelectedUserRole} label='Role'>
							<MenuItem value=''>None</MenuItem>
							{permissions &&
								permissions.config &&
								Object.keys(JSON.parse(permissions.config)?._roles).map((role, index) => (
									<MenuItem value={role} key={index}>
										{role}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<Button
						startIcon={addingLoad && <CircularProgress size={20} sx={{color: '#fff'}} />}
						variant='contained'
						onClick={handleShareResource}
						sx={{width: 120, borderRadius: 5, background: (!selectedUser || !selectedRole || addingLoad) && '#1776D280 !important', textTransform: 'none', fontFamily: 'Fredoka'}}
						disabled={!selectedUser || !selectedRole || addingLoad}
					>
						Share
					</Button>
				</Box>
				{usersSharesResource?.length > 0 ? (
					<Box>
						<CardHeader
							title={
								<Typography variant='h6' component='span'>
									Already shared with
								</Typography>
							}
						/>
						<Container>
							<Table sx={{overflow: 'auto'}}>
								{isUserCandidate || isUserQuestion ? (
									<TableRow sx={{alignItems: 'center'}}>
										<TableCell sx={{border: 'none', padding: 0.5}}>
											<Box display='flex' alignItems='center'>
												<Avatar variant='rounded' sx={{background: stringToColor(loggedInUser.email.split('@')[0]), width: 35, height: 35, marginRight: 1}}>
													{loggedInUser.email.toUpperCase().charAt(0)}
												</Avatar>
												<Typography>{loggedInUser.email}</Typography>
											</Box>
										</TableCell>
										<TableCell sx={{border: 'none', padding: 0.5}} align='center'>
											<Chip label='Owner' color='primary' />
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
								{usersSharesResource.map((user, index) => (
									<TableRow key={user.id} sx={{alignItems: 'center'}}>
										<TableCell sx={{border: 'none', padding: 0.5}}>
											<Box display='flex' alignItems='center'>
												<Avatar variant='rounded' sx={{background: stringToColor(user.email.split('@')[0]), width: 35, height: 35, marginRight: 1}}>
													{user.email.toUpperCase().charAt(0)}
												</Avatar>
												<Typography>{user.email}</Typography>
											</Box>
										</TableCell>
										<TableCell sx={{border: 'none', padding: 0.5}} align='center'>
											<Chip label={user.role} color='primary' />
										</TableCell>
										<TableCell sx={{border: 'none', padding: 0.5, verticalAlign: 'bottom'}}>{currentDeleting === index + 1 ? <CircularProgress size={24} /> : <Delete sx={{cursor: 'pointer'}} htmlColor='#d11a2a' onClick={() => handleDeleteShareUser(user.id, index)} />}</TableCell>
									</TableRow>
								))}
							</Table>
						</Container>
					</Box>
				) : (
					loading && (
						<Box display='flex' justifyContent='center'>
							<CircularProgress />
						</Box>
					)
				)}
			</CardContent>
		</Card>
	);
}

const mapDispatchToProps = dispatch => ({
	onApplyFetchCandidates: candidatesFetch => dispatch({type: 'CANDIDATES_FETCH', candidatesFetch}),
	onApplyFetchQuestions: questionsFetch => dispatch({type: 'QUESTIONS_FETCH', questionsFetch}),
});
const mapStateToProps = state => ({
	questionsFetch: state.questionsFetchState.questionsFetch,
	candidatesFetch: state.candidatesFetchState.candidatesFetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceShare);
