import {Card, CircularProgress, ClickAwayListener, List, ListItemButton, Typography} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useAuth} from '../../../AuthContext/AuthContext';
import Env from '../../../util/Env';
import {Arrow, Popper} from '../../common/PopperFragments';
import {Delete} from '@mui/icons-material';

export default function OptionsPopper({event, handleClose, anchor, placement, arrow, fetchReservations}) {
	const {enqueueSnackbar} = useSnackbar();
	const {token} = useAuth();
	const [arrowRef, setArrowRef] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleDeleteReservation = () => {
		setLoading(true);
		axios
			.delete(Env().url + '/userschedule/' + event.id, {headers: {Authorization: 'Bearer ' + token}})
			.then(() => {
				enqueueSnackbar('Deleted!', {variant: 'success'});
				setLoading(false);
				fetchReservations();
				handleClose();
			})
			.catch(err => {
				enqueueSnackbar("Couldn't delete reservation, try again!", {variant: 'error'});
				setLoading(false);
			});
	};

	return (
		<ClickAwayListener
			onClickAway={e => {
				e.stopPropagation(false);
				handleClose();
			}}
		>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card>
					<List sx={{width: 120}}>
						<ListItemButton dense sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={handleDeleteReservation}>
							<Typography>Delete</Typography>
							{loading ? <CircularProgress size={20} /> : <Delete fontSize='small' color='error' />}
						</ListItemButton>
					</List>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
}
