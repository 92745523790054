import {
  BorderColor,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  Subscript,
  Superscript,
} from "@mui/icons-material";
import { Button, ButtonGroup, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BubbleMenu as Bubble } from "@tiptap/react";

const useStyles = makeStyles(() => ({
  bubblePaper: {
    background: "#fff",
  },
}));
export const BubbleMenu = ({ editor }) => {
  const classes = useStyles();

  return (
    <Bubble editor={editor} tippyOptions={{ duration: 100 }}>
      <ButtonGroup size="small" className={classes.bubblePaper}>
        <Button
          size="small"
          disableRipple={true}
          variant={editor.isActive("bold") ? "contained" : "outlined"}
          onClick={() => {
            editor.chain().focus().toggleBold().run();
          }}
        >
          <b>B</b>
        </Button>

        <Button
          variant={editor.isActive("italic") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleItalic().run();
          }}
        >
          <em>I</em>
        </Button>

        <Button
          variant={editor.isActive("underline") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleUnderline().run();
          }}
        >
          <span style={{ textDecoration: "underline" }}>U</span>
        </Button>
        <Button
          variant={editor.isActive("strike") ? "contained" : "outlined"}
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().toggleStrike().run();
          }}
        >
          <span style={{ textDecoration: "line-through" }}>S</span>
        </Button>
        <Button
          variant={editor.isActive("superscript") ? "contained" : "outlined"}
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          className={editor.isActive("superscript") ? "is-active" : ""}
        >
          <Superscript />
        </Button>
        <Button
          variant={editor.isActive("subscript") ? "contained" : "outlined"}
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={editor.isActive("subscript") ? "is-active" : ""}
        >
          <Subscript />
        </Button>
        <Button
          size="small"
          disableRipple={true}
          variant={
            editor.isActive({ textAlign: "left" }) ? "contained" : "outlined"
          }
          onClick={() => {
            editor.chain().focus().setTextAlign("left").run();
          }}
        >
          <FormatAlignLeft fontSize="small" />
        </Button>
        <Button
          variant={
            editor.isActive({ textAlign: "center" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("center").run();
          }}
        >
          <FormatAlignCenter fontSize="small" />
        </Button>

        <Button
          variant={
            editor.isActive({ textAlign: "right" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("right").run();
          }}
        >
          <FormatAlignRight fontSize="small" />
        </Button>
        <Button
          variant={
            editor.isActive({ textAlign: "justify" }) ? "contained" : "outlined"
          }
          disableRipple={true}
          onClick={() => {
            editor.chain().focus().setTextAlign("justify").run();
          }}
        >
          <FormatAlignJustify fontSize="small" />
        </Button>
        <Button>
          <Input
            disableUnderline={true}
            sx={{
              width: 20,
            }}
            inputProps={{ style: { border: "none !important" } }}
            type="color"
            onInput={(event) =>
              editor.chain().focus().setColor(event.target.value).run()
            }
            value={editor.getAttributes("textStyle").color || "#000000"}
          />
        </Button>
        <Button
          variant={editor.isActive("highlight") ? "contained" : "outlined"}
          onClick={() => editor.chain().focus().toggleHighlight().run()}
        >
          <BorderColor fontSize="small" />
        </Button>
      </ButtonGroup>
    </Bubble>
  );
};
