import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, CircularProgress, ClickAwayListener, TextField} from '@mui/material';
import {Arrow, Popper} from '../../../common/PopperFragments';

export default function NewCandidatePopper({onCancel, onConfirm, onClose, anchor, placement, arrow, confirm, cancel, loading}) {
	const [arrowRef, setArrowRef] = React.useState(null);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState(false);

	const handleChangeName = event => {
		if (event.target.value) {
			setNameError(false);
		} else {
			setNameError(true);
		}
		setName(event.target.value);
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popper
				open={true}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Card elevation={5}>
					<CardContent>
						<TextField size='small' value={name} variant='outlined' label='Name' placeholder='Candidate Name' onChange={handleChangeName} error={nameError} />
					</CardContent>
					<CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button color='error' size='small' variant='outlined' onClick={onCancel}>
							{cancel}
						</Button>
						<Button color='primary' size='small' variant='contained' sx={{width: 100}} startIcon={loading ? <CircularProgress size={20} sx={{color: '#fff'}} /> : ''} onClick={() => onConfirm(name)} disabled={nameError || !name}>
							{confirm}
						</Button>
					</CardActions>
				</Card>
			</Popper>
		</ClickAwayListener>
	);
}
