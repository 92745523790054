import {DateRange, Public} from '@mui/icons-material';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GetFromToString} from '../../../util/commonFunctions';
import {TextEditor} from '../../Tiptap/Editor';

export default function EventPopperContent({event}) {
	const content = JSON.parse(event.reservation);
	const timeZone = JSON.parse(event.timeZone);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const duration = Math.ceil((new Date(event.endTime) - new Date(event.startTime)) / (1000 * 60));
	return (
		<Box mt={-2}>
			<Typography align='center' variant='h6' fontFamily='Fredoka' fontWeight={600}>
				{content.name}
			</Typography>
			<Typography align='center' variant='body1' fontFamily='Fredoka' fontSize={12} gutterBottom>
				{content.email}
			</Typography>
			<Box display='flex' gap={1}>
				<DateRange />
				<GetFromToString time={event.startTime} duration={duration} />
			</Box>
			<Box display='flex' gap={1}>
				<Public />
				<Typography fontFamily='Fredoka' fontWeight={600} fontSize={isMobile ? 14 : 15} component='p'>
					{typeof timeZone === 'object' ? timeZone.value : timeZone}
				</Typography>
			</Box>

			{content.description && (
				<>
					<Typography fontWeight={600} fontFamily='Fredoka' mt={1} color='#aaa'>
						Summary
					</Typography>
					<TextEditor notes={content.description} editable={false} />
				</>
			)}
			{content.cv && (
				<Typography fontFamily='Fredoka' color='#aaf'>
					<Typography fontWeight={600} component='strong'>
						•
					</Typography>{' '}
					Cv is attached
				</Typography>
			)}
		</Box>
	);
}
