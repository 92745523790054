import {ArrowBack, Close, Upload} from '@mui/icons-material';
import {Button, CardHeader, CircularProgress, TextField, Typography, useTheme, useMediaQuery, IconButton} from '@mui/material';
import {Box} from '@mui/system';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../AuthContext/AuthContext';
import Env from '../../../util/Env';
import {TextEditor} from '../../Tiptap/Editor';
import ConfirmedDialog from './ConfirmedDialog';
import {convertFromZoneToUTCString} from '../../../util/commonFunctions';

export default function ConfirmationCard({fetchReservations, durations, selectedDuration, currentReservation, setCurrentReservation, setConfirmation, setSelectDurationDisable, buttonColor, borderRadius, timeZone}) {
	const {enqueueSnackbar} = useSnackbar();
	const {loggedInUser, signInAnonymously, token, logout} = useAuth();
	const {userId} = useParams();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [loading, setLoading] = useState(false);
	const [openConfirmedDialog, setOpenConfirmedDialog] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [description, setDescription] = useState('');
	const timeZoneStr = typeof timeZone === 'object' ? timeZone.value : timeZone;
	const [file, setFile] = useState();

	const handleOpenConfirmedDialog = () => {
		handleCancel();
		setOpenConfirmedDialog(true);
	};

	const handleCloseConfirmedDialog = () => {
		setOpenConfirmedDialog(false);
	};

	const handleUploadCvFile = useCallback(event => {
		if (event.target.files[0]) {
			setFile(event.target.files[0]);
		}
	}, []);

	const handleReset = () => {
		setEmail('');
		setName('');
		setDescription('');
		setFile();
		setCurrentReservation('');
	};
	const handleCancel = () => {
		let scheduleComp = document.getElementById('ScheduleComp');
		scheduleComp.style.pointerEvents = '';
		setSelectDurationDisable(false);
		setConfirmation(false);
	};

	const handleConfirm = () => {
		setLoading(true);
		let endTime;
		if (currentReservation.splitTimeslot[1]) {
			endTime = currentReservation.splitTimeslot[1].startTime;
		} else {
			endTime = currentReservation.availableTimeslot.endTime;
		}
		if (file) {
			var formData = new FormData();
			formData.append('file', file);
			axios
				.post(Env().url + '/sketch/uploadCV', formData, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
				})
				.then(res => {
					if (res.data) {
						axios
							.post(Env().url + '/userschedule/reservation', {
								startTime: convertFromZoneToUTCString(currentReservation.startTime, timeZoneStr),
								endTime: convertFromZoneToUTCString(endTime, timeZoneStr),
								userId: userId,
								reservation: JSON.stringify({email, name, description, cv: res.data.id}),
								timeZone: JSON.stringify({value: timeZoneStr}),
							})
							.then(res => {
								loggedInUser.isAnonymous && logout();
								setLoading(false);
								handleReset();
								handleCancel();
								handleOpenConfirmedDialog();
							})
							.catch(() => {
								setLoading(false);
								enqueueSnackbar('failed! try again later', {variant: 'error'});
							});
					}
				})
				.catch(err => {
					enqueueSnackbar("couldn't upload cv", {variant: 'error'});
					enqueueSnackbar('you can clear cv and save your reservation', {variant: 'warning'});
					setLoading(false);
				});
		} else {
			axios
				.post(Env().url + '/userschedule/reservation', {
					startTime: convertFromZoneToUTCString(currentReservation.startTime, timeZoneStr),
					endTime: convertFromZoneToUTCString(endTime, timeZoneStr),
					userId: userId,
					reservation: JSON.stringify({email, name, description}),
					timeZone: JSON.stringify({value: timeZoneStr}),
				})
				.then(() => {
					setLoading(false);
					handleReset();
					handleCancel();
					handleOpenConfirmedDialog();
					loggedInUser.isAnonymous && logout();
				})
				.catch(() => {
					setLoading(false);
					enqueueSnackbar('failed! try again later', {variant: 'error'});
				});
		}
	};

	useEffect(() => {
		!loggedInUser &&
			signInAnonymously().then(user => {
				console.log(user);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ConfirmedDialog fetchReservations={fetchReservations} handleClose={handleCloseConfirmedDialog} openConfirmedDialog={openConfirmedDialog} />
			{durations && (
				<Box display='flex' alignItems='flex-start' flexWrap='wrap'>
					<Box p={isMobile ? '8px 0px' : '0px 8px 8px'} color='#00000080' display='flex' alignItems={'center'} gap={1}>
						<IconButton onClick={handleCancel}>
							<ArrowBack />
						</IconButton>
						<CardHeader
							title={
								<Typography fontFamily='Fredoka' color='#000' fontSize={30} fontWeight={600}>
									{durations[selectedDuration].name + ' '} Meeting
								</Typography>
							}
							sx={{padding: isMobile ? 0 : 0.5}}
						/>
						{/* <Box display='flex' gap={1}>
							<AccessTimeFilled />
							<Typography fontFamily='Fredoka' fontWeight={600} fontSize={isMobile ? 14 : 15}>
								{durations[selectedDuration].value < 60 ? durations[selectedDuration].value : '1'} {durations[selectedDuration].value < 60 ? ' min' : ' hr'}
							</Typography>
						</Box>
						<Box display='flex' gap={1}>
							<DateRange />
							<GetFromToString time={currentReservation.startTime} duration={durations[selectedDuration].value} />
						</Box>
						<Box display='flex' gap={1}>
							<Public />
							<Typography fontFamily='Fredoka' fontWeight={600} fontSize={isMobile ? 14 : 15}>
								(GMT {new Date().getTimezoneOffset() < 0 ? `+${Math.abs(new Date().getTimezoneOffset() / 60)}` : new Date().getTimezoneOffset() > 0 ? `-${Math.abs(new Date().getTimezoneOffset() / 60)}` : ''}) {Intl.DateTimeFormat().resolvedOptions().timeZone}
							</Typography>
						</Box> */}
					</Box>
					<Box display='flex' flexDirection='column' p={1} justifyContent='center' width='100%'>
						<Box display='flex' flexDirection='column' width='100%' flexWrap='wrap' gap={1}>
							<TextField variant='outlined' label='Email*' size='small' value={email} onChange={e => setEmail(e.target.value)} fullWidth />
							<TextField variant='outlined' label='Name*' size='small' value={name} onChange={e => setName(e.target.value)} fullWidth />
						</Box>
						<Typography color='#00000080' mt={1}>
							More info
						</Typography>
						<Box border='1px solid #EEE' bgcolor='#FFF' pl={1} pr={1} pb={1} height={213} maxHeight={213} overflow='auto' borderRadius={2} className='TiptapEditor'>
							<TextEditor notes={description} setNotes={setDescription} editable={true} extensions={['textStyles', 'heading', 'lists', 'other']} />
						</Box>
						<Box mt={1} display='flex' justifyContent={isMobile ? 'center' : 'space-between'} gap={isMobile ? 2 : 0}>
							<Box display='flex' alignItems='center' gap={1}>
								<Button variant='outlined' component='label' size='small' sx={{textTransform: 'none'}} endIcon={!file && <Upload fontSize='small' />}>
									{file ? (
										file.name
									) : (
										<>
											<Typography>File attachment</Typography>
											<input accept='application/pdf' type='file' onChange={handleUploadCvFile} hidden />
										</>
									)}
								</Button>
								{file && (
									<IconButton onClick={() => setFile('')} size='small' color='primary'>
										<Close fontSize='small' />
									</IconButton>
								)}
							</Box>
							<Button
								variant='contained'
								startIcon={loading ? <CircularProgress size={20} sx={{color: '#fff'}} /> : null}
								sx={{textTransform: 'none', borderRadius: borderRadius, background: !email || !name || loading ? `${buttonColor}80 !important` : `${buttonColor} !important`}}
								onClick={handleConfirm}
								disabled={!email || !name || loading}
							>
								Confirm
							</Button>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
}
