const INITIAL_STATE = {
	candidatesFetch: true,
};

const applyFetchCandidates = (state, action) => ({
	...state,
	candidatesFetch: action.candidatesFetch,
});

function candidatesFetchReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CANDIDATES_FETCH': {
			return applyFetchCandidates(state, action);
		}
		default:
			return state;
	}
}

export default candidatesFetchReducer;
