import {Close} from '@mui/icons-material';
import {Divider, Drawer, IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';

const useStyles = makeStyles(() => ({
	drawerPaper: {
		width: '50%',
		borderRadius: '5px 0px 0px 5px',
		marginTop: '45px',
		height: 'calc(100% - 45px) !important',
	},
	drawerMobilePaper: {
		width: '100%',
		marginTop: '45px',
		height: 'calc(100% - 45px) !important',
	},
}));

export default function GenericDrawer({children, position, open, handleClose, title, titleBackground, titleColor, divider}) {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Drawer open={open} onClose={handleClose} variant='temporary' anchor={position} classes={{paper: isMobile ? classes.drawerMobilePaper : classes.drawerPaper}} className={classes.drawer}>
			<Box padding='16px 8px' display='flex' gap={1} alignItems='center' bgcolor={titleBackground ? titleBackground : ''} color={titleColor ? titleColor : ''}>
				<IconButton onClick={handleClose} size='small' sx={{border: 'none !important'}}>
					<Close htmlColor={titleColor ? titleColor : ''} fontSize='small' />
				</IconButton>
				<Typography fontWeight={600} fontSize={18} fontFamily='Fredoka'>
					{title}
				</Typography>
			</Box>
			{divider && <Divider />}
			<Box sx={{overflowY: 'auto'}}>{children}</Box>
		</Drawer>
	);
}
