const firebaseConfig = {
	apiKey: 'AIzaSyCM9uv-f5fiMQLT3OHlfuQri4D2lJz1GCM',
	authDomain: 'linear-pad.firebaseapp.com',
	databaseURL: 'https://linear-pad-default-rtdb.firebaseio.com',
	projectId: 'linear-pad',
	storageBucket: 'linear-pad.appspot.com',
	messagingSenderId: '501126980598',
	appId: '1:501126980598:web:9748b831639b25de22ddd7',
	measurementId: 'G-FLC4G49MYE',
};

export default firebaseConfig;
