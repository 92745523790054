import * as React from 'react';
import Lottie from '../Fragments/Lottie';
import {Button, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

export default function LiveCodePad() {
	const history = useHistory();
	return (
		<React.Fragment>
			<Typography component='h2' variant='h6' color='primary' gutterBottom>
				Live Codepad
			</Typography>
			<Lottie url='https://assets1.lottiefiles.com/private_files/lf30_3rex3dkf.json' width='100%' height='200px' />
			<Button color='primary' onClick={() => history.push('/LinearPad/Ace')} variant='contained' sx={{textTransform: 'none', fontFamily: 'Fredoka'}}>
				Go To Pad
			</Button>
		</React.Fragment>
	);
}
