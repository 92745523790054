import {IconButton, ListItemButton} from '@mui/material';
import {Box} from '@mui/system';
import axios from 'axios';
import {memo, useCallback, useEffect, useState} from 'react';
import {useAuth} from '../../../AuthContext/AuthContext';
import {useRealtime} from '../../../RealtimeContext/RealtimeContext';
import Env from '../../../util/Env';
import firebase from 'firebase';
import {Stop} from '@mui/icons-material';
import RecommendationPopper from './RecommendationPopper';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import CandidatePageConfigPopper from './CandidatePageConfigPopper';

function CurrentCandidateSideSection({currentCandidateId, candidatesFetch, onApplyFetchCandidates}) {
	const [candidate, setCandidate] = useState('');
	const {loggedInUser, token} = useAuth();
	const {realtime, setRealtime} = useRealtime();
	const {enqueueSnackbar} = useSnackbar();

	const [openRecommendationPopper, setOpenRecommendationPopper] = useState(false);
	const [anchorRecommendationPopper, setAnchorRecommendationPopper] = useState('');
	const [openCandidatePageConfigPopper, setOpenCandidatePageConfigPopper] = useState(false);
	const [anchorCandidatePageConfigPopper, setAnchorCandidatePageConfigPopper] = useState('');
	const [endInterviewLoading, setEndInterviewLoading] = useState(false);
	const [notes, setNotes] = useState('');

	var ref = firebase.app('linearPad').database().ref();
	var roomRef = ref.child(loggedInUser.uid);

	const handleCandidateClick = useCallback(
		e => {
			setRealtime({...realtime, candidateClicked: true});
			setTimeout(() => {
				setRealtime({...realtime, candidateClicked: false});
			}, 1);
			handleOpenCandidatePageConfigPopper(e);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[realtime]
	);

	const handleOpenRecommendationPopper = e => {
		e.stopPropagation();
		setAnchorRecommendationPopper(e.currentTarget);
		setOpenRecommendationPopper(true);
	};

	const handleOpenCandidatePageConfigPopper = e => {
		e.stopPropagation();
		setAnchorCandidatePageConfigPopper(e.currentTarget);
		setOpenCandidatePageConfigPopper(true);
	};

	const handleCloseRecommendationPopper = () => {
		setOpenRecommendationPopper(false);
		setAnchorRecommendationPopper('');
	};
	const handleCloseCandidatePageConfigPopper = () => {
		setOpenCandidatePageConfigPopper(false);
		setAnchorCandidatePageConfigPopper('');
	};

	const handleEndInterview = () => {
		setEndInterviewLoading(true);
		let newNotes = [];
		candidate.stickyNotes &&
			Array.isArray(candidate.stickyNotes) &&
			candidate.stickyNotes.forEach(note => {
				let {editorState, ...pureNote} = note;
				newNotes.push(pureNote);
			});
		axios
			.post(Env().url + '/pad', {...candidate, status: 'Conducted', notes: JSON.stringify(notes), stickyNotes: JSON.stringify(newNotes), config: JSON.stringify(candidate.config)}, {headers: {Authorization: 'Bearer ' + token}})
			.then(() => {
				setEndInterviewLoading(false);
				enqueueSnackbar('interview ended!', {
					variant: 'success',
				});
				roomRef.child('currentCandidateId').set('');
				setRealtime({...realtime, currentCandidateId: '', currentCandidate: ''});
				handleCloseRecommendationPopper();
				onApplyFetchCandidates(!candidatesFetch);
			})
			.catch(err => {
				setEndInterviewLoading(false);
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	useEffect(() => {
		if (currentCandidateId) {
			axios
				.get(Env().url + '/pad/' + currentCandidateId, {
					headers: {Authorization: 'Bearer ' + token},
				})
				.then(res => {
					if (res.data) {
						setCandidate(res.data);
						setRealtime(realtime => ({...realtime, currentCandidate: res.data}));
					}
				});
		} else {
			setCandidate('');
		}
	}, [currentCandidateId, token, setRealtime]);

	return candidate ? (
		<>
			{openCandidatePageConfigPopper && <CandidatePageConfigPopper onCancel={handleCloseCandidatePageConfigPopper} onClose={handleCloseCandidatePageConfigPopper} anchor={anchorCandidatePageConfigPopper} arrow={true} placement='right' />}
			{openRecommendationPopper && (
				<RecommendationPopper
					notes={notes}
					setNotes={setNotes}
					onCancel={handleCloseRecommendationPopper}
					onClose={handleCloseRecommendationPopper}
					anchor={anchorRecommendationPopper}
					arrow={true}
					placement='right'
					cancel='Cancel'
					confirm='End Interview'
					onConfirm={handleEndInterview}
					loading={endInterviewLoading}
				/>
			)}
			<Box p={1} bgcolor='#fff'>
				<Box display='flex' alignItems='center'>
					<img src='https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media' alt='candidate' title='current candidate' width={25} height={25} />
					<ListItemButton onClick={handleCandidateClick} sx={{display: 'flex', justifyContent: 'space-between'}}>
						{candidate.name}
						<IconButton size='small' color='error' onClick={handleOpenRecommendationPopper}>
							<Stop fontSize='small' />
						</IconButton>
					</ListItemButton>
				</Box>
			</Box>
		</>
	) : null;
}

const mapDispatchToProps = dispatch => ({
	onApplyFetchCandidates: candidatesFetch => dispatch({type: 'CANDIDATES_FETCH', candidatesFetch}),
});
const mapStateToProps = state => ({
	candidatesFetch: state.candidatesFetchState.candidatesFetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CurrentCandidateSideSection));
