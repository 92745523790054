import {Button, Card, Container, Grid, Typography, useMediaQuery} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import {Box} from '@mui/system';
import {useHistory} from 'react-router-dom';
import Lottie from '../Fragments/Lottie';
import ReactGA from 'react-ga';
import AccessDialog from '../AccessDialog';
import {useCallback, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../AuthContext/AuthContext';
import {useLocation} from 'react-router-dom';
import Footer from '../Footer/Footer';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundary from '../ErrorBoundary/InternalErrorBoudaryPage';

const useStyles = makeStyles(() => ({
	button: {
		textTransform: 'none !important',
	},
	card: {
		padding: 5,
		borderRadius: '20px !important',
		display: 'flex',
		width: '230px',
		flexDirection: 'column',
		alignItems: 'center',
		height: 350,
		justifyContent: 'space-between',
		'&:hover': {
			boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
		},
		transition: '0.1s ease-in-out !important',
	},
}));

export default function Dashboard() {
	const classes = useStyles();
	const theme = useTheme();
	const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));
	const history = useHistory();
	const location = useLocation();
	const {loggedInUser} = useAuth();
	const {enqueueSnackbar} = useSnackbar();

	const [openAccessDialog, setOpenAccessDialog] = useState(false);
	const [isCodePadCardBugged, setIsCodePadCardBugged] = useState(false);
	const [isDocPadCardBugged, setIsDocPadCardBugged] = useState(false);
	const [isAccessDialogBugged, setIsAccessDialogBugged] = useState(false);

	const handleCloseAccessDialog = useCallback(login => {
		setOpenAccessDialog(false);
		if (login) {
			enqueueSnackbar('Logged in successfully!', {variant: 'success'});
			history.push('/Dashboard');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCodePadClick = useCallback(() => {
		ReactGA.event({
			category: 'Code pad',
			action: 'open the code pad',
		});

		if (loggedInUser) {
			setOpenAccessDialog(false);
			history.push('/Dashboard');
		} else {
			enqueueSnackbar('Login to continue', {variant: 'warning'});
			setOpenAccessDialog(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedInUser]);

	useEffect(() => {
		if (location.state?.login) {
			setOpenAccessDialog(true);
			enqueueSnackbar('Login to continue', {variant: 'warning'});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<ErrorBoundary FallbackComponent={InternalErrorBoundary} onReset={() => setIsAccessDialogBugged(false)} resetKeys={[isAccessDialogBugged]}>
				<AccessDialog open={openAccessDialog} handleCloseDialog={handleCloseAccessDialog} />
			</ErrorBoundary>

			<Box bgcolor='#E8EFFF' minHeight='100vh' display='flex' flexDirection='column' gap={2}>
				<Container maxWidth='xl'>
					<Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
						<Grid item lg={7} md={7} xl={7} xs={12} sm={12} sx={{marginTop: 1}}>
							<Box>
								<img src='/images/LinearPad.png' alt='linearpad' height={168.75} width={300} />
							</Box>
							<Typography variant='h3' fontWeight={600} fontFamily='Fredoka' fontSize={isNotMobile ? '2.5vw' : '6vw'} gutterBottom>
								Live editor - useful for coding, running codes, and conducting interviews.
							</Typography>
							<Typography fontWeight={600} fontFamily='Poppins' color='GrayText' fontSize={isNotMobile ? '1vw' : '4vw'}>
								LinearPad offers the ultimate collaboration coding experience.
							</Typography>
							<Box display='flex' flexWrap='wrap' gap={2} mt={4} justifyContent={isNotMobile ? 'flex-start' : 'center'}>
								<ErrorBoundary FallbackComponent={InternalErrorBoundary} onReset={() => setIsDocPadCardBugged(false)} resetKeys={[isDocPadCardBugged]}>
									<Card variant='outlined' className={classes.card}>
										<Box>
											<Box display='flex' justifyContent='center' alignItems='center' gap={1} mt={1.7}>
												<img src='https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fb2adefd7-5462-468a-a218-cdca996fd8a8.svg?generation=1663014897174751&alt=media' alt='' width='70.38px' height='70.38px' />
											</Box>
											<Typography gutterBottom variant='h4' align='center' fontFamily='Fredoka'>
												Simple-01
											</Typography>
											<Typography align='center' color='InactiveCaptionText' gutterBottom>
												Companies interviews tutorial problems, test cases and solutions.
											</Typography>
										</Box>
										<Button LinkComponent='a' variant='contained' className={classes.button} href='https://simple01.linearbits.com/' target='_blank'>
											Open site {' ❯'}
										</Button>
									</Card>
								</ErrorBoundary>
								<ErrorBoundary FallbackComponent={InternalErrorBoundary} onReset={() => setIsCodePadCardBugged(false)} resetKeys={[isCodePadCardBugged]}>
									<Card variant='outlined' className={classes.card}>
										<Box>
											<Box display='flex' justifyContent='center'>
												<Lottie url='https://assets5.lottiefiles.com/private_files/lf30_eo7tdckt.json' width='150px' height='84.38px' />
											</Box>
											<Typography gutterBottom variant='h4' align='center' fontFamily='Fredoka'>
												Code Pad
											</Typography>
											<Typography align='center' color='InactiveCaptionText' gutterBottom>
												You can share this live code editor with your friends.
											</Typography>
										</Box>
										<Box display='flex' flexDirection='column' alignItems='center'>
											<Typography variant='caption' align='center' color='InactiveCaptionText'>
												using
											</Typography>
											<Typography variant='h6' align='center' color='InactiveCaptionText'>
												Ace Editor
											</Typography>
										</Box>
										<Button variant='contained' className={classes.button} onClick={handleCodePadClick}>
											Try now {' ❯'}
										</Button>
									</Card>
								</ErrorBoundary>
							</Box>
						</Grid>
						<Box component={Grid} item md={5} lg={5} xl={5} xs={0} sm={0} display={{xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}>
							<Lottie url='https://assets8.lottiefiles.com/packages/lf20_c8pgmddl.json' />
						</Box>
					</Grid>
				</Container>
				<Footer />
			</Box>
		</>
	);
}
