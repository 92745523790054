import Env from '../../../../util/Env';
import {memo, useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Candidate from './Candidate';
import {useSnackbar} from 'notistack';
import {useAuth} from '../../../../AuthContext/AuthContext';
import {ErrorBoundary} from 'react-error-boundary';
import InternalErrorBoundaryPage from '../../../ErrorBoundary/InternalErrorBoudaryPage';
import useAxios from 'axios-hooks';
import {connect} from 'react-redux';
import {TabContext, TabPanel} from '@mui/lab';
import {StyledTab, StyledTabs} from '../../../common/CustomTabs';
import firebase from 'firebase';
import axios from 'axios';
import ReactGA from 'react-ga';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import CandidatesTable from './CandidatesTable';
import {Box} from '@mui/system';
import {useRealtime} from '../../../../RealtimeContext/RealtimeContext';
import {useAccount} from '../../../../AccountContext/AccountContext';

const useStyles = makeStyles(() => ({
	tabPanel: {
		padding: '5px !important',
	},
}));

function CandidatesTab({candidatesFetch}) {
	const classes = useStyles();
	const {loggedInUser, token} = useAuth();
	const {enqueueSnackbar} = useSnackbar();
	const roomRef = firebase.app('linearPad').database().ref().child(loggedInUser.uid);
	const {realtime, setRealtime} = useRealtime();
	const {planPermissions} = useAccount();

	const [currentCandidate, setCurrentCandidate] = useState('');
	const [isCandidateBugged, setIsCandidateBugged] = useState(false);
	const [isUserCandidatesBugged, setIsUserCandidatesBugged] = useState(false);
	const [isSharedCandidatesBugged, setIsSharedCandidatesBugged] = useState(false);
	const [tabsValue, setTabsValue] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [page, setPage] = useState(0);

	const [{data: candidatesData, loading: candidatesDataLoading, error: candidatesDataFetchError}, fetchCandidatesData] = useAxios({
		url: Env().url + '/pad/myLinearpads?_start=' + page * rowsPerPage + '&_end=' + (page * rowsPerPage + rowsPerPage),
		method: 'GET',
		headers: {Authorization: 'Bearer ' + token},
	});
	const [{data: sharedCandidatesData, loading: sharedCandidatesDataLoading, error: sharedCandidatesDataFetchError}, fetchSharedCandidatesData] = useAxios(
		{
			url: Env().url + '/pad/myShare',
			method: 'GET',
			headers: {Authorization: 'Bearer ' + token},
		},
		{manual: planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.share')) ? false : true}
	);

	const handleDeleteCandidate = useCallback(id => {
		axios
			.delete(Env().url + '/pad/' + id, {
				headers: {Authorization: 'Bearer ' + token},
			})
			.then(res => {
				if (realtime.currentCandidateId === id) {
					roomRef.child('currentCandidateId').set(null);
					setRealtime({...realtime, currentCandidateId: ''});
				}
				fetchCandidatesData();
				setCurrentCandidate('');
				enqueueSnackbar('candidate is deleted!', {variant: 'success'});
			})
			.catch(err => {
				enqueueSnackbar("couldn't delete candidate", {variant: 'error'});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleChangeTab = useCallback((event, newValue) => {
		ReactGA.event({
			category: 'Authorized user action',
			action: 'change tabs between user and shared candidates',
			value: newValue,
		});
		setTabsValue(newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentCandidate) {
			let index = candidatesData?.rows?.findIndex(candidate => candidate.linearpad.id === currentCandidate.id);
			setCurrentCandidate(candidatesData?.rows[index]?.linearpad);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [candidatesData]);

	useEffect(() => {
		fetchCandidatesData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [candidatesFetch]);

	return currentCandidate ? (
		<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCandidateBugged(false)} resetKeys={[isCandidateBugged]}>
			<Candidate setCurrentCandidate={setCurrentCandidate} currentExample={realtime.currentQuiz} userId={loggedInUser.uid} fetchCandidatesData={fetchCandidatesData} currentCandidate={currentCandidate} isUserCandidate={tabsValue === 0 ? true : false} />
		</ErrorBoundary>
	) : (
		<Box width='100%'>
			<TabContext value={tabsValue.toString()}>
				<StyledTabs value={tabsValue} onChange={handleChangeTab} variant='scrollable' scrollButtons='auto'>
					<StyledTab icon={<AssignmentIndIcon color='primary' />} iconPosition='start' label={'Mine'} wrapped />
					{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('linearpad') || planPermissions.permissions.includes('linearpad.share')) && sharedCandidatesData?.rows?.length > 0 && (
						<StyledTab icon={<FolderSharedIcon color='primary' />} iconPosition='start' label={'Shared'} wrapped />
					)}
				</StyledTabs>
				<TabPanel value='0' className={classes.tabPanel}>
					<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsUserCandidatesBugged(false)} resetKeys={[isUserCandidatesBugged]}>
						<CandidatesTable
							page={page}
							setPage={setPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							isUserCandidates={true}
							isSharedCandidates={false}
							userId={loggedInUser.uid}
							fetchCandidatesData={fetchCandidatesData}
							candidatesData={candidatesData}
							candidatesDataLoading={candidatesDataLoading}
							candidatesDataFetchError={candidatesDataFetchError}
							setCurrentCandidate={setCurrentCandidate}
							realtimeCandidateId={realtime.currentCandidateId}
							handleDeleteCandidate={handleDeleteCandidate}
						/>
					</ErrorBoundary>
				</TabPanel>
				{sharedCandidatesData?.rows?.length > 0 && (
					<TabPanel value='1' className={classes.tabPanel}>
						<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsSharedCandidatesBugged(false)} resetKeys={[isSharedCandidatesBugged]}>
							<CandidatesTable
								page={page}
								setPage={setPage}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								isUserCandidates={false}
								isSharedCandidates={true}
								userId={loggedInUser.uid}
								setTabsValue={setTabsValue}
								fetchCandidatesData={fetchSharedCandidatesData}
								candidatesData={sharedCandidatesData}
								candidatesDataLoading={sharedCandidatesDataLoading}
								candidatesDataFetchError={sharedCandidatesDataFetchError}
								setCurrentCandidate={setCurrentCandidate}
								realtimeCandidateId={realtime.currentCandidateId}
								handleDeleteCandidate={handleDeleteCandidate}
							/>
						</ErrorBoundary>
					</TabPanel>
				)}
			</TabContext>
		</Box>
	);
}

const mapStateToProps = state => ({
	candidatesFetch: state.candidatesFetchState.candidatesFetch,
});

export default connect(mapStateToProps, null)(memo(CandidatesTab));
