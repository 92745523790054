const INITIAL_STATE = {
	notifications: 0,
};

const applyIncrementNotifications = state => {
	return {...state, notifications: state.notifications + 1};
};
const applyDecrementNotifications = state => {
	return {...state, notifications: state.notifications - 1};
};

function notificationsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'NOTIFICATIONS_INCREMENT': {
			return applyIncrementNotifications(state);
		}
		case 'NOTIFICATIONS_DECREMENT': {
			return applyDecrementNotifications(state);
		}
		default:
			return state;
	}
}

export default notificationsReducer;
