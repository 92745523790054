import {Close} from '@mui/icons-material';
import {Dialog, IconButton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import Pricing from '../Fragments/Pricing';

const useStyles = makeStyles(() => ({
	paper: {
		minWidth: 320,
	},
}));

export default function PricingDialog({open, handleClose}) {
	const classes = useStyles();
	return (
		<Dialog open={open} maxWidth='xl' sx={{overflow: 'auto'}} scroll='body' classes={{paper: classes.paper}}>
			<Box padding={2} bgcolor='#F5F5F5' position='relative'>
				<IconButton sx={{position: 'absolute', top: 5, right: 5, border: 'none !important'}} onClick={handleClose}>
					<Close />
				</IconButton>
				<Pricing />
			</Box>
		</Dialog>
	);
}
