import {Delete, Share} from '@mui/icons-material';
import {CircularProgress, ListItemIcon, Menu, MenuItem, MenuList} from '@mui/material';
import {useAccount} from '../../../../AccountContext/AccountContext';

export default function OptionsMenu({revokeLoading, isUserQuestions, anchorEl, handleDelete, handleShare, handleRevoke, handleClone, onClose, cloneLoading}) {
	const {planPermissions} = useAccount();
	return (
		<>
			<Menu open={true} anchorEl={anchorEl} onClose={onClose}>
				{isUserQuestions ? (
					<MenuList dense disablePadding>
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('questions') || planPermissions.permissions.includes('questions.share')) && (
							<MenuItem onClick={handleShare}>
								<ListItemIcon>
									<Share color='primary' fontSize='small' />
								</ListItemIcon>
								Share
							</MenuItem>
						)}
						<MenuItem onClick={handleDelete}>
							<ListItemIcon>
								<Delete color='error' fontSize='small' />
							</ListItemIcon>
							Delete
						</MenuItem>
					</MenuList>
				) : (
					<MenuList dense disablePadding>
						<MenuItem onClick={handleClone}>
							{cloneLoading && (
								<ListItemIcon>
									<CircularProgress size={20} />
								</ListItemIcon>
							)}
							Clone Question
						</MenuItem>
						<MenuItem onClick={handleRevoke}>
							{revokeLoading && (
								<ListItemIcon>
									<CircularProgress size={20} />
								</ListItemIcon>
							)}
							Revoke Share
						</MenuItem>
					</MenuList>
				)}
			</Menu>
		</>
	);
}
