import {Button, Chip, Dialog, FormControl, InputLabel, MenuItem, Select, Slide, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import {connect} from 'react-redux';
import {TextEditor} from '../Tiptap/Editor';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {ColorPicker} from 'material-ui-color';
import GenericCard from './GenericCard';
import useAxios from 'axios-hooks';
import Env from '../../util/Env';
import {useAuth} from '../../AuthContext/AuthContext';
import {useSnackbar} from 'notistack';
import Validator from 'validator';
import {makeStyles} from '@mui/styles';
import UserSearchBox from './UserSearchBox';

const useStyles = makeStyles(() => ({
	showOpacityBox: {
		opacity: 1,
		maxHeight: 100,
		transition: '0.3s ease-in-out !important',
	},
	hideOpacityBox: {
		opacity: 0,
		maxHeight: 0,
		transition: '0.3s ease-in-out !important',
	},
}));

function AddAlertDialog({handleClose, onApplyIncrementNotifications}) {
	const {token} = useAuth();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const statuses = [
		{name: 'Global', value: 'global'},
		{name: 'Targeted', value: 'targeted'},
	];

	const [type, setType] = useState('');
	const [notificationContent, setNotificationContent] = useState('');
	const [date, setDate] = useState(new Date());
	const [expiryDate, setExpiryDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
	const [typeColor, setTypeColor] = useState();
	const [status, setStatus] = useState('global');
	const [typeBackground, setTypeBackground] = useState();
	const [selectedUser, setSelectedUser] = useState('');

	const [, addNewNotification] = useAxios({url: Env().url + '/notifications/notify', method: 'POST', headers: {Authorization: 'Bearer ' + token}}, {manual: true});

	const handleSelectUsers = (e, selectedValue) => {
		if ((selectedValue && Validator.isEmail(selectedValue)) || !selectedValue) {
			setSelectedUser(selectedValue);
		} else {
			enqueueSnackbar('invalid email address', {variant: 'error'});
		}
	};

	const handleChangeType = e => {
		setType(e.target.value);
	};
	const handleChangeTypeBackground = e => {
		setTypeBackground(e.css.backgroundColor);
	};
	const handleChangeTypeColor = e => {
		setTypeColor(e.css.backgroundColor);
	};

	const handleChangeStatus = e => {
		setStatus(e.target.value);
	};

	const handleAddNotification = () => {
		let data = {msg: notificationContent, ext: JSON.stringify({date: date, type: type, typeBackground: typeBackground, typeColor: typeColor}), expiresAt: expiryDate};
		addNewNotification({data: data})
			.then(() => {
				enqueueSnackbar('added!', {variant: 'success'});
				onApplyIncrementNotifications();
				handleClose();
			})
			.catch(err => {
				enqueueSnackbar(err.message, {variant: 'error'});
			});
	};

	const handleReset = () => {
		setType('');
		setNotificationContent('');
		setTypeBackground('');
		setTypeColor('');
	};
	const Actions = () => (
		<>
			<Button variant='contained' size='small' onClick={handleAddNotification} sx={{textTransform: 'none'}} disabled={!notificationContent}>
				Add
			</Button>
			<Button variant='outlined' size='small' onClick={handleReset} sx={{textTransform: 'none'}}>
				Reset
			</Button>
		</>
	);

	return (
		<Dialog open={true} onClose={() => null} maxWidth='md'>
			<GenericCard
				actions={<Actions />}
				handleClose={handleClose}
				title={
					<Typography variant='h6' fontWeight={600} fontFamily='Fredoka'>
						Add Notification
					</Typography>
				}
			>
				<>
					<Box display='flex' gap={2} flexWrap='wrap'>
						<FormControl size='small' variant='outlined'>
							<InputLabel>Status</InputLabel>

							<Select value={status} onChange={handleChangeStatus} label='Status'>
								{statuses.map((status, index) => (
									<MenuItem value={status.value} key={index} dense>
										{status.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Box>
							<TextField value={type} onChange={handleChangeType} label='Type' size='small' />
						</Box>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								renderInput={props => <TextField size='small' {...props} />}
								label='Date'
								value={date}
								onChange={newValue => {
									setDate(newValue);
								}}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								renderInput={props => <TextField size='small' {...props} />}
								label='Expiry Date'
								value={expiryDate}
								onChange={newValue => {
									setExpiryDate(newValue);
								}}
							/>
						</LocalizationProvider>
						<Box display='flex' alignItems='center'>
							<Chip label={type ? type : 'Test'} sx={{background: typeBackground, color: typeColor}} size='small' />
							<ColorPicker value={typeBackground} onChange={handleChangeTypeBackground} disableTextfield />
							<ColorPicker value={typeColor} onChange={handleChangeTypeColor} disableTextfield />
						</Box>
						<Slide in={status === 'targeted'} direction='right'>
							<Box className={status === 'targeted' ? classes.showOpacityBox : classes.hideOpacityBox} width='100%' display='flex'>
								<UserSearchBox handleSelectUsers={handleSelectUsers} selectedUser={selectedUser} />
							</Box>
						</Slide>
					</Box>
					<Box border='1px solid #EEE' pl={1} pr={1} pb={1} mt={1} height={300} overflow='auto' borderRadius={2} className='TiptapEditor' key='textEditor'>
						<TextEditor notes={notificationContent} setNotes={setNotificationContent} editable={true} extensions={['textStyles', 'heading', 'lists', 'image']} />
					</Box>
				</>
			</GenericCard>
		</Dialog>
	);
}

const mapDispatchToProps = dispatch => ({
	onApplyIncrementNotifications: () => dispatch({type: 'NOTIFICATIONS_INCREMENT'}),
});

export default connect(null, mapDispatchToProps)(AddAlertDialog);
