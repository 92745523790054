import {Box, Divider, Typography} from '@mui/material';
import {memo, useCallback, useEffect, useState} from 'react';
import {TabContext, TabPanel} from '@mui/lab';
import {makeStyles} from '@mui/styles';
import CandidatesTab from './Tabs/Candidates/CandidatesTab';
import ExamplesTab from './Tabs/Examples/ExamplesTab';
import {Assignment, Gesture, QuestionAnswer} from '@mui/icons-material';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import {StyledTab, StyledTabs} from '../common/CustomTabs';
import ReactGA from 'react-ga';
import UserQuestions from './Tabs/UserQuestions/UserQuestions';
import InternalErrorBoundaryPage from '../ErrorBoundary/InternalErrorBoudaryPage';
import {ErrorBoundary} from 'react-error-boundary';
import DrawTab from './Tabs/DrawTab';
import {useRealtime} from '../../RealtimeContext/RealtimeContext';
import {useAccount} from '../../AccountContext/AccountContext';

const useStyles = makeStyles(() => ({
	tabPanel: {
		padding: '5px !important',
	},
	button: {
		textTransform: 'none !important',
	},
	customQuestionPanel: {
		position: 'relative',
		display: 'flex',
	},
}));

export default memo(function AceTabs({tabsValue, setTabsValue}) {
	const classes = useStyles();
	const {realtime} = useRealtime();
	const {planPermissions} = useAccount();

	const [isExamplesTabBugged, setIsExamplesTabBugged] = useState(false);
	const [isCandidatesTabBugged, setIsCandidatesTabBugged] = useState(false);
	const [isQuestionsTabBugged, setIsQuestionsTabBugged] = useState(false);
	const [isDrawTabBugged, setIsDrawTabBugged] = useState(false);

	const handleChangeTab = useCallback(
		(event, newValue) => {
			ReactGA.event({
				category: 'Authorized user action',
				action: 'change top level tabs',
				value: newValue,
			});
			setTabsValue(newValue);
		},
		[setTabsValue]
	);
	useEffect(() => {
		if (realtime.candidateClicked) {
			setTabsValue(1);
		}
	}, [realtime.candidateClicked, setTabsValue]);

	return (
		<>
			<Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
				<Box>
					<TabContext value={tabsValue.toString()}>
						<StyledTabs value={tabsValue} onChange={handleChangeTab} aria-label='styled tabs example' variant='scrollable' scrollButtons='auto' sx={{height: 50}}>
							<StyledTab icon={<Assignment color='primary' />} iconPosition='start' label={'Simple-01'} wrapped />
							<StyledTab icon={<ListAltRoundedIcon color='primary' />} iconPosition='start' label={'Candidates'} wrapped />
							<StyledTab label={'Questions'} icon={<QuestionAnswer color='primary' />} iconPosition='start' wrapped />
							{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('draw') || planPermissions.permissions.includes('draw.accessable')) && <StyledTab label={'Draw'} icon={<Gesture color='primary' />} iconPosition='start' wrapped />}
						</StyledTabs>
						<Divider />
						<TabPanel value='0' className={classes.tabPanel}>
							<Box width='100%'>
								<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsExamplesTabBugged(false)} resetKeys={[isExamplesTabBugged]}>
									<ExamplesTab />
								</ErrorBoundary>
							</Box>
						</TabPanel>
						<TabPanel value='1' className={classes.tabPanel}>
							<Box width='100%'>
								<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsCandidatesTabBugged(false)} resetKeys={[isCandidatesTabBugged]}>
									<CandidatesTab />
								</ErrorBoundary>
							</Box>
						</TabPanel>
						<TabPanel value='2' className={classes.tabPanel}>
							<Box width='100%'>
								<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsQuestionsTabBugged(false)} resetKeys={[isQuestionsTabBugged]}>
									<UserQuestions />
								</ErrorBoundary>
							</Box>
						</TabPanel>
						{planPermissions && planPermissions.permissions && (planPermissions.permissions.includes('draw') || planPermissions.permissions.includes('draw.accessable')) && (
							<TabPanel value='3' className={classes.tabPanel}>
								<Box width='100%'>
									<ErrorBoundary FallbackComponent={InternalErrorBoundaryPage} onReset={() => setIsDrawTabBugged(false)} resetKeys={[isDrawTabBugged]}>
										<DrawTab height='800px' />
									</ErrorBoundary>
								</Box>
							</TabPanel>
						)}
						<Typography className={classes.padding} />
					</TabContext>
				</Box>
			</Box>
		</>
	);
});
