import React from 'react';
import {ClickAwayListener, Paper} from '@mui/material';
import {Arrow, Popper} from '../common/PopperFragments';
import UsersListComponent from '../Fragments/UsersListComponent';
import {useAuth} from '../../AuthContext/AuthContext';

export default function UsersPopper({open, onClose, anchor, placement, arrow, setTabsValue}) {
	const {loggedInUser} = useAuth();
	const [arrowRef, setArrowRef] = React.useState(null);
	const [isConnectedUsersSectionBugged, setIsConnectedUsersSectionBugged] = React.useState(false);
	const [isCurrentQuizSectionBugged, setIsCurrentQuizSectionBugged] = React.useState(false);
	const [isCurrentCandidateSectionBugged, setIsCurrentCandidateSectionBugged] = React.useState(false);

	return (
		<ClickAwayListener
			onClickAway={e => {
				if (open) {
					e.stopPropagation(false);
					onClose();
				}
			}}
		>
			<Popper
				keepMounted={true}
				open={open}
				anchorEl={anchor}
				placement={placement}
				modifiers={[
					{
						name: 'arrow',
						enabled: arrow,
						options: {
							element: arrowRef,
						},
					},
				]}
			>
				{arrow ? <Arrow className='MuiPopper-arrow' ref={setArrowRef} /> : null}
				<Paper>
					<UsersListComponent
						isConnectedUsersSectionBugged={isConnectedUsersSectionBugged}
						isCurrentCandidateSectionBugged={isCurrentCandidateSectionBugged}
						isCurrentQuizSectionBugged={isCurrentQuizSectionBugged}
						loggedInUser={loggedInUser}
						setIsConnectedUsersSectionBugged={setIsConnectedUsersSectionBugged}
						setIsCurrentCandidateSectionBugged={setIsCurrentCandidateSectionBugged}
						setIsCurrentQuizSectionBugged={setIsCurrentQuizSectionBugged}
						setTabsValue={setTabsValue}
					/>
				</Paper>
			</Popper>
		</ClickAwayListener>
	);
}
